<div class="calendar__zoom">
  <mat-icon
    class="svg-enlarged"
    svgIcon="zoom_out"
    (click)="zoomOut()"
    (keyup)="$event.stopPropagation()"
    [ngClass]="zoomLevel === 10 ? 'icon-disabled' : ''"
    [matTooltip]="zoomLevel === 10 ? '' : zoomOutTooltip"
    aria-label="zoom out"
  ></mat-icon>
  <mat-icon
    class="svg-enlarged"
    svgIcon="zoom_in"
    (click)="zoomIn()"
    (keyup)="$event.stopPropagation()"
    [ngClass]="zoomLevel === 100 ? 'icon-disabled' : ''"
    [matTooltip]="zoomLevel === 100 ? '' : zoomInTooltip"
    aria-label="zoom in"
  ></mat-icon>
</div>
