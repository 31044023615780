import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as jsonData from '../../../assets/config.json';
import { NavigationService } from './navigation.service';
import { InitializationService } from '../core/initialization-service';

@Injectable({ providedIn: 'root' })
export class HttpRequestInterceptor implements HttpInterceptor {
  configProperties: any = jsonData;

  constructor(
    private navigationService: NavigationService,
    private initializationService: InitializationService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.handleSpinners(request.method, true);
    this.navigationService.setErrorLoading(false);

    return next.handle(request).pipe(
      catchError((e) => {
        if (e instanceof HttpErrorResponse) {
          if (e.status === 403) {
            this.initializationService
              .logoutAuthenticatedUser(true)
              .then(() => {
                throw e;
              });
          } else if (e.status === 400) {
            const errorResponse: ErrorResponseDTO = e.error;
            console.error(errorResponse);
          }
        }
        this.handleSpinners(request.method, false);

        throw e;
      }),
      tap((e) => {
        if (e instanceof HttpResponse) {
          this.handleSpinners(request.method, false);
        }
      }),
    );
  }

  private handleSpinners(method: string, enable: boolean) {
    if (method === 'GET') {
      this.navigationService.setLoading(enable);
    } else {
      this.navigationService.setSending(enable);
    }
  }
}

interface ErrorResponseDTO {
  status: number;
  message: string;
  timestamp: string;
  path: string;
  method: string;
}
