import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JobPosition } from '../../model/job-position/job-position';
import { JobPositionLight } from '../../model/job-position/job-position-light';
import { CreateJobPositionRequest } from '../../model/job-position/create-job-position-request';
import { EditJobPositionRequest } from '../../model/job-position/edit-job-position-request';
import { ReorderObjectsRequest } from '../../model/general/reorder-objects-request';

@Injectable({ providedIn: 'root' })
export class JobPositionService {
  constructor(private http: HttpClient) {}

  getJobPositions(
    full: boolean,
  ): Observable<JobPosition[] | JobPositionLight[]> {
    const params = new HttpParams().set('full', full.toString());
    return this.http.get<JobPosition[] | JobPositionLight[]>('/api/position', {
      params,
    });
  }

  createJobPosition(
    createJobPositionRequest: CreateJobPositionRequest,
  ): Observable<JobPosition> {
    return this.http.post<JobPosition>(
      '/api/position',
      createJobPositionRequest,
    );
  }

  updateJobPosition(
    editJobPositionRequest: EditJobPositionRequest,
  ): Observable<JobPosition> {
    return this.http.put<JobPosition>(
      `/api/position/${editJobPositionRequest.jobPosition.id}`,
      editJobPositionRequest,
    );
  }

  deleteJobPosition(id: string): Observable<string[]> {
    return this.http.delete<string[]>(`/api/position/${id}`);
  }

  reorderJobPositions(
    reorderObjectsRequest: ReorderObjectsRequest,
  ): Observable<void> {
    return this.http.post<void>('/api/position/reorder', reorderObjectsRequest);
  }
}
