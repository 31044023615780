<ng-container *ngIf="dataLoaded && !errorLoadingData">
  <mat-tab-group color="accent" [(selectedIndex)]="selectedTabIndex">
    <mat-tab [disabled]="noStoresAvailable || generationInProgress">
      <ng-template mat-tab-label>
        <span>{{ "calculate" | translate }}</span>
      </ng-template>

      <ng-container *ngIf="!noStoresAvailable">
        <mat-card class="mat-mdc-card-outlined">
          <mat-card-header>
            <mat-card-title
              >{{ "calculationParameters" | translate }}
            </mat-card-title>
          </mat-card-header>

          <mat-card-actions class="formed__actions">
            <form
              [formGroup]="scheduleForm"
              *ngIf="scheduleForm"
              class="form__selectable-criteria"
            >
              <div>
                <label for="storesChips" class="underlined__text"
                  >{{ "store" | translate }}</label
                >
                <mat-chip-listbox id="storesChips">
                  <mat-chip-option
                    *ngFor="
                      let s of stores;
                      let i = index;
                      trackBy: trackByStoreSchedule
                    "
                    [selected]="s === selectedStore"
                    (click)="selectStore(s)"
                    (keyup)="$event.stopPropagation()"
                    (keydown)="$event.stopPropagation()"
                    #storesChips
                  >
                    <span>{{ s.name }}</span>
                  </mat-chip-option>
                </mat-chip-listbox>
              </div>

              <mat-form-field class="calendar">
                <mat-label>{{ "dateRange" | translate }}</mat-label>
                <mat-date-range-input
                  [min]="today"
                  [rangePicker]="picker"
                  (click)="picker.open()"
                  (keydown)="$event.stopPropagation()"
                  (keyup)="$event.stopPropagation()"
                >
                  <input readonly matStartDate formControlName="startDate" />
                  <input readonly matEndDate formControlName="endDate" />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker
                  #picker
                  disabled="false"
                ></mat-date-range-picker>

                <mat-error
                  *ngIf="
                    scheduleForm.controls['endDate']?.hasError('dateRange')
                  "
                >
                  {{ "dateRangeExceedsLimit" | translate }}
                </mat-error>
              </mat-form-field>
              <mat-slide-toggle formControlName="slowSolver">
                {{ "slowSolverPlaceholder" | translate }}
              </mat-slide-toggle>
            </form>
          </mat-card-actions>
        </mat-card>

        <mat-card class="form__actions-waved">
          <mat-card-content class="separate__container-actions">
            <button
              (click)="generateSchedule()"
              class="primary-button svg__icon-button"
              mat-flat-button
              [disabled]="
                !scheduleForm ||
                !scheduleForm.valid ||
                requestSent ||
                generationInProgress ||
                foundOverlappingShifts
              "
            >
              <mat-icon
                aria-label="generate"
                svgIcon="schedule_generate"
              ></mat-icon>
              {{ "calculate" | translate }}
            </button>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </mat-tab>

    <mat-tab [disabled]="noStoresAvailable || !generationInProgress">
      <ng-template mat-tab-label>
        <span>{{ "schedule" | translate }}</span>
      </ng-template>

      <ng-container *ngIf="!noStoresAvailable">
        <mat-card class="mat-mdc-card-outlined">
          <mat-card-header class="schedule__generation-header">
            <mat-card-title>
              <span
                >{{ shiftsLoaded ? ("generatedSchedule" | translate) :
                ("calculationParameters" | translate) }}</span
              >
              <div class="generation-details">
                <span>
                  {{ selectedStore.name }}: {{ startDate }} - {{ endDate }} ({{
                  slowSolver ? ("withSlowAlgorithm" | translate) :
                  ("withQuickAlgorithm" | translate) }}).
                </span>

                <span *ngIf="scheduleProblemShiftsCount > 0">
                  {{ "shiftsInGeneration" | translate }}: {{
                  scheduleProblemShiftsCount }}
                </span>

                <span
                  *ngIf="scheduleProblemReducedShiftsCount > 0"
                  style="font-style: italic"
                >
                  {{ scheduleProblemReducedShiftsCount }} {{
                  "shiftsReducedDueOnLeave" | translate }}
                </span>
              </div>
            </mat-card-title>
            <mat-icon
              class="generation-cancel"
              (click)="cancelSchedule()"
              (keyup)="$event.stopPropagation()"
              matTooltip="{{ 'cancel' | translate }}"
              aria-label="cancel"
              svgIcon="schedule_cancel"
            ></mat-icon>
          </mat-card-header>

          <mat-card-content
            *ngIf="shiftsLoaded"
            [@expandCollapse]="shiftsLoaded ? 'expanded' : 'collapsed'"
          >
            <mat-divider></mat-divider>
            <div class="schedule__table-selections">
              <mat-chip-listbox>
                <mat-chip-option
                  *ngFor="
                    let s of scheduleTableOptions;
                    let i = index;
                    trackBy: trackByChip
                  "
                  [selected]="s.name === currentTableSelection"
                  (click)="changeTableOption(s)"
                  (keyup)="$event.stopPropagation()"
                  (keydown)="$event.stopPropagation()"
                  #scheduleTableChipOptions
                >
                  <span
                    class="chip__avatar-img"
                    [innerHTML]="s.svg | svgSanitizerMedium"
                  ></span>
                  <span>{{ s.name | translate }} </span>
                </mat-chip-option>
              </mat-chip-listbox>
              <div class="schedule__table-selections-actions">
                <button
                  class="stroked__icon-button"
                  type="button"
                  mat-icon-button
                  matTooltip="{{ 'scrollDown' | translate }}"
                  (click)="scrollToBottomSmoothly()"
                >
                  <mat-icon aria-label="down">arrow_downward</mat-icon>
                </button>
                <button
                  class="stroked__icon-button stroked__icon-button-heavier"
                  type="button"
                  mat-icon-button
                  [disabled]="!previewEnabled"
                  [ngClass]="previewEnabled ? '' : 'lower__opacity'"
                  matTooltip="{{ 'preview' | translate }}"
                  (click)="previewScheduleCalendar()"
                >
                  <mat-icon
                    aria-label="preview"
                    svgIcon="eye_preview"
                  ></mat-icon>
                </button>
              </div>
            </div>
            <mat-divider></mat-divider>

            <ng-container
              *ngIf="
                currentTableSelection === scheduleTableOptions[0].name;
                else employeeShiftsView
              "
            >
              <div class="table__container">
                <table
                  mat-table
                  [dataSource]="generatedShifts"
                  matSort
                  matSortDisableClear
                  (matSortChange)="sortShifts($event, true)"
                >
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let shift">
                      {{ shift.name ? shift.name : "" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ "startDate" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let shift">
                      {{ formatDate(shift.startDate) }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ "endDate" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let shift">
                      {{ formatDate(shift.endDate) }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="totalDuration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ "totalDuration" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let shift">
                      {{ shift.hoursDuration }}<span
                        *ngIf="shift.minutesDuration > 0"
                        >:{{ formatScheduleMinutes(shift.minutesDuration)
                        }}</span
                      >
                      {{ "hours" | translate }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="jobPositionName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ "jobPosition" | translate }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let shift"
                      [style.color]="
                        customColorService.getColor(
                          shift.jobPositionPreferredColor
                        )
                      "
                    >
                      {{ shift.jobPositionName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="employee">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "employee" | translate }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let shift"
                      class="schedule__employees_selection"
                    >
                      <div class="table__td-flex">
                        <div
                          class="legend__color"
                          [style.background-color]="
                            shift.employee.preferredColor
                          "
                        ></div>
                        <mat-select
                          panelClass="schedule__employees_select-box"
                          [(value)]="shift.employee"
                          [compareWith]="compareEmployees"
                          (selectionChange)="
                            onEmployeeSelectionChange(shift, $event)
                          "
                        >
                          <mat-option
                            class="schedule__employees_option"
                            *ngFor="
                              let employee of storeEmployees;
                              let i = index;
                              trackBy: trackByEmployeeLight
                            "
                            [value]="employee"
                          >
                            {{ employee.firstName }} {{ employee.lastName }}
                          </mat-option>
                        </mat-select>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="information">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ "information" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let shift">
                      <app-shift-column
                        [shift]="shift"
                        [storeEmployees]="storeEmployees"
                        [initialShiftsEmployeesMap]="initialShiftsEmployeesMap"
                      ></app-shift-column>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="shiftsDisplayedColumns"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: shiftsDisplayedColumns"
                    [@listAnimation]
                  ></tr>
                </table>
              </div>
            </ng-container>

            <ng-template #employeeShiftsView>
              <div class="table__container">
                <table
                  mat-table
                  [dataSource]="scheduleEmployees"
                  matSort
                  matSortDisableClear
                  matSortActive="totalAssignments"
                  matSortDirection="desc"
                  (matSortChange)="sortEmployees($event)"
                >
                  <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ "firstName" | translate }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let employeeAssignment"
                      [style.color]="employeeAssignment.employee.preferredColor"
                    >
                      {{ employeeAssignment.employee.firstName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ "lastName" | translate }}
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let employeeAssignment"
                      [style.color]="employeeAssignment.employee.preferredColor"
                    >
                      {{ employeeAssignment.employee.lastName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="contractHours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ "contractHours" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let employeeAssignment">
                      <mat-chip-listbox
                        *ngIf="employeeAssignment.contract.weeklyHours !== null"
                      >
                        <mat-chip class="surface__chip" disableRipple>
                          {{ employeeAssignment.contract.weeklyHours ? ("weekly"
                          | translate) : ("monthly" | translate) }}: {{
                          employeeAssignment.contract.minHours ===
                          employeeAssignment.contract.maxHours ?
                          employeeAssignment.contract.maxHours :
                          employeeAssignment.contract.minHours + ("to" |
                          translate) + employeeAssignment.contract.maxHours }}
                        </mat-chip>
                      </mat-chip-listbox>
                      <mat-chip-listbox
                        *ngIf="employeeAssignment.contract.weeklyHours === null"
                      >
                        <mat-chip disabled>{{ "none" | translate }}</mat-chip>
                      </mat-chip-listbox>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="totalAssignments">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ "totalAssignments" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let employeeAssignment">
                      <mat-chip-listbox>
                        <mat-chip class="surface__chip" disableRipple>
                          {{ employeeAssignment.totalHours }}<span
                            *ngIf="employeeAssignment.totalMinutes > 0"
                            >:{{ formatScheduleMinutes(
                            employeeAssignment.totalMinutes) }}</span
                          >
                          {{ "hours" | translate }}
                        </mat-chip>
                      </mat-chip-listbox>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="information">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ "information" | translate }}
                    </th>
                    <td mat-cell *matCellDef="let employeeAssignment">
                      <app-employee-column
                        [employee]="employeeAssignment"
                      ></app-employee-column>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="employeesDisplayedColumns"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: employeesDisplayedColumns"
                    [@listAnimation]
                  ></tr>
                </table>
              </div>
            </ng-template>
          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="!shiftsLoaded" class="svg__panel">
          <img
            srcset="/assets/svg/illustrator/schedule_loading.svg"
            alt="schedule loading"
            class="loading__animation"
            [@slideOut]="shiftsLoadingAnimationOut ? 'out' : 'in'"
          />
        </mat-card>

        <mat-card *ngIf="shiftsLoaded">
          <mat-card-content class="schedule__finalize-actions">
            <mat-slide-toggle
              [(ngModel)]="scheduleNotification"
              (change)="scheduleNotificationChange($event)"
              >{{ "notifyEmployees" | translate }}
            </mat-slide-toggle>

            <div class="split__button">
              <button
                class="main__button primary-button svg__icon-button"
                mat-flat-button
                [disabled]="requestSent"
                (click)="regenerateSchedule()"
              >
                <mat-icon
                  aria-label="regenerate"
                  svgIcon="schedule_regenerate"
                ></mat-icon>
                <span>{{ "regenerateSchedule" | translate }}</span>
              </button>
              <button
                class="secondary__button tertiary-button svg__icon-button"
                mat-flat-button
                [disabled]="requestSent"
                (click)="finalizeSchedule()"
              >
                <mat-icon aria-label="save" svgIcon="schedule_save"></mat-icon>
                <span>{{ "finalize" | translate }}</span>
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span>{{ "explanations" | translate }}</span>
      </ng-template>
      <app-schedule-explanations></app-schedule-explanations>
    </mat-tab>
  </mat-tab-group>

  <app-scroll-to-top></app-scroll-to-top>
</ng-container>
