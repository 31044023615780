<div class="flex__box dialog__header" mat-dialog-title>
  <span></span>
  <div class="dialog__header-actions">
    <button
      (click)="dialogRef.close()"
      aria-label="close"
      class="stroked__icon-button"
      mat-icon-button
      type="button"
      matTooltip="{{ 'close' | translate }}"
    >
      <mat-icon aria-label="close">close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content class="schedule__violations">
  <span class="violation__title"> {{ "violationsTitle" | translate }} </span>

  <div *ngFor="let v of violations" class="violation__message">
    <span><mat-icon aria-label="report">warning</mat-icon></span>
    <span>{{ "violation." + v | translate }}</span>
  </div>
</mat-dialog-content>
