<ng-container *ngIf="dataLoaded && !errorLoadingData">
  <app-wave-background></app-wave-background>

  <div class="wave__background-after">
    <mat-card appearance="outlined" class="search__bar">
      <mat-form-field
        appearance="outline"
        class="search-bar-form-field"
        color="primary"
      >
        <input
          [formControl]="searchControl"
          matInput
          placeholder="{{ 'searchEmployeeBy' | translate }}"
          type="text"
        />
        <button
          (click)="$event.stopPropagation(); searchControl.reset()"
          [disabled]="currentSearchTerm === ''"
          [ngClass]="
            currentSearchTerm === '' ? 'mat__icon-button-disabled' : ''
          "
          mat-icon-button
          type="button"
          matTooltip="{{ 'clear' | translate }}"
          matSuffix
        >
          <mat-icon aria-label="close">close</mat-icon>
        </button>
        <span class="mini__separator" matSuffix></span>
        <button
          (click)="$event.stopPropagation(); expandCriteria = !expandCriteria"
          mat-icon-button
          type="button"
          matTooltip="{{ 'filters' | translate }}"
          matSuffix
        >
          <mat-icon aria-label="actions">more_vert</mat-icon>
        </button>
      </mat-form-field>
    </mat-card>

    <mat-card class="card__transparent">
      <mat-card-header
        [@slideToggle]="expandCriteria ? 'true' : 'false'"
        [style.pointer-events]="expandCriteria ? 'auto' : 'none'"
      >
        <form class="form__selectable-criteria formed__header-actions">
          <ng-container *ngIf="stores.length > 1">
            <div class="partial__panel">
              <label for="storeChips" class="label__bold">{{
                "stores" | translate
              }}</label>
              <mat-chip-listbox id="storeChips" multiple selectable>
                <mat-chip-option
                  #storeChips
                  *ngFor="
                    let store of stores;
                    let i = index;
                    trackBy: trackByStoreLight
                  "
                  (click)="clickStoreChip(store)"
                  (keydown.enter)="clickStoreChip(store)"
                  (keydown.space)="clickStoreChip(store)"
                  class="surface__chip-bold white__chip"
                >
                  <span class="always__white">{{ store.name }}</span>
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
          </ng-container>

          <ng-container *ngIf="jobPositions.length > 1">
            <div class="partial__panel">
              <label for="jobPositionChips" class="label__bold">{{
                "jobPositions" | translate
              }}</label>
              <mat-chip-listbox id="jobPositionChips" multiple selectable>
                <mat-chip-option
                  #jobPositionChips
                  [style.background-color]="
                    customColorService.getColor(jobPosition.preferredColor)
                  "
                  class="white__chip"
                  (click)="clickJobPositionChip(jobPosition)"
                  (keydown.enter)="clickJobPositionChip(jobPosition)"
                  (keydown.space)="clickJobPositionChip(jobPosition)"
                  *ngFor="
                    let jobPosition of jobPositions;
                    let i = index;
                    trackBy: trackByJobPositionLight
                  "
                >
                  <span class="always__white">{{ jobPosition.name }}</span>
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
          </ng-container>

          <mat-slide-toggle
            [disabled]="!expandCriteria"
            (change)="
              retrieveEmployeesDTO.showDisabled =
                !retrieveEmployeesDTO.showDisabled;
              getEmployees()
            "
            >{{ "showDisabledEmployees" | translate }}
          </mat-slide-toggle>
        </form>

        <mat-divider></mat-divider>
      </mat-card-header>

      <mat-card-content>
        <ng-container *ngIf="expandCriteria">
          <mat-divider></mat-divider>
        </ng-container>

        <div class="employees__table table__container">
          <table
            (matSortChange)="sortEmployees($event)"
            [dataSource]="dataSource"
            mat-table
            matSort
            matSortDisableClear
            multiTemplateDataRows
          >
            <ng-container matColumnDef="avatar">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef mat-cell class="td__icon-40">
                <mat-icon
                  aria-label="avatar"
                  svgIcon="employee_avatar"
                ></mat-icon>
              </td>
            </ng-container>
            <ng-container matColumnDef="firstName">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>
                {{ "firstName" | translate }}
              </th>
              <td
                *matCellDef="let employee"
                mat-cell
                class="heavier__row"
                [style.color]="employee.preferredColor"
              >
                {{ employee.firstName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="lastName">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>
                {{ "lastName" | translate }}
              </th>
              <td
                *matCellDef="let employee"
                mat-cell
                class="heavier__row"
                [style.color]="employee.preferredColor"
              >
                {{ employee.lastName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="stores">
              <th *matHeaderCellDef mat-header-cell>
                {{ "stores" | translate }}
              </th>
              <td *matCellDef="let employee" mat-cell>
                <mat-chip-listbox>
                  <mat-chip
                    *ngFor="
                      let store of employee.stores;
                      let i = index;
                      trackBy: trackByStoreLight
                    "
                    class="surface__chip-bold"
                    disableRipple
                    ><span class="always__white">{{ store.name }}</span>
                  </mat-chip>
                </mat-chip-listbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="jobPositions">
              <th *matHeaderCellDef mat-header-cell>
                {{ "jobPositions" | translate }}
              </th>
              <td *matCellDef="let employee" mat-cell>
                <mat-chip-listbox>
                  <mat-chip
                    *ngFor="
                      let jobPosition of employee.jobPositions;
                      let i = index;
                      trackBy: trackByJobPositionLight
                    "
                    [style.background-color]="
                      customColorService.getColor(jobPosition.preferredColor)
                    "
                    disableRipple
                    ><span class="always__white">{{ jobPosition.name }}</span>
                  </mat-chip>
                </mat-chip-listbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="disabled">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>
                {{ "disabled" | translate }}
              </th>
              <td *matCellDef="let employee" mat-cell>
                <mat-checkbox
                  [(ngModel)]="employee.disabled"
                  disabled
                ></mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="updatedAt">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>
                {{ "lastChange" | translate }}
              </th>
              <td *matCellDef="let employee" mat-cell>
                {{
                  datepickerService.getDateTimeInStringFormatForUI(
                    employee.updatedAt
                  )
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="expand">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let element" mat-cell>
                <div style="display: flex; flex-direction: row-reverse">
                  <button
                    (click)="
                      expandedEmployee =
                        expandedEmployee === element ? null : element;
                      $event.stopPropagation()
                    "
                    mat-icon-button
                    type="button"
                    matTooltip="{{
                      (expandedEmployee === element ? 'collapse' : 'expand')
                        | translate
                    }}"
                  >
                    <mat-icon
                      aria-label="collapse"
                      *ngIf="expandedEmployee === element"
                      >keyboard_arrow_up
                    </mat-icon>
                    <mat-icon
                      aria-label="expand"
                      *ngIf="expandedEmployee !== element"
                      >keyboard_arrow_down
                    </mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <td
                *matCellDef="let employee"
                [attr.colspan]="columnsToDisplayWithExpand.length"
                mat-cell
              >
                <div
                  [@expandDown]="
                    employee === expandedEmployee ? 'expanded' : 'collapsed'
                  "
                  class=""
                >
                  <div class="employee__actions" *ngIf="isOwner">
                    <button
                      mat-flat-button
                      routerLink="/employee/{{ expandedEmployee?.id ?? '' }}"
                    >
                      <mat-icon
                        svgIcon="edit_pencil"
                        aria-label="edit"
                      ></mat-icon>
                      {{ "edit" | translate }}
                    </button>

                    <div class="further__actions">
                      <button
                        mat-stroked-button
                        routerLink="/employee/availabilities/{{
                          expandedEmployee?.id ?? ''
                        }}"
                      >
                        <mat-icon
                          svgIcon="calendar_plain"
                          aria-label="availabilities"
                        ></mat-icon>
                        {{ "availabilities" | translate }}
                      </button>

                      <button
                        mat-stroked-button
                        routerLink="/employee/pairings/{{
                          expandedEmployee?.id ?? ''
                        }}"
                      >
                        <mat-icon
                          style="width: 30px; height: 30px"
                          svgIcon="employee_pairings"
                          aria-label="pairings"
                        ></mat-icon>
                        {{ "pairings" | translate }}
                      </button>
                    </div>
                  </div>

                  <div class="employee__column-xs expanded__employee-chips">
                    <span class="tr__like">{{ "stores" | translate }}</span>
                    <mat-chip-listbox>
                      <mat-chip
                        *ngFor="
                          let store of employee.stores;
                          let i = index;
                          trackBy: trackByStoreLight
                        "
                        class="surface__chip-bold"
                        disableRipple
                        ><span class="always__white">{{ store.name }}</span>
                      </mat-chip>
                    </mat-chip-listbox>
                  </div>

                  <div class="employee__column-sm expanded__employee-chips">
                    <span class="tr__like">{{
                      "jobPositions" | translate
                    }}</span>
                    <mat-chip-listbox>
                      <mat-chip
                        *ngFor="
                          let jobPosition of employee.jobPositions;
                          let i = index;
                          trackBy: trackByJobPositionLight
                        "
                        [style.background-color]="
                          customColorService.getColor(
                            jobPosition.preferredColor
                          )
                        "
                        disableRipple
                        ><span class="always__white">{{
                          jobPosition.name
                        }}</span>
                      </mat-chip>
                    </mat-chip-listbox>
                  </div>

                  <div class="expanded__employee-info">
                    <mat-card
                      appearance="outlined"
                      class="custom__card custom__card-info employee__column-md"
                    >
                      <div class="custom__card-body">
                        <div class="custom__card-container">
                          <div>
                            <mat-icon
                              aria-label="info"
                              svgIcon="employee_enabled"
                            ></mat-icon>
                          </div>
                          <div class="custom__card-section">
                            <mat-card-header>
                              <mat-card-title
                                >{{ "disabled" | translate }}
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <p>
                                {{
                                  (employee.disabled ? "yes" : "no") | translate
                                }}
                              </p>
                            </mat-card-content>
                          </div>
                        </div>
                      </div>
                    </mat-card>

                    <ng-container *ngIf="employee.user.email; else noUser">
                      <mat-card
                        class="custom__card custom__card-info"
                        appearance="outlined"
                      >
                        <div class="custom__card-body">
                          <div class="custom__card-container">
                            <div>
                              <mat-icon
                                aria-label="email"
                                svgIcon="employee_access"
                              >
                              </mat-icon>
                            </div>
                            <div class="custom__card-section">
                              <mat-card-header>
                                <mat-card-title
                                  >{{ "systemUser" | translate }}
                                </mat-card-title>
                              </mat-card-header>
                              <mat-card-content>
                                <p>{{ employee.user.email }}</p>
                              </mat-card-content>
                            </div>
                          </div>
                        </div>
                      </mat-card>
                    </ng-container>

                    <ng-template #noUser>
                      <mat-card
                        class="custom__card custom__card-info"
                        appearance="outlined"
                      >
                        <div class="custom__card-body">
                          <div class="custom__card-container">
                            <div>
                              <mat-icon
                                aria-label="user"
                                svgIcon="employee_no_access"
                              ></mat-icon>
                            </div>
                            <div class="custom__card-section">
                              <mat-card-header>
                                <mat-card-title
                                  >{{ "systemUser" | translate }}
                                </mat-card-title>
                              </mat-card-header>
                              <mat-card-content>
                                <p>{{ "no" | translate }}</p>
                              </mat-card-content>
                            </div>
                          </div>
                        </div>
                      </mat-card>
                    </ng-template>

                    <mat-card
                      appearance="outlined"
                      class="custom__card custom__card-info"
                    >
                      <div class="custom__card-body">
                        <div class="custom__card-container">
                          <div>
                            <mat-icon
                              aria-label="history"
                              svgIcon="employee_creation"
                            ></mat-icon>
                          </div>
                          <div class="custom__card-section">
                            <mat-card-header>
                              <mat-card-title
                                >{{ "creationDate" | translate }}
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <p>
                                {{
                                  datepickerService.getDateTimeInStringFormatForUI(
                                    employee.createdAt
                                  )
                                }}
                              </p>
                            </mat-card-content>
                          </div>
                        </div>
                      </div>
                    </mat-card>

                    <mat-card
                      appearance="outlined"
                      class="custom__card custom__card-info employee__column-md"
                    >
                      <div class="custom__card-body">
                        <div class="custom__card-container">
                          <div>
                            <mat-icon
                              aria-label="history"
                              svgIcon="employee_edit"
                            >
                            </mat-icon>
                          </div>
                          <div class="custom__card-section">
                            <mat-card-header>
                              <mat-card-title
                                >{{ "lastChange" | translate }}
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <p>
                                {{
                                  datepickerService.getDateTimeInStringFormatForUI(
                                    employee.updatedAt
                                  )
                                }}
                              </p>
                            </mat-card-content>
                          </div>
                        </div>
                      </div>
                    </mat-card>
                  </div>

                  <mat-card
                    appearance="outlined"
                    class="custom__card custom__card-info"
                  >
                    <div class="custom__card-body">
                      <div class="custom__card-container">
                        <div>
                          <mat-icon
                            aria-label="history"
                            svgIcon="employee_contract"
                          >
                          </mat-icon>
                        </div>
                        <div class="custom__card-section">
                          <mat-card-header>
                            <mat-card-title
                              >{{ "contractHours" | translate }}
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <ng-container
                              *ngIf="
                                employee.contract?.weeklyHours !== null;
                                else noContractHours
                              "
                            >
                              <p style="text-align: left !important">
                                {{
                                  employee.contract.weeklyHours
                                    ? ("weekly" | translate)
                                    : ("monthly" | translate)
                                }}:
                                {{
                                  employee.contract.minHours ===
                                  employee.contract.maxHours
                                    ? employee.contract.maxHours
                                    : employee.contract.minHours +
                                      ("to" | translate) +
                                      employee.contract.maxHours
                                }}
                                {{ "hours" | translate }}
                              </p>
                            </ng-container>
                            <ng-template #noContractHours>
                              <p style="text-align: left !important">
                                {{ "none" | translate }}
                              </p>
                            </ng-template>
                          </mat-card-content>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </td>
            </ng-container>

            <tr
              *matHeaderRowDef="columnsToDisplayWithExpand"
              mat-header-row
            ></tr>
            <tr
              (click)="expandedEmployee = expandedEmployee === row ? null : row"
              (keyup)="$event.stopPropagation()"
              (keydown)="$event.stopPropagation()"
              *matRowDef="let row; columns: columnsToDisplayWithExpand"
              [class.expanded__row]="expandedEmployee === row"
              class="expandable__element-row"
              mat-row
            ></tr>
            <tr
              *matRowDef="let row; columns: ['expandedDetail']"
              class="expandable__details-row"
              mat-row
            ></tr>
          </table>
        </div>

        <mat-paginator
          (page)="pageResults($event)"
          *ngIf="!requestSent"
          [length]="totalElements"
          [pageIndex]="currentPage"
          [pageSizeOptions]="pageSizeOptions"
          [pageSize]="pageSize"
          [showFirstLastButtons]="true"
        >
        </mat-paginator>

        <div *ngIf="requestSent" class="table__loading-spinner">
          <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <ng-container *ngIf="isOwner; else scrollUp">
    <div class="overlay__button-group">
      <app-scroll-to-top [buttonOnly]="true"></app-scroll-to-top>

      <button
        routerLink="/employee"
        color="accent"
        mat-fab
        matTooltip="{{ 'addNew' | translate }}"
        matTooltipPosition="left"
      >
        <mat-icon aria-label="add" class="always__white">add</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-template #scrollUp>
    <app-scroll-to-top></app-scroll-to-top>
  </ng-template>
</ng-container>
