import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  MatDialogActions,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { UserService } from '../../../../services/crud/user.service';
import { NotificationService } from '../../../../services/common/notification.service';
import { MatDivider } from '@angular/material/divider';
import { MatButton } from '@angular/material/button';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { AppLanguage } from '../../../../model/app/app-language';
import * as jsonData from '../../../../../assets/config.json';
import { LanguageLoaderService } from '../../../../services/core/language-loader';
import { MatChipOption, MatChipsModule } from '@angular/material/chips';
import { SvgSanitizerMedium } from '../../../../utils/svg-sanitizer-medium';
import { TermsEl } from './languages/el/terms-el/terms-el';
import { TermsEn } from './languages/en/terms-en/terms-en';

@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDivider,
    NgIf,
    TranslateModule,
    MatButton,
    MatDialogActions,
    MatProgressSpinner,
    MatChipsModule,
    NgForOf,
    SvgSanitizerMedium,
    TermsEl,
    TermsEn,
  ],
  templateUrl: './terms-and-conditions-dialog.html',
})
export class TermsAndConditionsDialog implements OnInit {
  readonly configProperties: any = jsonData;
  languages: AppLanguage[] = [];
  currentLanguage: string = 'en';

  requestSent = false;

  @ViewChildren('languageChipOptions')
  languageChipOptions: QueryList<MatChipOption>;

  constructor(
    public dialogRef: MatDialogRef<TermsAndConditionsDialog>,
    private userService: UserService,
    private notificationService: NotificationService,
    private languageLoaderService: LanguageLoaderService,
  ) {}

  ngOnInit() {
    this.languages = this.languageLoaderService.loadLanguages();
  }

  changeLang(lang: AppLanguage) {
    if (lang.name === this.currentLanguage) {
      const index = this.languages.indexOf(lang);
      const chipToSelect = this.languageChipOptions.toArray()[index];
      chipToSelect.selected = true;
    } else {
      this.currentLanguage = lang.name;
    }
  }

  acceptTerms() {
    this.requestSent = true;
    this.userService
      .acceptTermsAndConditions({ lang: this.currentLanguage })
      .subscribe({
        next: () => {
          this.notificationService.showTimedMessage(
            'termsAndConditionsAccepted',
          );
          this.dialogRef.close();
        },
        error: () => {
          this.requestSent = false;
          this.notificationService.showErrorMessage('dataError');
        },
      });
  }
}
