import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EmployeeService } from '../../services/crud/employee.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {
  AsyncPipe,
  LowerCasePipe,
  NgClass,
  NgFor,
  NgIf,
} from '@angular/common';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { Employee } from '../../model/employee/employee';
import { NotificationService } from '../../services/common/notification.service';
import * as jsonData from '../../../assets/config.json';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipOption, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { StoreService } from '../../services/crud/store.service';
import { FilterEmployeesRequest } from '../../model/employee/filter-employees-request';
import { MatDialog } from '@angular/material/dialog';
import { Spinner } from '../shared/spinner/spinner';
import { JobPositionService } from '../../services/crud/job-position.service';
import { JobPositionLight } from '../../model/job-position/job-position-light';
import { StoreLight } from '../../model/store/store-light';
import { catchError, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { RouterLink } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NavigationService } from '../../services/common/navigation.service';
import { ScrollToTop } from '../shared/scroll-to-top/scroll-to-top';
import { InitializationService } from '../../services/core/initialization-service';
import { expandDown, slideInOut, slideToggle } from '../../utils/animations';
import {
  trackByJobPositionLight,
  trackByStoreLight,
} from '../../utils/common-utils';
import { DatepickerService } from '../../services/common/datepicker.service';
import { CustomColorService } from '../../services/common/custom-color-service';
import { WaveBackground } from '../shared/wave-background/wave-background';

@Component({
  selector: 'app-employees',
  standalone: true,
  imports: [
    Spinner,
    TranslateModule,
    MatIconModule,
    MatAccordion,
    MatExpansionModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    FormsModule,
    MatTooltipModule,
    NgClass,
    MatPaginatorModule,
    MatProgressSpinner,
    NgIf,
    NgFor,
    MatButtonModule,
    MatCardModule,
    MatListModule,
    RouterLink,
    AsyncPipe,
    ScrollToTop,
    LowerCasePipe,
    WaveBackground,
  ],
  templateUrl: './employees.html',
  animations: [expandDown, slideToggle, slideInOut],
})
export class Employees implements OnInit, AfterViewInit, OnDestroy {
  readonly configProperties: any = jsonData;

  readonly employeeTableMediumBreakpoint =
    this.configProperties.employeeTableMediumBreakpoint;
  readonly employeeTableSmallBreakpoint =
    this.configProperties.employeeTableSmallBreakpoint;
  readonly employeeTableExtraSmallBreakpoint =
    this.configProperties.employeeTableExtraSmallBreakpoint;

  isOwner: boolean = false;

  public dataSource = new MatTableDataSource<Employee>();
  public displayedColumns_largeScreen: string[] = [
    'avatar',
    'firstName',
    'lastName',
    'stores',
    'jobPositions',
    'disabled',
    'updatedAt',
  ];
  public displayedColumns_mediumScreen: string[] = [
    'avatar',
    'firstName',
    'lastName',
    'stores',
    'jobPositions',
  ];
  public displayedColumns_smallScreen: string[] = [
    'avatar',
    'firstName',
    'lastName',
    'stores',
  ];
  public displayedColumns_extraSmallScreen: string[] = [
    'firstName',
    'lastName',
  ];

  public columnsToDisplayWithExpand = [
    ...this.displayedColumns_largeScreen,
    'expand',
  ];
  public expandedEmployee: Employee | null = null;

  readonly pageSizeOptions: number[] = this.configProperties.paginationOptions;
  public pageSize = this.pageSizeOptions[0];
  public totalElements = 0;
  public currentPage = 0;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChildren('storeChips') storeChipOptions: QueryList<MatChipOption>;

  @ViewChildren('jobPositionChips')
  jobPositionChipOptions: QueryList<MatChipOption>;

  dataLoaded: boolean = false;
  errorLoadingData: boolean = false;
  requestSent: boolean = false;

  searchControl = new FormControl();

  currentSearchTerm: string = '';

  stores: StoreLight[] = [];
  selectedStoreIds: string[] = [];

  jobPositions: JobPositionLight[] = [];
  selectedJobPositionIds: string[] = [];

  retrieveEmployeesDTO: FilterEmployeesRequest = {
    pageNo: 0,
    pageSize: this.pageSizeOptions[0],
    sortBy: '',
    sortDir: '',
    searchTerm: '',
    showDisabled: false,
    storeIds: [],
    jobPositionIds: [],
  };

  public expandCriteria: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    private employeesService: EmployeeService,
    private notificationService: NotificationService,
    private storeService: StoreService,
    public dialog: MatDialog,
    private jobPositionService: JobPositionService,
    private breakpointObserver: BreakpointObserver,
    private initializationService: InitializationService,
    private navigationService: NavigationService,
    protected datepickerService: DatepickerService,
    protected customColorService: CustomColorService,
  ) {}

  ngOnInit() {
    this.isOwner = this.initializationService.isAuthenticateUserOwner();

    this.loadData().subscribe(() => {
      this.dataLoaded = true;
    });

    this.breakpointObserver
      .observe([
        this.employeeTableMediumBreakpoint,
        this.employeeTableSmallBreakpoint,
        this.employeeTableExtraSmallBreakpoint,
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result.breakpoints[this.employeeTableExtraSmallBreakpoint]) {
          this.columnsToDisplayWithExpand = [
            ...this.displayedColumns_extraSmallScreen,
            'expand',
          ];
        } else if (result.breakpoints[this.employeeTableSmallBreakpoint]) {
          this.columnsToDisplayWithExpand = [
            ...this.displayedColumns_smallScreen,
            'expand',
          ];
        } else if (result.breakpoints[this.employeeTableMediumBreakpoint]) {
          this.columnsToDisplayWithExpand = [
            ...this.displayedColumns_mediumScreen,
            'expand',
          ];
        } else {
          this.columnsToDisplayWithExpand = [
            ...this.displayedColumns_largeScreen,
            'expand',
          ];
        }
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadData() {
    const stores$ = this.storeService.getBusinessStores(false).pipe(
      catchError(() => {
        return of(null);
      }),
    );

    const jobPositions$ = this.jobPositionService.getJobPositions(false).pipe(
      catchError(() => {
        return of(null);
      }),
    );

    return forkJoin({
      stores: stores$,
      jobPositions: jobPositions$,
    }).pipe(
      map(({ stores, jobPositions }) => {
        if (!stores || !jobPositions) {
          this.errorLoadingData = true;
          this.navigationService.setErrorLoading(true);
          return;
        }

        this.stores = stores as StoreLight[];
        this.jobPositions = jobPositions as JobPositionLight[];

        this.initForms();
        this.getEmployees();
      }),
    );
  }

  initForms() {
    let storedPageSize =
      this.initializationService.getUserPreferences().pageSize;
    if (storedPageSize) {
      this.pageSize = storedPageSize;
      this.retrieveEmployeesDTO.pageSize = storedPageSize;
    }

    const { sortBy, sortDir } =
      this.initializationService.getUserSortPreferences();
    this.retrieveEmployeesDTO.sortBy = sortBy;
    this.retrieveEmployeesDTO.sortDir = sortDir;

    this.searchControl.valueChanges.subscribe((searchTerm) => {
      this.currentSearchTerm = searchTerm;
      this.getEmployees();
    });
  }

  public sortEmployees($event: Sort) {
    this.retrieveEmployeesDTO.pageNo = 0;
    this.retrieveEmployeesDTO.sortBy = $event.active;
    this.retrieveEmployeesDTO.sortDir = $event.direction;

    if ($event.active !== 'disabled') {
      this.initializationService
        .updateEmployeeSortPreference($event.active + ', ' + $event.direction)
        .then(() => {});
    }
    this.getEmployees();
  }

  public pageResults($event: PageEvent) {
    if (this.retrieveEmployeesDTO.pageSize == $event.pageSize) {
      this.retrieveEmployeesDTO.pageNo = $event.pageIndex;
    } else {
      this.retrieveEmployeesDTO.pageSize = $event.pageSize;
      this.retrieveEmployeesDTO.pageNo = 0;

      this.initializationService
        .updatePageSizePreference($event.pageSize)
        .then(() => {});
    }
    this.getEmployees();
  }

  clickStoreChip(store: StoreLight) {
    if (store.id !== undefined) {
      const index = this.selectedStoreIds.indexOf(store.id);
      if (index > -1) {
        this.selectedStoreIds.splice(index, 1);
      } else {
        this.selectedStoreIds.push(store.id);
      }
      this.retrieveEmployeesDTO.storeIds = this.selectedStoreIds;
      this.retrieveEmployeesDTO.pageNo = 0;
      this.getEmployees();
    }
  }

  clickJobPositionChip(jobPosition: JobPositionLight) {
    if (jobPosition.id !== undefined) {
      const index = this.selectedJobPositionIds.indexOf(jobPosition.id);
      if (index > -1) {
        this.selectedJobPositionIds.splice(index, 1);
      } else {
        this.selectedJobPositionIds.push(jobPosition.id);
      }
      this.retrieveEmployeesDTO.jobPositionIds = this.selectedJobPositionIds;
      this.retrieveEmployeesDTO.pageNo = 0;
      this.getEmployees();
    }
  }

  protected getEmployees() {
    this.dataSource.data = [];
    this.requestSent = true;

    this.retrieveEmployeesDTO.searchTerm = this.currentSearchTerm || '';

    this.employeesService
      .getFilteredEmployees(this.retrieveEmployeesDTO)
      .subscribe({
        next: (data) => {
          this.dataSource.data = data.employees as Employee[];
          this.totalElements = data.totalElements!;
          this.currentPage = data.pageNo!;
          this.pageSize = data.pageSize!;
          this.requestSent = false;
        },
        error: () => {
          this.notificationService.showTimedMessage('employeesLoadingError');
          this.requestSent = false;
        },
      });
  }

  protected readonly trackByStoreLight = trackByStoreLight;
  protected readonly trackByJobPositionLight = trackByJobPositionLight;
}
