import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export const maxHoursValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const group = control as FormGroup;
  const minHours = Number(group.get('minHours')?.value);
  const maxHours = Number(group.get('maxHours')?.value);

  if (minHours !== null && maxHours !== null && maxHours < minHours) {
    return { maxLessThanMin: true };
  }

  return null;
};
