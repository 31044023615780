import { Injectable } from '@angular/core';
import { Locale } from '../../model/employee/availabilities/locale';
import { DatePipe } from '@angular/common';
import * as jsonData from '../../../assets/config.json';
import { LanguageLoaderService } from '../core/language-loader';

@Injectable({ providedIn: 'root' })
export class DatepickerService {
  readonly configProperties: any = jsonData;
  readonly timePeriods = this.configProperties.timePeriods;

  readonly today = new Date();

  //TODO This needs to be adapted based on region
  readonly defaultLocale = Locale.EN;
  readonly defaultStartOfWeek: string = 'MONDAY';
  readonly datePipe = new DatePipe(this.defaultLocale);
  readonly dateFormat: string = this.configProperties.dateFormat;
  readonly dateTimeFormat: string = this.configProperties.dateTimeFormat;
  readonly dateNoYearFormat: string = this.configProperties.dateNoYearFormat;
  readonly restProcessDateFormat: string =
    this.configProperties.restProcessDateFormat;

  constructor(private languageLoaderService: LanguageLoaderService) {}

  getToday(): Date {
    return this.today;
  }

  getDateInStringFormat(date: Date): string {
    return this.datePipe.transform(date, this.restProcessDateFormat) ?? '';
  }

  getDateInStringFormatForUI(date: Date | null): string {
    if (date) {
      return this.datePipe.transform(date, this.dateFormat) ?? '';
    } else {
      return '';
    }
  }

  getDateNoYearInStringFormatForUI(date: Date | null): string {
    if (date) {
      return this.datePipe.transform(date, this.dateNoYearFormat) ?? '';
    } else {
      return '';
    }
  }

  getDateTimeInStringFormatForUI(date: Date | undefined): string {
    if (date) {
      return this.datePipe.transform(date, this.dateTimeFormat) ?? '';
    } else {
      return '';
    }
  }

  getPickerLocale(): string {
    const currentLanguage = this.languageLoaderService.currentLanguage;

    if (currentLanguage?.name === 'el') {
      return Locale.EL;
    } else {
      return this.defaultLocale;
    }
  }

  defineStartOfWeek(day: string): number {
    if (!day) {
      day = this.defaultStartOfWeek;
    }
    return day.toUpperCase() === 'MONDAY' ? 1 : 0;
  }

  calculateNextAvailableWeekOrMonthStartDate(
    firstDayOfWeek: number,
    weeklyRange: boolean,
  ): Date {
    const currentDayOfWeek = this.today.getDay();

    // Check if today is the first day of the week or the first day of the month
    if (
      (weeklyRange && currentDayOfWeek === firstDayOfWeek) ||
      (!weeklyRange && this.today.getDate() === 1)
    ) {
      return this.today;
    }

    const nextDate = new Date();
    if (weeklyRange) {
      if (currentDayOfWeek === firstDayOfWeek) {
        nextDate.setDate(this.today.getDate() + 7);
      } else {
        const diff = (firstDayOfWeek - currentDayOfWeek + 7) % 7;
        nextDate.setDate(this.today.getDate() + diff);
      }
    } else {
      nextDate.setMonth(this.today.getMonth() + 1);
      nextDate.setDate(1);
    }

    return nextDate;
  }

  getCurrentWeekOrMonthStartDate(
    firstDayOfWeek: number,
    weeklyRange: boolean,
    startDate: Date,
  ): Date {
    const currentDayOfWeek = startDate.getDay();
    const currentDate = new Date(startDate);

    // Check if the provided date is the first day of the week or the first day of the month
    if (
      (weeklyRange && currentDayOfWeek === firstDayOfWeek) ||
      (!weeklyRange && startDate.getDate() === 1)
    ) {
      return startDate;
    }

    if (weeklyRange) {
      const diff = (currentDayOfWeek - firstDayOfWeek + 7) % 7;
      currentDate.setDate(startDate.getDate() - diff);
    } else {
      currentDate.setDate(1);
    }

    return currentDate;
  }

  isValidDate(date: any): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  convertTimeTo24HourFormat(time: string): string {
    // Check if the time string contains "am" or "pm" (or their localized versions)
    const am = this.timePeriods[this.getPickerLocale()]['am'];
    const pm = this.timePeriods[this.getPickerLocale()]['pm'];
    const timePeriodRegex = new RegExp(`${am}|${pm}`, 'i');
    const match = RegExp(timePeriodRegex).exec(time);

    if (match) {
      // The time string contains "am" or "pm", so we need to convert it to 24-hour format
      const [hours, minutes] = time.split(':');
      let hoursIn24HourFormat = parseInt(hours);

      if (match[0].toLowerCase() === pm && hoursIn24HourFormat < 12) {
        hoursIn24HourFormat += 12;
      } else if (match[0].toLowerCase() === am && hoursIn24HourFormat === 12) {
        hoursIn24HourFormat = 0;
      }

      // Remove the "am" or "pm" part from the minutes string
      const actualMinutes = minutes.split(/\D/)[0];

      return `${hoursIn24HourFormat.toString().padStart(2, '0')}:${actualMinutes}`;
    } else {
      // The time string is already in 24-hour format
      return time.trim();
    }
  }

  convertTimeTo12HourFormat(time: string): string {
    // Check if the time string contains "am" or "pm" (or their localized versions)
    const am = this.timePeriods[this.getPickerLocale()]['am'];
    const pm = this.timePeriods[this.getPickerLocale()]['pm'];
    const timePeriodRegex = new RegExp(`${am}|${pm}`, 'i');
    const match = RegExp(timePeriodRegex).exec(time);

    if (match) {
      // The time string contains "am" or "pm", so it's already in 12-hour format
      return time;
    } else {
      // The time string is in 24-hour format, so we need to convert it to 12-hour format
      const [hours, minutes] = time.split(':');
      let hoursIn12HourFormat = parseInt(hours);
      let timePeriod;

      if (hoursIn12HourFormat < 12) {
        timePeriod = am;
        if (hoursIn12HourFormat === 0) {
          hoursIn12HourFormat = 12;
        }
      } else {
        timePeriod = pm;
        if (hoursIn12HourFormat > 12) {
          hoursIn12HourFormat -= 12;
        }
      }

      return `${hoursIn12HourFormat.toString().padStart(2, '0')}:${minutes} ${timePeriod}`;
    }
  }

  formatDateForSchedule(date: Date, twelveHourFormat: boolean): string {
    if (!twelveHourFormat) {
      return this.getDateTimeInStringFormatForUI(date);
    } else {
      try {
        const dateObject = new Date(date);
        const dateOnly = new Date(
          dateObject.getFullYear(),
          dateObject.getMonth(),
          dateObject.getDate(),
        );
        const timeOnly = new Date(
          1970,
          0,
          1,
          dateObject.getHours(),
          dateObject.getMinutes(),
        );

        // Format the date and time components
        const datePart = this.getDateInStringFormatForUI(dateOnly);
        const timePart = this.convertTimeTo12HourFormat(
          timeOnly.toTimeString().split(' ')[0],
        );

        return `${datePart} ${timePart}`;
      } catch (error) {
        return this.getDateTimeInStringFormatForUI(date);
      }
    }
  }

  isToday(date: Date): boolean {
    const givenDate = new Date(date);
    return this.getToday().toDateString() === givenDate.toDateString();
  }

  formatUITime(time: string, twelveHourFormat: boolean) {
    if (twelveHourFormat) {
      return this.convertTimeTo12HourFormat(time);
    } else {
      return time;
    }
  }
}
