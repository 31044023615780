import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { filter, Subject, takeUntil } from 'rxjs';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/common/navigation.service';
import { InitializationService } from '../../services/core/initialization-service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatListModule, MatNavList } from '@angular/material/list';
import { Cookies } from '../shared/cookie-popup/cookies';
import { SystemAnnouncement } from '../../model/system/system-announcement';
import { SystemAnnouncementBanner } from '../shared/system-announcement/system-announcement-banner';
import * as jsonData from '../../../assets/config.json';
import { MatTooltip } from '@angular/material/tooltip';
import { SvgWrap } from '../shared/svg-wrap/svg-wrap';
import { NavbarStatusService } from '../../services/common/navbar-status-service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.html',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    MatSidenavModule,
    NgClass,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    RouterLink,
    TranslateModule,
    MatProgressBarModule,
    MatCardModule,
    RouterOutlet,
    MatNavList,
    MatListModule,
    Cookies,
    Cookies,
    SystemAnnouncementBanner,
    MatTooltip,
    SvgWrap,
  ],
})
export class Navigation
  implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
  readonly configProperties: any = jsonData;
  readonly extraSmallBreakpoint = this.configProperties.extraSmallBreakpoint;

  isHandset: boolean = false;
  isAuthenticated: boolean = false;
  isOwner: boolean = false;
  isManager: boolean = false;
  isEmployee: boolean = false;
  username: string;
  @ViewChild('sidenav') sidenav: MatSidenav;
  readonly year = new Date().getFullYear();

  systemAnnouncement: SystemAnnouncement | null;

  private destroy$ = new Subject<void>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    public router: Router,
    public navigationService: NavigationService,
    private renderer: Renderer2,
    private initializationService: InitializationService,
    private translateService: TranslateService,
    private navbarStatusService: NavbarStatusService,
  ) {
    this.isAuthenticated = this.initializationService.isUserAuthenticated();
    this.isOwner = this.initializationService.isAuthenticateUserOwner();
    this.isManager = this.initializationService.isAuthenticateUserManager();
    this.isEmployee = this.initializationService.isAuthenticateUserEmployee();
    this.loadUsername();
  }

  async ngOnInit() {
    this.username =
      this.initializationService.getUserDetails().firstName +
      ' ' +
      this.initializationService.getUserDetails().lastName;

    this.breakpointObserver
      .observe(this.extraSmallBreakpoint)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.isHandset = !!result.breakpoints[this.extraSmallBreakpoint];
      });

    this.initializationService.userAuthStatusChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userAuthStatus) => {
        this.isAuthenticated = userAuthStatus.isLoggedIn;
        this.isOwner = userAuthStatus.isOwner;
        this.isManager = userAuthStatus.isManager;
        this.isEmployee = userAuthStatus.isEmployee;
      });

    this.initializationService.userDetailsChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userDetails) => {
        this.loadUsername();
      });

    this.initializationService.systemAnnouncementChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((systemAnnouncement) => {
        this.systemAnnouncement = systemAnnouncement;
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.navigationService.setErrorLoading(false);
      });
  }

  ngAfterViewInit() {
    this.renderer.setStyle(
      this.sidenav._content.nativeElement,
      'scrollbar-width',
      'none',
    );
    this.navbarStatusService.setNavbarOpen(this.sidenav.opened);
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onMenuItemSelected() {
    if (this.isHandset) {
      this.sidenav.close().then(() => {});
    }
  }

  toggleSidenav() {
    this.sidenav.toggle().then(() => {
      this.navbarStatusService.setNavbarOpen(this.sidenav.opened);
    });
  }

  isSidenavOpened(): boolean {
    return this.sidenav ? this.sidenav.opened : false;
  }

  isActivated(routeValues: string[]): boolean {
    return routeValues.some((val) => this.router.url.includes(val));
  }

  openLoginDialog() {
    this.navigationService.setLoginOverlay(true);
  }

  private loadUsername() {
    const userDetails = this.initializationService.getUserDetails();
    const firstName = userDetails.firstName;
    const lastName = userDetails.lastName;

    if (firstName && lastName) {
      this.username = firstName + ' ' + lastName;
    } else {
      this.username = this.translateService.instant('user');
    }
  }

  protected readonly open = open;
}
