import { Injectable } from '@angular/core';
import * as jsonData from '../../../assets/config.json';
import { PreferredColorOption } from '../../model/app/preferred-color-option';

@Injectable({
  providedIn: 'root',
})
export class CustomColorService {
  readonly configProperties: any = jsonData;
  readonly preferredColorOptions: PreferredColorOption[] =
    this.configProperties.preferredColorOptions;

  getColor(preferredColor: string): string {
    const colorOption = this.preferredColorOptions.find(
      (option) => option.color === preferredColor,
    );
    return colorOption
      ? colorOption.color
      : this.preferredColorOptions[0].color;
  }
}

export interface ColorOption {
  name: string;
  color: string;
}
