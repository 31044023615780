import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { StoreService } from '../../services/crud/store.service';
import { Store } from '../../model/store/store';
import { Spinner } from '../shared/spinner/spinner';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { catchError, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { NavigationService } from '../../services/common/navigation.service';
import { ScrollToTop } from '../shared/scroll-to-top/scroll-to-top';
import { trackByJobPosition, trackByStore } from '../../utils/common-utils';
import { popUp, scaleUp } from '../../utils/animations';
import { CryptoService } from '../../services/common/crypto-service';
import { MatRipple } from '@angular/material/core';
import { ThemeLoaderService } from '../../services/core/theme-loader';
import { MatTooltip } from '@angular/material/tooltip';
import { DatepickerService } from '../../services/common/datepicker.service';
import { MatSuffix } from '@angular/material/form-field';
import { WaveBackground } from '../shared/wave-background/wave-background';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { ReorderObjectsList } from '../../model/general/reorder-objects-list';
import { ReorderObjectsRequest } from '../../model/general/reorder-objects-request';
import { NotificationService } from '../../services/common/notification.service';
import { InitializationService } from '../../services/core/initialization-service';

@Component({
  selector: 'app-stores',
  standalone: true,
  imports: [
    Spinner,
    TranslateModule,
    MatCardModule,
    RouterModule,
    MatIconModule,
    NgIf,
    NgFor,
    MatButtonModule,
    MatDivider,
    ScrollToTop,
    MatRipple,
    MatTooltip,
    MatSuffix,
    WaveBackground,
    CdkDrag,
    CdkDragHandle,
    CdkDropList,
  ],
  templateUrl: './stores.html',
  animations: [popUp, scaleUp],
})
export class Stores implements OnInit {
  isOwner: boolean = false;

  reorderMode: boolean = false;
  dataLoaded: boolean = false;
  errorLoadingData: boolean = false;

  hoverState: { [id: string]: string } = {};
  stores: Store[] = [];

  buttonRippleEffect = '';

  constructor(
    private storeService: StoreService,
    private navigationService: NavigationService,
    private cryptoService: CryptoService,
    private router: Router,
    private themeLoaderService: ThemeLoaderService,
    protected datepickerService: DatepickerService,
    private notificationService: NotificationService,
    private initializationService: InitializationService,
    private activatedRoute: ActivatedRoute,
  ) {}

  async ngOnInit() {
    this.isOwner = this.initializationService.isAuthenticateUserOwner();

    this.loadData().subscribe(() => {
      this.dataLoaded = true;

      if (
        this.activatedRoute.snapshot.url.some(
          (segment) => segment.path === 'edit',
        )
      ) {
        if (this.stores.length > 0) {
          this.router.navigate(['/store/' + this.stores[0].id]).then(() => {});
        }
      }
    });

    this.buttonRippleEffect =
      this.themeLoaderService.currentColorScheme === 'dark'
        ? 'rgba(74, 68, 89, 0.5)'
        : 'rgba(232, 222, 249, 0.5)';
  }

  loadData() {
    const stores$ = this.storeService.getBusinessStores(true).pipe(
      catchError(() => {
        return of(null);
      }),
    );

    return forkJoin({ stores: stores$ }).pipe(
      map(({ stores }) => {
        if (!stores) {
          this.errorLoadingData = true;
          this.navigationService.setErrorLoading(true);
          return;
        }

        this.stores = stores as Store[];
      }),
    );
  }

  goToCalendar(storeId: string) {
    this.router
      .navigate(['/calendar/' + this.cryptoService.encryptData(storeId)])
      .then(() => {});
  }

  onHover(id: string) {
    this.hoverState[id] = 'hovered';
  }

  onLeave(id: string) {
    this.hoverState[id] = 'default';
  }

  countWeeklyShifts(store: Store) {
    return store.shifts.reduce((acc, shift) => acc + shift.requiredCount, 0);
  }

  reorderStores(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.stores, event.previousIndex, event.currentIndex);

    const reorderObjectsList: ReorderObjectsList[] = this.stores.map(
      (store, index) => {
        return { id: store.id ?? '', orderIndex: index + 1 };
      },
    );

    const reorderObjectsRequest: ReorderObjectsRequest = {
      objects: reorderObjectsList,
    };

    this.storeService.reorderStores(reorderObjectsRequest).subscribe({
      next: () => {},
      error: () => {
        this.notificationService.showErrorMessage('dataError');
      },
    });
  }

  protected readonly trackByStore = trackByStore;
  protected readonly trackByJobPosition = trackByJobPosition;
}
