import { Component, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { CanDeactivateComponent } from '../../../../utils/guards/can-deactivate-component';
import { EmployeeSourcePairing } from '../../../../model/employee/pairing/employee-source-pairing';
import { EmployeePairingService } from '../../../../services/crud/employee-pairing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, firstValueFrom, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationService } from '../../../../services/common/navigation.service';
import { NgForOf, NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { InitializationService } from '../../../../services/core/initialization-service';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ScrollToTop } from '../../../shared/scroll-to-top/scroll-to-top';
import { ConfirmationDialog } from '../../../shared/confirmation-dialog/confirmation-dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { compareEmployeeExtraLight } from '../../../../utils/common-utils';
import { EmployeePairingType } from '../../../../model/employee/pairing/employee-pairing-type';
import { EmployeeTargetPairing } from '../../../../model/employee/pairing/employee-target-pairing';
import { EmployeeStorePairing } from '../../../../model/employee/pairing/employee-store-pairing';
import { SetEmployeePairingsRequest } from '../../../../model/employee/pairing/set-employee-pairings-request';
import { NotificationService } from '../../../../services/common/notification.service';

@Component({
  selector: 'app-employee-pairings-form',
  standalone: true,
  imports: [
    MatTabsModule,
    MatCardModule,
    TranslateModule,
    MatBadgeModule,
    NgIf,
    NgForOf,
    MatIcon,
    MatButtonModule,
    MatProgressSpinner,
    ScrollToTop,
    MatSelectModule,
  ],
  templateUrl: './employee-pairings-form.html',
})
export class EmployeePairingsForm implements OnInit, CanDeactivateComponent {
  dataLoaded: boolean = false;
  errorLoadingData: boolean = false;
  requestSent: boolean = false;

  pairingsUpdated: boolean = false;
  formSubmitted: boolean = false;
  formValid: boolean = true;
  storePairingValidity: Map<string, boolean> = new Map();

  hideBanners: boolean = false;

  employeePairings: EmployeeSourcePairing;
  employeePairingTypes = Object.values(EmployeePairingType);
  deletedPairingIds: string[] = [];

  constructor(
    private employeePairingService: EmployeePairingService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private initializationService: InitializationService,
    private router: Router,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {}

  async ngOnInit() {
    this.loadData().subscribe(async () => {
      this.dataLoaded = true;

      this.hideBanners =
        this.initializationService.getUserPreferences().hideInfoBanners ??
        false;
    });
  }

  private loadData() {
    const pairings$ = this.employeePairingService
      .getEmployeePairings(
        this.activatedRoute.snapshot.paramMap.get('id') ?? '',
      )
      .pipe(
        catchError(() => {
          return of(null);
        }),
      );

    return forkJoin({
      pairings: pairings$,
    }).pipe(
      map(({ pairings }) => {
        if (!pairings) {
          this.errorLoadingData = true;
          this.navigationService.setErrorLoading(true);
          return;
        }

        this.employeePairings = pairings;

        this.employeePairings.storePairings.forEach((storePairing) => {
          storePairing.targets.forEach((target) => {
            this.updateEmployeeSelection(
              storePairing,
              target.targetEmployee?.id,
              true,
            );
          });
        });
      }),
    );
  }

  getTranslatablePairingType(type: string): string {
    return this.translateService.instant(
      `employeePairingType.${type.toLowerCase()}`,
    );
  }

  removePairing(
    storePairing: EmployeeStorePairing,
    target: EmployeeTargetPairing,
    isAdded: boolean = false,
  ) {
    if (isAdded) {
      // Remove from added pairings
      storePairing.addedPairings = storePairing.addedPairings.filter(
        (pairing) => pairing !== target,
      );
    } else {
      // Remove from existing pairings and add to deleted list
      storePairing.targets = storePairing.targets.filter(
        (pairing) => pairing.id !== target.id,
      );
      if (target.id) {
        this.deletedPairingIds.push(target.id);
      }
    }

    this.updateEmployeeSelection(
      storePairing,
      target.targetEmployee?.id,
      false,
    );
    this.pairingsUpdated = true;
  }

  addPairing(storePairing: EmployeeStorePairing) {
    const newPairing: EmployeeTargetPairing = {};

    if (!storePairing.addedPairings) {
      storePairing.addedPairings = [];
    }

    storePairing.addedPairings.push(newPairing);

    this.pairingsUpdated = true;
  }

  onEmployeeSelectionChange(storePairing: EmployeeStorePairing, event: any) {
    const newEmployeeId = event.value ? event.value.id : null;
    if (newEmployeeId) {
      this.updateEmployeeSelection(storePairing, newEmployeeId, true);
    }
  }

  onTypeChange(pairing: EmployeeTargetPairing) {
    pairing.updated = true;
    this.pairingsUpdated = true;
  }

  isLastPairingValid(storePairing: EmployeeStorePairing): boolean {
    if (
      !storePairing.addedPairings ||
      storePairing.addedPairings.length === 0
    ) {
      return true;
    }
    const lastPairing =
      storePairing.addedPairings[storePairing.addedPairings.length - 1];

    const valid = !(
      lastPairing.targetEmployee === undefined || lastPairing.type === undefined
    );

    this.storePairingValidity.set(storePairing.store.id, valid);
    this.formValid = Array.from(this.storePairingValidity.values()).every(
      (isValid) => isValid,
    );

    return valid;
  }

  private updateEmployeeSelection(
    storePairing: EmployeeStorePairing,
    employeeId: string | null | undefined,
    selected: boolean,
  ) {
    if (employeeId) {
      const employee = storePairing.employees.find(
        (emp) => emp.id === employeeId,
      );
      if (employee) {
        employee.selected = selected;
      }
    }
  }

  getPairingCount(storePairing: EmployeeStorePairing): number {
    return (
      (storePairing.targets?.length || 0) +
      (storePairing.addedPairings?.length || 0)
    );
  }

  setPairings() {
    const request: SetEmployeePairingsRequest = {
      sourceEmployee: this.employeePairings.sourceEmployee,
      storePairings: this.employeePairings.storePairings,
      deletedPairings: this.deletedPairingIds,
    };

    this.employeePairingService.setEmployeePairings(request).subscribe({
      next: () => {
        this.notificationService.showTimedMessage(
          'employeeAvailabilitiesUpdated',
        );
        this.formSubmitted = true;
        this.router.navigate(['/employees']).then(() => {});
      },
      error: (error) => {
        if (error.status === 425) {
          this.notificationService.showActionMessage(
            'requestFailed_SchedulerInProgress',
          );
        } else {
          this.notificationService.showErrorMessage('dataError');
        }
        this.requestSent = false;
      },
    });
  }

  abort() {
    if (this.pairingsUpdated) {
      const dialogRef = this.matDialog.open(ConfirmationDialog, {
        data: { dialogContentKey: 'cancelActionConfirmation' },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.formSubmitted = true;
          this.router.navigate(['/employees']).then(() => {});
        }
      });
    } else {
      this.router.navigate(['/employees']).then(() => {});
    }
  }

  async canDeactivate(): Promise<boolean> {
    if (this.formSubmitted) {
      return true;
    }

    if (this.pairingsUpdated) {
      const dialogRef = this.matDialog.open(ConfirmationDialog, {
        data: { dialogContentKey: 'cancelActionConfirmation' },
      });

      const result = await firstValueFrom(dialogRef.afterClosed());
      return result as boolean;
    } else {
      return true;
    }
  }

  protected readonly compareEmployeeExtraLight = compareEmployeeExtraLight;
}
