<ng-container *ngIf="dataLoaded && !errorLoadingData">
  <mat-card class="mat-mdc-card-outlined">
    <mat-stepper #stepper [orientation]="(stepperOrientation | async)!">
      <mat-step
        [errorMessage]="getInfoError()"
        [stepControl]="employeeInfoForm"
      >
        <form [formGroup]="employeeInfoForm">
          <ng-template matStepLabel
            >{{ "information" | translate }}
          </ng-template>

          <p></p>
          <div class="form__grouped-two-elements">
            <div>
              <mat-form-field>
                <mat-label>{{ "employeeFirstName" | translate }}</mat-label>
                <input
                  formControlName="firstName"
                  matInput
                  placeholder="{{ 'namePlaceholder' | translate }}"
                />
                <mat-error
                  *ngIf="
                    employeeInfoForm.controls['firstName'].hasError('required')
                  "
                  >{{ "nameRequired" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field>
                <mat-label>{{ "employeeLastName" | translate }}</mat-label>
                <input
                  formControlName="lastName"
                  matInput
                  placeholder="{{ 'lastNamePlaceholder' | translate }}"
                />
                <mat-error
                  *ngIf="
                    employeeInfoForm.controls['lastName'].hasError('required')
                  "
                  >{{ "lastNameRequired" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <ng-container *ngIf="editMode && !hideBanners">
            <mat-card
              class="custom__card custom__card-info"
              appearance="outlined"
              style="margin-top: 0"
            >
              <div class="custom__card-body">
                <div class="custom__card-container">
                  <div>
                    <mat-icon aria-label="edit">edit</mat-icon>
                  </div>
                  <div class="custom__card-section">
                    <mat-card-header>
                      <mat-card-title
                        >{{ "editEmployee" | translate }}
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <p>{{ "editEmployeeInfoWarning" | translate }}</p>
                    </mat-card-content>
                  </div>
                </div>
              </div>
            </mat-card>
          </ng-container>

          <div class="form__grouped-two-elements">
            <div>
              <p>{{ "employeeStores" | translate }}:</p>
              <mat-form-field>
                <mat-label>{{ "stores" | translate }}</mat-label>
                <mat-select
                  [compareWith]="compareStores"
                  formControlName="stores"
                  multiple
                  placeholder="{{ 'selectStores' | translate }}"
                >
                  <mat-option
                    *ngFor="
                      let store of stores;
                      let i = index;
                      trackBy: trackByStoreLight
                    "
                    [value]="store"
                    >{{ store.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    employeeInfoForm.controls['stores'].hasError('required')
                  "
                >
                  {{ "atLeastOneStoreRequired" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div>
              <div>
                <p>{{ "employeeJobPositions" | translate }}:</p>
                <mat-form-field>
                  <mat-label>{{ "jobPositions" | translate }}</mat-label>
                  <mat-select
                    [compareWith]="compareJobPositions"
                    formControlName="jobPositions"
                    multiple
                    placeholder="{{ 'selectJobPositions' | translate }}"
                  >
                    <mat-option
                      *ngFor="
                        let jobPosition of jobPositions;
                        let i = index;
                        trackBy: trackByJobPositionLight
                      "
                      [value]="jobPosition"
                      >{{ jobPosition.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      employeeInfoForm.controls['jobPositions'].hasError(
                        'required'
                      )
                    "
                  >
                    {{ "atLeastOneJobPositionRequired" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="form__grouped-two-elements">
            <div class="employee__color-button">
              <button
                type="button"
                mat-raised-button
                [(colorPicker)]="employee.preferredColor"
                cpOutputFormat="hex"
                [style.color]="employee.preferredColor"
                cpPosition="auto"
                [(cpToggle)]="colorPickerToggle"
                [cpOKButton]="true"
                cpOKButtonText="{{ 'save' | translate }}"
                cpCancelButtonText="{{ 'cancel' | translate }}"
                cpPresetLabel="{{ 'suggestions' | translate }}"
                [cpCancelButton]="true"
                cpOKButtonClass="color__picker-button color__picker-save"
                cpCancelButtonClass="color__picker-button color__picker-cancel"
                [cpPresetColors]="colorSuggestions"
                [cpExtraTemplate]="clearColorSelection"
                (colorPickerChange)="employeeInfoForm.markAsDirty()"
              >
                <mat-icon>palette</mat-icon>
                {{ "displayColor" | translate }}
              </button>
              <ng-template #clearColorSelection>
                <div class="color__picker-embed">
                  <button
                    class="color__picker-button color__picker-reset"
                    (click)="
                      colorPickerToggle = false;
                      employee.preferredColor = '';
                      employeeInfoForm.markAsDirty()
                    "
                    type="button"
                  >
                    {{ "clear" | translate }}
                  </button>
                </div>
              </ng-template>
            </div>

            <div class="flex__box">
              <mat-slide-toggle formControlName="disabled">
                {{ "disabledEmployeePlaceholder" | translate }}
              </mat-slide-toggle>

              <button
                mat-icon-button
                type="button"
                matTooltip="{{ 'disabledEmployeeTooltip' | translate }}"
              >
                <mat-icon aria-label="info">question_mark</mat-icon>
              </button>
            </div>
          </div>

          <div class="stepper__step-actions">
            <button disabled mat-icon-button matStepperPrevious>
              <mat-icon aria-label="previous">keyboard_arrow_left</mat-icon>
            </button>
            <button mat-icon-button matStepperNext>
              <mat-icon aria-label="next">keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step
        [errorMessage]="getContractError()"
        [stepControl]="employeeContractForm"
      >
        <ng-template matStepLabel>{{ "contract" | translate }}</ng-template>
        <p></p>
        <form [formGroup]="employeeContractForm">
          <div class="form__grouped-two-elements form__divider-after">
            <mat-radio-group formControlName="weeklyHours" color="primary">
              <mat-radio-button
                (click)="updateWeeklyHours(null)"
                (keydown)="$event.stopPropagation()"
                value="null"
                >{{ "noneF" | translate }}
              </mat-radio-button>
              <mat-radio-button
                (click)="updateWeeklyHours(true)"
                (keydown)="$event.stopPropagation()"
                value="true"
                >{{ "weekly" | translate }}
              </mat-radio-button>
              <mat-radio-button
                (click)="updateWeeklyHours(false)"
                (keydown)="$event.stopPropagation()"
                value="false"
                >{{ "monthly" | translate }}
              </mat-radio-button>
            </mat-radio-group>

            <div class="flex__box">
              <p style="margin: 0">{{ "contractHours" | translate }}</p>
              <button
                mat-icon-button
                type="button"
                matTooltip="{{ 'contractHoursTooltip' | translate }}"
              >
                <mat-icon aria-label="info">question_mark</mat-icon>
              </button>
            </div>
          </div>

          <mat-divider
            [ngClass]="
              employee.contract.weeklyHours === null ? 'lower__opacity' : ''
            "
          ></mat-divider>

          <div class="form__grouped-two-elements form__divider-before">
            <mat-form-field>
              <mat-label>{{ "minHours" | translate }}</mat-label>
              <input
                formControlName="minHours"
                matInput
                (keydown)="preventNonNumericInput($event)"
                (focusout)="onMinHoursFocusOut($event)"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ "maxHours" | translate }}</mat-label>
              <input
                formControlName="maxHours"
                matInput
                (keydown)="preventNonNumericInput($event)"
                (focusout)="onMaxHoursFocusOut($event)"
              />
            </mat-form-field>
          </div>

          <mat-error *ngIf="employeeContractForm.hasError('maxLessThanMin')">
            {{ "minHoursAfterMaxHoursError" | translate }}
          </mat-error>

          <div class="stepper__step-actions">
            <button mat-icon-button matStepperPrevious>
              <mat-icon aria-label="previous">keyboard_arrow_left</mat-icon>
            </button>
            <button mat-icon-button matStepperNext>
              <mat-icon aria-label="next">keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step
        [errorMessage]="getSystemUserError()"
        [optional]="!systemUserForm.get('enableSystemUser')?.value"
        [stepControl]="systemUserForm"
      >
        <ng-template matStepLabel>{{ "systemUser" | translate }}</ng-template>

        <p></p>
        <form [formGroup]="systemUserForm">
          <div class="form__grouped-two-elements form__divider-after">
            <div class="flex__box">
              <mat-slide-toggle formControlName="enableSystemUser"
                >{{ "enableSystemUser" | translate }}
              </mat-slide-toggle>

              <button
                mat-icon-button
                type="button"
                matTooltip="{{ 'enableSystemUserTooltip' | translate }}"
              >
                <mat-icon aria-label="info">question_mark</mat-icon>
              </button>
            </div>
          </div>

          <mat-divider
            [ngClass]="
              systemUserForm.get('enableSystemUser')?.value === false
                ? 'lower__opacity'
                : ''
            "
          ></mat-divider>

          <ng-container
            *ngIf="
              !hideBanners &&
              systemUserForm.get('enableSystemUser')?.value === true
            "
          >
            <mat-card
              class="custom__card custom__card-info custom__card-larger"
              appearance="outlined"
            >
              <div class="custom__card-body">
                <div class="custom__card-container">
                  <div>
                    <mat-icon
                      class="card__icon-larger"
                      svgIcon="employee_keychain"
                      aria-label="access"
                    ></mat-icon>
                  </div>
                  <div class="custom__card-section">
                    <mat-card-header>
                      <mat-card-title
                        >{{ "userRoles" | translate }}
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <p>{{ "userRolesExplanation1" | translate }}</p>
                      <ul class="card__list-items">
                        <span
                          [innerHTML]="'userRolesExplanation2' | translate"
                        ></span>
                      </ul>
                    </mat-card-content>
                  </div>
                </div>
              </div>
            </mat-card>
          </ng-container>

          <ng-container
            *ngIf="
              (systemUserForm.get('enableSystemUser')?.value === true &&
                hideBanners) ||
              systemUserForm.get('enableSystemUser')?.value === false
            "
          >
            <div class="form__divider-before"></div>
          </ng-container>

          <div class="form__grouped-two-elements">
            <div>
              <mat-form-field>
                <mat-label>{{ "userEmail" | translate }}</mat-label>
                <input
                  formControlName="email"
                  matInput
                  placeholder="{{ 'emailPlaceholder' | translate }}"
                />
                <mat-error
                  *ngIf="
                    systemUserForm.controls['email'].hasError('invalidEmail')
                  "
                >
                  {{ "emailRequired" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field>
                <mat-label>{{ "userRoles" | translate }}</mat-label>
                <mat-select
                  [compareWith]="compareRoles"
                  formControlName="roles"
                  multiple
                  placeholder="{{ 'selectRoles' | translate }}"
                >
                  <mat-option
                    *ngFor="
                      let role of roles;
                      let i = index;
                      trackBy: trackByRole
                    "
                    [value]="role"
                  >
                    {{ "ROLE_" + role.name | translate }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="systemUserForm.controls['roles'].hasError('required')"
                >
                  {{ "atLeastOneRoleRequired" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="form__grouped-two-elements">
            <div class="flex__box">
              <mat-slide-toggle
                formControlName="enabled"
                matTooltip="{{ 'userEnabledTooltip' | translate }}"
                ><span
                  [ngClass]="
                    systemUserForm.get('enableSystemUser')?.value === false
                      ? 'lower__opacity'
                      : ''
                  "
                  >{{ "enabledAccess" | translate }}</span
                >
              </mat-slide-toggle>

              <button
                mat-icon-button
                type="button"
                [disabled]="
                  systemUserForm.get('enableSystemUser')?.value === false
                "
                matTooltip="{{ 'userEnabledTooltip' | translate }}"
              >
                <mat-icon aria-label="info">question_mark</mat-icon>
              </button>
            </div>
          </div>

          <div class="stepper__step-actions">
            <button mat-icon-button matStepperPrevious>
              <mat-icon aria-label="previous">keyboard_arrow_left</mat-icon>
            </button>
            <button disabled mat-icon-button matStepperNext>
              <mat-icon aria-label="next">keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </form>
      </mat-step>

      <ng-container *ngIf="editMode">
        <mat-step state="delete" [editable]="false" [completed]="false">
          <ng-template matStepLabel>{{ "delete" | translate }}</ng-template>

          <mat-card
            *ngIf="!hideBanners"
            class="custom__card custom__card-alert"
            appearance="outlined"
          >
            <div class="custom__card-body">
              <div class="custom__card-container">
                <div>
                  <mat-icon aria-label="delete">delete</mat-icon>
                </div>
                <div class="custom__card-section">
                  <mat-card-header>
                    <mat-card-title
                      >{{ "deleteEmployee" | translate }}
                    </mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <p>{{ "deleteEmployeeWarning" | translate }}</p>
                    <p>{{ "deleteEmployeeDisableHint" | translate }}</p>
                  </mat-card-content>
                </div>
              </div>
            </div>
          </mat-card>
        </mat-step>
      </ng-container>
    </mat-stepper>
  </mat-card>

  <mat-card class="form__actions-waved">
    <mat-card-content class="separate__container-actions">
      <button (click)="abort()" [disabled]="requestSent" mat-stroked-button>
        {{ "cancel" | translate }}
      </button>

      <ng-container *ngIf="stepper.selectedIndex !== 3; else deleteAction">
        <button
          mat-flat-button
          (click)="saveEmployee()"
          [disabled]="
            employeeInfoForm.invalid ||
            employeeContractForm.invalid ||
            systemUserForm.invalid ||
            requestSent ||
            !(
              employeeInfoForm.dirty ||
              employeeContractForm.dirty ||
              systemUserForm.dirty
            )
          "
        >
          <mat-spinner *ngIf="requestSent" diameter="20"></mat-spinner>
          <span *ngIf="!requestSent">{{ "save" | translate }} </span>
          <mat-icon aria-label="save" *ngIf="!requestSent">save</mat-icon>
        </button>
      </ng-container>
      <ng-template #deleteAction>
        <button
          mat-flat-button
          class="tertiary-button"
          (click)="deleteEmployee()"
          [disabled]="requestSent"
        >
          <mat-spinner *ngIf="requestSent" diameter="20"></mat-spinner>
          <span *ngIf="!requestSent">{{ "delete" | translate }} </span>
          <mat-icon aria-label="delete" *ngIf="!requestSent">delete</mat-icon>
        </button>
      </ng-template>
    </mat-card-content>
  </mat-card>

  <app-scroll-to-top></app-scroll-to-top>
</ng-container>
