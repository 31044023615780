import { Injectable, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { NavigationService } from '../common/navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TitleCustomStrategy extends TitleStrategy {
  public static readonly Provider = {
    provide: TitleStrategy,
    useClass: TitleCustomStrategy,
  };

  private router: Router;

  constructor(
    private readonly title: Title,
    private injector: Injector,
    private navigationService: NavigationService,
    private translateService: TranslateService,
  ) {
    super();

    setTimeout(() => {
      this.router = this.injector.get(Router);
      this.translateService.onLangChange.subscribe(() => {
        this.updateTitle(this.router.routerState.snapshot);
      });
    });
  }

  override updateTitle(snapshot: RouterStateSnapshot) {
    let title = this.buildTitle(snapshot);
    if (title) {
      title = this.translateService.instant(title);
      this.title.setTitle(`${title} | BeeEase`);
      this.navigationService.setTitle(title ?? '');
    } else {
      this.title.setTitle('BeeEase');
      this.navigationService.setTitle('');
    }
  }
}
