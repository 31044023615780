import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserProfile } from '../../model/user/user-profile';
import { Observable } from 'rxjs';
import { UserPreferences } from '../../model/user/user-preferences';
import { AcceptTermsAndConditionsRequest } from '../../model/user/accept-terms-and-conditions-request';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {}

  getUserProfile(): Observable<UserProfile> {
    return this.http.get<UserProfile>('/api/user');
  }

  updateUserPreferences(userPreferences: UserPreferences) {
    this.http.post('/api/user/preferences', userPreferences).subscribe();
  }

  acceptTermsAndConditions(
    acceptTermsAndConditionsRequest: AcceptTermsAndConditionsRequest,
  ): Observable<void> {
    return this.http.post<void>(
      '/api/user/terms/accept',
      acceptTermsAndConditionsRequest,
    );
  }
}
