import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatDivider } from '@angular/material/divider';
import * as jsonData from '../../../../assets/config.json';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../../services/common/auth.service';
import { NotificationService } from '../../../services/common/notification.service';
import { InitializationService } from '../../../services/core/initialization-service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NavigationService } from '../../../services/common/navigation.service';
import { shake, slideIn } from '../../../utils/animations';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDivider,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    NgIf,
    MatProgressSpinnerModule,
  ],
  templateUrl: './login.html',
  animations: [shake, slideIn],
})
export class Login implements OnInit {
  readonly configProperties: any = jsonData;

  passwordHide: boolean = true;
  loginForm: FormGroup;
  requestSent: boolean = false;
  invalidCredentials: boolean = false;

  shakeState: string = 'inactive';

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private initializationService: InitializationService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  async login() {
    if (this.loginForm.valid) {
      const { email, password } = this.loginForm.value;

      this.requestSent = true;
      this.invalidCredentials = false;

      this.authService.login(email, password).subscribe({
        next: (data) => {
          this.notificationService.showTimedClasslessMessage('loginSuccess');
          this.initializationService.loginAuthenticatedUser(data).then(() => {
            setTimeout(() => {
              this.navigationService.setLoginOverlay(false);
            }, 0);
          });
        },
        error: (err) => {
          if (err.status === 401) {
            this.invalidCredentials = true;
          }
          this.shakeState = 'active';
          setTimeout(() => (this.shakeState = 'inactive'), 500);
          this.notificationService.showClasslessMessage('dataError');
          this.requestSent = false;
        },
      });
    }
  }

  forgotPassword() {
    this.requestSent = true;

    Object.keys(this.loginForm.controls).forEach((key) => {
      const control = this.loginForm.controls[key];
      control.markAsPristine();
      control.markAsUntouched();
    });

    if (this.loginForm.controls['email'].invalid) {
      this.loginForm.controls['email'].markAsTouched();
      this.requestSent = false;
    } else {
      this.notificationService.closeMessages();
      this.notificationService.showActionMessage('dataLoading');

      this.authService.resetPassword(this.loginForm.value.email).subscribe({
        next: () => {
          this.notificationService.closeMessages();
          this.notificationService.showTimedClasslessMessage(
            'resetPasswordSuccess',
          );
          this.abort();
        },
        error: () => {
          this.notificationService.closeMessages();
          this.notificationService.showClasslessMessage('dataError');
          this.requestSent = false;
        },
      });
    }
  }

  abort() {
    this.navigationService.setLoginOverlay(false);
  }
}
