<div class="schedule__shift-info" style="justify-content: center">
  <ng-container
    *ngIf="employee.initialTotalHours === employee.totalHours; else swapped"
  >
    <ng-container *ngIf="employee.violations.length > 0">
      <button
        mat-icon-button
        type="button"
        *ngIf="employee.initialTotalHours === employee.totalHours"
        [matTooltip]="getViolation()"
      >
        <mat-icon
          aria-label="violations"
          svgIcon="schedule_violations"
        ></mat-icon>
      </button>
    </ng-container>

    <ng-container
      *ngIf="
        employee.rewards.length > 0 &&
        !(
          employee.rewards.length === 1 &&
          employee.rewards[0] === 'fluidContractMaxHoursMet'
        )
      "
    >
      <button mat-icon-button type="button" [matTooltip]="getReward()">
        <mat-icon aria-label="contract met" svgIcon="schedule_heart"></mat-icon>
      </button>
    </ng-container>

    <ng-container *ngIf="employee.contract.weeklyHours !== null">
      <button
        mat-icon-button
        type="button"
        matTooltip="{{ 'previousSameStoresShiftHoursChecked' | translate }}"
        matBadge="{{
          employee.olderGeneratedShiftsTotalHours +
            (employee.olderGeneratedShiftsTotalMinutes > 0
              ? ':' +
                formatScheduleMinutes(employee.olderGeneratedShiftsTotalMinutes)
              : '')
        }}"
      >
        <mat-icon
          aria-label="history"
          svgIcon="schedule_employee_history"
        ></mat-icon>
      </button>
    </ng-container>
  </ng-container>

  <ng-template #swapped>
    <button
      mat-icon-button
      type="button"
      matTooltip="{{ 'initialAssignment' | translate }} {{
        employee.initialTotalHours
      }}{{
        employee.initialTotalMinutes > 0
          ? ':' + formatScheduleMinutes(employee.initialTotalMinutes)
          : ''
      }} {{ 'hours' | translate }}"
    >
      <mat-icon aria-label="swapped" svgIcon="schedule_swapped"></mat-icon>
    </button>

    <div class="shift__info-separator lower__opacity"></div>

    <button
      mat-icon-button
      type="button"
      class="lower__opacity"
      matTooltip="{{ 'violation.employee.swapped' | translate }}"
      matBadge="0"
    >
      <mat-icon aria-label="violations" svgIcon="schedule_violations">
      </mat-icon>
    </button>
  </ng-template>
</div>
