import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-overlapping-shifts-dialog',
  standalone: true,
  imports: [TranslateModule, MatDialogModule, MatButton, MatIcon],
  templateUrl: './overlapping-shifts-dialog.html',
})
export class OverlappingShiftsDialog {
  calendarRequest: string;

  constructor(
    public dialogRef: MatDialogRef<OverlappingShiftsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.calendarRequest = data.calendarRequest;
  }
}
