import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import {
  MatStepper,
  MatStepperIntl,
  MatStepperModule,
  StepperOrientation,
} from '@angular/material/stepper';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NotificationService } from '../../../services/common/notification.service';
import { StoreService } from '../../../services/crud/store.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from '../../../services/crud/employee.service';
import { MatDialog } from '@angular/material/dialog';
import { JobPositionService } from '../../../services/crud/job-position.service';
import { NavigationService } from '../../../services/common/navigation.service';
import * as jsonData from '../../../../assets/config.json';
import {
  catchError,
  distinctUntilChanged,
  firstValueFrom,
  forkJoin,
  Observable,
  of,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';
import { Store } from '../../../model/store/store';
import { EmployeeLight } from '../../../model/employee/employee-light';
import { JobPositionLight } from '../../../model/job-position/job-position-light';
import { WeekDay } from '../../../model/store/store-weekly-shift/week-day';
import { map } from 'rxjs/operators';
import { ConfirmationDialog } from '../../shared/confirmation-dialog/confirmation-dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  checkForChanges,
  compareEmployees,
  preventNonNumericInput,
} from '../../../utils/common-utils';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { FirstLetterPipe } from '../../../utils/first-letter-pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BlurDirective } from '../../../utils/blur-directive';
import { MatSliderModule } from '@angular/material/slider';
import { StoreShiftInfo } from './store-shift-info/store-shift-info';
import { MatDivider } from '@angular/material/divider';
import { DisabledEmployeesDialog } from '../../shared/disabled-employees-dialog/disabled-employees-dialog';
import { StoreWeeklyShift } from '../../../model/store/store-weekly-shift/store-weekly-shift';
import { EditStoreRequest } from '../../../model/store/edit-store-request';
import { CreateStoreRequest } from '../../../model/store/create-store-request';
import { WeeklySeparateShift } from '../../../model/schedule/shift/weekly-separate-shift';
import { expandDownAnimated } from '../../../utils/animations';
import { CanDeactivateComponent } from '../../../utils/guards/can-deactivate-component';
import { InitializationService } from '../../../services/core/initialization-service';
import {
  NgxMatTimepickerLocaleService,
  NgxMatTimepickerModule,
} from 'ngx-mat-timepicker';
import { DatepickerService } from '../../../services/common/datepicker.service';
import { CustomColorService } from '../../../services/common/custom-color-service';

@Component({
  selector: 'app-store-form',
  standalone: true,
  imports: [
    MatCardModule,
    MatStepperModule,
    TranslateModule,
    NgIf,
    NgFor,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinner,
    AsyncPipe,
    MatError,
    MatFormField,
    MatHint,
    MatLabel,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatRadioModule,
    FirstLetterPipe,
    MatButtonToggleModule,
    FormsModule,
    NgClass,
    BlurDirective,
    MatSliderModule,
    MatDivider,
    NgxMatTimepickerModule,
  ],
  templateUrl: './store-form.html',
  animations: [expandDownAnimated],
})
export class StoreForm implements OnInit, OnDestroy, CanDeactivateComponent {
  readonly configProperties: any = jsonData;
  readonly smallBreakpoint = this.configProperties.smallBreakpoint;
  currentLocale: string = '';

  dataLoaded: boolean = false;
  errorLoadingData: boolean = false;
  requestSent: boolean = false;
  formSubmitted: boolean = false;
  hideBanners: boolean = false;

  storeInfoForm: FormGroup;
  jobPositions: JobPositionLight[] = [];
  store: Store;
  editMode: boolean = false;
  initialName: string = '';

  storeEmployeesForm: FormGroup;
  employees: EmployeeLight[] = [];
  initialEmployees: EmployeeLight[] = [];
  filteredEmployees: EmployeeLight[] = [];
  showSelectedEmployees: boolean = false;
  selectedEmployeeIds: string[] = [];

  readonly defaultStartOfWeek: string =
    this.configProperties.defaultStartOfWeek;
  weekDays: WeekDay[] = [];
  storeWeekDayShiftsForm: { [index: number]: FormGroup } = {};
  selectedWeekDay: number = 0;
  expandedShiftIndex: number | null = null;

  shiftsErrorForm = new FormGroup({
    errors: new FormControl(),
    errorDayIndex: new FormControl(),
    errorShiftIndex: new FormControl(),
  });

  storeWeeklyShiftNamesForm: FormGroup;

  @ViewChild('stepper') stepper: MatStepper;
  stepperOrientation: Observable<StepperOrientation>;
  private destroy$ = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private storeService: StoreService,
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService,
    private matStepperIntl: MatStepperIntl,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private employeeService: EmployeeService,
    private dialogRef: MatDialog,
    private formBuilder: FormBuilder,
    private jobPositionService: JobPositionService,
    private dialog: MatDialog,
    private navigationService: NavigationService,
    private initializationService: InitializationService,
    private datePickerService: DatepickerService,
    private ngxMatTimepickerLocaleService: NgxMatTimepickerLocaleService,
    protected customColorService: CustomColorService,
  ) {}

  async ngOnInit() {
    this.matStepperIntl.optionalLabel =
      this.translateService.instant('optional');

    this.currentLocale = this.datePickerService.getPickerLocale();
    this.ngxMatTimepickerLocaleService.updateLocale(this.currentLocale);

    this.loadData().subscribe(async () => {
      if (this.editMode) {
        this.populateShiftForms();
        await new Promise((resolve) => setTimeout(resolve, 0));
      }

      this.dataLoaded = true;
      this.notificationService.closeMessages();

      this.hideBanners =
        this.initializationService.getUserPreferences().hideInfoBanners ??
        false;
    });

    this.stepperOrientation = this.breakpointObserver
      .observe(this.smallBreakpoint)
      .pipe(
        map(({ matches }) => (matches ? 'vertical' : 'horizontal')),
        takeUntil(this.destroy$),
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadData() {
    this.notificationService.showActionMessage('dataLoading');

    const employees$ = this.employeeService.getEmployees().pipe(
      catchError(() => {
        return of(null);
      }),
    );

    const jobPositions$ = this.jobPositionService.getJobPositions(false).pipe(
      catchError(() => {
        return of(null);
      }),
    );

    let store$: Observable<Store | null> = of(null);

    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id) {
      this.editMode = true;
      store$ = this.storeService.getStore(id).pipe(
        catchError(() => {
          return of(null);
        }),
      );
    }

    return forkJoin({
      employees: employees$,
      jobPositions: jobPositions$,
      store: store$,
    }).pipe(
      map(({ employees, jobPositions, store }) => {
        if (!employees || !jobPositions$ || (this.editMode && !store)) {
          this.errorLoadingData = true;
          this.navigationService.setErrorLoading(true);
          return;
        }

        if (store) {
          for (let shift of store.shifts) {
            shift.startTime = shift.startTime.split(':').slice(0, 2).join(':');
            shift.endTime = shift.endTime.split(':').slice(0, 2).join(':');
          }
          this.store = store;
        } else {
          this.store = {
            name: '',
            employees: [],
            shiftsHoursOffset: 10,
            shiftsHoursOffsetOtherStores: 0,
            startOfWeek: this.defaultStartOfWeek,
            maxJobCountPerShift: 20,
            twelveHourFormat: false,
            oneShiftPerDay: true,
            shifts: [],
            separateShifts: [],
          };
        }

        this.employees = employees;
        this.filteredEmployees = [...this.employees];

        this.jobPositions = jobPositions as JobPositionLight[];

        if (this.editMode) {
          this.initialEmployees = [...(this.store.employees ?? [])];
          this.selectedEmployeeIds = this.initialEmployees.map(
            (employee) => employee.id,
          );
          this.initialName = this.store.name ?? '';
        }

        this.initForms();
      }),
    );
  }

  private initForms() {
    this.initInfoForm();
    this.initWeekDaysShiftForms();
    this.initStoreWeeklyShiftNamesForm();
    this.initEmployeesForm();
  }

  private initInfoForm() {
    this.storeInfoForm = new FormGroup({
      name: new FormControl(this.store.name, Validators.required),
      oneShiftPerDay: new FormControl(this.store.oneShiftPerDay),
      shiftsHoursOffset: new FormControl(this.store.shiftsHoursOffset),
      shiftsHoursOffsetOtherStores: new FormControl(
        this.store.shiftsHoursOffsetOtherStores,
      ),
      startOfWeek: new FormControl(this.store.startOfWeek, Validators.required),
      maxJobCountPerShift: new FormControl(this.store.maxJobCountPerShift),
      twelveHourFormat: new FormControl(
        this.store.twelveHourFormat,
        Validators.required,
      ),
    });

    this.storeInfoForm.valueChanges.subscribe((value) => {
      this.store.name = value.name ?? '';
      this.store.oneShiftPerDay = value.oneShiftPerDay ?? true;
      this.store.shiftsHoursOffset = value.shiftsHoursOffset ?? 0;
      this.store.shiftsHoursOffsetOtherStores =
        value.shiftsHoursOffsetOtherStores ?? 0;
      this.store.maxJobCountPerShift = value.maxJobCountPerShift ?? 0;
      this.store.twelveHourFormat = value.twelveHourFormat ?? false;
    });

    this.storeInfoForm.controls['startOfWeek'].valueChanges.subscribe(
      (value) => {
        this.store.startOfWeek = value ?? 'MONDAY';
        this.updateWeekDayShiftFormsOrder();
      },
    );

    this.storeInfoForm
      .get('twelveHourFormat')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        const shiftNamesFormArray = this.getWeeklyShiftNamesForm();
        for (let i = 0; i < shiftNamesFormArray.length; i++) {
          const shiftFormGroup = shiftNamesFormArray.at(i) as FormGroup;
          const timeRange = shiftFormGroup.get('timeRange')?.value;
          const [startTime, endTime] = timeRange.split(' - ');

          if (value) {
            // Convert from 24-hour format to 12-hour format
            shiftFormGroup.patchValue({
              timeRange: `${this.datePickerService.convertTimeTo12HourFormat(startTime)} - ${this.datePickerService.convertTimeTo12HourFormat(endTime)}`,
            });
          } else {
            // Convert from 12-hour format to 24-hour format
            shiftFormGroup.patchValue({
              timeRange: `${this.datePickerService.convertTimeTo24HourFormat(startTime)} - ${this.datePickerService.convertTimeTo24HourFormat(endTime)}`,
            });
          }
        }
      });
  }

  getInfoFormError() {
    if (this.storeInfoForm.controls['name'].hasError('required')) {
      return this.translateService.instant('naming');
    }
    return '';
  }

  private setWeekDaysOrder() {
    const orderOffset =
      this.store.startOfWeek === this.defaultStartOfWeek ? 0 : 1;
    this.weekDays.forEach((weekDay) => {
      switch (weekDay.name) {
        case 'monday':
          weekDay.order = (0 + orderOffset) % 7;
          break;
        case 'tuesday':
          weekDay.order = (1 + orderOffset) % 7;
          break;
        case 'wednesday':
          weekDay.order = (2 + orderOffset) % 7;
          break;
        case 'thursday':
          weekDay.order = (3 + orderOffset) % 7;
          break;
        case 'friday':
          weekDay.order = (4 + orderOffset) % 7;
          break;
        case 'saturday':
          weekDay.order = (5 + orderOffset) % 7;
          break;
        case 'sunday':
          weekDay.order = (6 + orderOffset) % 7;
          break;
      }
    });

    this.weekDays.sort((a, b) => a.order - b.order);
  }

  private initWeekDaysShiftForms() {
    this.weekDays = [
      { name: 'monday', order: 0 },
      { name: 'tuesday', order: 0 },
      { name: 'wednesday', order: 0 },
      { name: 'thursday', order: 0 },
      { name: 'friday', order: 0 },
      { name: 'saturday', order: 0 },
      { name: 'sunday', order: 0 },
    ];

    this.setWeekDaysOrder();

    this.weekDays.forEach((weekDay) => {
      this.storeWeekDayShiftsForm[weekDay.order] = this.formBuilder.group({});
    });
  }

  private updateWeekDayShiftFormsOrder() {
    // Create a temporary mapping between the weekday names and their form groups
    const tempMapping: { [name: string]: FormGroup } = {};
    for (let i = 0; i < this.weekDays.length; i++) {
      tempMapping[this.weekDays[i].name] = this.storeWeekDayShiftsForm[i];
    }

    // Store the name of the currently selected week day
    const selectedWeekDayName = this.weekDays[this.selectedWeekDay].name;

    // Update the order of the week days
    this.setWeekDaysOrder();

    // Find the new index of the selected week day
    this.selectedWeekDay = this.weekDays.findIndex(
      (weekDay) => weekDay.name === selectedWeekDayName,
    );
    this.expandedShiftIndex = null;

    // Create a temporary object to hold the reordered form groups
    const reorderedStoreWeekDayShiftsForm: { [index: number]: FormGroup } = {};

    // Iterate over the weekDays array
    for (let i = 0; i < this.weekDays.length; i++) {
      // Assign the form group from the temporary mapping to the new object using the new order of the weekDay
      reorderedStoreWeekDayShiftsForm[i] = tempMapping[this.weekDays[i].name];
    }

    // Replace the old object with the new one
    this.storeWeekDayShiftsForm = reorderedStoreWeekDayShiftsForm;
  }

  private calculateTimeOffset(startTime: string, endTime: string): number {
    const start = new Date(`1970-01-01T${startTime}:00Z`);
    const end = new Date(`1970-01-01T${endTime}:00Z`);
    let offset = end.getTime() - start.getTime();
    if (offset < 0) {
      offset += 24 * 60 * 60 * 1000;
    }
    return offset;
  }

  private addTimeOffset(time: string, offset: number): string {
    const date = new Date(`1970-01-01T${time}:00Z`);
    date.setTime(date.getTime() + offset);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  private setBreakMinutesDurationValidators(
    value: boolean,
    control: AbstractControl | null,
  ): void {
    if (value) {
      control?.enable();
      control?.setValidators([Validators.required]);
    } else {
      control?.setValue(0);
      control?.disable();
      control?.clearValidators();
    }
    control?.updateValueAndValidity();
  }

  onBreakMinutesDurationFocusOut(event: any, shiftForm: FormGroup): void {
    const value = Number(event.target.value);
    shiftForm.controls['breakMinutesDuration'].setValue(value);
  }

  private populateShiftForms() {
    // Iterate over the keys in storeWeekDayShiftsForm
    for (const weekDayOrder in this.storeWeekDayShiftsForm) {
      // Get the dayForm for the current day
      const dayForm = this.storeWeekDayShiftsForm[weekDayOrder];

      // Get the shifts for the current day from the store
      const dayShifts = this.store.shifts.filter(
        (shift) =>
          shift.dayOfWeek.toLowerCase() === this.weekDays[weekDayOrder].name,
      );

      // Group the shifts by start and end time
      const groupedShifts: Record<string, StoreWeeklyShift[]> = {};
      for (const shift of dayShifts) {
        const timeKey = `${shift.startTime}-${shift.endTime}`;
        if (!groupedShifts[timeKey]) {
          groupedShifts[timeKey] = [];
        }
        shift.jobPosition.parentShiftId = shift.id;
        groupedShifts[timeKey].push(shift);
      }

      // Iterate over the grouped shifts
      for (const timeKey in groupedShifts) {
        // Add missing job positions to the shifts
        for (const jobPosition of this.jobPositions) {
          const foundShift = groupedShifts[timeKey].find(
            (shift) => shift.jobPosition.id === jobPosition.id,
          );

          if (!foundShift && groupedShifts[timeKey].length > 0) {
            const newShift = { ...groupedShifts[timeKey][0] };
            newShift.jobPosition = jobPosition;
            newShift.requiredCount = 0;
            groupedShifts[timeKey].push(newShift);
          }
        }

        // Create a new shift form group
        const shiftFormGroup = this.formBuilder.group({
          startTime: new FormControl(
            groupedShifts[timeKey][0].startTime,
            Validators.required,
          ),
          endTime: new FormControl(
            groupedShifts[timeKey][0].endTime,
            Validators.required,
          ),
          hasBreakIncluded: new FormControl(
            groupedShifts[timeKey][0]
              ? groupedShifts[timeKey][0].breakMinutesDuration > 0
              : false,
          ),
          breakMinutesDuration: new FormControl(
            groupedShifts[timeKey][0]
              ? groupedShifts[timeKey][0].breakMinutesDuration
              : 0,
          ),
          jobPositions: this.formBuilder.array([]),
        });

        // Listen to changes in the hasBreakIncluded control
        const breakMinutesDurationControl = shiftFormGroup.get(
          'breakMinutesDuration',
        );
        shiftFormGroup
          .get('hasBreakIncluded')
          ?.valueChanges.subscribe((value) => {
            this.setBreakMinutesDurationValidators(
              value || false,
              breakMinutesDurationControl,
            );
          });

        // Check the initial value of hasBreakIncluded
        this.setBreakMinutesDurationValidators(
          shiftFormGroup.get('hasBreakIncluded')?.value || false,
          breakMinutesDurationControl,
        );

        // Add the job positions to the shift form group
        const jobPositionsFormArray = shiftFormGroup.get(
          'jobPositions',
        ) as FormArray;

        for (const shift of groupedShifts[timeKey]) {
          const jobPositionFormGroup = this.formBuilder.group({
            jobPosition: new FormControl(shift.jobPosition),
            preferredColor: new FormControl(shift.jobPosition.preferredColor),
            name: new FormControl(shift.jobPosition.name, Validators.required),
            count: new FormControl(shift.requiredCount, [Validators.required]),
          });

          jobPositionsFormArray.push(jobPositionFormGroup);
        }

        // Add the shift form group to the day form
        dayForm.addControl(
          `shift${Object.keys(dayForm.controls).length}`,
          shiftFormGroup,
        );
      }
    }
  }

  addShift() {
    const dayForm = this.storeWeekDayShiftsForm[this.selectedWeekDay];
    let previousShift: FormGroup | null = null;
    let previousShiftFromSameDay = false;

    // Look in previous days based on the index
    for (let i = this.selectedWeekDay - 1; i >= 0; i--) {
      const previousDayForm = this.storeWeekDayShiftsForm[i];
      const previousDayShifts = Object.values(previousDayForm.controls);
      const dayFormControlsArray = Object.values(dayForm.controls);
      if (previousDayShifts.length > dayFormControlsArray.length) {
        previousShift = previousDayShifts[
          dayFormControlsArray.length
        ] as FormGroup;
        break;
      }
    }

    // If no shift was found in previous days, look in the previous shift of the same day
    if (!previousShift) {
      const shifts = Object.values(dayForm.controls);
      if (shifts.length > 0) {
        previousShift = shifts[shifts.length - 1] as FormGroup;
        previousShiftFromSameDay = true;
      }
    }

    let startTime = '';
    let endTime = '';
    if (previousShift) {
      if (previousShiftFromSameDay) {
        startTime = this.datePickerService.convertTimeTo24HourFormat(
          previousShift.get('endTime')?.value,
        );
        const timeOffset = this.calculateTimeOffset(
          this.datePickerService.convertTimeTo24HourFormat(
            previousShift.get('startTime')?.value,
          ),
          this.datePickerService.convertTimeTo24HourFormat(
            previousShift.get('endTime')?.value,
          ),
        );
        endTime = this.addTimeOffset(startTime, timeOffset);
      } else {
        startTime = this.datePickerService.convertTimeTo24HourFormat(
          previousShift.get('startTime')?.value,
        );
        endTime = this.datePickerService.convertTimeTo24HourFormat(
          previousShift.get('endTime')?.value,
        );
      }
    }

    // Create a new shift form group
    const shiftFormGroup = this.formBuilder.group({
      startTime: new FormControl(startTime, Validators.required),
      endTime: new FormControl(endTime, Validators.required),
      hasBreakIncluded: new FormControl(
        previousShift?.get('hasBreakIncluded')?.value || false,
      ),
      breakMinutesDuration: new FormControl(
        previousShift?.get('breakMinutesDuration')?.value || 0,
      ),
      jobPositions: this.formBuilder.array([]),
    });

    // luxon bug with el locale
    shiftFormGroup
      .get('startTime')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (value) {
          shiftFormGroup
            .get('startTime')
            ?.setValue(
              this.datePickerService.convertTimeTo24HourFormat(value),
              { emitEvent: false },
            );
        }
      });
    shiftFormGroup
      .get('endTime')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (value) {
          shiftFormGroup
            .get('endTime')
            ?.setValue(
              this.datePickerService.convertTimeTo24HourFormat(value),
              { emitEvent: false },
            );
        }
      });

    // Listen to changes in the hasBreakIncluded control
    const breakMinutesDurationControl = shiftFormGroup.get(
      'breakMinutesDuration',
    );
    shiftFormGroup.get('hasBreakIncluded')?.valueChanges.subscribe((value) => {
      this.setBreakMinutesDurationValidators(
        value,
        breakMinutesDurationControl,
      );
    });

    // Check the initial value of hasBreakIncluded
    this.setBreakMinutesDurationValidators(
      shiftFormGroup.get('hasBreakIncluded')?.value || false,
      breakMinutesDurationControl,
    );

    // Create a map to store the count of each job position from the previous shift
    const jobPositionCountMap: { [id: string]: number } = {};

    if (previousShift) {
      const previousJobPositionsFormArray = previousShift.get(
        'jobPositions',
      ) as FormArray;
      previousJobPositionsFormArray.controls.forEach(
        (control: AbstractControl) => {
          const jobPositionFormGroup = control as FormGroup;
          const jobPositionId =
            jobPositionFormGroup.get('jobPosition')?.value.id;
          jobPositionCountMap[jobPositionId] =
            jobPositionFormGroup.get('count')?.value;
        },
      );
    }

    // Add the job positions form array to the shift form group
    for (let jobPosition of this.jobPositions) {
      const jobPositionCount = jobPositionCountMap[jobPosition.id] || 0;
      const jobPositionFormGroup = this.formBuilder.group({
        jobPosition: new FormControl(jobPosition),
        name: new FormControl(jobPosition.name, Validators.required),
        preferredColor: new FormControl(jobPosition.preferredColor),
        count: new FormControl(jobPositionCount, [Validators.required]),
      });
      (shiftFormGroup.get('jobPositions') as FormArray).push(
        jobPositionFormGroup,
      );
    }

    // Push the new shift form group to the form array of the selected day
    dayForm.addControl(
      `shift${Object.keys(dayForm.controls).length}`,
      shiftFormGroup,
    );
    dayForm.markAsDirty();
    this.expandedShiftIndex = Object.keys(dayForm.controls).length - 1;
  }

  getShiftForms(control: AbstractControl): FormGroup[] {
    if (control instanceof FormArray) {
      return control.controls as FormGroup[];
    } else if (control instanceof FormGroup) {
      return Object.values(control.controls) as FormGroup[];
    } else {
      return [];
    }
  }

  reindexControls(formGroup: FormGroup) {
    const controls = Object.keys(formGroup.controls);
    controls.forEach((controlKey, index) => {
      // Get the control
      const control = formGroup.get(controlKey);
      if (control != null) {
        // Remove the control from the form group
        formGroup.removeControl(controlKey);
        // Add the control back to the form group with the new key
        formGroup.addControl(`shift${index}`, control);
      }
    });
  }

  removeShift(index: number) {
    const dialogContentKey = this.editMode
      ? 'removeWeeklyShiftConfirmationEditMode'
      : 'removeWeeklyShiftConfirmation';
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: { dialogContentKey },
      enterAnimationDuration: 0,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Get the form group for the selected day
        const dayForm = this.storeWeekDayShiftsForm[this.selectedWeekDay];
        // Remove the shift at the specified index
        dayForm.removeControl(`shift${index}`);
        // Reindex the controls in the form group
        this.reindexControls(dayForm);
        this.expandedShiftIndex = null;
        dayForm.markAsDirty();
      }
    });
  }

  toggleExpandedShift(index: number): void {
    this.expandedShiftIndex = this.expandedShiftIndex === index ? null : index;
  }

  isShiftExpanded(index: number): boolean {
    return this.expandedShiftIndex === index;
  }

  openJobPositionsInfo() {
    this.dialog.open(StoreShiftInfo, {
      enterAnimationDuration: 0,
    });
  }

  getJobPositions(shiftForm: FormGroup): FormArray {
    return shiftForm.get('jobPositions') as FormArray;
  }

  private initStoreWeeklyShiftNamesForm() {
    let doNotSetNamesValue = false;
    if (this.editMode && this.store.separateShifts) {
      doNotSetNamesValue = this.store.separateShifts.every(
        (shift) => shift.name === null,
      );
    }

    this.storeWeeklyShiftNamesForm = this.formBuilder.group({
      doNotSetNames: new FormControl(doNotSetNamesValue),
      shiftNames: this.formBuilder.array([]),
    });

    if (this.editMode && this.store.separateShifts) {
      const shiftNamesFormArray = this.getWeeklyShiftNamesForm();
      for (const shift of this.store.separateShifts) {
        let timeRange;
        if (this.store.twelveHourFormat) {
          timeRange = `${this.datePickerService.convertTimeTo12HourFormat(shift.startTime)} - ${this.datePickerService.convertTimeTo12HourFormat(shift.endTime)}`;
        } else {
          timeRange = `${shift.startTime} - ${shift.endTime}`;
        }

        const shiftFormGroup = this.formBuilder.group({
          timeRange: new FormControl(timeRange),
          name: new FormControl(
            {
              value: shift.name,
              disabled: doNotSetNamesValue,
            },
            doNotSetNamesValue ? null : Validators.required,
          ),
        });
        shiftNamesFormArray.push(shiftFormGroup);
      }
    }

    this.storeWeeklyShiftNamesForm
      .get('doNotSetNames')
      ?.valueChanges.subscribe((value) => {
        const shiftNamesFormArray = this.getWeeklyShiftNamesForm();
        for (let i = 0; i < shiftNamesFormArray.length; i++) {
          const shiftFormGroup = shiftNamesFormArray.at(i) as FormGroup;
          const nameControl = shiftFormGroup.get('name');
          if (value) {
            nameControl?.clearValidators();
            nameControl?.disable();
          } else {
            nameControl?.setValidators(Validators.required);
            nameControl?.enable();
          }
          nameControl?.updateValueAndValidity();
        }
      });
  }

  getWeeklyShiftNamesForm() {
    return this.storeWeeklyShiftNamesForm.get('shiftNames') as FormArray;
  }

  getShiftName(shiftForm: FormGroup, index: number): string {
    const doNotSetNames =
      this.storeWeeklyShiftNamesForm.get('doNotSetNames')?.value;
    if (doNotSetNames) {
      // If doNotSetNames is true, return the default name without checking the forms
      return `${this.translateService.instant('shift')} ${index + 1}`;
    }

    let startTime;
    let endTime;

    if (this.store.twelveHourFormat) {
      startTime = this.datePickerService.convertTimeTo12HourFormat(
        shiftForm.get('startTime')?.value,
      );
      endTime = this.datePickerService.convertTimeTo12HourFormat(
        shiftForm.get('endTime')?.value,
      );
    } else {
      startTime = this.datePickerService.convertTimeTo24HourFormat(
        shiftForm.get('startTime')?.value,
      );
      endTime = this.datePickerService.convertTimeTo24HourFormat(
        shiftForm.get('endTime')?.value,
      );
    }

    const shiftNamesFormArray = this.storeWeeklyShiftNamesForm.get(
      'shiftNames',
    ) as FormArray;
    for (let i = 0; i < shiftNamesFormArray.length; i++) {
      const shiftNameFormGroup = shiftNamesFormArray.at(i) as FormGroup;
      if (
        shiftNameFormGroup.get('timeRange')?.value ===
          `${startTime} - ${endTime}` &&
        shiftNameFormGroup.get('name')?.value
      ) {
        return shiftNameFormGroup.get('name')?.value;
      }
    }

    // If no match is found, return the default name
    return `${this.translateService.instant('shift')} ${index + 1}`;
  }

  reloadShiftNames() {
    let timeRanges = new Set<string>();

    // Iterate over the keys of storeWeekDayShiftsForm
    for (const weekDayOrder in this.storeWeekDayShiftsForm) {
      // Get the dayForm for the current day
      const dayForm = this.storeWeekDayShiftsForm[weekDayOrder];

      // Iterate over the shifts in the dayForm
      for (let shiftKey in dayForm.controls) {
        const shiftFormGroup = dayForm.get(shiftKey) as FormGroup;

        const startTimeControl = shiftFormGroup.get('startTime');
        const endTimeControl = shiftFormGroup.get('endTime');

        if (startTimeControl?.valid && endTimeControl?.valid) {
          let startTime = startTimeControl.value;
          let endTime = endTimeControl.value;

          if (this.store.twelveHourFormat) {
            startTime =
              this.datePickerService.convertTimeTo12HourFormat(startTime);
            endTime = this.datePickerService.convertTimeTo12HourFormat(endTime);
          }

          // Add the time range to the timeRanges set
          timeRanges.add(`${startTime} - ${endTime}`);
        }
      }
    }

    const shiftNamesFormArray = this.getWeeklyShiftNamesForm();

    // Remove any time ranges from shiftNamesFormArray that are not in timeRanges
    for (let i = shiftNamesFormArray.length - 1; i >= 0; i--) {
      const shiftNameFormGroup = shiftNamesFormArray.at(i) as FormGroup;
      const timeRangeControl = shiftNameFormGroup.get('timeRange');
      const timeRange = timeRangeControl?.value;

      if (!timeRanges.has(timeRange)) {
        shiftNamesFormArray.removeAt(i);
      } else {
        timeRanges.delete(timeRange);
      }
    }

    // Add any time ranges from timeRanges that are not in shiftNamesFormArray
    const doNotSetNamesValue =
      this.storeWeeklyShiftNamesForm.get('doNotSetNames')?.value;
    timeRanges.forEach((timeRange) => {
      const shiftFormGroup = this.formBuilder.group({
        timeRange: new FormControl(timeRange),
        name: new FormControl(
          {
            value: '',
            disabled: doNotSetNamesValue,
          },
          doNotSetNamesValue ? null : Validators.required,
        ),
      });
      shiftNamesFormArray.push(shiftFormGroup);
    });

    // Sort the shiftNamesFormArray controls based on the start time and duration
    shiftNamesFormArray.controls.sort((a, b) => {
      const timeRangeA = a.get('timeRange')?.value.split('-');
      const timeRangeB = b.get('timeRange')?.value.split('-');

      const dateAStart = new Date(
        `1970-01-01T${this.datePickerService.convertTimeTo24HourFormat(timeRangeA[0])}:00Z`,
      );
      const dateBStart = new Date(
        `1970-01-01T${this.datePickerService.convertTimeTo24HourFormat(timeRangeB[0])}:00Z`,
      );

      const dateAEnd = new Date(
        `1970-01-01T${this.datePickerService.convertTimeTo24HourFormat(timeRangeA[1])}:00Z`,
      );
      const dateBEnd = new Date(
        `1970-01-01T${this.datePickerService.convertTimeTo24HourFormat(timeRangeB[1])}:00Z`,
      );

      if (dateAEnd.getTime() < dateAStart.getTime()) {
        dateAEnd.setDate(dateAEnd.getDate() + 1);
      }
      if (dateBEnd.getTime() < dateBStart.getTime()) {
        dateBEnd.setDate(dateBEnd.getDate() + 1);
      }

      const durationA = dateAEnd.getTime() - dateAStart.getTime();
      const durationB = dateBEnd.getTime() - dateBStart.getTime();

      if (dateAStart.getTime() !== dateBStart.getTime()) {
        return dateAStart.getTime() - dateBStart.getTime();
      } else {
        return durationA - durationB;
      }
    });
  }

  getWeeklyShiftNamesError() {
    // Check if doNotSetNames is false
    if (!this.storeWeeklyShiftNamesForm.get('doNotSetNames')?.value) {
      const shiftNamesFormArray = this.getWeeklyShiftNamesForm();
      for (let i = 0; i < shiftNamesFormArray.length; i++) {
        const shiftFormGroup = shiftNamesFormArray.at(i) as FormGroup;
        const nameControl = shiftFormGroup.get('name');
        if (nameControl?.hasError('required')) {
          return this.translateService.instant('namings');
        }
      }
    }
    return '';
  }

  isWeeklyDayShiftsFormDirty(): boolean {
    for (const key in this.storeWeekDayShiftsForm) {
      if (this.storeWeekDayShiftsForm[key].dirty) {
        return true;
      }
    }
    return false;
  }

  isWeeklyDayShiftsFormInvalid(): boolean {
    for (const key in this.storeWeekDayShiftsForm) {
      if (this.storeWeekDayShiftsForm[key].invalid) {
        return true;
      }
    }
    return false;
  }

  checkAndSetShiftsFormErrors() {
    // Clear any existing errors
    this.shiftsErrorForm.setValue({
      errors: null,
      errorDayIndex: null,
      errorShiftIndex: null,
    });
    this.shiftsErrorForm.setErrors(null);

    // Check if any form in storeWeekDayShiftsForm is invalid
    for (const key in this.storeWeekDayShiftsForm) {
      const dayForm = this.storeWeekDayShiftsForm[key];

      // Check if each shift has at least one job position with a count greater than 0
      const shifts = Object.values(dayForm.controls);
      for (const shiftKey in shifts) {
        const shift = shifts[shiftKey];
        if (shift.get('startTime')?.hasError('required')) {
          this.shiftsErrorForm.setValue({
            errors: { invalidStartTime: true },
            errorDayIndex: key,
            errorShiftIndex: shiftKey,
          });
          this.shiftsErrorForm.setErrors({ invalid: true });
          return;
        }
        if (shift.get('endTime')?.hasError('required')) {
          this.shiftsErrorForm.setValue({
            errors: { invalidEndTime: true },
            errorDayIndex: key,
            errorShiftIndex: shiftKey,
          });
          this.shiftsErrorForm.setErrors({ invalid: true });
          return;
        }
      }
    }
  }

  getWeeklyDayShiftsFormError() {
    const error = this.shiftsErrorForm.get('errors')?.value;
    if (error) {
      if (error['invalidStartTime']) {
        return this.translateService.instant('startTime');
      } else if (error['invalidEndTime']) {
        return this.translateService.instant('endTime');
      }
    }
    return '';
  }

  private initEmployeesForm() {
    this.storeEmployeesForm = new FormGroup({
      selectedEmployees: new FormControl(this.store.employees),
      selectedEmployeesSearch: new FormControl(''),
    });

    this.storeEmployeesForm.valueChanges.subscribe((value) => {
      this.store.employees = value.selectedEmployees ?? [];
    });

    this.storeEmployeesForm.controls['selectedEmployeesSearch'].valueChanges
      .pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name ? this._filterEmployeeNames(name) : this.employees.slice(),
        ),
      )
      .subscribe((filteredEmployees) => {
        this.filteredEmployees = filteredEmployees;
      });

    this.storeEmployeesForm.controls[
      'selectedEmployees'
    ].valueChanges.subscribe((selectedEmployeeIds) => {
      this.selectedEmployeeIds = selectedEmployeeIds;
    });
  }

  onStepChange(event: StepperSelectionEvent) {
    if (event.selectedIndex === 1) {
      const errorDayIndex = this.shiftsErrorForm.get('errorDayIndex')?.value;
      const errorShiftIndex =
        this.shiftsErrorForm.get('errorShiftIndex')?.value;
      if (errorDayIndex !== null && errorShiftIndex !== null) {
        this.selectedWeekDay = parseInt(errorDayIndex);
        this.expandedShiftIndex = parseInt(
          errorShiftIndex.replace('shift', ''),
        );
      }
    } else if (event.selectedIndex === 2) {
      this.reloadShiftNames();
      this.checkAndSetShiftsFormErrors();
    } else {
      this.checkAndSetShiftsFormErrors();
    }
  }

  getSelectedEmployeeNames(): string {
    return this.storeEmployeesForm.controls['selectedEmployees'].value
      .map(
        (employee: EmployeeLight) =>
          employee.firstName + ' ' + employee.lastName,
      )
      .join(', ');
  }

  private _filterEmployeeNames(name: string): EmployeeLight[] {
    const filterValue = name.toLowerCase();

    return this.employees.filter((employee) =>
      (employee.firstName + ' ' + employee.lastName)
        .toLowerCase()
        .includes(filterValue),
    );
  }

  private calculateShifts() {
    let updatedShifts = false;

    const formShifts: StoreWeeklyShift[] = this.createWeeklyShifts();

    //DEEP COPY OF SHIFTS, DO NOT OVERRIDE ORIGINAL SHIFTS
    let originalShiftsCopy: StoreWeeklyShift[] = JSON.parse(
      JSON.stringify(this.store.shifts),
    );

    let addedShifts: StoreWeeklyShift[] = [];
    for (let i = formShifts.length - 1; i >= 0; i--) {
      if (!formShifts[i].id) {
        addedShifts.push(formShifts[i]);
        formShifts.splice(i, 1);
        updatedShifts = true;
      }
    }

    let removedShifts: StoreWeeklyShift[] = [];
    for (let originalShift of originalShiftsCopy) {
      let shiftIndex = formShifts.findIndex(
        (formShift) => formShift.id === originalShift.id,
      );
      if (shiftIndex === -1) {
        removedShifts.push(originalShift);
        updatedShifts = true;
      }
    }

    originalShiftsCopy = originalShiftsCopy.filter(
      (shift) => !removedShifts.includes(shift),
    );

    if (originalShiftsCopy.length !== formShifts.length) {
      this.unexpectedError(formShifts, addedShifts, removedShifts);
    } else {
      for (let formShift of formShifts) {
        let originalShift = originalShiftsCopy.find(
          (shift) => shift.id === formShift.id,
        );

        if (!originalShift) {
          this.unexpectedError(formShifts, addedShifts, removedShifts);
          break;
        }

        if (
          originalShift.startTime !== formShift.startTime ||
          originalShift.endTime !== formShift.endTime ||
          originalShift.breakMinutesDuration !==
            formShift.breakMinutesDuration ||
          originalShift.requiredCount !== formShift.requiredCount
        ) {
          formShift.updated = true;
          updatedShifts = true;
        }
      }
    }

    return {
      formShifts,
      updatedShifts,
      addedShifts,
      removedShifts,
    };
  }

  private createWeeklyShifts(): StoreWeeklyShift[] {
    const weeklyShifts: StoreWeeklyShift[] = [];

    // Iterate over the keys in storeWeekDayShiftsForm
    for (const weekDayOrder in this.storeWeekDayShiftsForm) {
      // Get the dayForm for the current day
      const dayForm = this.storeWeekDayShiftsForm[weekDayOrder];

      // Iterate over the shifts in the dayForm
      for (let shiftKey in dayForm.controls) {
        const shiftFormGroup = dayForm.get(shiftKey) as FormGroup;

        // Get the jobPositions FormArray
        const jobPositions = shiftFormGroup.get('jobPositions') as FormArray;

        // Iterate over the jobPositions
        for (let jobPosition of jobPositions.controls) {
          const storeWeeklyShift: StoreWeeklyShift = {
            id: jobPosition.get('jobPosition')?.value.parentShiftId,
            dayOfWeek: this.weekDays[weekDayOrder].name.toUpperCase(),
            startTime: this.datePickerService.convertTimeTo24HourFormat(
              shiftFormGroup.get('startTime')?.value,
            ),
            endTime: this.datePickerService.convertTimeTo24HourFormat(
              shiftFormGroup.get('endTime')?.value,
            ),
            breakMinutesDuration: shiftFormGroup.get('breakMinutesDuration')
              ?.value,
            jobPosition: jobPosition.get('jobPosition')?.value,
            requiredCount: jobPosition.get('count')?.value,
          };

          weeklyShifts.push(storeWeeklyShift);
        }
      }
    }

    return weeklyShifts;
  }

  private createWeeklyShiftNames(): WeeklySeparateShift[] {
    const weeklyShiftNames: WeeklySeparateShift[] = [];
    this.store.separateShifts = []; // Reset the separateShifts array

    // Check if doNotSetNames is false
    if (!this.storeWeeklyShiftNamesForm.get('doNotSetNames')?.value) {
      const shiftNamesFormArray = this.getWeeklyShiftNamesForm();

      for (let i = 0; i < shiftNamesFormArray.length; i++) {
        const shiftNameFormGroup = shiftNamesFormArray.at(i) as FormGroup;

        // Get the timeRange and name FormControls
        const timeRangeControl = shiftNameFormGroup.get('timeRange');
        const nameControl = shiftNameFormGroup.get('name');

        // Get the time range and name
        const timeRange = timeRangeControl?.value;
        const name = nameControl?.value;

        // Create a new WeeklySeparateShift object
        const separateShift: WeeklySeparateShift = {
          name: name,
          startTime: this.datePickerService.convertTimeTo24HourFormat(
            timeRange.split('-')[0],
          ),
          endTime: this.datePickerService.convertTimeTo24HourFormat(
            timeRange.split('-')[1],
          ),
          ids: [], // Initialize with an empty array or any default value
        };

        // Push the new WeeklySeparateShift object into the separateShifts array
        weeklyShiftNames.push(separateShift);
        this.store.separateShifts.push(separateShift);
      }
    }

    return weeklyShiftNames;
  }

  saveStore() {
    this.reloadShiftNames();
    this.checkAndSetShiftsFormErrors();
    const doNotSetNames =
      this.storeWeeklyShiftNamesForm.get('doNotSetNames')?.value;

    if (
      this.storeInfoForm.valid &&
      this.shiftsErrorForm.valid &&
      (doNotSetNames || this.storeWeeklyShiftNamesForm.valid)
    ) {
      this.requestSent = true;
      this.notificationService.closeMessages();

      let request;
      let storeObservable;

      try {
        if (this.editMode) {
          let {
            addedList: addedEmployees,
            removedList: removedEmployees,
            updated: updatedEmployees,
          } = checkForChanges(
            this.initialEmployees,
            this.storeEmployeesForm.value.selectedEmployees,
          );

          let { formShifts, updatedShifts, addedShifts, removedShifts } =
            this.calculateShifts();
          this.store.separateShifts = this.createWeeklyShiftNames();

          request = {
            store: JSON.parse(JSON.stringify(this.store)),
            updatedName: this.initialName !== this.store.name,
            updatedEmployees: updatedEmployees,
            addedEmployees: addedEmployees,
            removedEmployees: removedEmployees,
            updatedShifts: updatedShifts,
            addedShifts: addedShifts,
            removedShifts: removedShifts,
          } as EditStoreRequest;

          // do not override, in case an error occurs
          request.store.shifts = formShifts;

          storeObservable = this.storeService.updateStore(request);
        } else {
          const storeShifts = this.createWeeklyShifts();
          this.store.separateShifts = this.createWeeklyShiftNames();

          request = {
            store: JSON.parse(JSON.stringify(this.store)),
          } as CreateStoreRequest;

          request.store.shifts = storeShifts;

          storeObservable = this.storeService.createStore(request);
        }
      } catch (error) {
        return;
      }

      storeObservable.subscribe({
        next: (data) => {
          if (data.affectedEmployees && data.affectedEmployees.length > 0) {
            const disabledEmployeesDialogRef = this.dialog.open(
              DisabledEmployeesDialog,
              {
                data: {
                  affectedEmployees: data.affectedEmployees,
                  editedStore: this.editMode,
                },
              },
            );

            disabledEmployeesDialogRef.afterClosed().subscribe(() => {
              this.handleSuccessfulSave();
            });
          } else {
            this.handleSuccessfulSave();
          }
        },
        error: (err) => {
          if (err.status === 409) {
            this.notificationService.showActionMessage('storeNamingExists');
          } else if (err.status === 425) {
            this.notificationService.showActionMessage(
              'requestFailed_SchedulerInProgress',
            );
          } else {
            this.notificationService.showActionMessage('dataError');
          }
          this.requestSent = false;
        },
      });
    } else if (!doNotSetNames && !this.storeWeeklyShiftNamesForm.valid) {
      window.scrollTo(0, 0);
      this.stepper.selectedIndex = 2;
      this.storeWeeklyShiftNamesForm.markAllAsTouched();
    }
  }

  private handleSuccessfulSave() {
    this.notificationService.showTimedMessage(
      this.editMode ? 'storeUpdated' : 'storeAdded',
    );

    this.formSubmitted = true;
    this.router.navigate(['/stores']).then(() => {});
  }

  abort() {
    if (
      this.storeInfoForm.dirty ||
      this.isWeeklyDayShiftsFormDirty() ||
      this.storeWeeklyShiftNamesForm.dirty ||
      this.storeEmployeesForm.dirty
    ) {
      const dialogRef = this.dialogRef.open(ConfirmationDialog, {
        data: { dialogContentKey: 'cancelActionConfirmation' },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.formSubmitted = true;
          this.router.navigate(['/stores']).then(() => {});
        }
      });
    } else {
      this.router.navigate(['/stores']).then(() => {});
    }
  }

  async canDeactivate(): Promise<boolean> {
    if (this.formSubmitted) {
      return true;
    }

    if (
      this.dataLoaded &&
      !this.errorLoadingData &&
      (this.storeInfoForm.dirty ||
        this.isWeeklyDayShiftsFormDirty() ||
        this.storeWeeklyShiftNamesForm.dirty ||
        this.storeEmployeesForm.dirty)
    ) {
      const dialogRef = this.dialogRef.open(ConfirmationDialog, {
        data: { dialogContentKey: 'cancelActionConfirmation' },
      });

      const result = await firstValueFrom(dialogRef.afterClosed());
      return result as boolean;
    } else {
      return true;
    }
  }

  getPositionColor(jobPosition: AbstractControl): string {
    return this.customColorService.getColor(
      jobPosition.get('preferredColor')?.value,
    );
  }

  protected readonly compareEmployees = compareEmployees;

  private unexpectedError(
    formShifts: StoreWeeklyShift[],
    addedShifts: StoreWeeklyShift[],
    removedShifts: StoreWeeklyShift[],
  ) {
    console.log('store.shifts:', JSON.stringify(this.store.shifts, null, 2));
    console.log('formShifts:', JSON.stringify(formShifts, null, 2));
    console.log('addedShifts:', JSON.stringify(addedShifts, null, 2));
    console.log('removedShifts:', JSON.stringify(removedShifts, null, 2));

    alert(
      'If you are seeing this message, contact the system administrator. This is a bug in the application. You cannot do this change at the moment.',
    );

    throw new Error('Unexpected error in calculateShifts');
  }

  protected readonly preventNonNumericInput = preventNonNumericInput;
}
