import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmployeeListResponse } from '../../model/employee/employee-list-response';
import { FilterEmployeesRequest } from '../../model/employee/filter-employees-request';
import { Employee } from '../../model/employee/employee';
import { EditEmployeeRequest } from '../../model/employee/edit-employee-request';
import { CreateEmployeeRequest } from '../../model/employee/create-employee-request';
import { EmployeeLight } from '../../model/employee/employee-light';
import { InitializationService } from '../core/initialization-service';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
  constructor(
    private http: HttpClient,
    private initializationService: InitializationService,
  ) {}

  getEmployees(): Observable<EmployeeLight[]> {
    const { sortBy, sortDir } =
      this.initializationService.getUserSortPreferences();
    return this.http.get<EmployeeLight[]>(
      `/api/employee?sortBy=${sortBy}&sortDir=${sortDir}`,
    );
  }

  getStoreEmployees(storeId: string | undefined): Observable<EmployeeLight[]> {
    return this.http.get<EmployeeLight[]>(`/api/employee/store/${storeId}`);
  }

  getEmployeeById(id: string): Observable<Employee> {
    return this.http.get<Employee>(`/api/employee/${id}`);
  }

  getFilteredEmployees(
    filterEmployeesRequest: FilterEmployeesRequest,
  ): Observable<EmployeeListResponse> {
    return this.http.post<EmployeeListResponse>(
      '/api/employee/filter',
      filterEmployeesRequest,
    );
  }

  createEmployee(
    createEmployeeRequest: CreateEmployeeRequest,
  ): Observable<Employee> {
    return this.http.post<Employee>('/api/employee', createEmployeeRequest);
  }

  updateEmployee(
    editEmployeeRequest: EditEmployeeRequest,
  ): Observable<Employee> {
    return this.http.put<Employee>(
      `/api/employee/${editEmployeeRequest.employee.id}`,
      editEmployeeRequest,
    );
  }

  deleteEmployee(id: string): Observable<void> {
    return this.http.delete<void>(`/api/employee/${id}`);
  }
}
