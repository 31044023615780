import { Component } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [MatProgressSpinner],
  template: `
    <mat-progress-spinner color="accent" mode="indeterminate">
    </mat-progress-spinner>
  `,
})
export class Spinner {}
