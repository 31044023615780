import { Component, Input } from '@angular/core';
import { Shift } from '../../../../model/schedule/generator/shift';
import { NgClass, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { EmployeeLight } from '../../../../model/employee/employee-light';
import { MatBadgeModule } from '@angular/material/badge';
import { ShiftViolationsDialog } from '../shift-violations-dialog/shift-violations-dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-shift-column',
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatTooltipModule,
    NgClass,
    TranslateModule,
    MatIconModule,
    MatBadgeModule,
  ],
  templateUrl: './shift-info-column.html',
})
export class ShiftInfoColumn {
  @Input() shift: Shift;
  @Input() storeEmployees: EmployeeLight[];
  @Input() initialShiftsEmployeesMap: { [shiftId: string]: string };

  constructor(private matDialog: MatDialog) {}

  showShiftViolations(violations: string[]) {
    this.matDialog.open(ShiftViolationsDialog, {
      data: { violations: violations },
      enterAnimationDuration: 0,
    });
  }

  isRewardedWith(rewardNames: string[]): boolean {
    return rewardNames.some((rewardName) =>
      this.shift.rewards.includes(rewardName),
    );
  }

  getEmployeeName(employeeId: string): string {
    const employee = this.storeEmployees.find((e) => e.id === employeeId);
    return employee ? employee.firstName + ' ' + employee.lastName : '';
  }
}
