import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { RetrieveCalendarShiftsRequest } from '../../model/schedule/shift/retrieve-calendar-shifts-request';
import { CalendarShifts } from '../../model/schedule/shift/calendar-shifts';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import { NotificationService } from '../common/notification.service';
import { DatepickerService } from '../common/datepicker.service';

@Injectable({ providedIn: 'root' })
export class ScheduleShiftService {
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private datepickerService: DatepickerService,
  ) {}

  getShiftsByStoreIdAndDateRange(
    retrieveCalendarShifts: RetrieveCalendarShiftsRequest,
  ): Observable<CalendarShifts> {
    return this.http.post<CalendarShifts>(
      '/api/schedule/calendar',
      retrieveCalendarShifts,
    );
  }

  exportScheduleInExcel(
    retrieveCalendarShifts: RetrieveCalendarShiftsRequest,
  ): Observable<any> {
    return this.http
      .post('/api/schedule/calendar/exporter/xlsx', retrieveCalendarShifts, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((response) => {
          let filename = retrieveCalendarShifts.blobName + '.xlsx';

          const blob = new Blob([response.body ?? ''], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(blob, filename);
        }),
        catchError((error) => {
          this.notificationService.showErrorMessage('dataError');
          return of(error);
        }),
      );
  }
}
