<div class="dialog__header" mat-dialog-title>
  <span>{{ "termsAndConditions" | translate }}</span>
  <p>{{ "termsAndConditionsAction" | translate }}</p>
</div>

<mat-divider></mat-divider>

<div class="terms__container">
  <div class="terms__languages">
    <mat-chip-listbox id="languagesChips">
      <mat-chip-option
        *ngFor="let l of languages"
        [selected]="l.name === currentLanguage"
        (click)="changeLang(l)"
        (keyup)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        [disabled]="l.name === 'el'"
        #languageChipOptions
      >
        <span
          class="chip__avatar-img"
          [innerHTML]="l.svg | svgSanitizerMedium"
        ></span>
        <span>{{ l.displayName | translate }} </span>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>

  <div>
    <ng-container *ngIf="currentLanguage === 'el'; else enTerms">
      <terms-of-use-el></terms-of-use-el>
    </ng-container>

    <ng-template #enTerms>
      <terms-of-conditions-en></terms-of-conditions-en>
    </ng-template>
  </div>
</div>

<mat-dialog-actions>
  <ng-container *ngIf="!requestSent; else spinner">
    <button (click)="acceptTerms()" type="button" mat-flat-button>
      <span>{{ "accept" | translate }} </span>
    </button>
  </ng-container>

  <ng-template #spinner>
    <button [disabled]="true" mat-flat-button>
      <mat-spinner diameter="20"></mat-spinner>
    </button>
  </ng-template>
</mat-dialog-actions>
