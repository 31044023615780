import { Routes } from '@angular/router';
import { Employees } from './pages/employees/employees';
import { Schedule } from './pages/schedule/schedule';
import { OwnerGuard } from './utils/guards/owner-guard';
import { IsLoggedIn } from './utils/guards/is-logged-in';
import { Stores } from './pages/stores/stores';
import { JobPositions } from './pages/job-positions/job-positions';
import { Calendar } from './pages/calendar/calendar';
import { AuthGuard } from './utils/guards/auth-guard';
import { Dashboard } from './pages/dashboard/dashboard';
import { UserProfile } from './pages/auth/user-profile/user-profile';
import { EmployeeForm } from './pages/employees/form/employee-form';
import { EmployeeAvailabilitiesForm } from './pages/employees/availabilities-form/employee-availabilities-form';
import { StoreForm } from './pages/stores/form/store-form';
import { CanDeactivateGuard } from './utils/guards/can-deactivate-component';
import { About } from './pages/general/about/about';
import { Versions } from './pages/general/versions/versions';
import { DashboardSteps } from './pages/dashboard/steps/dashboard-steps/dashboard-steps';
import { EmployeePairingsForm } from './pages/employees/pairings-form/employee-pairings-form/employee-pairings-form';
import { ManagerGuard } from './utils/guards/manager-guard';
import { TermsEn } from './pages/general/privacy/terms-and-conditions/languages/en/terms-en/terms-en';
import { Policies } from './pages/general/privacy/policies/policies';

export const ROUTES: Routes = [
  {
    path: 'steps',
    component: DashboardSteps,
  },
  {
    path: '',
    title: 'about',
    component: About,
    resolve: [IsLoggedIn],
  },
  {
    path: 'versions',
    title: 'versions',
    component: Versions,
    canActivate: [AuthGuard],
  },
  {
    path: 'policies',
    title: 'policies',
    component: Policies,
    canActivate: [AuthGuard],
  },
  {
    path: 'reset-password/:id',
    component: About,
    resolve: [IsLoggedIn],
    data: { resetPassword: true },
  },
  {
    path: 'register/:id',
    component: About,
    resolve: [IsLoggedIn],
    data: { register: true },
  },
  {
    path: 'dashboard',
    title: 'dashboard',
    component: Dashboard,
    canActivate: [ManagerGuard],
  },
  {
    path: 'employees',
    title: 'employees',
    component: Employees,
    canActivate: [ManagerGuard],
  },
  {
    path: 'employee',
    title: 'employee',
    component: EmployeeForm,
    canActivate: [OwnerGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'employee/:id',
    title: 'employee',
    component: EmployeeForm,
    canActivate: [OwnerGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'employee/availabilities/:id',
    title: 'availabilities',
    component: EmployeeAvailabilitiesForm,
    canActivate: [OwnerGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'employee/pairings/:id',
    title: 'pairings',
    component: EmployeePairingsForm,
    canActivate: [OwnerGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'stores',
    title: 'stores',
    component: Stores,
    canActivate: [ManagerGuard],
  },
  {
    path: 'stores/edit',
    title: 'stores',
    component: Stores,
    canActivate: [OwnerGuard],
  },
  {
    path: 'store',
    title: 'store',
    component: StoreForm,
    canActivate: [OwnerGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'store/:id',
    title: 'store',
    component: StoreForm,
    canActivate: [OwnerGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'jobs',
    title: 'jobPositions',
    component: JobPositions,
    canActivate: [ManagerGuard],
  },
  {
    path: 'jobs/add',
    title: 'jobPositions',
    component: JobPositions,
    canActivate: [OwnerGuard],
  },
  {
    path: 'schedule',
    title: 'generation',
    component: Schedule,
    canActivate: [ManagerGuard],
  },
  {
    path: 'calendar',
    title: 'calendar',
    component: Calendar,
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar/:criteria',
    title: 'calendar',
    component: Calendar,
    canActivate: [AuthGuard],
  },
  {
    path: 'user',
    title: 'user',
    component: UserProfile,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
