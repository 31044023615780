import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as jsonData from '../../../assets/config.json';
import { AppLanguage } from '../../model/app/app-language';

@Injectable({ providedIn: 'root' })
export class LanguageLoaderService {
  readonly configProperties: any = jsonData;
  languages: AppLanguage[] = [];
  currentLanguage: AppLanguage | undefined;
  systemLanguageUsed: boolean = false;

  constructor(private translateService: TranslateService) {}

  loadLanguages(): AppLanguage[] {
    this.languages = this.configProperties.languageOptions;
    return this.languages;
  }

  setLanguage(lang: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.currentLanguage = undefined;
      if (lang) {
        this.currentLanguage = this.languages.find((l) => l.name === lang);
      }

      let browserLang = navigator.language;
      if (!this.currentLanguage) {
        for (let l of this.languages) {
          if (browserLang.includes(l.name)) {
            this.currentLanguage = l;
            this.systemLanguageUsed = true;
            break;
          }
        }
      }

      if (!this.currentLanguage && this.languages.length > 0) {
        this.currentLanguage = this.languages.find((l) => l.isDefault);
      }
      if (this.currentLanguage) {
        this.translateService.use(this.currentLanguage.name);
      }
      resolve();
    });
  }

  translateValue(key: string): string {
    return this.translateService.instant(key);
  }
}
