<ng-container
  *ngIf="systemAnnouncement.message === 'systemMaintenanceScheduledOn'"
>
  <mat-card appearance="outlined" class="custom__card custom__card-banner">
    <div class="custom__card-body">
      <div class="custom__card-container">
        <div>
          <mat-icon
            class="svg-enlarged primary-color"
            (click)="toggleAnnouncementAck()"
            (keyup)="$event.stopPropagation()"
            aria-label="ok"
            svgIcon="circle_ok"
            >done
          </mat-icon>
        </div>
        <div class="custom__card-section">
          <mat-card-header>
            <mat-card-title
              >{{ systemAnnouncement.message | translate }}: {{
              datePickerService.getDateTimeInStringFormatForUI(
              systemAnnouncement.timestamp) }}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>{{ systemAnnouncement.subtitle | translate }}</p>
          </mat-card-content>
        </div>
      </div>
    </div>
  </mat-card>
</ng-container>
