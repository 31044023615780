<div class="schedule__shift-info">
  <ng-container *ngIf="!shift.swapped; else swapped">
    <ng-container *ngIf="shift.violations.length > 0; else noViolations">
      <button
        mat-icon-button
        type="button"
        (click)="showShiftViolations(shift.violations)"
        [matBadge]="shift.violations.length"
      >
        <mat-icon aria-label="violations" svgIcon="schedule_violations">
        </mat-icon>
      </button>
    </ng-container>

    <ng-template #noViolations>
      <button
        mat-icon-button
        type="button"
        class="lower__opacity"
        matTooltip="{{ 'violation.shift.none' | translate }}"
        matBadge="0"
      >
        <mat-icon aria-label="violations" svgIcon="schedule_violations">
        </mat-icon>
      </button>
    </ng-template>

    <ng-container *ngIf="shift.rewards.length > 0">
      <div class="shift__info-separator lower__opacity"></div>
    </ng-container>

    <ng-container
      *ngIf="
        isRewardedWith(['similarAssignmentExistsInPreviousWeek', 'similarAssignmentExistsInPreviousMonth']);
      "
    >
      <button
        mat-icon-button
        type="button"
        matTooltip="{{ 'reward.previousSimilarAssignmentExists' | translate }}"
      >
        <mat-icon
          aria-label="history"
          svgIcon="schedule_employee_history"
        ></mat-icon>
      </button>
    </ng-container>

    <ng-container *ngIf="isRewardedWith(['employeeHasDesiredWeeklyShift'])">
      <button
        mat-icon-button
        type="button"
        matTooltip="{{ 'reward.employeeHasDesiredWeeklyShift' | translate }}"
      >
        <mat-icon aria-label="desired" svgIcon="schedule_heart"></mat-icon>
      </button>
    </ng-container>

    <ng-container *ngIf="isRewardedWith(['employeeHasDesiredDate'])">
      <button
        mat-icon-button
        type="button"
        matTooltip="{{ 'reward.employeeHasDesiredDate' | translate }}"
      >
        <mat-icon aria-label="desired" svgIcon="schedule_stars"></mat-icon>
      </button>
    </ng-container>

    <ng-container *ngIf="isRewardedWith(['employeesAreAssignedTogether'])">
      <button
        mat-icon-button
        type="button"
        matTooltip="{{ 'reward.employeesAreAssignedTogether' | translate }}"
      >
        <mat-icon aria-label="pairing" svgIcon="employee_pairings"></mat-icon>
      </button>
    </ng-container>

    <ng-container *ngIf="isRewardedWith(['employeesAreAssignedSeparately'])">
      <button
        mat-icon-button
        type="button"
        matTooltip="{{ 'reward.employeesAreAssignedSeparately' | translate }}"
      >
        <mat-icon
          aria-label="pairing"
          svgIcon="schedule_pairing_separate"
        ></mat-icon>
      </button>
    </ng-container>

    <ng-container
      *ngIf="isRewardedWith(['employeesAreWeeklyRotated', 'employeesAreWeeklyRotatedFromOlderShifts'])"
    >
      <button
        mat-icon-button
        type="button"
        matTooltip="{{ 'reward.employeesAreWeeklyRotated' | translate }}"
      >
        <mat-icon
          aria-label="rotated"
          svgIcon="schedule_pairing_rotate"
        ></mat-icon>
      </button>
    </ng-container>
  </ng-container>

  <ng-template #swapped>
    <button
      mat-icon-button
      type="button"
      matTooltip="{{ 'initialAssignment' | translate }} {{
        getEmployeeName(initialShiftsEmployeesMap[shift.id])
      }}"
    >
      <mat-icon aria-label="person" svgIcon="schedule_person"></mat-icon>
    </button>

    <div class="shift__info-separator lower__opacity"></div>

    <button
      mat-icon-button
      type="button"
      class="lower__opacity"
      matTooltip="{{ 'violation.shift.swapped' | translate }}"
      matBadge="0"
    >
      <mat-icon aria-label="violations" svgIcon="schedule_violations">
      </mat-icon>
    </button>
  </ng-template>
</div>
