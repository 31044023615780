<mat-dialog-content>
  <ng-container *ngIf="editedJobPosition; else notEditedJobPosition"
    ><span class="disabled__warning-header"
      >{{ "autoDisabledEmployees_editedJobPosition" | translate }}
    </span>
  </ng-container>
  <ng-template #notEditedJobPosition>
    <ng-container *ngIf="removedJobPosition; else notRemovedJobPosition"
      ><span class="disabled__warning-header">{{
        "autoDisabledEmployees_deletedJobPosition" | translate
      }}</span>
    </ng-container>
  </ng-template>
  <ng-template #notRemovedJobPosition>
    <ng-container *ngIf="editedStore; else notEditedStore"
      ><span class="disabled__warning-header">{{
        "autoDisabledEmployees_editedStore" | translate
      }}</span>
    </ng-container>
  </ng-template>
  <ng-template #notEditedStore>
    <ng-container *ngIf="deletedStore"
      ><span class="disabled__warning-header">{{
        "autoDisabledEmployees_deletedStore" | translate
      }}</span>
    </ng-container>
  </ng-template>

  <mat-list>
    <mat-list-item *ngFor="let employee of affectedEmployees"
      >{{ employee }}
    </mat-list-item>
  </mat-list>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="dialogRef.close()" mat-raised-button>
    {{ "gotIt" | translate }}
  </button>
</mat-dialog-actions>
