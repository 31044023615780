import { JobPositionLight } from '../model/job-position/job-position-light';
import { Role } from '../model/user/security/role';
import { StoreLight } from '../model/store/store-light';
import { EmployeeLight } from '../model/employee/employee-light';
import { ScheduleShiftCalendar } from '../model/schedule/shift/schedule-shift-calendar';
import { WeeklySeparateShift } from '../model/schedule/shift/weekly-separate-shift';
import { AssignedShift } from '../model/schedule/shift/assigned-shift';
import { ChipSelection } from '../model/app/chip-selection';
import { JobPosition } from '../model/job-position/job-position';
import { Store } from '../model/store/store';
import { StoreMedium } from '../model/store/store-medium';
import { StoreSchedule } from '../model/store/store-schedule';
import { WeekDay } from '../model/store/store-weekly-shift/week-day';
import { EmployeeExtraLight } from '../model/employee/employee-extra-light';

export function checkForChanges(
  initialList: any[],
  finalList: any[],
): { addedList: any[]; removedList: any[]; updated: boolean } {
  let initialIds = initialList.map((item) => item.id);
  let finalIds = finalList.map((item) => item.id);

  let addedList = finalList.filter((item) => !initialIds.includes(item.id));
  let removedList = initialList.filter((item) => !finalIds.includes(item.id));

  let updated = addedList.length > 0 || removedList.length > 0;

  return { addedList, removedList, updated };
}

export function compare(
  a: number | string,
  b: number | string,
  isAsc: boolean,
) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export function compareDates(a: Date, b: Date, isAsc: boolean) {
  return (a.getTime() - b.getTime()) * (isAsc ? 1 : -1);
}

export function compareStores(store1: StoreLight, store2: StoreLight): boolean {
  return store1 && store2 ? store1.id === store2.id : store1 === store2;
}

export function compareJobPositions(
  jobPosition1: JobPositionLight,
  jobPosition2: JobPositionLight,
): boolean {
  return jobPosition1 && jobPosition2
    ? jobPosition1.id === jobPosition2.id
    : jobPosition1 === jobPosition2;
}

export function compareRoles(role1: Role, role2: Role): boolean {
  return role1 && role2 ? role1.id === role2.id : role1 === role2;
}

export function compareEmployees(
  e1: EmployeeLight,
  e2: EmployeeLight,
): boolean {
  return e1 && e2 ? e1.id === e2.id : e1 === e2;
}

export function compareEmployeeExtraLight(
  e1: EmployeeExtraLight,
  e2: EmployeeExtraLight,
): boolean {
  return e1 && e2 ? e1.id === e2.id : e1 === e2;
}

export function trackByJobPosition(index: number, item: JobPosition) {
  return item.id;
}

export function trackByJobPositionLight(index: number, item: JobPositionLight) {
  return item.id;
}

export function trackByStore(index: number, item: Store) {
  return item.id;
}

export function trackByStoreLight(index: number, item: StoreLight) {
  return item.id;
}

export function trackByStoreMedium(index: number, item: StoreMedium) {
  return item.id;
}

export function trackByStoreSchedule(index: number, item: StoreSchedule) {
  return item.id;
}

export function trackByEmployeeLight(index: number, item: EmployeeLight) {
  return item.id;
}

export function trackByDay(index: number, item: ScheduleShiftCalendar) {
  return item.date;
}

export function trackByWeeklyShift(index: number, item: WeeklySeparateShift) {
  return item.startTime + '-' + item.endTime;
}

export function trackByShift(index: number, item: AssignedShift) {
  return item.id;
}

export function trackByChip(index: number, item: ChipSelection) {
  return item.name;
}

export function trackByString(index: number, item: string) {
  return item;
}

export function trackByRole(index: number, item: Role) {
  return item.id;
}

export function trackByWeekDay(index: number, item: WeekDay) {
  return item.order;
}

export function preventNonNumericInput(event: any): boolean {
  // Allow: backspace, delete, tab, escape, enter and .
  if (
    [46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
    // Allow: Ctrl+A
    (event.keyCode === 65 && event.ctrlKey === true) ||
    // Allow: Ctrl+C
    (event.keyCode === 67 && event.ctrlKey === true) ||
    // Allow: Ctrl+X
    (event.keyCode === 88 && event.ctrlKey === true) ||
    // Allow: home, end, left, right
    (event.keyCode >= 35 && event.keyCode <= 39)
  ) {
    // let it happen, don't do anything
    return true;
  }
  // Ensure that it is a number and stop the keypress
  if (
    (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
    (event.keyCode < 96 || event.keyCode > 105)
  ) {
    event.preventDefault();
    return false;
  }
  return true;
}

export function formatScheduleMinutes(minutes: number): string {
  return minutes.toString().padStart(2, '0');
}
