import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ScheduleShiftService } from '../../services/crud/schedule-shift.service';
import { RetrieveCalendarShiftsRequest } from '../../model/schedule/shift/retrieve-calendar-shifts-request';
import { NotificationService } from '../../services/common/notification.service';
import { CalendarShifts } from '../../model/schedule/shift/calendar-shifts';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Spinner } from '../shared/spinner/spinner';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DatepickerService } from '../../services/common/datepicker.service';
import { StoreService } from '../../services/crud/store.service';
import { Store } from '../../model/store/store';
import {
  catchError,
  distinctUntilChanged,
  forkJoin,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { StoreLight } from '../../model/store/store-light';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { DateAdapter, provideNativeDateAdapter } from '@angular/material/core';
import { MatIconButton } from '@angular/material/button';
import { MatChipOption, MatChipsModule } from '@angular/material/chips';
import { BreakpointObserver } from '@angular/cdk/layout';
import { InitializationService } from '../../services/core/initialization-service';
import { NavigationService } from '../../services/common/navigation.service';
import { MatCardModule } from '@angular/material/card';
import * as jsonData from '../../../assets/config.json';
import { ChipSelection } from '../../model/app/chip-selection';
import {
  trackByChip,
  trackByStoreLight,
  trackByString,
} from '../../utils/common-utils';
import { expandDownAnimated } from '../../utils/animations';
import { CryptoService } from '../../services/common/crypto-service';
import { ActivatedRoute } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScheduleExporterService } from '../../services/common/schedule-exporter/schedule-exporter-service';
import { SvgSanitizerSmall } from '../../utils/svg-sanitizer-small';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CalendarTable } from './views/table/calendar-table';
import { CalendarList } from './views/list/calendar-list/calendar-list';
import { ScrollToTop } from '../shared/scroll-to-top/scroll-to-top';
import { SvgSanitizerMedium } from '../../utils/svg-sanitizer-medium';

@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [
    NgIf,
    Spinner,
    NgClass,
    MatCardModule,
    MatIconButton,
    MatTooltipModule,
    MatIconModule,
    MatChipsModule,
    NgForOf,
    SvgSanitizerSmall,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatProgressSpinner,
    CalendarTable,
    CalendarList,
    ScrollToTop,
    SvgSanitizerMedium,
  ],
  templateUrl: './calendar.html',
  providers: [provideNativeDateAdapter()],
  animations: [expandDownAnimated],
})
export class Calendar implements OnInit, OnDestroy {
  readonly configProperties: any = jsonData;
  readonly extraSmallBreakpoint = this.configProperties.extraSmallBreakpoint;
  currentLocale: string = '';

  dataLoaded: boolean = false;
  errorLoadingData: boolean = false;
  noStoresAvailable: boolean = false;

  calendarForm: FormGroup;
  criteriaVisible: boolean = false;

  stores: StoreLight[] = [];
  @ViewChildren('storeChips') storeChips: QueryList<MatChipOption>;
  selectedStore: StoreLight;
  storeStartOfWeek: number;

  readonly calendarLayoutOptions: ChipSelection[] =
    this.configProperties.calendarLayoutOptions;
  currentLayoutSelection: string;
  @ViewChildren('layoutChips') layoutChips: QueryList<MatChipOption>;

  readonly dateRangeOptions: string[] = [
    'dailyRange',
    'weeklyRange',
    'monthlyRange',
    'selection',
  ];
  dateRangeSelection: string;
  @ViewChildren('dateRangeChips') dateRangeChips: QueryList<MatChipOption>;

  startDate: Date = new Date();
  endDate: Date = new Date();

  loadingShifts: boolean = false;
  calendarShiftsLoaded: boolean = false;

  calendarShifts: CalendarShifts;

  scheduleExportInProgress: boolean = false;
  private scheduleExportSubscription: Subscription;

  constructor(
    private scheduleShiftService: ScheduleShiftService,
    private notificationService: NotificationService,
    protected datepickerService: DatepickerService,
    private storeService: StoreService,
    private cryptoService: CryptoService,
    private _adapter: DateAdapter<any>,
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService,
    private initializationService: InitializationService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private scheduleExporterService: ScheduleExporterService,
  ) {}

  async ngOnInit() {
    this.loadData().subscribe(() => {
      this.dataLoaded = true;
    });

    this.currentLocale = this.datepickerService.getPickerLocale();

    this.scheduleExportSubscription = this.scheduleExporterService
      .getScheduleExportInProgress()
      .subscribe((status) => (this.scheduleExportInProgress = status));
  }

  ngOnDestroy() {
    this.scheduleExportSubscription.unsubscribe();
  }

  loadData() {
    let stores$: Observable<Store[] | StoreLight[] | null>;

    if (
      this.initializationService.isAuthenticateUserOwner() ||
      this.initializationService.isAuthenticateUserManager()
    ) {
      stores$ = this.storeService.getBusinessStores(false);
    } else {
      stores$ = this.storeService.getEmployeeStores();
    }

    stores$ = stores$.pipe(
      catchError(() => {
        return of(null);
      }),
    );

    return forkJoin({ stores: stores$ }).pipe(
      map(({ stores }) => {
        if (!stores) {
          this.errorLoadingData = true;
          this.navigationService.setErrorLoading(true);
          return;
        }

        if (stores.length === 0) {
          this.noStoresAvailable = true;
          return;
        }

        this.stores = stores as StoreLight[];

        this.initForm();

        const preferredLayout =
          this.initializationService.getUserPreferences().calendarLayout;
        if (preferredLayout) {
          const layoutOption = this.calendarLayoutOptions.find(
            (option) => option.name === preferredLayout,
          );
          this.currentLayoutSelection = layoutOption
            ? layoutOption.name
            : this.calendarLayoutOptions[0].name;
        } else {
          this.currentLayoutSelection = this.calendarLayoutOptions[0].name;
        }

        this.dateRangeSelection = this.breakpointObserver.isMatched(
          this.extraSmallBreakpoint,
        )
          ? this.dateRangeOptions[0]
          : this.dateRangeOptions[1];

        let loadedStoreRequest = null;
        let loadedCompleteRequest: RetrieveCalendarShiftsRequest | null = null;

        let criteria;
        let criteriaParam =
          this.activatedRoute.snapshot.paramMap.get('criteria');
        if (criteriaParam) {
          try {
            criteria = JSON.parse(
              this.cryptoService.decryptData(criteriaParam),
            );
          } catch (e) {
            criteria = criteriaParam;
          }
          criteria = this.cryptoService.decryptData(criteriaParam);

          try {
            criteria = JSON.parse(criteria);

            loadedCompleteRequest = {
              storeId: criteria.storeId,
              startDate: criteria.startDate,
              endDate: criteria.endDate,
            };

            if (
              loadedCompleteRequest.startDate === loadedCompleteRequest.endDate
            ) {
              this.dateRangeSelection = this.dateRangeOptions[0];
            } else {
              this.dateRangeSelection = this.dateRangeOptions[3];
            }
          } catch {
            if (typeof criteria === 'string') {
              loadedStoreRequest = criteria;
            }
          }
        }

        if (loadedStoreRequest) {
          this.selectStore(
            this.stores.find((store) => store.id === loadedStoreRequest) ||
              this.stores[0],
          );
        } else if (loadedCompleteRequest) {
          this.selectStore(
            this.stores.find(
              (store) => store.id === loadedCompleteRequest.storeId,
            ) || this.stores[0],
            true,
          );

          this.startDate = new Date(loadedCompleteRequest.startDate);
          this.endDate = new Date(loadedCompleteRequest.endDate);
          this.calendarForm.get('startDate')?.setValue(this.startDate);
          this.calendarForm.get('endDate')?.setValue(this.endDate);
        } else {
          this.selectStore(this.stores[0]);
        }
      }),
    );
  }

  private initForm() {
    this._adapter.setLocale(this.datepickerService.getPickerLocale());

    this.calendarForm = new FormGroup({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
    });

    this.calendarForm
      .get('startDate')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (this.datepickerService.isValidDate(value)) {
          this.startDate = new Date(value);
        }
      });

    this.calendarForm
      .get('endDate')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (this.datepickerService.isValidDate(value)) {
          this.endDate = new Date(value);
          setTimeout(() => {
            this.loadShifts();
          }, 0);
        }
      });
  }

  selectTableLayout(l: ChipSelection) {
    if (l.name === this.currentLayoutSelection) {
      const index = this.calendarLayoutOptions.indexOf(l);
      const chipToSelect = this.layoutChips.toArray()[index];
      if (chipToSelect) {
        chipToSelect.selected = true;
      }
    } else {
      this.currentLayoutSelection = l.name;
      this.initializationService.updateCalendarLayoutPreference(l.name);
    }
  }

  selectStore(s: StoreLight, bypassDateRangeSelection?: boolean) {
    if (s === this.selectedStore) {
      const index = this.stores.indexOf(s);
      const chipToSelect = this.storeChips.toArray()[index];
      if (chipToSelect) {
        chipToSelect.selected = true;
      }
    } else {
      this.selectedStore = s;
      this.storeStartOfWeek = this.datepickerService.defineStartOfWeek(
        this.selectedStore.startOfWeek,
      );
      this._adapter.getFirstDayOfWeek = () => this.storeStartOfWeek;

      if (!bypassDateRangeSelection) {
        this.setupScheduleDateRange();
      }
    }
  }

  selectDateRange(d: string) {
    if (d === this.dateRangeSelection) {
      const index = this.dateRangeOptions.indexOf(d);
      const chipToSelect = this.dateRangeChips.toArray()[index];
      if (chipToSelect) {
        chipToSelect.selected = true;
      }
    } else {
      this.dateRangeSelection = d;

      if (d === this.dateRangeOptions[1] || d === this.dateRangeOptions[2]) {
        this.setupScheduleDateRange();
      } else if (d === this.dateRangeOptions[0]) {
        this.calendarForm.get('endDate')?.setValue('');
        this.startDate = new Date(this.startDate);
        this.endDate = new Date(this.startDate);
        this.calendarForm.get('startDate')?.setValue(this.startDate);
        this.calendarForm.get('endDate')?.setValue(this.endDate);
      }
    }
  }

  private setupScheduleDateRange() {
    this.calendarForm.get('endDate')?.setValue('');
    let newStartDate = new Date(this.startDate);
    let newEndDate = new Date(this.endDate);

    if (this.dateRangeSelection === this.dateRangeOptions[1]) {
      // weekly
      newStartDate = this.datepickerService.getCurrentWeekOrMonthStartDate(
        this.storeStartOfWeek,
        true,
        newStartDate,
      );
      newEndDate = new Date(newStartDate);
      newEndDate.setDate(newStartDate.getDate() + 6); // End of the week
    } else if (this.dateRangeSelection === this.dateRangeOptions[2]) {
      // monthly
      newStartDate = this.datepickerService.getCurrentWeekOrMonthStartDate(
        this.storeStartOfWeek,
        false,
        newStartDate,
      );
      newEndDate = new Date(
        newStartDate.getFullYear(),
        newStartDate.getMonth() + 1,
        0,
      ); // End of the month
    }

    this.startDate = newStartDate;
    this.endDate = newEndDate;

    this.calendarForm.get('startDate')?.setValue(this.startDate);
    this.calendarForm.get('endDate')?.setValue(this.endDate);
  }

  changeDateRange(direction: string) {
    let dateIncrement = direction === 'next' ? 1 : -1;

    let newStartDate = new Date(this.startDate);
    let newEndDate = new Date(this.endDate);

    if (this.dateRangeSelection === this.dateRangeOptions[0]) {
      // daily
      newStartDate.setDate(newStartDate.getDate() + dateIncrement);
      newEndDate.setDate(newEndDate.getDate() + dateIncrement);
    } else if (this.dateRangeSelection === this.dateRangeOptions[1]) {
      // weekly
      let startOfWeek = newStartDate.getDay();
      let diff =
        (startOfWeek < this.storeStartOfWeek ? 7 : 0) +
        startOfWeek -
        this.storeStartOfWeek;
      newStartDate = new Date(
        newStartDate.setDate(newStartDate.getDate() - diff + dateIncrement * 7),
      );
      newEndDate = new Date(newStartDate);
      newEndDate.setDate(newStartDate.getDate() + 6); // End of the week
    } else if (this.dateRangeSelection === this.dateRangeOptions[2]) {
      // monthly
      newStartDate.setMonth(newStartDate.getMonth() + dateIncrement);
      newEndDate = new Date(
        newStartDate.getFullYear(),
        newStartDate.getMonth() + 1,
        0,
      ); // End of the month
    }

    this.startDate = newStartDate;
    this.endDate = newEndDate;

    this.calendarForm.get('startDate')?.setValue(this.startDate);
    this.calendarForm.get('endDate')?.setValue(this.endDate);
  }

  private loadShifts() {
    if (this.calendarForm.valid && this.dataLoaded) {
      this.loadingShifts = true;

      this.scheduleShiftService
        .getShiftsByStoreIdAndDateRange(this.populateCalendarRequestBody())
        .subscribe({
          next: (data) => {
            this.calendarShifts = data;
            this.loadingShifts = false;
            this.calendarShiftsLoaded = true;
          },
          error: (error) => {
            this.calendarShiftsLoaded = false;
            this.loadingShifts = false;
            if (error.status === 404) {
              //do nothing, a message is shown
            } else {
              this.notificationService.showTimedMessage('dataError');
            }
          },
        });
    }
  }

  protected populateCalendarRequestBody(): RetrieveCalendarShiftsRequest {
    let startDateString = this.datepickerService.getDateInStringFormatForUI(
      this.startDate,
    );
    let endDateString = this.datepickerService.getDateInStringFormatForUI(
      this.endDate,
    );

    let blobName =
      this.selectedStore.name +
      ', (' +
      startDateString +
      (startDateString === endDateString ? '' : ' - ' + endDateString) +
      ')';

    return {
      storeId: this.selectedStore.id,
      lang: this.translateService.currentLang,
      startDate: this.datepickerService.getDateInStringFormat(
        this.calendarForm.get('startDate')?.value,
      ),
      endDate: this.datepickerService.getDateInStringFormat(
        this.calendarForm.get('endDate')?.value,
      ),
      blobName: blobName,
    };
  }

  protected readonly trackByStoreLight = trackByStoreLight;
  protected readonly trackByChip = trackByChip;
  protected readonly trackByString = trackByString;
}
