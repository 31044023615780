import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmployeeSourcePairing } from '../../model/employee/pairing/employee-source-pairing';
import { SetEmployeePairingsRequest } from '../../model/employee/pairing/set-employee-pairings-request';
import { InitializationService } from '../core/initialization-service';

@Injectable({ providedIn: 'root' })
export class EmployeePairingService {
  constructor(
    private http: HttpClient,
    private initializationService: InitializationService,
  ) {}

  getEmployeePairings(id: string): Observable<EmployeeSourcePairing> {
    const { sortBy, sortDir } =
      this.initializationService.getUserSortPreferences();
    return this.http.get<EmployeeSourcePairing>(
      `/api/employee/pairing/${id}?sortBy=${sortBy}&sortDir=${sortDir}`,
    );
  }

  setEmployeePairings(request: SetEmployeePairingsRequest): Observable<void> {
    return this.http.put<void>('/api/employee/pairing', request);
  }
}
