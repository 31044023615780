import {
  animate,
  keyframes,
  query,
  sequence,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const slideIn = trigger('slideIn', [
  state(
    'in',
    style({
      transform: 'translateY(0)',
    }),
  ),
  transition('void => *', [
    style({ transform: 'translateY(100%)' }),
    animate('500ms ease-in-out'),
  ]),
]);

export const shake = trigger('shake', [
  state(
    'inactive',
    style({
      transform: 'translateX(0)',
    }),
  ),
  transition('* => active', [
    animate(
      '0.5s',
      keyframes([
        style({ transform: 'translateX(0)', offset: 0 }),
        style({ transform: 'translateX(-10px)', offset: 0.1 }),
        style({ transform: 'translateX(10px)', offset: 0.2 }),
        style({ transform: 'translateX(-10px)', offset: 0.3 }),
        style({ transform: 'translateX(10px)', offset: 0.4 }),
        style({ transform: 'translateX(-10px)', offset: 0.5 }),
        style({ transform: 'translateX(10px)', offset: 0.6 }),
        style({ transform: 'translateX(-10px)', offset: 0.7 }),
        style({ transform: 'translateX(0)', offset: 1.0 }),
      ]),
    ),
  ]),
  transition('active => inactive', [animate('0.5s')]),
]);

export const slideFromLeft = trigger('slideFromLeft', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),
    animate('0.8s ease', style({ transform: 'translateX(0)' })),
  ]),
]);

export const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    sequence([
      style({ opacity: 0 }),
      animate('1s 0.2s ease', style({ opacity: 1 })),
    ]),
  ]),
]);

export const popUp = trigger('popUp', [
  state(
    'void',
    style({
      transform: 'scale(0.5)',
      opacity: 0,
    }),
  ),
  state(
    '*',
    style({
      transform: 'scale(1)',
      opacity: 1,
    }),
  ),
  transition('void <=> *', [animate('0.5s {{delay}}s ease-out')]),
]);

export const scaleUp = trigger('scaleUp', [
  state(
    'default',
    style({
      transform: 'scale(1)',
    }),
  ),
  state(
    'hovered',
    style({
      transform: 'scale(1.04)',
    }),
  ),
  transition('default <=> hovered', [animate('0.5s')]),
]);

export const reorder = trigger('reorder', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ transform: 'translateY(-100%)', opacity: 0 }),
        animate(
          '0.5s ease-in-out',
          style({ transform: 'translateY(0%)', opacity: 1 }),
        ),
      ],
      { optional: true },
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateY(0%)', opacity: 1 }),
        animate(
          '0.5s ease-in-out',
          style({ transform: 'translateY(-100%)', opacity: 0 }),
        ),
      ],
      { optional: true },
    ),
  ]),
]);

export const fadeInDelayed = trigger('fadeInDelayed', [
  transition('void => *', [
    style({ opacity: 0 }),
    animate('0.5s {{delay}}s ease-out', style({ opacity: 1 })),
  ]),
  transition('* => void', [
    animate('0.5s {{delay}}s ease-out', style({ opacity: 0 })),
  ]),
]);

export const expandDown = trigger('expandDown', [
  state('collapsed,void', style({ height: '0px', minHeight: '0' })),
  state('expanded', style({ height: '*' })),
  transition(
    'expanded <=> collapsed',
    animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
  ),
]);

export const slideToggle = trigger('slideToggle', [
  state('false', style({ height: '0px', opacity: '0' })),
  state('true', style({ height: '*', opacity: '1' })),
  transition('false <=> true', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
]);

export const slideInOut = trigger('slideInOut', [
  state('in', style({ transform: 'translateX(0)' })),
  transition('void => *', [
    style({ transform: 'translateX(100%)' }),
    animate(200),
  ]),
  transition('* => void', [
    animate(200, style({ transform: 'translateX(100%)' })),
  ]),
]);

export const appearInOut = trigger('appearInOut', [
  state(
    'in',
    style({
      transform: 'translateX(0)',
      opacity: '1',
      visibility: 'visible',
      height: 'auto',
      overflow: 'hidden',
    }),
  ),
  state(
    'out',
    style({
      transform: 'translateX(-100%)',
      opacity: '0',
      visibility: 'hidden',
      height: '0',
      overflow: 'hidden',
    }),
  ),
  transition('in => out', [animate('400ms ease-in-out')]),
  transition('out => in', [animate('400ms ease-in-out')]),
]);

export const expandCollapse = trigger('expandCollapse', [
  state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
  state('expanded', style({ height: '*' })),
  transition(
    'expanded <=> collapsed',
    animate('225ms cubic-bezier(0.4,0.0,0.2,1)'),
  ),
]);

export const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'translateY(-15px)' }),
        stagger(
          '50ms',
          animate(
            '550ms ease-out',
            style({ opacity: 1, transform: 'translateY(0px)' }),
          ),
        ),
      ],
      { optional: true },
    ),
  ]),
]);

export const expandDownAnimated = trigger('expandDownAnimated', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('0.5s ease-in', style({ opacity: 1 })),
  ]),
  transition(':leave', [animate('0.5s ease-out', style({ opacity: 0 }))]),
]);

export const fadeInOut = trigger('fadeInOut', [
  state(
    'void',
    style({
      opacity: 0,
    }),
  ),
  transition('void <=> *', animate(200)),
]);
