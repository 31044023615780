<div class="flex__box dialog__header" mat-dialog-title>
  <span>{{
    editMode ? ("editJobPosition" | translate) : ("addJobPosition" | translate)
  }}</span>

  <div class="dialog__header-actions">
    <ng-container *ngIf="editMode">
      <button
        mat-icon-button
        type="button"
        class="stroked__icon-button"
        [matMenuTriggerFor]="menu"
        aria-label="actions"
        [disabled]="requestSent"
        matTooltip="{{ 'moreOptions' | translate }}"
      >
        <mat-icon aria-label="actions">more_vert</mat-icon>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteJobPosition()">
            <mat-icon aria-label="delete">delete</mat-icon>
            <span>{{ "delete" | translate }}</span>
          </button>
        </mat-menu>
      </button>
    </ng-container>

    <button
      (click)="abort()"
      [disabled]="requestSent"
      aria-label="close"
      class="stroked__icon-button"
      mat-icon-button
      type="button"
      matTooltip="{{ 'close' | translate }}"
    >
      <mat-icon aria-label="close">close</mat-icon>
    </button>
  </div>
</div>

<mat-divider></mat-divider>

<form [formGroup]="jobPositionForm">
  <mat-dialog-content>
    <mat-form-field class="form__field">
      <mat-label>{{ "jobPositionName" | translate }}</mat-label>
      <input
        formControlName="name"
        matInput
        placeholder="{{ 'namePlaceholder' | translate }}"
      />
      <mat-error *ngIf="jobPositionForm.controls['name'].hasError('required')"
        >{{ "nameRequired" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form__field">
      <mat-label>{{ "displayColor" | translate }}</mat-label>
      <mat-select formControlName="preferredColor">
        <mat-option>
          <div class="flex__box">
            <div class="legend__color"></div>
            <span>{{ "none" | translate }}</span>
          </div>
        </mat-option>
        <mat-option
          *ngFor="let color of preferredColorOptions"
          [value]="color.color"
        >
          <div class="flex__box">
            <div
              class="legend__color"
              [style.background-color]="color.color"
            ></div>
            <span>{{ color.name }}</span>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="form__field zero__padding">
      <mat-label>{{ "jobPositionEmployees" | translate }}</mat-label>
      <mat-select
        [compareWith]="compareEmployees"
        formControlName="selectedEmployees"
        multiple
        placeholder="{{ 'selectEmployeesPlaceholder' | translate }}"
      >
        <mat-select-trigger>
          {{ selectedEmployeeIds.length }} {{ "selectedEmployees" | translate }}
        </mat-select-trigger>
        <mat-option>
          <ngx-mat-select-search
            formControlName="selectedEmployeesSearch"
            noEntriesFoundLabel="{{ 'noResults' | translate }}"
            placeholderLabel="{{ 'searchName' | translate }}"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let employee of filteredEmployees"
          [value]="employee"
          >{{ employee.firstName }} {{ employee.lastName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="selectedEmployeeIds.length === 0; else employeesNames">
      <mat-hint class="light__text">
        {{ "jobPositionEmployeesNotRequired" | translate }}
      </mat-hint>
    </ng-container>
    <ng-template #employeesNames>
      <mat-hint
        (click)="showSelectedEmployees = !showSelectedEmployees"
        (keydown.enter)="showSelectedEmployees = !showSelectedEmployees"
        class="aligned__div light__text on__hover"
        tabindex="0"
      >
        {{ "showEmployeeNames" | translate }}
        <mat-icon aria-label="expand"
          >{{ showSelectedEmployees ? "arrow_drop_up" : "arrow_drop_down" }}
        </mat-icon>
      </mat-hint>
      <div *ngIf="showSelectedEmployees" class="light__text no__hover">
        {{ getSelectedEmployeeNames() }}
      </div>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button (click)="abort()" [disabled]="requestSent" mat-stroked-button>
      {{ "cancel" | translate }}
    </button>
    <ng-container *ngIf="!requestSent; else spinner">
      <button
        (click)="saveJobPosition()"
        type="submit"
        [disabled]="jobPositionForm.invalid || !jobPositionForm.dirty"
        mat-flat-button
      >
        <span>{{ "save" | translate }} </span>
        <mat-icon aria-label="save" style="vertical-align: top">save</mat-icon>
      </button>
    </ng-container>

    <ng-template #spinner>
      <button [disabled]="true" mat-flat-button>
        <mat-spinner diameter="20"></mat-spinner>
      </button>
    </ng-template>
  </mat-dialog-actions>
</form>
