<ng-container *ngIf="dataLoaded && !errorLoadingData">
  <div class="employee__forms-name secondary-color">
    <span>{{ employee.firstName }} {{ employee.lastName }}</span>
  </div>

  <div #tabGroup>
    <mat-tab-group (selectedTabChange)="onTabChange($event)" color="accent">
      <mat-tab>
        <ng-template mat-tab-label>
          <span
            [matBadge]="getDateAvailabilitiesCount()"
            matBadgeOverlap="false"
            matBadgeSize="medium"
            >{{ "calendar" | translate }}</span
          >
        </ng-template>

        <ng-container
          *ngIf="unavailabilityAffectedShifts.length === 0; else affectedShifts"
        >
          <mat-card class="mat-mdc-card-outlined">
            <mat-card-content>
              <div [@appearInOut]="datesLegends" class="legend__container">
                <div class="flex__box">
                  <div class="legend__color desired"></div>
                  <span>{{ "desiredDate" | translate }}</span>
                </div>
                <div class="flex__box">
                  <div class="legend__color undesired"></div>
                  <span>{{ "undesiredDate" | translate }}</span>
                </div>
                <div class="flex__box">
                  <div class="legend__color unavailable"></div>
                  <span>{{ "unavailable" | translate }}</span>
                </div>
              </div>

              <div
                [@appearInOut]="datesButtons"
                class="availability__selections"
              >
                <span class="availability__selection_header secondary-color"
                  >{{ datepickerService.getDateInStringFormatForUI(selectedDate)
                  }}</span
                >

                <div class="legend__container">
                  <button
                    (click)="
                      setDateAvailabilityType(AvailabilityType.Desired, true)
                    "
                    [ngClass]="{
                      'button__availability-selected':
                        selectedDateAvailabilityType ===
                        AvailabilityType.Desired,
                    }"
                    class="button__availability-desired"
                    mat-flat-button
                  >
                    <span>{{ "desiredDate" | translate }}</span>
                  </button>

                  <button
                    (click)="
                      setDateAvailabilityType(AvailabilityType.Undesired, true)
                    "
                    [ngClass]="{
                      'button__availability-selected':
                        selectedDateAvailabilityType ===
                        AvailabilityType.Undesired,
                    }"
                    class="button__availability-undesired"
                    mat-flat-button
                  >
                    <span>{{ "undesiredDate" | translate }}</span>
                  </button>

                  <button
                    (click)="
                      setDateAvailabilityType(
                        AvailabilityType.Unavailable,
                        true
                      )
                    "
                    [ngClass]="{
                      'button__availability-selected':
                        selectedDateAvailabilityType ===
                        AvailabilityType.Unavailable,
                    }"
                    class="button__availability-unavailable"
                    mat-flat-button
                  >
                    <span>{{ "unavailable" | translate }}</span>
                  </button>

                  <button
                    (click)="
                      setDateAvailabilityType(AvailabilityType.Null, true)
                    "
                    class="button__availability-unselect"
                    mat-icon-button
                    type="button"
                  >
                    <mat-icon
                      aria-label="cancel"
                      matTooltip="{{ 'delete' | translate }}"
                      >undo
                    </mat-icon>
                  </button>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card class="aligned__div mat-mdc-card-outlined">
            <mat-card-content class="date__availabilities-form">
              <mat-calendar
                class="availabilities__calendar"
                #calendar
                (selectedChange)="addDateAvailability($event, true)"
                [dateClass]="dateClass"
                [minDate]="today"
              >
              </mat-calendar>
            </mat-card-content>
          </mat-card>
        </ng-container>

        <ng-template #affectedShifts>
          <mat-card class="mat-mdc-card-outlined">
            <mat-card-header>
              <mat-card-title>
                {{ "foundUnavailableAffectedStoreShiftsHeader" | translate }}
                <p>
                  {{ "foundUnavailableAffectedStoreShiftsSubtitle1" | translate
                  }}
                </p>
                <p>
                  {{ "foundUnavailableAffectedStoreShiftsSubtitle2" | translate
                  }}
                </p>
                <p>
                  {{ "foundUnavailableAffectedStoreShiftsSubtitle3" | translate
                  }}
                </p>
                <p>
                  {{ "foundUnavailableAffectedStoreShiftsSubtitle4" | translate
                  }}
                </p>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="affected__shifts-container">
                <div
                  class="affected__shifts-card"
                  *ngFor="
                    let affectedWeeklyShift of unavailabilityAffectedShifts;
                    let i = index
                  "
                >
                  <div class="affected__shifts-info">
                    {{ affectedWeeklyShift.dayOfWeek | lowercase | translate }},
                    {{ datepickerService.getDateInStringFormatForUI(
                    affectedWeeklyShift.unavailabilityDate) }} ({{
                    affectedWeeklyShift.storeName }})
                  </div>
                  <mat-divider></mat-divider>

                  <ng-container
                    *ngIf="
                      affectedWeeklyShift.affectedScheduleShifts.length > 0
                    "
                  >
                    <div class="table__container">
                      <table
                        mat-table
                        [dataSource]="
                          affectedWeeklyShift.affectedScheduleShifts
                        "
                      >
                        <ng-container
                          matColumnDef="action"
                          class="affected__shifts-action-column"
                        >
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "assignments" | translate }}
                          </th>
                          <td mat-cell *matCellDef="let shift">
                            <button
                              mat-icon-button
                              type="button"
                              matTooltip="{{ 'replacementSuggestion' | translate }}"
                            >
                              <mat-icon aria-label="swap"
                                >swap_horizontal_circle
                              </mat-icon>
                            </button>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="startDate">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "startDate" | translate }}
                          </th>
                          <td mat-cell *matCellDef="let assignment">
                            {{ datepickerService.formatDateForSchedule(
                            assignment.startDate,
                            affectedWeeklyShift.twelveHourFormat) }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="endDate">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "endDate" | translate }}
                          </th>
                          <td mat-cell *matCellDef="let assignment">
                            {{ datepickerService.formatDateForSchedule(
                            assignment.endDate,
                            affectedWeeklyShift.twelveHourFormat) }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "shift" | translate }}
                          </th>
                          <td mat-cell *matCellDef="let assignment">
                            {{ assignment.name ? assignment.name : ("noName" |
                            translate) }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="jobPositionName">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "jobPosition" | translate }}
                          </th>
                          <td mat-cell *matCellDef="let assignment">
                            {{ assignment.jobPositionName }}
                          </td>
                        </ng-container>

                        <tr
                          mat-header-row
                          *matHeaderRowDef="assignmentsDisplayedColumn"
                        ></tr>
                        <tr
                          mat-row
                          *matRowDef="
                            let row;
                            columns: assignmentsDisplayedColumn
                          "
                          [@listAnimation]
                        ></tr>
                      </table>
                    </div>
                  </ng-container>

                  <ng-container
                    *ngIf="
                  affectedWeeklyShift.affectedScheduleShifts.length > 0 && affectedWeeklyShift.affectedWeeklyShifts.length > 0
                  "
                  >
                    <div
                      class="affected__shifts-separator lowest__opacity"
                    ></div>
                  </ng-container>

                  <ng-container
                    *ngIf="affectedWeeklyShift.affectedWeeklyShifts.length > 0"
                  >
                    <div class="table__container">
                      <table
                        mat-table
                        [dataSource]="affectedWeeklyShift.affectedWeeklyShifts"
                      >
                        <ng-container matColumnDef="action">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "weeklyShifts" | translate }}
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let shift"
                            class="affected__shifts-action-column"
                          >
                            <button
                              mat-icon-button
                              type="button"
                              *ngIf="
                                !shiftExistsInReducedList(
                                  shift.id,
                                  affectedWeeklyShift.unavailabilityDate
                                );
                                else addButton
                              "
                              (click)="
                                reduceWeeklyShift(
                                  shift,
                                  affectedWeeklyShift.unavailabilityDate
                                )
                              "
                              matTooltip="{{ 'reduce' | translate }}"
                            >
                              <mat-icon aria-label="reduce"
                                >remove_circle
                              </mat-icon>
                            </button>
                            <ng-template #addButton>
                              <button
                                mat-icon-button
                                type="button"
                                (click)="
                                  undoReduceWeeklyShift(
                                    shift,
                                    affectedWeeklyShift.unavailabilityDate
                                  )
                                "
                                matTooltip="{{ 'undo' | translate }}"
                              >
                                <mat-icon aria-label="increase">undo</mat-icon>
                              </button>
                            </ng-template>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="startTime">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "startTime" | translate }}
                          </th>
                          <td mat-cell *matCellDef="let shift">
                            {{ datepickerService.formatUITime(shift.startTime,
                            affectedWeeklyShift.twelveHourFormat) }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="endTime">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "endTime" | translate }}
                          </th>
                          <td mat-cell *matCellDef="let shift">
                            {{ datepickerService.formatUITime(shift.endTime,
                            affectedWeeklyShift.twelveHourFormat) }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "shift" | translate }}
                          </th>
                          <td mat-cell *matCellDef="let shift">
                            {{ shift.name ? shift.name : ("noName" | translate)
                            }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="jobPositionName">
                          <th mat-header-cell *matHeaderCellDef>
                            {{ "jobPosition" | translate }}
                          </th>
                          <td mat-cell *matCellDef="let shift">
                            {{ shift.jobPosition.name }} ({{ shift.requiredCount
                            }})
                          </td>
                        </ng-container>

                        <tr
                          mat-header-row
                          *matHeaderRowDef="weeklyShiftsDisplayedColumn"
                        ></tr>
                        <tr
                          mat-row
                          *matRowDef="
                            let row;
                            columns: weeklyShiftsDisplayedColumn
                          "
                          [@listAnimation]
                        ></tr>
                      </table>
                    </div>
                  </ng-container>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-template>
      </mat-tab>
      <mat-tab
        *ngFor="
          let store of employee.stores;
          let i = index;
          trackBy: trackByStoreMedium
        "
      >
        <ng-template mat-tab-label>
          <span
            [matBadge]="getStoreShiftAvailabilitiesCount(store.id)"
            matBadgeOverlap="false"
            matBadgeSize="medium"
            >{{ store.name }}</span
          >
        </ng-template>

        <ng-container *ngIf="hasStoreShifts(store.id); else noShiftsAlert">
          <mat-card class="mat-mdc-card-outlined">
            <mat-card-content>
              <div [@appearInOut]="shiftsLegends" class="legend__container">
                <div class="flex__box">
                  <div class="legend__color desired"></div>
                  <span>{{ "desiredShift" | translate }}</span>
                </div>
                <div class="flex__box">
                  <div class="legend__color undesired"></div>
                  <span>{{ "undesiredShift" | translate }}</span>
                </div>
                <div class="flex__box">
                  <div class="legend__color unavailable"></div>
                  <span>{{ "unavailable" | translate }}</span>
                </div>
                <div class="flex__box">
                  <div class="legend__color shifts__unassigned-cell"></div>
                  <span>{{ "nonAvailableShift" | translate }}</span>
                </div>
              </div>

              <div
                [@appearInOut]="shiftsButtons"
                class="availability__selections"
              >
                <ng-container *ngIf="selectedWeeklyShiftAvailability">
                  <span class="availability__selection_header secondary-color"
                    >{{ selectedWeeklyShiftAvailability.dayOfWeek.toLowerCase()
                    | translate }} {{ datepickerService.formatUITime(
                    selectedWeeklyShiftAvailability.startTime,
                    store.twelveHourFormat) }} - {{
                    datepickerService.formatUITime(
                    selectedWeeklyShiftAvailability.endTime,
                    store.twelveHourFormat) }} / {{
                    selectedWeeklyShiftAvailability.jobPosition.name }} @if
                    (selectedWeeklyShiftAvailability.name) {
                    <br />
                    ({{ selectedWeeklyShiftAvailability.name }}) }
                  </span>
                </ng-container>
                <div class="legend__container">
                  <button
                    (click)="
                      setWeeklyShiftAvailability(AvailabilityType.Desired)
                    "
                    [ngClass]="{
                      'button__availability-selected':
                        selectedWeeklyShiftAvailabilityType ===
                        AvailabilityType.Desired,
                    }"
                    class="button__availability-desired"
                    mat-flat-button
                  >
                    <span>{{ "desiredShift" | translate }}</span>
                  </button>

                  <button
                    (click)="
                      setWeeklyShiftAvailability(AvailabilityType.Undesired)
                    "
                    [ngClass]="{
                      'button__availability-selected':
                        selectedWeeklyShiftAvailabilityType ===
                        AvailabilityType.Undesired,
                    }"
                    class="button__availability-undesired"
                    mat-flat-button
                  >
                    <span>{{ "undesiredShift" | translate }}</span>
                  </button>

                  <button
                    (click)="
                      setWeeklyShiftAvailability(AvailabilityType.Unavailable)
                    "
                    [ngClass]="{
                      'button__availability-selected':
                        selectedWeeklyShiftAvailabilityType ===
                        AvailabilityType.Unavailable,
                    }"
                    class="button__availability-unavailable"
                    mat-flat-button
                  >
                    <span>{{ "unavailable" | translate }}</span>
                  </button>

                  <button
                    (click)="setWeeklyShiftAvailability(AvailabilityType.Null)"
                    class="button__availability-unselect"
                    mat-icon-button
                    type="button"
                  >
                    <mat-icon
                      aria-label="cancel"
                      matTooltip="{{ 'delete' | translate }}"
                      >undo
                    </mat-icon>
                  </button>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card class="mat-mdc-card-outlined">
            <mat-card-content>
              <div class="shifts__availabilities-container">
                <table>
                  <tr>
                    <th></th>
                    <ng-container
                      *ngFor="
                        let shift of separateShiftsMap.get(store.id);
                        let i = index;
                        trackBy: trackByWeeklyShift
                      "
                    >
                      <th class="shift__info">
                        <span
                          >{{ datepickerService.formatUITime(shift.startTime,
                          store.twelveHourFormat) }} - {{
                          datepickerService.formatUITime(shift.endTime,
                          store.twelveHourFormat) }}</span
                        >
                        <br />
                        <span [style.color]="shift.preferredColor"
                          >{{ shift.jobPositionName }}</span
                        >
                        <p>{{ shift.name }}</p>
                      </th>
                    </ng-container>
                  </tr>
                  <tr
                    *ngFor="
                      let day of store.startOfWeek === 'MONDAY'
                        ? DAYS_STARTING_MONDAY
                        : DAYS_STARTING_SUNDAY
                    "
                  >
                    <th class="days__column">
                      {{ day.toLowerCase() | translate }}
                    </th>
                    <ng-container
                      *ngFor="
                        let daySeparateShift of separateShiftsMap.get(store.id);
                        let i = index;
                        trackBy: trackByWeeklyShift
                      "
                    >
                      <ng-container
                        *ngIf="
                          retrieveStoreShiftTableCell(
                            daySeparateShift,
                            store,
                            day
                          ) as storeWeeklyShift;
                          else noStoreWeeklyShift
                        "
                      >
                        <td
                          (click)="addWeeklyShiftAvailability(storeWeeklyShift)"
                          (keyup)="$event.stopPropagation()"
                          (keydown)="$event.stopPropagation()"
                          [ngClass]="{
                            'shifts__desired-cell':
                              getShiftAvailabilityType(storeWeeklyShift.id) ===
                              AvailabilityType.Desired,
                            'shifts__undesired-cell':
                              getShiftAvailabilityType(storeWeeklyShift.id) ===
                              AvailabilityType.Undesired,
                            'shifts__unavailable-cell':
                              getShiftAvailabilityType(storeWeeklyShift.id) ===
                              AvailabilityType.Unavailable,
                            'shifts__selected-cell':
                              storeWeeklyShift ===
                                selectedWeeklyShiftAvailability &&
                              selectedWeeklyShiftAvailabilityType ===
                                AvailabilityType.Null,
                          }"
                        ></td>
                      </ng-container>
                      <ng-template #noStoreWeeklyShift>
                        <td class="shifts__unassigned-cell">
                          <mat-icon aria-label="no shifts"
                            >hourglass_disabled
                          </mat-icon>
                        </td>
                      </ng-template>
                    </ng-container>
                  </tr>
                </table>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>

        <ng-template #noShiftsAlert>
          <mat-card
            appearance="outlined"
            class="custom__card custom__card-alert"
          >
            <div class="custom__card-body">
              <div class="custom__card-container">
                <div>
                  <mat-icon aria-label="no shifts">error</mat-icon>
                </div>
                <div class="custom__card-section">
                  <mat-card-header>
                    <mat-card-title
                      >{{ "noneShifts" | translate }}
                    </mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <p>{{ "assignedEmployeeNoShifts" | translate }}</p>
                  </mat-card-content>
                </div>
              </div>
            </div>
          </mat-card>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>

  <mat-card style="margin-top: 0" class="form__actions-waved">
    <mat-card-content class="separate__container-actions">
      <button (click)="abort()" [disabled]="requestSent" mat-stroked-button>
        {{ "cancel" | translate }}
      </button>
      <button
        (click)="setAvailabilities()"
        [disabled]="requestSent || !availabilitiesUpdated"
        mat-flat-button
      >
        <mat-spinner *ngIf="requestSent" diameter="20"></mat-spinner>
        <span *ngIf="!requestSent">{{ "save" | translate }} </span>
        <mat-icon aria-label="save" *ngIf="!requestSent">save</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <app-scroll-to-top></app-scroll-to-top>
</ng-container>
