import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StoreService } from '../../services/crud/store.service';
import { NotificationService } from '../../services/common/notification.service';
import {
  catchError,
  concatMap,
  delay,
  distinctUntilChanged,
  forkJoin,
  interval,
  of,
  retry,
  Subscription,
} from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { DateAdapter, provideNativeDateAdapter } from '@angular/material/core';
import { DatepickerService } from '../../services/common/datepicker.service';
import * as jsonData from '../../../assets/config.json';
import { StoreSchedule } from '../../model/store/store-schedule';
import { GenerateScheduleRequest } from '../../model/schedule/generator/generate-schedule-request';
import { ScheduleGeneratorService } from '../../services/crud/schedule-generator.service';
import { ScheduleStatus } from '../../model/schedule/generator/schedule-status';
import { Shift } from '../../model/schedule/generator/shift';
import { MatDialog } from '@angular/material/dialog';
import { OverlappingShiftsDialog } from './overlapping-shifts-dialog/overlapping-shifts-dialog';
import { ConfirmationDialog } from '../shared/confirmation-dialog/confirmation-dialog';
import { Router, RouterLink } from '@angular/router';
import { EmployeeLight } from '../../model/employee/employee-light';
import { EmployeeService } from '../../services/crud/employee.service';
import { EmployeeSwap } from '../../model/schedule/generator/employee-swap';
import { FinalizeScheduleRequest } from '../../model/schedule/generator/finalize-schedule-request';
import { RetrieveCalendarShiftsRequest } from '../../model/schedule/shift/retrieve-calendar-shifts-request';
import { MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import {
  compare,
  compareDates,
  compareEmployees,
  formatScheduleMinutes,
  trackByChip,
  trackByEmployeeLight,
  trackByStoreSchedule,
} from '../../utils/common-utils';
import { ScheduleEmployee } from '../../model/schedule/generator/schedule-employee';
import { MatTabsModule } from '@angular/material/tabs';
import { InitializationService } from '../../services/core/initialization-service';
import { NavigationService } from '../../services/common/navigation.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { MatChipOption, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatSlideToggle,
  MatSlideToggleChange,
} from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { ChipSelection } from '../../model/app/chip-selection';
import { ScrollToTop } from '../shared/scroll-to-top/scroll-to-top';
import { SvgSanitizerMedium } from '../../utils/svg-sanitizer-medium';
import { expandCollapse, listAnimation } from '../../utils/animations';
import { CryptoService } from '../../services/common/crypto-service';
import { ScheduleShiftService } from '../../services/crud/schedule-shift.service';
import { CalendarShifts } from '../../model/schedule/shift/calendar-shifts';
import { PreviewCalendarDialog } from './preview-calendar/preview-calendar/preview-calendar-dialog';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ScheduleExplanations } from './explanations/schedule-explanations/schedule-explanations';
import { CustomColorService } from '../../services/common/custom-color-service';
import { ShiftInfoColumn } from './generation-info/shift-column/shift-info-column';
import { EmployeeColumn } from './generation-info/employee-column/employee-column';

@Component({
  selector: 'app-schedule',
  standalone: true,
  imports: [
    MatTabsModule,
    NgIf,
    TranslateModule,
    MatCardModule,
    MatIconModule,
    MatDivider,
    RouterLink,
    ReactiveFormsModule,
    MatChipsModule,
    NgFor,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSlideToggle,
    NgClass,
    MatProgressSpinner,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    MatBadgeModule,
    ScrollToTop,
    SvgSanitizerMedium,
    FormsModule,
    ScheduleExplanations,
    ShiftInfoColumn,
    EmployeeColumn,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './schedule.html',
  animations: [
    expandCollapse,
    listAnimation,
    trigger('slideOut', [
      state('in', style({ transform: 'translateX(0)', opacity: 1 })),
      state('out', style({ transform: 'translateX(100%)', opacity: 0 })),
      transition('in => out', [animate('0.5s ease-out')]),
    ]),
  ],
})
export class Schedule implements OnInit, OnDestroy {
  readonly configProperties: any = jsonData;
  currentLocale: string = '';

  dataLoaded: boolean = false;
  errorLoadingData: boolean = false;
  noStoresAvailable: boolean = false;

  requestSent: boolean = false;
  generationInProgress: boolean = false;

  stores: StoreSchedule[] = [];
  selectedStore: StoreSchedule;
  scheduleForm: FormGroup;

  readonly today: Date = this.datepickerService.getToday();
  readonly twoMonthsLater: Date = new Date(this.today);
  tomorrow: Date = new Date(this.today);

  startDate: string;
  endDate: string;

  scheduleStatusSubscription: Subscription;
  storeEmployeesSubscription: Subscription;
  tempShiftsSubscription: Subscription;
  scheduleProblemId: string;
  slowSolver: boolean = false;
  scheduleNotification: boolean = false;

  scheduleProblemShiftsCount: number = 0;
  scheduleProblemReducedShiftsCount: number = 0;
  shiftsLoaded: boolean = false;
  shiftsLoadingAnimationOut: boolean = false;
  generatedShifts: Shift[] = [];
  scheduleEmployees: ScheduleEmployee[] = [];
  foundOverlappingShifts: boolean = false;
  overwriteOlderShifts: boolean = false;
  shiftsHaveViolations: boolean = false;

  selectedStartDateRest: string;
  selectedEndDateRest: string;

  readonly scheduleTableOptions: ChipSelection[] =
    this.configProperties.scheduleTableOptions;
  currentTableSelection: string = this.scheduleTableOptions[0].name;

  @ViewChildren('scheduleTableChipOptions')
  scheduleTableChipOptions: QueryList<MatChipOption>;

  shiftsDisplayedColumns: string[] = [
    'name',
    'startDate',
    'endDate',
    'totalDuration',
    'jobPositionName',
    'employee',
    'information',
  ];

  employeesDisplayedColumns: string[] = [
    'firstName',
    'lastName',
    'contractHours',
    'totalAssignments',
    'information',
  ];

  storeEmployees: EmployeeLight[] = [];
  initialShiftsEmployeesMap: { [shiftId: string]: string } = {};
  shiftsEmployeesMap: { [shiftId: string]: string } = {};

  selectedTabIndex: number = 0;

  @ViewChildren('storesChips')
  storesChips: QueryList<MatChipOption>;

  readonly calendarLayoutOptions: ChipSelection[] =
    this.configProperties.calendarLayoutOptions;
  preferredUserLayout: string;
  previewEnabled: boolean = false;
  calendarShifts: CalendarShifts | null;

  autoSetSlowSolver: boolean = false;

  constructor(
    private storeService: StoreService,
    private notificationService: NotificationService,
    private _adapter: DateAdapter<any>,
    private datepickerService: DatepickerService,
    private scheduleService: ScheduleGeneratorService,
    private matDialog: MatDialog,
    private router: Router,
    private employeeService: EmployeeService,
    private translateService: TranslateService,
    private initializationService: InitializationService,
    private navigationService: NavigationService,
    private cryptoService: CryptoService,
    private scheduleShiftService: ScheduleShiftService,
    protected customColorService: CustomColorService,
  ) {}

  async ngOnInit() {
    this.slowSolver =
      this.initializationService.getUserPreferences().slowSolver || false;

    this.scheduleNotification =
      this.initializationService.getUserPreferences().scheduleNotification ||
      false;

    this.tomorrow.setDate(this.tomorrow.getDate() + 1);

    this.currentLocale = this.datepickerService.getPickerLocale();

    this.loadData().subscribe(() => {
      this.dataLoaded = true;
    });

    const layout =
      this.initializationService.getUserPreferences().calendarLayout;
    if (layout) {
      this.preferredUserLayout = layout;
    } else {
      this.preferredUserLayout =
        this.configProperties.calendarLayoutOptions[0].name;
    }
  }

  ngOnDestroy() {
    this.unSubscriptions();
  }

  private unSubscriptions() {
    if (this.scheduleStatusSubscription) {
      this.scheduleStatusSubscription.unsubscribe();
    }
    if (this.storeEmployeesSubscription) {
      this.storeEmployeesSubscription.unsubscribe();
    }
    if (this.tempShiftsSubscription) {
      this.tempShiftsSubscription.unsubscribe();
    }
  }

  private loadData() {
    const stores$ = this.storeService.getSchedulerStores().pipe(
      catchError(() => {
        return of(null);
      }),
    );

    return forkJoin({
      stores: stores$,
    }).pipe(
      map(({ stores }) => {
        if (!stores) {
          this.errorLoadingData = true;
          this.navigationService.setErrorLoading(true);
          return;
        }
        if (stores.length === 0) {
          this.noStoresAvailable = true;
          this.selectedTabIndex = 2;
          return;
        }

        this.initForm();
        this.stores = stores;

        this.stores.some((store) => {
          if (
            store?.scheduleGeneration?.id &&
            !store.scheduleGeneration.finalized
          ) {
            this.generationInProgress = true;
            this.selectedStore = store;
            this.slowSolver = store.scheduleGeneration.slowSolver;
            this.selectedStartDateRest =
              this.datepickerService.getDateInStringFormat(
                store.scheduleGeneration.startDate,
              );
            this.selectedEndDateRest =
              this.datepickerService.getDateInStringFormat(
                store.scheduleGeneration.endDate,
              );
            this.scheduleProblemId = store.scheduleGeneration.id;
            this.startDate = this.datepickerService.getDateInStringFormatForUI(
              store.scheduleGeneration.startDate,
            );
            this.endDate = this.datepickerService.getDateInStringFormatForUI(
              store.scheduleGeneration.endDate,
            );
            this.scheduleProblemShiftsCount =
              store.scheduleGeneration.producedShifts;
            this.scheduleProblemReducedShiftsCount =
              store.scheduleGeneration.reducedShifts;
            this.setupCalendarDates(store);
            return true;
          }
          return false;
        });

        if (this.generationInProgress) {
          this.selectedTabIndex = 1;
          this.requestSent = true;
          this.fetchScheduleStatus(0, true);
        } else {
          this.selectStore(this.stores[0]);
        }
      }),
    );
  }

  private initForm() {
    this._adapter.setLocale(this.datepickerService.getPickerLocale());

    this.scheduleForm = new FormGroup({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      slowSolver: new FormControl(this.slowSolver || false),
    });

    this.scheduleForm
      .get('startDate')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        if (this.scheduleForm.get('endDate')?.value) {
          this.scheduleForm.get('endDate')?.reset();
        }
        this.dateRangeMarginCalculation();
        this.foundOverlappingShifts = false;
      });
    this.scheduleForm
      .get('endDate')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        this.dateRangeMarginCalculation();
        this.foundOverlappingShifts = false;
      });

    this.scheduleForm
      .get('slowSolver')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (!this.autoSetSlowSolver) {
          this.initializationService.updateSlowSolverPreference(value);
        }
        this.slowSolver = value;
      });
  }

  selectStore(s: StoreSchedule) {
    if (s === this.selectedStore) {
      const index = this.stores.indexOf(s);
      const chipToSelect = this.storesChips.toArray()[index];
      if (chipToSelect) {
        chipToSelect.selected = true;
      }
    } else {
      this.selectedStore = s;
      this.setupCalendarDates(null);
    }
  }

  private setupCalendarDates(s: StoreSchedule | null) {
    const firstDayOfWeek = this.datepickerService.defineStartOfWeek(
      this.selectedStore.startOfWeek,
    );
    this._adapter.getFirstDayOfWeek = () => firstDayOfWeek;

    if (s) {
      this.scheduleForm
        .get('startDate')
        ?.setValue(new Date(s.scheduleGeneration.startDate));
      this.scheduleForm
        .get('endDate')
        ?.setValue(new Date(s.scheduleGeneration.endDate));
    } else {
      const startDate =
        this.datepickerService.calculateNextAvailableWeekOrMonthStartDate(
          firstDayOfWeek,
          true,
        );
      this.scheduleForm.get('startDate')?.setValue(startDate);

      if (this.selectedStore.latestExecutionRange > 0) {
        let endDate = new Date(startDate);
        endDate.setDate(
          endDate.getDate() + this.selectedStore.latestExecutionRange,
        );
        this.scheduleForm.get('endDate')?.setValue(endDate);
      }
    }
  }

  generateSchedule() {
    if (this.scheduleForm.valid) {
      this.requestSent = true;

      this.notificationService.closeMessages();
      this.notificationService.showActionMessage('dataLoading');

      const sd = this.datepickerService.getDateInStringFormat(
        this.scheduleForm.get('startDate')?.value,
      );
      this.selectedStartDateRest = sd;

      const ed = this.datepickerService.getDateInStringFormat(
        this.scheduleForm.get('endDate')?.value,
      );
      this.selectedEndDateRest = ed;

      const generateScheduleRequest: GenerateScheduleRequest = {
        storeId: this.selectedStore.id,
        startDate: sd,
        endDate: ed,
        slowSolver: this.slowSolver,
        overwriteOlderShifts: this.overwriteOlderShifts,
      };

      this.overwriteOlderShifts = false;

      this.startDate = this.datepickerService.getDateInStringFormatForUI(
        this.scheduleForm.get('startDate')?.value,
      );
      this.endDate = this.datepickerService.getDateInStringFormatForUI(
        this.scheduleForm.get('endDate')?.value,
      );

      this.scheduleService.generateSchedule(generateScheduleRequest).subscribe({
        next: (data) => {
          this.navigationService.setInitializationProcess(false);
          this.generationInProgress = true;
          this.selectedTabIndex = 1;
          this.scheduleProblemId = data.id;
          if (data.producedShifts && data.producedShifts > 0) {
            this.scheduleProblemShiftsCount = data.producedShifts;
          }
          if (data.reducedShifts && data.reducedShifts > 0) {
            this.scheduleProblemReducedShiftsCount = data.reducedShifts;
          }
          this.fetchScheduleStatus(
            this.slowSolver
              ? this.configProperties.fetchScheduleGenerationSlow
              : this.configProperties.fetchScheduleGenerationQuick,
            false,
          );
          this.notificationService.closeMessages();
          this.notificationService.showTimedMessage(
            'scheduleGenerationInProgressAlert',
          );
        },
        error: (err) => {
          this.notificationService.closeMessages();
          if (err.status === 409) {
            this.detectedOverlappingShifts();
          } else if (err.status === 411) {
            this.notificationService.showActionMessage('noShiftsToGenerate');
          } else if (err.status === 412) {
            this.notificationService.showActionMessage('noEmployeesToGenerate');
          } else {
            this.notificationService.showTimedMessage('scheduleErrorAlert');
          }
          this.requestSent = false;
        },
      });
    }
  }

  private detectedOverlappingShifts() {
    const dialogRef = this.matDialog.open(OverlappingShiftsDialog, {
      enterAnimationDuration: 0,
      data: { calendarRequest: this.createCalendarViewRequest() },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.overwriteOlderShifts = true;
        this.generateSchedule();
      } else {
        this.foundOverlappingShifts = true;
      }
    });
  }

  regenerateSchedule() {
    this.requestSent = true;

    this.notificationService.closeMessages();
    this.notificationService.showActionMessage('dataLoading');

    this.slowSolver = true;
    this.updateShiftsLoadedValue(false);
    this.generatedShifts = [];
    this.scheduleEmployees = [];
    this.previewEnabled = false;
    this.calendarShifts = null;
    this.shiftsHaveViolations = false;

    const generateScheduleRequest: GenerateScheduleRequest = {
      id: this.scheduleProblemId,
      storeId: this.selectedStore.id,
      startDate: this.selectedStartDateRest,
      endDate: this.selectedEndDateRest,
      slowSolver: this.slowSolver,
      overwriteOlderShifts: false,
    };

    this.scheduleService
      .retryScheduleGeneration(generateScheduleRequest)
      .subscribe({
        next: (data) => {
          this.scheduleProblemId = data.id;
          if (data.producedShifts && data.producedShifts > 0) {
            this.scheduleProblemShiftsCount = data.producedShifts;
          }
          if (data.reducedShifts && data.reducedShifts > 0) {
            this.scheduleProblemReducedShiftsCount = data.reducedShifts;
          }
          this.shiftsEmployeesMap = {};
          this.initialShiftsEmployeesMap = {};
          this.fetchScheduleStatus(
            this.slowSolver
              ? this.configProperties.fetchScheduleGenerationSlow
              : this.configProperties.fetchScheduleGenerationQuick,
            false,
          );
          this.notificationService.closeMessages();
          this.notificationService.showTimedMessage(
            'scheduleRegenerationInProgressAlert',
          );
        },
        error: () => {
          this.requestSent = false;
          this.notificationService.closeMessages();
          this.notificationService.showTimedMessage('dataError');
        },
      });
  }

  private fetchScheduleStatus(intervalDelay: number, forced: boolean = false) {
    let errorCount = 0;
    this.updateShiftsLoadedValue(false);

    this.scheduleStatusSubscription = interval(intervalDelay)
      .pipe(
        concatMap(() => {
          return forkJoin({
            schedule: this.scheduleService
              .getScheduleStatus(this.scheduleProblemId, this.slowSolver)
              .pipe(
                catchError((error) => {
                  errorCount++;
                  if (
                    errorCount >=
                    this.configProperties.fetchScheduleGenerationRetry
                  ) {
                    this.scheduleService
                      .deleteScheduleJob(
                        this.scheduleProblemId,
                        this.slowSolver,
                      )
                      .pipe()
                      .subscribe();
                    this.notificationService.showTimedMessage(
                      'scheduleErrorAlert',
                    );
                    this.cancelledSchedulerAftermath();
                    throw error;
                  }
                  return of(error).pipe(
                    delay(intervalDelay),
                    retry(
                      forced
                        ? 0
                        : this.configProperties.fetchScheduleGenerationRetry,
                    ),
                  );
                }),
              ),
          });
        }),
      )
      .subscribe({
        next: ({ schedule }) => {
          if (schedule.status === ScheduleStatus.NOT_SOLVING) {
            this.scheduleStatusSubscription.unsubscribe();
            this.generatedShifts = schedule.shifts;
            this.scheduleProblemShiftsCount = schedule.shifts.length;
            this.scheduleEmployees = schedule.employees;
            this.shiftsHaveViolations = this.generatedShifts.some((shift) =>
              shift.violations.some((v) => Number(v) > 0),
            );

            const { sortBy, sortDir } =
              this.initializationService.getScheduleShiftSort();
            const sortEvent: Sort = {
              active: sortBy,
              direction: sortDir as SortDirection,
            };
            this.sortShifts(sortEvent, false);

            this.loadStoreEmployees(0, true);
          } else if (forced) {
            this.scheduleStatusSubscription.unsubscribe();
            this.fetchScheduleStatus(
              this.slowSolver
                ? this.configProperties.fetchScheduleGenerationSlow
                : this.configProperties.fetchScheduleGenerationQuick,
              false,
            );
          }
        },
      });
  }

  private loadStoreEmployees(intervalDelay: number, forced: boolean = false) {
    let errorCount = 0;

    this.storeEmployeesSubscription = interval(intervalDelay)
      .pipe(
        concatMap(() => {
          return forkJoin({
            employees: this.employeeService
              .getStoreEmployees(this.selectedStore.id)
              .pipe(
                catchError((error) => {
                  errorCount++;
                  if (
                    errorCount >=
                    this.configProperties.fetchScheduleGenerationRetry
                  ) {
                    this.notificationService.showErrorMessage(
                      'dataErrorRefresh',
                    );
                    throw error;
                  }
                  return of(error).pipe(
                    delay(intervalDelay / 2),
                    retry(
                      forced
                        ? 0
                        : this.configProperties.fetchScheduleGenerationRetry,
                    ),
                  );
                }),
              ),
          });
        }),
      )
      .subscribe({
        next: ({ employees }) => {
          if (employees?.length > 0) {
            this.storeEmployeesSubscription.unsubscribe();
            this.requestSent = false;
            this.shiftsLoadingAnimationOut = true;
            this.updateShiftsLoadedValue(true);
            this.storeEmployees = employees;
            this.generatedShifts.forEach((shift) => {
              const employeeId = shift.employee.id;
              this.shiftsEmployeesMap[shift.id] = employeeId;
              this.initialShiftsEmployeesMap[shift.id] = employeeId;
            });
            this.notificationService.showTimedMessage('scheduleGenerated');

            this.loadTempShifts(0, true);
          } else if (forced) {
            this.storeEmployeesSubscription.unsubscribe();
            this.loadStoreEmployees(
              this.slowSolver
                ? this.configProperties.fetchScheduleGenerationSlow
                : this.configProperties.fetchScheduleGenerationQuick,
              false,
            );
          }
        },
      });
  }

  private loadTempShifts(intervalDelay: number, forced: boolean = false) {
    let errorCount = 0;

    const request: RetrieveCalendarShiftsRequest = {
      scheduleJobId: this.scheduleProblemId,
      storeId: this.selectedStore.id,
      startDate: this.selectedStartDateRest,
      endDate: this.selectedEndDateRest,
    };

    this.tempShiftsSubscription = interval(intervalDelay)
      .pipe(
        concatMap(() => {
          return forkJoin({
            tempShifts: this.scheduleShiftService
              .getShiftsByStoreIdAndDateRange(request)
              .pipe(
                catchError((error) => {
                  errorCount++;
                  if (
                    errorCount >= this.configProperties.fetchTempShiftsRetry
                  ) {
                    throw error;
                  }
                  return of(error).pipe(
                    delay(intervalDelay),
                    retry(
                      forced ? 0 : this.configProperties.fetchTempShiftsRetry,
                    ),
                  );
                }),
              ),
          });
        }),
      )
      .subscribe({
        next: ({ tempShifts }) => {
          if (tempShifts.weeklyAssignedShifts?.length > 0) {
            this.tempShiftsSubscription.unsubscribe();
            this.previewEnabled = true;
            this.calendarShifts = tempShifts;
          } else if (forced) {
            this.tempShiftsSubscription.unsubscribe();
            this.loadTempShifts(
              this.slowSolver
                ? this.configProperties.fetchTempShiftsSlow
                : this.configProperties.fetchTempShiftsQuick,
              false,
            );
          }
        },
      });
  }

  previewScheduleCalendar() {
    this.matDialog.open(PreviewCalendarDialog, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      data: {
        preferredUserLayout: this.preferredUserLayout,
        calendarShifts: this.calendarShifts,
        storeName: this.selectedStore.name,
        twelveHourFormat: this.selectedStore.twelveHourFormat,
        singleDayCalendar:
          this.selectedStartDateRest === this.selectedEndDateRest,
        shiftRequest: {
          scheduleJobId: this.scheduleProblemId,
          storeId: this.selectedStore.id,
          lang: this.currentLocale,
          startDate: this.selectedStartDateRest,
          endDate: this.selectedEndDateRest,
          blobName:
            this.selectedStore.name +
            ', (' +
            this.startDate +
            (this.selectedStartDateRest === this.selectedEndDateRest
              ? ''
              : ' - ' + this.endDate) +
            ')',
        },
      },
    });
  }

  cancelSchedule() {
    const dialogRef = this.matDialog.open(ConfirmationDialog, {
      data: { dialogContentKey: 'cancelActionConfirmation' },
      enterAnimationDuration: 0,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.requestSent = true;

        this.notificationService.closeMessages();
        this.notificationService.showActionMessage('dataLoading');

        this.scheduleService
          .deleteScheduleJob(this.scheduleProblemId, this.slowSolver)
          .subscribe({
            next: () => {
              this.unSubscriptions();
              this.cancelledSchedulerAftermath();
              this.notificationService.closeMessages();
              this.notificationService.showTimedMessage('scheduleCancelled');
              this.updateShiftsLoadedValue(false);
            },
            error: () => {
              this.notificationService.closeMessages();
              this.notificationService.showTimedMessage('dataError');
              this.requestSent = false;
            },
          });
      }
    });
  }

  finalizeSchedule() {
    let employeeSwaps: EmployeeSwap[] = [];

    for (const shiftId in this.shiftsEmployeesMap) {
      const currentEmployeeId = this.shiftsEmployeesMap[shiftId];
      const initialEmployeeId = this.initialShiftsEmployeesMap[shiftId];
      if (currentEmployeeId !== initialEmployeeId) {
        employeeSwaps.push({
          shiftId: shiftId,
          initialEmployeeId: initialEmployeeId,
          newEmployeeId: currentEmployeeId,
        });
      }
    }

    let message = this.translateService.instant('finalizeScheduleWarning');

    if (employeeSwaps.length > 0) {
      const swaps1 = this.translateService.instant(
        'finalizeScheduleWarningSwaps1',
      );

      const swaps2 = this.translateService.instant(
        'finalizeScheduleWarningSwaps2',
      );
      message += ` ${swaps1} ${employeeSwaps.length} ${swaps2}`;
    }

    const dialogRef = this.matDialog.open(ConfirmationDialog, {
      data: { dialogContentKey: message },
      enterAnimationDuration: 0,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.doFinalize(employeeSwaps);
      }
    });
  }

  private doFinalize(swaps: EmployeeSwap[]) {
    this.requestSent = true;

    this.notificationService.closeMessages();
    this.notificationService.showActionMessage('dataLoading');

    const calendarRequest = this.createCalendarViewRequest();

    const finalizeScheduleRequest: FinalizeScheduleRequest = {
      id: this.scheduleProblemId,
      swaps: swaps,
      urlCriteria: calendarRequest,
      scheduleNotification: this.scheduleNotification,
    };

    this.scheduleService.finalizeSchedule(finalizeScheduleRequest).subscribe({
      next: () => {
        this.notificationService.closeMessages();
        this.notificationService.showTimedMessage('scheduleFinalized');

        this.router.navigate(['/calendar/' + calendarRequest]).then(() => {});
      },
      error: () => {
        this.requestSent = false;
        this.notificationService.closeMessages();
        this.notificationService.showTimedMessage('dataError');
      },
    });
  }

  onEmployeeSelectionChange(shift: Shift, event: MatSelectChange) {
    const selectedEmployee = event.value as EmployeeLight;
    this.shiftsEmployeesMap[shift.id] = selectedEmployee.id;

    // Compare the selected employee's ID with the initial employee's ID
    shift.swapped =
      this.initialShiftsEmployeesMap[shift.id] !== selectedEmployee.id;

    // Initialize totalHours to 0 for each employee
    this.scheduleEmployees.forEach((employee) => {
      employee.totalHours = 0;
      employee.totalMinutes = 0;
    });

    // Loop through each shift
    this.generatedShifts.forEach((shift) => {
      // Get the shift duration in hours and minutes
      const shiftHoursDuration = shift.hoursDuration;
      const shiftMinutesDuration = shift.minutesDuration;

      // Get the assigned employee ID for the current shift
      const assignedEmployeeId = this.shiftsEmployeesMap[shift.id];

      // Find the corresponding employee in scheduleEmployees
      const assignedEmployee = this.scheduleEmployees.find(
        (employee) => employee.employee.id === assignedEmployeeId,
      );

      // Add the shift duration to the totalHours of the found employee
      if (assignedEmployee) {
        assignedEmployee.totalHours += shiftHoursDuration;
        assignedEmployee.totalMinutes += shiftMinutesDuration;
      }
    });

    // Recalculate totalHours and totalMinutes for each employee
    this.scheduleEmployees.forEach((employee) => {
      const additionalHours = Math.floor(employee.totalMinutes / 60);
      employee.totalHours += additionalHours;
      employee.totalMinutes %= 60;
    });
  }

  formatDate(date: Date): string {
    return this.datepickerService.formatDateForSchedule(
      date,
      this.selectedStore.twelveHourFormat,
    );
  }

  sortEmployees(sort: Sort) {
    const data = this.scheduleEmployees.slice();
    if (!sort.active || sort.direction === '') {
      this.scheduleEmployees = data;
      return;
    }

    this.scheduleEmployees = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'firstName':
          return compare(a.employee.firstName, b.employee.firstName, isAsc);
        case 'lastName':
          return compare(a.employee.lastName, b.employee.lastName, isAsc);
        case 'totalAssignments': {
          const hoursComparison = compare(a.totalHours, b.totalHours, isAsc);
          if (hoursComparison !== 0) {
            return hoursComparison;
          } else {
            return compare(a.totalMinutes, b.totalMinutes, isAsc);
          }
        }
        case 'contractHours': {
          const aMaxHours =
            a.contract.weeklyHours != null && a.contract.maxHours != null
              ? a.contract.maxHours
              : -Infinity;
          const bMaxHours =
            b.contract.weeklyHours != null && b.contract.maxHours != null
              ? b.contract.maxHours
              : -Infinity;
          return compare(aMaxHours, bMaxHours, isAsc);
        }
        default:
          return 0;
      }
    });
  }

  sortShifts(sort: Sort, userAction: boolean) {
    const data = this.generatedShifts.slice();
    if (!sort.active || sort.direction === '') {
      this.generatedShifts = data;
      return;
    }

    if (userAction) {
      this.initializationService
        .updateScheduleShiftPreference(sort.active + ', ' + sort.direction)
        .then(() => {});
    }

    this.generatedShifts = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'startDate':
          return compareDates(
            new Date(a.startDate),
            new Date(b.startDate),
            isAsc,
          );
        case 'endDate':
          return compareDates(new Date(a.endDate), new Date(b.endDate), isAsc);
        case 'totalDuration': {
          const hoursComparison = compare(
            a.hoursDuration,
            b.hoursDuration,
            isAsc,
          );
          if (hoursComparison !== 0) {
            return hoursComparison;
          } else {
            return compare(a.minutesDuration, b.minutesDuration, isAsc);
          }
        }
        case 'jobPositionName':
          return compare(a.jobPositionName, b.jobPositionName, isAsc);
        case 'information':
          if (!a.swapped && !b.swapped) {
            if (this.shiftsHaveViolations) {
              return compare(a.violations.length, b.violations.length, isAsc);
            } else {
              return compare(a.rewards.length, b.rewards.length, isAsc);
            }
          }
          return 0;
        default:
          return 0;
      }
    });
  }

  private dateRangeMarginCalculation() {
    const startDate = this.scheduleForm.get('startDate')?.value;
    const endDate = this.scheduleForm.get('endDate')?.value;

    // Check if both dates are valid
    if (startDate && endDate) {
      const diffInMilliseconds = Math.abs(
        endDate.getTime() - startDate.getTime(),
      );
      const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

      if (diffInDays >= 31 && this.scheduleForm.get('endDate')?.valid) {
        this.scheduleForm.get('endDate')?.patchValue(null);
        this.notificationService.showTimedMessage('dateRangeExceedsLimit');
      } else if (
        diffInDays >= 14 &&
        this.scheduleForm.get('endDate')?.valid &&
        !this.scheduleForm.get('slowSolver')?.value
      ) {
        this.autoSetSlowSolver = true;
        this.notificationService.showTimedMessage('slowSolverSetToTrue');
        this.scheduleForm.get('slowSolver')?.setValue(true);
      } else if (this.autoSetSlowSolver && diffInDays < 14) {
        this.autoSetSlowSolver = false;
        this.scheduleForm.get('slowSolver')?.setValue(false);
      }
    }
  }

  changeTableOption(s: ChipSelection) {
    if (s.name === this.currentTableSelection) {
      const index = this.scheduleTableOptions.indexOf(s);
      const chipToSelect = this.scheduleTableChipOptions.toArray()[index];
      chipToSelect.selected = true;
    } else {
      this.currentTableSelection = s.name;
    }
  }

  scrollToBottomSmoothly() {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }

  updateShiftsLoadedValue(value: boolean) {
    this.shiftsLoadingAnimationOut = value;
    setTimeout(
      () => {
        this.shiftsLoaded = value;
      },
      value ? 450 : 200,
    );
  }

  scheduleNotificationChange(event: MatSlideToggleChange) {
    this.initializationService.updateScheduleNotificationPreference(
      event.checked,
    );
  }

  private createCalendarViewRequest(): string {
    const retrieveCalendarShiftsRequest: RetrieveCalendarShiftsRequest = {
      storeId: this.selectedStore.id,
      startDate: this.selectedStartDateRest,
      endDate: this.selectedEndDateRest,
    };
    return this.cryptoService.encryptData(
      JSON.stringify(retrieveCalendarShiftsRequest),
    );
  }

  private cancelledSchedulerAftermath() {
    this.generationInProgress = false;
    this.selectedTabIndex = 0;
    this.requestSent = false;
    this.generatedShifts = [];
    this.shiftsHaveViolations = false;
    this.scheduleEmployees = [];
    this.scheduleProblemId = '';
    this.scheduleProblemShiftsCount = 0;
    this.scheduleProblemReducedShiftsCount = 0;
    this.storeEmployees = [];
    this.shiftsEmployeesMap = {};
    this.initialShiftsEmployeesMap = {};
    this.previewEnabled = false;
    this.calendarShifts = null;
  }

  protected readonly compareEmployees = compareEmployees;
  protected readonly trackByChip = trackByChip;
  protected readonly trackByEmployeeLight = trackByEmployeeLight;
  protected readonly trackByStoreSchedule = trackByStoreSchedule;
  protected readonly formatScheduleMinutes = formatScheduleMinutes;
}
