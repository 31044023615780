import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardService } from '../../services/crud/dashboard.service';
import { catchError, count, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardInfo } from '../../model/dashboard/dashboard-info';
import { DatePipe, NgIf } from '@angular/common';
import { ChartConfiguration, ChartType } from 'chart.js';
import { BaseChartDirective, ThemeService } from 'ng2-charts';
import { MatDialog } from '@angular/material/dialog';
import { ChartDialog } from './chart-dialog/chart-dialog';
import { RouterLink } from '@angular/router';
import { NavigationService } from '../../services/common/navigation.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconButton } from '@angular/material/button';
import { ThemeLoaderService } from '../../services/core/theme-loader';
import Chart from 'chart.js/auto';
import { color } from 'chart.js/helpers';
import autocolors from 'chartjs-plugin-autocolors';
import { fadeIn, slideFromLeft } from '../../utils/animations';
import { DatepickerService } from '../../services/common/datepicker.service';
import { DashboardSteps } from './steps/dashboard-steps/dashboard-steps';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    MatCardModule,
    NgIf,
    TranslateModule,
    DatePipe,
    MatIconModule,
    RouterLink,
    MatIconButton,
    BaseChartDirective,
    DashboardSteps,
  ],
  templateUrl: './dashboard.html',
  animations: [slideFromLeft, fadeIn],
})
export class Dashboard implements OnInit {
  dataLoaded: boolean = false;
  errorLoadingData: boolean = false;
  businessInitMode: boolean = false;

  dashboardInfo: DashboardInfo;
  weekAssignedShifts: number;

  hovering: { [key: number]: boolean } = {};

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  public lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.5,
      },
    },
  };

  public lineChartLabels: string[] = [];
  public lineChartType: ChartType = 'line';
  public lineChartLegend = true;
  public lineChartData: any[] = [];

  constructor(
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private themeService: ThemeService,
    private themeLoaderService: ThemeLoaderService,
    private navigationService: NavigationService,
    protected datepickerService: DatepickerService,
  ) {
    Chart.register(autocolors);

    //Chart.register(Colors);
  }

  async ngOnInit() {
    this.loadData().subscribe(() => {
      this.dataLoaded = true;
      this.navigationService.setTitle(this.dashboardInfo.businessName);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.chart?.render();
  }

  private loadData() {
    const info$ = this.dashboardService.loadDashboard().pipe(
      catchError(() => {
        return of(null);
      }),
    );

    return forkJoin({
      info: info$,
    }).pipe(
      map(({ info }) => {
        if (!info) {
          this.errorLoadingData = true;
          this.navigationService.setErrorLoading(true);
          return;
        }

        this.dashboardInfo = info;

        if (
          this.dashboardInfo.stores.count === 0 ||
          this.dashboardInfo.jobPositions.count === 0 ||
          this.dashboardInfo.employees.count === 0 ||
          this.dashboardInfo.weeklyShifts.count === 0 ||
          this.dashboardInfo.assignedShifts.count === 0
        ) {
          this.businessInitMode = true;
          this.navigationService.setInitializationProcess(true);
        } else {
          this.weekAssignedShifts =
            this.dashboardInfo.assignedWeekShiftsPerStore.reduce(
              (total, storeShifts) => total + storeShifts.count,
              0,
            );
          this.initializeChartData();
        }
      }),
    );
  }

  expandChart() {
    this.dialog.open(ChartDialog, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      data: {
        lineChartOptions: this.lineChartOptions,
        lineChartLabels: this.lineChartLabels,
        lineChartType: this.lineChartType,
        lineChartLegend: this.lineChartLegend,
        lineChartData: this.lineChartData,
      },
    });
  }

  private initializeChartData(): void {
    this.updateChartColors(
      this.themeLoaderService.currentColorScheme === 'light',
    );

    this.lineChartLabels = [
      ...new Set(
        this.dashboardInfo.assignedWeekShiftsPerStore.flatMap(
          (storeShifts) => storeShifts.day,
        ),
      ),
    ];

    const groupedData = this.dashboardInfo.assignedWeekShiftsPerStore.reduce(
      (acc: { [key: string]: number[] }, curr) => {
        if (!acc[curr.storeName]) {
          acc[curr.storeName] = [];
        }
        acc[curr.storeName].push(curr.count);
        return acc;
      },
      {},
    );

    this.lineChartData = Object.entries(groupedData).map(
      ([storeName, data]) => ({
        data,
        label: storeName,
      }),
    );
  }

  private updateChartColors(isLight: boolean) {
    let overrides: ChartConfiguration['options'];

    const white = 'rgba(255, 255, 255, 0.9)';
    const black = 'rgba(0, 0, 0, 0.9)';
    const backgroundColor = isLight ? black : white;
    const fontColor = isLight ? white : black;

    const lightGrey = 'rgba(255, 255, 255, 0.2)';
    const darkGrey = 'rgba(0, 0, 0, 0.1)';
    const gridColor = isLight ? darkGrey : lightGrey;

    const primaryColorDark = '#cfbcff';
    const primaryColorLight = '#6750a4';
    const primaryColor = isLight ? primaryColorLight : primaryColorDark;

    const lighten = (c: string) =>
      color(c)
        .alpha(isLight ? 0.6 : 0.9)
        .rgbString();

    overrides = {
      scales: {
        x: {
          grid: {
            color: gridColor,
          },
          ticks: {
            color: backgroundColor,
          },
        },
        y: {
          grid: {
            color: gridColor,
          },
          ticks: {
            color: backgroundColor,
          },
        },
      },
      plugins: {
        colors: {
          forceOverride: true,
          enabled: false,
        },
        autocolors: {
          mode: 'dataset',
          offset: this.dashboardInfo.stores.count,
          customize(context) {
            const colors = context.colors;
            return {
              background: lighten(colors.background),
              border: lighten(colors.border),
            };
          },
        },
        tooltip: {
          backgroundColor: backgroundColor,
          titleColor: fontColor,
          bodyColor: fontColor,
        },
        legend: {
          labels: {
            color: primaryColor,
          },
        },
      },
    };

    this.themeService.setColorschemesOptions(overrides);
  }
}
