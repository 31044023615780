<div class="flex__box dialog__header" mat-dialog-title>
  <span></span>
  <div class="dialog__header-actions">
    <button
      (click)="dialogRef.close()"
      aria-label="close"
      class="stroked__icon-button"
      mat-icon-button
      type="button"
      matTooltip="{{ 'close' | translate }}"
    >
      <mat-icon aria-label="close">close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content class="store__shift-info-sections">
  <div class="store__shift-info-section">
    <span class="store__shift-info-section-header">{{
      "shiftJobPositionsAddExplanationHeader" | translate
    }}</span>
    <span
      ><mat-icon>chevron_right</mat-icon>
      {{ "shiftJobPositionsAddExplanationSubtitle1" | translate }}</span
    >
    <span
      ><mat-icon>chevron_right</mat-icon>
      {{ "shiftJobPositionsAddExplanationSubtitle2" | translate }}</span
    >
  </div>

  <mat-divider></mat-divider>

  <div class="store__shift-info-section">
    <span class="store__shift-info-section-header">{{
      "shiftJobPositionsEditExplanationHeader" | translate
    }}</span>
    <span
      ><mat-icon>chevron_right</mat-icon>
      {{ "shiftJobPositionsEditExplanationSubtitle1" | translate }}</span
    >
    <span
      ><mat-icon>chevron_right</mat-icon>
      {{ "shiftJobPositionsEditExplanationSubtitle2" | translate }}</span
    >
  </div>

  <mat-divider></mat-divider>

  <div class="store__shift-info-section">
    <span class="store__shift-info-section-header">{{
      "shiftJobPositionNamesExplanationHeader" | translate
    }}</span>
    <span
      ><mat-icon>chevron_right</mat-icon>
      {{ "shiftJobPositionNamesExplanationSubtitle1" | translate }}</span
    >
    <span
      ><mat-icon>chevron_right</mat-icon>
      {{ "shiftJobPositionNamesExplanationSubtitle2" | translate }}</span
    >
  </div>
</mat-dialog-content>
