import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-zoom-calendar',
  standalone: true,
  imports: [MatIcon, MatIconButton, MatTooltip, TranslateModule, NgClass],
  templateUrl: './zoom-calendar.html',
})
export class ZoomCalendar implements OnInit {
  @Input() zoomContainer: ElementRef;
  zoomLevel: number = 100;

  zoomInTooltip: string;
  zoomOutTooltip: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.updateTooltips();
  }

  zoomIn() {
    if (this.zoomLevel < 100) {
      this.zoomLevel += 10;
      this.zoomContainer.nativeElement.style.zoom = `${this.zoomLevel}%`;
      this.updateTooltips();
    }
  }

  zoomOut() {
    if (this.zoomLevel > 10) {
      this.zoomLevel -= 10;
      this.zoomContainer.nativeElement.style.zoom = `${this.zoomLevel}%`;
      this.updateTooltips();
    }
  }

  updateTooltips() {
    this.zoomInTooltip = `${this.translateService.instant('zoomIn')} (${this.zoomLevel + 10}%)`;
    this.zoomOutTooltip = `${this.translateService.instant('zoomOut')} (${this.zoomLevel - 10}%)`;
  }
}
