import { Component, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { DatepickerService } from '../../../services/common/datepicker.service';
import { TranslateModule } from '@ngx-translate/core';
import { WaveBackground } from '../../shared/wave-background/wave-background';

@Component({
  selector: 'app-versions',
  standalone: true,
  imports: [MatExpansionModule, MatIconModule, TranslateModule, WaveBackground],
  templateUrl: './versions.html',
})
export class Versions implements OnInit {
  changelog: Map<string, Date> = new Map<string, Date>();

  constructor(private datepickerService: DatepickerService) {}

  ngOnInit() {
    this.changelog.set('1.0.0', new Date('2024-09-01'));
  }

  getReleaseDate(version: string): string {
    const date = this.changelog.get(version);
    if (date) {
      return this.datepickerService.getDateInStringFormatForUI(date);
    }

    return '';
  }
}
