<ng-container *ngIf="dataLoaded && !errorLoadingData">
  <app-wave-background></app-wave-background>
  <div class="wave__background-after">
    <ng-container *ngIf="!reorderMode; else cardsView">
      <div class="information__cards">
        <mat-card
          *ngFor="
            let jobPosition of jobPositions;
            let i = index;
            trackBy: trackByJobPosition
          "
          class="information__card mat-elevation-z6"
          appearance="outlined"
          [id]="'jobPosition-' + jobPosition.id"
          [@popUp]="{ value: '', params: { delay: i * 0.1 } }"
          [@scaleUp]="hoverState[jobPosition.id] || 'default'"
          (mouseover)="onHover(jobPosition.id)"
          onFocus="onHover(jobPosition.id)"
          (mouseleave)="onLeave(jobPosition.id)"
        >
          <mat-card-header
            class="information__card-header no__hover"
            [style.background-color]="
              customColorService.getColor(jobPosition.preferredColor)
            "
          >
            <mat-card-title class="information__card-name">
              {{ jobPosition.name }}
            </mat-card-title>
          </mat-card-header>

          <mat-card-content class="information__card-description no__hover">
            <p class="information__card-info">
              <mat-icon aria-label="employees" svgIcon="group"></mat-icon>
              <span
                >{{ jobPosition.employees.length }}
                {{ "employeesHaveThisJobPosition" | translate }}</span
              >
            </p>
            <p class="information__card-info">
              <mat-icon aria-label="shifts" svgIcon="shift_fireball"></mat-icon>
              <span
                >{{ "requiredIn" | translate }}
                {{ countWeeklyShifts(jobPosition) }}
                {{ "storeWeeklyShifts" | translate }}</span
              >
            </p>

            <mat-divider></mat-divider>
            <p class="information__card-info-discreet">
              <mat-icon aria-label="history" svgIcon="edit_history"></mat-icon>
              <span
                >{{ "lastChange" | translate }}
                {{
                  datepickerService.getDateTimeInStringFormatForUI(
                    jobPosition.updatedAt
                  )
                }}
              </span>
            </p>
          </mat-card-content>

          <ng-container *ngIf="isOwner; else emptyActions">
            <mat-card-actions
              class="information__card-action"
              (click)="editJobPosition(jobPosition)"
              (keyup)="$event.stopPropagation()"
              (keydown)="$event.stopPropagation()"
              matRipple
              [matRippleColor]="buttonRippleEffect"
            >
              <mat-icon
                svgIcon="dynamic_edit"
                aria-label="edit"
                class="icon__normal"
              ></mat-icon>
              <img
                srcset="/assets/gif/edit_pencil.gif"
                class="icon__hover"
                width="30"
                height="30"
                alt="edit"
              />
              <span>{{ "edit" | translate }}</span>
            </mat-card-actions>
          </ng-container>

          <ng-template #emptyActions>
            <mat-card-actions class="empty__actions"></mat-card-actions>
          </ng-template>
        </mat-card>
      </div>
    </ng-container>

    <ng-template #cardsView>
      <mat-card class="mat-mdc-card-outlined">
        <mat-card-header>
          <mat-card-title>
            {{ "jobPositionsReorderHeader" | translate }}
            <p>{{ "jobPositionsReorderSubtitle" | translate }}</p>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div
            class="information__cards-list"
            cdkDropList
            (cdkDropListDropped)="reorderJobPositions($event)"
          >
            <div
              *ngFor="
                let jobPosition of jobPositions;
                let i = index;
                trackBy: trackByJobPosition
              "
              class="information__card-list-item"
              cdkDrag
            >
              <button
                class="stroked__icon-button"
                mat-icon-button
                type="button"
                cdkDragHandle
              >
                <mat-icon aria-label="sort">dehaze</mat-icon>
              </button>
              <span
                [style.color]="
                  customColorService.getColor(jobPosition.preferredColor)
                "
                >{{ jobPosition.name }}
              </span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </div>

  <div class="overlay__button-group">
    <app-scroll-to-top [buttonOnly]="true"></app-scroll-to-top>

    <ng-container *ngIf="jobPositions.length > 1">
      <ng-container *ngIf="!reorderMode; else switchToCards">
        <button
          (click)="reorderMode = !reorderMode"
          color="secondary"
          mat-mini-fab
          matTooltip="{{ 'reorder' | translate }}"
          matTooltipPosition="left"
        >
          <mat-icon aria-label="list" svgIcon="reorder_cards"></mat-icon>
        </button>
      </ng-container>

      <ng-template #switchToCards>
        <button
          (click)="reorderMode = !reorderMode"
          color="secondary"
          mat-mini-fab
          matTooltip="{{ 'view' | translate }}"
          matTooltipPosition="left"
        >
          <mat-icon aria-label="cards" svgIcon="cards_layout"></mat-icon>
        </button>
      </ng-template>
    </ng-container>

    <button
      *ngIf="isOwner"
      (click)="addJobPosition()"
      color="accent"
      mat-fab
      matTooltip="{{ 'addNewF' | translate }}"
      matTooltipPosition="left"
    >
      <mat-icon aria-label="add" class="always__white">add</mat-icon>
    </button>
  </div>
</ng-container>
