<ng-container *ngIf="scheduleExportInProgress">
  <div class="calendar__export-overlay">
    <app-spinner></app-spinner>
  </div>
</ng-container>

<ng-container *ngIf="calendarShifts; else errorMessage">
  <div [ngClass]="scheduleExportInProgress ? 'lower__opacity' : ''">
    <div class="flex__box dialog__header" mat-dialog-title>
      <div class="dialog__header-form">
        <span></span>
        <mat-button-toggle-group [(ngModel)]="preferredUserLayout">
          <mat-button-toggle
            [value]="layout.name"
            *ngFor="
              let layout of calendarLayoutOptions;
              let i = index;
              trackBy: trackByChip
            "
            >{{ layout.name | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>

        <button
          (click)="dialogRef.close()"
          class="stroked__icon-button"
          mat-icon-button
          type="button"
          matTooltip="{{ 'close' | translate }}"
        >
          <mat-icon aria-label="full screen off">close_fullscreen</mat-icon>
        </button>
      </div>
    </div>

    <div class="dialog__table-content">
      <mat-divider></mat-divider>
      <ng-container
        *ngIf="
          preferredUserLayout === calendarLayoutOptions[0].name;
          else listView
        "
      >
        <app-calendar-table
          [calendarShifts]="calendarShifts"
          [storeName]="storeName"
          [twelveHourFormat]="twelveHourFormat"
          [singleDayCalendar]="singleDayCalendar"
          [shiftRequest]="shiftRequest"
          [previewMode]="true"
        ></app-calendar-table>
      </ng-container>

      <ng-template #listView>
        <app-calendar-list
          [calendarShifts]="calendarShifts"
          [twelveHourFormat]="twelveHourFormat"
          [singleDayCalendar]="singleDayCalendar"
          [shiftRequest]="shiftRequest"
          [previewMode]="true"
        ></app-calendar-list>
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-template #errorMessage>
  <div class="error-color centered__block">
    <span class="warn">{{ "dataErrorRefresh" | translate }}</span>
  </div>
</ng-template>
