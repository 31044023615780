import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { UserService } from '../crud/user.service';
import { LanguageLoaderService } from './language-loader';
import { ThemeLoaderService } from './theme-loader';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { Role } from '../../model/user/security/role';
import { UserPreferences } from '../../model/user/user-preferences';
import { UserDetails } from '../../model/user/security/user-details';
import { UserAuthStatus } from '../../model/user/security/user-auth-status';
import * as jsonData from '../../../assets/config.json';
import { NotificationService } from '../common/notification.service';
import { SystemAnnouncementService } from '../common/system-announcement.service';
import { SystemAnnouncement } from '../../model/system/system-announcement';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsDialog } from '../../pages/general/privacy/terms-and-conditions/terms-and-conditions-dialog';

const JWT_STORAGE_KEY = 'BEEEASE-TOKEN';

@Injectable({ providedIn: 'root' })
export class InitializationService {
  configProperties: any = jsonData;

  readonly emptyPreferences: UserPreferences = {
    theme: '',
    lang: '',
    calendarLayout: '',
    pageSize: 0,
  };

  readonly emptyDetails: UserDetails = {
    firstName: '',
    lastName: '',
    roles: [],
  };

  readonly unauthorizedUser: UserAuthStatus = {
    isLoggedIn: false,
    isOwner: false,
    isManager: false,
    isEmployee: false,
  };

  private userAuthStatusChangeSource: BehaviorSubject<UserAuthStatus> =
    new BehaviorSubject<UserAuthStatus>(this.unauthorizedUser);
  userAuthStatusChange$ = this.userAuthStatusChangeSource.asObservable();

  private userDetailsSource = new BehaviorSubject<UserDetails>(
    this.emptyDetails,
  );
  userDetailsChange$ = this.userDetailsSource.asObservable();

  private userPreferencesSource = new BehaviorSubject<UserPreferences>(
    this.emptyPreferences,
  );

  private systemRolesSource = new BehaviorSubject<Role[]>([]);

  private themeChangeSource = new BehaviorSubject<boolean>(true); // default to light theme
  themeChange$ = this.themeChangeSource.asObservable();

  private systemAnnouncementSource =
    new BehaviorSubject<SystemAnnouncement | null>(null);
  systemAnnouncementChange$ = this.systemAnnouncementSource.asObservable();

  constructor(
    private jwtHelperService: JwtHelperService,
    private router: Router,
    private userService: UserService,
    private languageLoaderService: LanguageLoaderService,
    private themeLoaderService: ThemeLoaderService,
    private notificationService: NotificationService,
    private systemAnnouncementService: SystemAnnouncementService,
    private matDialog: MatDialog,
  ) {}

  async loginAuthenticatedUser(data: any) {
    localStorage.setItem(JWT_STORAGE_KEY, data.accessToken);
    this.emitUserStatusChange(true);
    await this.initialize();
    this.redirectToHomePage();
  }

  async logoutAuthenticatedUser(forceLogout: boolean) {
    if (forceLogout) {
      setTimeout(async () => {
        this.notificationService.showTimedClasslessMessage(
          this.languageLoaderService.translateValue('accessRevoked'),
        );
      }, 500);
    } else {
      this.notificationService.showTimedClasslessMessage(
        this.languageLoaderService.translateValue('logoutSuccess'),
      );
    }

    localStorage.removeItem(JWT_STORAGE_KEY);
    this.emitUserStatusChange(false);
    this.clearObservables();
    await this.initialize();
    this.router.navigate(['general']).then(() => {});
  }

  redirectToHomePage() {
    if (this.isAuthenticatedUserInRoles(['OWNER'])) {
      this.router.navigate(['dashboard']).then(() => {});
    } else if (this.isAuthenticatedUserInRoles(['MANAGER'])) {
      this.router.navigate(['dashboard']).then(() => {});
    } else if (this.isAuthenticatedUserInRoles(['EMPLOYEE'])) {
      this.router.navigate(['calendar']).then(() => {});
    } else {
      this.router.navigate(['dummy']).then(() => {});
    }
  }

  emitUserStatusChange(isAuthenticated: boolean) {
    if (!isAuthenticated) {
      this.userAuthStatusChangeSource.next(this.unauthorizedUser);
    } else {
      this.userAuthStatusChangeSource.next({
        isLoggedIn: true,
        isOwner: this.isAuthenticateUserOwner(),
        isManager: this.isAuthenticateUserManager(),
        isEmployee: this.isAuthenticateUserEmployee(),
      });
    }
  }

  isUserAuthenticated(): boolean {
    const token = localStorage.getItem(JWT_STORAGE_KEY);
    try {
      return !this.jwtHelperService.isTokenExpired(token);
    } catch (error) {
      return false;
    }
  }

  isAuthenticateUserOwner(): boolean {
    return this.isAuthenticatedUserInRoles(['OWNER']);
  }

  isAuthenticateUserManager(): boolean {
    return this.isAuthenticatedUserInRoles(['MANAGER']);
  }

  isAuthenticateUserEmployee(): boolean {
    return this.isAuthenticatedUserInRoles(['EMPLOYEE']);
  }

  isAuthenticatedUserInRoles(roles: string[]): boolean {
    return (
      this.isUserAuthenticated() &&
      roles.some((role) => this.getUserDetails().roles.includes(role))
    );
  }

  getUserDetails(): UserDetails {
    return this.userDetailsSource.getValue();
  }

  getSystemRoles(): Role[] {
    return this.systemRolesSource.getValue();
  }

  getUserPreferences(): UserPreferences {
    return this.userPreferencesSource.getValue();
  }

  getUserSortPreferences(): { sortBy: string; sortDir: string } {
    const es = this.getUserPreferences().employeeSort;
    const sortBy = es?.split(',')[0]?.trim() ?? 'updatedAt';
    const sortDir = es?.split(',')[1]?.trim() ?? 'desc';
    return { sortBy, sortDir };
  }

  getScheduleShiftSort(): { sortBy: string; sortDir: string } {
    const es = this.getUserPreferences().scheduleShiftSort;
    const sortBy = es?.split(',')[0]?.trim() ?? 'startDate';
    const sortDir = es?.split(',')[1]?.trim() ?? 'asc';
    return { sortBy, sortDir };
  }

  async updatePageSizePreference(newPageSize: number) {
    if (this.getUserPreferences().pageSize !== newPageSize) {
      this.userPreferencesSource.next({
        ...this.getUserPreferences(),
        pageSize: newPageSize,
      });

      this.updateUserPreferences();
    }
  }

  async updateEmployeeSortPreference(newEmployeeSort: string) {
    if (this.getUserPreferences().employeeSort !== newEmployeeSort) {
      this.userPreferencesSource.next({
        ...this.getUserPreferences(),
        employeeSort: newEmployeeSort,
      });

      this.updateUserPreferences();
    }
  }

  async updateScheduleShiftPreference(newScheduleShiftSort: string) {
    if (this.getUserPreferences().scheduleShiftSort !== newScheduleShiftSort) {
      this.userPreferencesSource.next({
        ...this.getUserPreferences(),
        scheduleShiftSort: newScheduleShiftSort,
      });

      this.updateUserPreferences();
    }
  }

  updateSlowSolverPreference(newSlowSolver: boolean) {
    if (this.getUserPreferences().slowSolver !== newSlowSolver) {
      this.userPreferencesSource.next({
        ...this.getUserPreferences(),
        slowSolver: newSlowSolver,
      });

      this.updateUserPreferences();
    }
  }

  updateScheduleNotificationPreference(newScheduleNotification: boolean) {
    if (
      this.getUserPreferences().scheduleNotification !== newScheduleNotification
    ) {
      this.userPreferencesSource.next({
        ...this.getUserPreferences(),
        scheduleNotification: newScheduleNotification,
      });

      this.updateUserPreferences();
    }
  }

  updateCalendarLayoutPreference(newCalendarLayout: string) {
    if (this.getUserPreferences().calendarLayout !== newCalendarLayout) {
      this.userPreferencesSource.next({
        ...this.getUserPreferences(),
        calendarLayout: newCalendarLayout,
      });

      this.updateUserPreferences();
    }
  }

  updateAnnouncementAck() {
    this.userPreferencesSource.next({
      ...this.getUserPreferences(),
      announcementAck: true,
    });

    this.systemAnnouncementSource.next(null);

    this.updateUserPreferences();
  }

  private updateUserPreferences() {
    this.userService.updateUserPreferences(this.getUserPreferences());
  }

  updateHideInfoBannersPreference(hideInfoBanners: boolean) {
    if (this.getUserPreferences().hideInfoBanners !== hideInfoBanners) {
      this.userPreferencesSource.next({
        ...this.getUserPreferences(),
        hideInfoBanners: hideInfoBanners,
      });

      this.updateUserPreferences();
    }
  }

  async updateLangPreference(lang: string, updatePreferences: boolean) {
    this.languageLoaderService.systemLanguageUsed = false;
    await this.languageLoaderService.setLanguage(lang);

    if (updatePreferences) {
      if (this.getUserPreferences().lang !== lang) {
        this.userPreferencesSource.next({
          ...this.getUserPreferences(),
          lang: lang,
        });

        this.updateUserPreferences();
      }
    }

    //TODO check this
    if (
      !(
        this.userDetailsSource.getValue().firstName &&
        this.userDetailsSource.getValue().lastName
      )
    ) {
      this.userDetailsSource.next({
        firstName: '',
        lastName: '',
        roles: this.userDetailsSource.getValue().roles,
      });
    }
  }

  async updateThemePreference(theme: string, updatePreferences: boolean) {
    this.themeLoaderService.systemThemeUsed = false;
    await this.themeLoaderService.setTheme(theme);

    if (updatePreferences) {
      if (this.getUserPreferences().theme !== theme) {
        this.userPreferencesSource.next({
          ...this.getUserPreferences(),
          theme: theme,
        });

        this.updateUserPreferences();
      }
    }

    this.themeChangeSource.next(theme === 'dark');
  }

  async initialize() {
    if (this.isUserAuthenticated()) {
      const userProfile = await firstValueFrom(
        this.userService.getUserProfile(),
      );
      if (userProfile) {
        if (userProfile.systemRoles) {
          this.systemRolesSource.next(userProfile.systemRoles);
        } else {
          this.systemRolesSource.next([]);
        }

        if (userProfile.preferences) {
          this.userPreferencesSource.next(userProfile.preferences);

          if (!userProfile.preferences.announcementAck) {
            this.fetchSystemAnnouncement();
          }
        } else {
          this.userPreferencesSource.next(this.emptyPreferences);
          this.fetchSystemAnnouncement();
        }

        if (!userProfile.acceptedTerms) {
          document.body.classList.add('overflow__hidden');
          const dialogRef = this.matDialog.open(TermsAndConditionsDialog, {
            width: '98vw',
            height: '98vh',
            maxWidth: '98vw',
            disableClose: true,
            enterAnimationDuration: 0,
          });

          dialogRef.afterClosed().subscribe(() => {
            document.body.classList.remove('overflow__hidden');
          });
        }

        this.userDetailsSource.next({
          firstName: userProfile.firstName ?? '',
          lastName: userProfile.lastName ?? '',
          roles: userProfile.roles,
        });
      } else {
        this.clearObservables();
      }

      this.emitUserStatusChange(true);
    }

    await this.updateThemePreference(
      this.getUserPreferences().theme ?? '',
      false,
    );
    await this.updateLangPreference(
      this.getUserPreferences().lang ?? '',
      false,
    );
  }

  private clearObservables() {
    this.systemRolesSource.next([]);

    this.userPreferencesSource.next(this.emptyPreferences);

    this.userDetailsSource.next(this.emptyDetails);
  }

  private fetchSystemAnnouncement() {
    this.systemAnnouncementService
      .getLatestAnnouncement()
      .pipe()
      .subscribe((announcement) => {
        if (announcement) {
          this.systemAnnouncementSource.next(announcement);
        }
      });
  }
}
