import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  AsyncPipe,
  DOCUMENT,
  NgIf,
  NgTemplateOutlet,
  ViewportScroller,
} from '@angular/common';
import { fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterLink } from '@angular/router';
import { fadeInOut } from '../../../utils/animations';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-scroll-to-top',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    AsyncPipe,
    NgIf,
    RouterLink,
    NgTemplateOutlet,
    MatTooltip,
    TranslateModule,
  ],
  templateUrl: './scroll-to-top.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut],
})
export class ScrollToTop implements OnDestroy {
  @Input() buttonOnly: boolean;
  @Output() scrollToTop = new EventEmitter<void>();
  readonly showScroll$: Observable<boolean>;
  private readonly document: Document;
  private readonly viewport: ViewportScroller;
  private destroy$ = new Subject<void>();

  constructor(@Inject(DOCUMENT) document: any, viewport: ViewportScroller) {
    this.document = document;
    this.viewport = viewport;

    this.showScroll$ = fromEvent(this.document, 'scroll').pipe(
      map(() => this.viewport.getScrollPosition()?.[1] > 400),
      takeUntil(this.destroy$),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onScrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
