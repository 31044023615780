import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [TranslateModule, MatDialogModule, MatButtonModule, MatIconModule],
  template: `
    <div mat-dialog-title>{{ 'confirmationPanel' | translate }}</div>
    <div mat-dialog-content>{{ dialogContentKey | translate }}</div>
    <div mat-dialog-actions>
      <button mat-stroked-button color="primary" (click)="onAction(false)">
        {{ 'no' | translate }}
      </button>
      <button mat-flat-button class="tertiary-button" (click)="onAction(true)">
        <mat-icon>warning</mat-icon>
        {{ 'yes' | translate }}
      </button>
    </div>
  `,
})
export class ConfirmationDialog {
  dialogContentKey: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.dialogContentKey = data.dialogContentKey;
  }

  onAction(close: boolean): void {
    this.dialogRef.close(close);
  }
}
