<mat-card class="mat-mdc-card-outlined">
  <mat-card-header>
    <mat-card-title>{{ "userPreferences" | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-actions class="formed__actions">
    <div class="form__selectable-criteria">
      <div>
        <label for="languagesChips" class="label__normal">{{
          "systemLanguage" | translate
        }}</label>
        <mat-chip-listbox id="languagesChips">
          <mat-chip-option
            *ngFor="let l of languages"
            [selected]="l.name === currentLanguage"
            (click)="changeLang(l)"
            (keyup)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()"
            #languageChipOptions
          >
            <span
              class="chip__avatar-img"
              [innerHTML]="l.svg | svgSanitizerMedium"
            ></span>
            <span>{{ l.displayName | translate }} </span>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>

      <div>
        <label for="themeChips" class="label__normal">{{
          "systemTheme" | translate
        }}</label>
        <mat-chip-listbox id="themeChips">
          <mat-chip-option
            *ngFor="let t of themeOptions"
            [selected]="t.name === currentTheme"
            (click)="changeTheme(t)"
            (keyup)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()"
            #themeChipOptions
          >
            <span
              class="chip__avatar-img"
              [innerHTML]="t.svg | svgSanitizerMedium"
            ></span>
            <span>{{ t.name | translate }} </span>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>

      <mat-slide-toggle
        (change)="changeHideInfoBanners($event)"
        [(ngModel)]="hideInfoBanners"
        >{{ "hideInfoBanners" | translate }}
      </mat-slide-toggle>
    </div>
  </mat-card-actions>
</mat-card>

<mat-card class="form__actions-waved">
  <mat-card-content class="separate__container-actions">
    <button
      (click)="logout()"
      class="tertiary-button svg__icon-button"
      mat-flat-button
    >
      <mat-icon aria-label="logout" svgIcon="logout_plain"></mat-icon>
      {{ "logout" | translate }}
    </button>
  </mat-card-content>
</mat-card>
