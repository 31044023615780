import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import {
  appInitializerFactory,
  HttpLoaderFactory,
  initializeApp,
} from './bee-ease-app';
import { LanguageLoaderService } from './services/core/language-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  provideAnimations,
  provideNoopAnimations,
} from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslatedMatPaginator } from './utils/translated-mat-paginator';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpRequestInterceptor } from './services/common/http-request.interceptor';
import { TitleCustomStrategy } from './services/core/title-custom-strategy';
import { ROUTES } from './routes';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import { InitializationService } from './services/core/initialization-service';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

const prefersReducedMotion =
  typeof matchMedia === 'function'
    ? matchMedia('(prefers-reduced-motion)').matches
    : false;

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    //provideClientHydration(),
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: false,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [LanguageLoaderService, TranslateService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [InitializationService],
      multi: true,
    },
    prefersReducedMotion ? provideNoopAnimations() : provideAnimations(),
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    provideRouter(
      ROUTES,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
    ),
    TitleCustomStrategy.Provider,
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          headerName: 'Authorization',
          authScheme: 'Bearer ',
        },
      }),
    ),
    { provide: MatPaginatorIntl, useClass: TranslatedMatPaginator },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        subscriptSizing: 'dynamic',
      },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        width: 'inherit',
        height: 'auto',
        autoFocus: false,
        disableClose: true,
        enterAnimationDuration: 600,
        exitAnimationDuration: 100,
      },
    },
    {
      provide: MAT_CARD_CONFIG,
      useValue: { appearance: 'outline' },
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true, displayDefaultIndicatorType: false },
    },
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    provideCharts(withDefaultRegisterables()),
  ],
};

export function tokenGetter() {
  return localStorage.getItem('BEEEASE-TOKEN');
}
