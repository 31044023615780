<div class="login__overlay" [@slideIn]>
  <mat-card appearance="outlined" class="login__card">
    <mat-card-header>
      <mat-card-title>
        {{
          isResetMode
            ? ("resetPassword" | translate)
            : ("completeRegistration" | translate)
        }}
      </mat-card-title>
      <button
        mat-icon-button
        type="button"
        class="stroked__icon-button"
        (click)="abort()"
      >
        <mat-icon aria-label="close">close</mat-icon>
      </button>
    </mat-card-header>
    <mat-divider></mat-divider>

    <form (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm">
      <mat-card-content>
        <mat-form-field appearance="outline" class="form__field">
          <mat-label>{{ "password" | translate }}</mat-label>
          <input
            [type]="passwordHide ? 'password' : 'text'"
            autocomplete="new-password"
            formControlName="password"
            matInput
            placeholder="{{ 'passwordPlaceholder' | translate }}"
          />
          <button
            (click)="passwordHide = !passwordHide"
            mat-icon-button
            matSuffix
            tabindex="-1"
            type="button"
          >
            <mat-icon aria-label="show password"
              >{{ passwordHide ? "visibility_off" : "visibility" }}
            </mat-icon>
          </button>
          <mat-error
            *ngIf="
              resetPasswordForm.controls['password'].invalid &&
              resetPasswordForm.controls['password'].touched
            "
          >
            {{ "passwordRequired" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form__field">
          <mat-label>{{ "repeatPassword" | translate }}</mat-label>
          <input
            [type]="passwordHide ? 'password' : 'text'"
            autocomplete="new-password"
            formControlName="repeatPassword"
            matInput
          />
          <button
            (click)="passwordHide = !passwordHide"
            mat-icon-button
            matSuffix
            tabindex="-1"
            type="button"
          >
            <mat-icon aria-label="show password"
              >{{ passwordHide ? "visibility_off" : "visibility" }}
            </mat-icon>
          </button>
          <mat-error
            *ngIf="
              resetPasswordForm.controls['repeatPassword'].invalid &&
              resetPasswordForm.controls['repeatPassword'].touched
            "
          >
            {{ "repeatPasswordRequired" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-error
          *ngIf="
            resetPasswordForm.errors?.['PasswordNoMatch'] &&
            !resetPasswordForm.controls['password'].invalid &&
            !resetPasswordForm.controls['repeatPassword'].invalid
          "
        >
          {{ "passwordsDontMatch" | translate }}
        </mat-error>
      </mat-card-content>

      <mat-card-actions>
        <button
          [disabled]="resetPasswordForm.invalid || requestSent"
          color="primary"
          mat-stroked-button
          type="submit"
        >
          <mat-spinner *ngIf="requestSent" diameter="20"></mat-spinner>
          <span *ngIf="!requestSent">{{
            isResetMode ? ("reset" | translate) : ("register" | translate)
          }}</span>
          <mat-icon
            aria-label="reset"
            *ngIf="!requestSent"
            iconPositionEnd
            matButtonIcon
            >lock_reset
          </mat-icon>
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
