import { Injectable } from '@angular/core';
import { ScheduleShiftService } from '../../crud/schedule-shift.service';
import { BehaviorSubject, finalize } from 'rxjs';
import { RetrieveCalendarShiftsRequest } from '../../../model/schedule/shift/retrieve-calendar-shifts-request';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { TranslateService } from '@ngx-translate/core';
import { DatepickerService } from '../datepicker.service';
import { CalendarShifts } from '../../../model/schedule/shift/calendar-shifts';

@Injectable({
  providedIn: 'root',
})
export class ScheduleExporterService {
  private _scheduleExportInProgress = new BehaviorSubject<boolean>(false);

  constructor(
    private scheduleShiftService: ScheduleShiftService,
    private translateService: TranslateService,
    private datepickerService: DatepickerService,
  ) {}

  setScheduleExportInProgress(value: boolean) {
    this._scheduleExportInProgress.next(value);
  }

  getScheduleExportInProgress() {
    return this._scheduleExportInProgress.asObservable();
  }

  xlsxExport(shiftRequest: RetrieveCalendarShiftsRequest): Promise<void> {
    document.body.classList.add('overflow__hidden');

    return new Promise((resolve, reject) => {
      this.scheduleShiftService
        .exportScheduleInExcel(shiftRequest)
        .pipe(
          finalize(() => {
            document.body.classList.remove('overflow__hidden');
          }),
        )
        .subscribe({
          next: () => resolve(),
          error: (err) => reject(new Error(err)),
        });
    });
  }

  pdfExport(
    storeName: string,
    calendarShifts: CalendarShifts,
    selectedWeekIndex: number,
  ): Promise<void> {
    document.body.classList.add('overflow__hidden');

    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          let table: HTMLElement = document.getElementById(
            'calendar',
          ) as HTMLElement;

          await html2canvas(table, { logging: false, scale: 2 }).then(
            (canvas) => {
              const componentWidth = table.offsetWidth;
              const componentHeight = table.offsetHeight;

              const orientation = componentWidth >= componentHeight ? 'l' : 'p';

              const imgData = canvas.toDataURL('image/png');
              const pdf = new jspdf({
                orientation,
                unit: 'px',
              });

              pdf.internal.pageSize.width = componentWidth;
              pdf.internal.pageSize.height = componentHeight;

              pdf.addImage(
                imgData,
                'PNG',
                0,
                0,
                componentWidth,
                componentHeight,
              );
              pdf.save(
                '' +
                  storeName +
                  ', ' +
                  this.translateService.instant('schedule') +
                  ' (' +
                  this.datepickerService.getDateInStringFormatForUI(
                    new Date(
                      calendarShifts.weeklyAssignedShifts[
                        selectedWeekIndex
                      ].assignedShifts[0].date,
                    ),
                  ) +
                  ' - ' +
                  this.datepickerService.getDateInStringFormatForUI(
                    new Date(
                      calendarShifts.weeklyAssignedShifts[
                        selectedWeekIndex
                      ].assignedShifts[
                        calendarShifts.weeklyAssignedShifts[selectedWeekIndex]
                          .assignedShifts.length - 1
                      ].date,
                    ),
                  ) +
                  ') ' +
                  '.pdf',
              );
              resolve();
            },
          );
        } catch (error) {
          reject(error instanceof Error ? error : new Error(String(error)));
        } finally {
          document.body.classList.remove('overflow__hidden');
        }
      }, 10);
    });
  }
}
