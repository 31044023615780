import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'svgSanitizerMedium', standalone: true })
export class SvgSanitizerMedium implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  //https://uxwing.com/
  transform(html: string): SafeHtml {
    const styledHtml = html.replace(
      '<svg ',
      '<svg style="vertical-align: middle; width: 24px; height: 24px; border-radius: 4px;" ',
    );
    return this.sanitizer.bypassSecurityTrustHtml(styledHtml);
  }
}
