<mat-tab-group color="accent">
  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ "termsAndConditions" | translate }}</span>
    </ng-template>

    <mat-card class="mat-mdc-card-outlined">
      <mat-card-content>
        <terms-of-conditions-en></terms-of-conditions-en>
      </mat-card-content>
    </mat-card>
  </mat-tab>

  <mat-tab disabled>
    <ng-template mat-tab-label>
      <span>{{ "privacyPolicy" | translate }}</span>
    </ng-template>
  </mat-tab>
</mat-tab-group>
