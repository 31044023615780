import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ThemeLoaderService {
  currentColorScheme: string;
  private renderer: Renderer2;
  private readonly colorSchemePrefix: string = 'main__theme-';

  systemThemeUsed: boolean = false;

  htmlTag: HTMLElement = this.document.getElementsByTagName('html')[0];

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  setTheme(cs: string) {
    return new Promise<void>((resolve) => {
      let colorScheme;
      if (cs) {
        colorScheme = cs === 'dark' ? 'dark' : 'light';
      } else {
        this.systemThemeUsed = true;
        colorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light';
      }

      this.currentColorScheme = colorScheme;

      this.renderer.removeClass(this.htmlTag, this.colorSchemePrefix + 'dark');
      this.renderer.removeClass(this.htmlTag, this.colorSchemePrefix + 'light');

      this.renderer.addClass(
        this.htmlTag,
        this.colorSchemePrefix + colorScheme,
      );

      resolve();
    });
  }
}
