<ng-container *ngIf="scheduleExportInProgress">
  <div class="calendar__export-overlay">
    <app-spinner></app-spinner>
  </div>
</ng-container>

<ng-container *ngIf="dataLoaded && !errorLoadingData">
  <div [ngClass]="scheduleExportInProgress ? 'lower__opacity' : ''">
    <ng-container *ngIf="!noStoresAvailable; else noStores">
      <mat-card appearance="outlined" class="calendar__criteria">
        <mat-card-header>
          <div class="criteria__header formed__header-actions">
            <span
              class="hide__small-devices criteria__store-badge primary-background"
              >{{ selectedStore.name }}</span
            >

            <span class="hide__small-devices range__text secondary-color">
              <span
                >{{ datepickerService.getDateInStringFormatForUI(startDate)
                }}</span
              >
              <span *ngIf="dateRangeSelection !== dateRangeOptions[0]">
                <code> &#8212; </code>{{
                datepickerService.getDateInStringFormatForUI(endDate) }}</span
              >
            </span>

            <div class="criteria__header-small">
              <span class="criteria__store-badge primary-background"
                >{{ selectedStore.name }}</span
              >

              <span class="range__text secondary-color">
                <span
                  >{{ datepickerService.getDateInStringFormatForUI(startDate)
                  }}</span
                >
                <span *ngIf="dateRangeSelection !== dateRangeOptions[0]">
                  <code> &#8212; </code>{{
                  datepickerService.getDateInStringFormatForUI(endDate) }}</span
                >
              </span>
            </div>

            <button
              (click)="
                $event.stopPropagation(); criteriaVisible = !criteriaVisible
              "
              mat-icon-button
              type="button"
              matTooltip="{{ 'filters' | translate }}"
            >
              <mat-icon aria-label="actions">more_vert</mat-icon>
            </button>
          </div>
        </mat-card-header>

        <mat-card-actions
          class="formed__actions higher"
          *ngIf="criteriaVisible"
          @expandDownAnimated
        >
          <div class="form__selectable-criteria">
            <div>
              <label for="storeChips" class="label__normal"
                >{{ "store" | translate }}</label
              >
              <mat-chip-listbox id="storeChips">
                <mat-chip-option
                  *ngFor="
                    let s of stores;
                    let i = index;
                    trackBy: trackByStoreLight
                  "
                  [selected]="s === selectedStore"
                  (click)="selectStore(s)"
                  (keyup)="$event.stopPropagation()"
                  (keydown)="$event.stopPropagation()"
                  #storeChips
                >
                  <span>{{ s.name }}</span>
                </mat-chip-option>
              </mat-chip-listbox>
            </div>

            <div>
              <label for="layoutChips" class="label__normal"
                >{{ "layout" | translate }}</label
              >
              <mat-chip-listbox id="layoutChips">
                <mat-chip-option
                  *ngFor="
                    let l of calendarLayoutOptions;
                    let i = index;
                    trackBy: trackByChip
                  "
                  [selected]="l.name === currentLayoutSelection"
                  (click)="selectTableLayout(l)"
                  (keyup)="$event.stopPropagation()"
                  (keydown)="$event.stopPropagation()"
                  #layoutChips
                >
                  <span
                    class="chip__avatar-img colorable__svg"
                    [innerHTML]="l.svg | svgSanitizerMedium"
                  ></span>
                  <span>{{ l.name | translate }} </span>
                </mat-chip-option>
              </mat-chip-listbox>
            </div>

            <div>
              <label class="label__normal">{{ "range" | translate }}</label>

              <mat-chip-listbox
                class="mat-mdc-chip-set-stacked"
                id="dateRangeChips"
              >
                <mat-chip-option
                  *ngFor="
                    let d of dateRangeOptions;
                    let i = index;
                    trackBy: trackByString
                  "
                  [value]="d"
                  [selected]="d === dateRangeSelection"
                  (click)="selectDateRange(d)"
                  (keyup)="$event.stopPropagation()"
                  (keydown)="$event.stopPropagation()"
                  #dateRangeChips
                  >{{ d | translate }}
                </mat-chip-option>
              </mat-chip-listbox>

              <div class="criteria__date-range">
                <div
                  *ngIf="
                    calendarForm && dateRangeSelection !== dateRangeOptions[3]
                  "
                >
                  <button
                    mat-icon-button
                    type="button"
                    (click)="changeDateRange('previous')"
                    matTooltip="{{ 'previousRange' | translate }}"
                  >
                    <mat-icon aria-label="previous"
                      >keyboard_arrow_left
                    </mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    type="button"
                    (click)="changeDateRange('next')"
                    matTooltip="{{ 'nextRange' | translate }}"
                  >
                    <mat-icon aria-label="next">keyboard_arrow_right</mat-icon>
                  </button>
                </div>

                <form
                  [formGroup]="calendarForm"
                  *ngIf="
                    calendarForm && dateRangeSelection === dateRangeOptions[3]
                  "
                  style="padding-top: 8px"
                >
                  <mat-form-field class="calendar">
                    <mat-label>{{ "dateRange" | translate }}</mat-label>
                    <mat-date-range-input
                      [rangePicker]="picker"
                      (click)="picker.open()"
                      (keydown)="$event.stopPropagation()"
                      (keyup)="$event.stopPropagation()"
                    >
                      <input
                        readonly
                        matStartDate
                        formControlName="startDate"
                      />
                      <input readonly matEndDate formControlName="endDate" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker
                      #picker
                      disabled="false"
                    ></mat-date-range-picker>
                  </mat-form-field>
                </form>
              </div>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>

      <ng-container *ngIf="loadingShifts; else shiftsLoaded">
        <mat-card class="flex__box">
          <mat-card-content>
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
          </mat-card-content>
        </mat-card>
      </ng-container>

      <ng-template #shiftsLoaded>
        <ng-container *ngIf="!calendarShiftsLoaded; else calendarShiftsView">
          <mat-card class="calendar__container">
            <mat-card-content>
              <p>{{ "shiftsNotFound" | translate }}</p>
            </mat-card-content>
          </mat-card>
        </ng-container>

        <ng-template #calendarShiftsView>
          <mat-card>
            <mat-card-content>
              <ng-container
                *ngIf="
                  currentLayoutSelection === calendarLayoutOptions[0].name;
                  else listView
                "
              >
                <app-calendar-table
                  [calendarShifts]="calendarShifts"
                  [storeName]="selectedStore.name"
                  [twelveHourFormat]="selectedStore.twelveHourFormat"
                  [singleDayCalendar]="
                    dateRangeSelection === dateRangeOptions[0]
                  "
                  [shiftRequest]="populateCalendarRequestBody()"
                  [previewMode]="false"
                ></app-calendar-table>
              </ng-container>

              <ng-template #listView>
                <app-calendar-list
                  [calendarShifts]="calendarShifts"
                  [twelveHourFormat]="selectedStore.twelveHourFormat"
                  [singleDayCalendar]="
                    dateRangeSelection === dateRangeOptions[0]
                  "
                  [shiftRequest]="populateCalendarRequestBody()"
                  [previewMode]="false"
                ></app-calendar-list>
              </ng-template>
            </mat-card-content>
          </mat-card>
        </ng-template>
      </ng-template>
    </ng-container>

    <ng-template #noStores>
      <mat-card appearance="outlined" class="custom__card custom__card-alert">
        <div class="custom__card-body">
          <div class="custom__card-container">
            <div>
              <mat-icon aria-label="no shifts">error</mat-icon>
            </div>
            <div class="custom__card-section">
              <mat-card-header>
                <mat-card-title
                  >{{ "noStoresAvailable" | translate }}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <p>{{ "contactAdministrator" | translate }}</p>
              </mat-card-content>
            </div>
          </div>
        </div>
      </mat-card>
    </ng-template>
  </div>
</ng-container>

<app-scroll-to-top></app-scroll-to-top>
