<div [ngClass]="previewMode ? '' : 'calendar__page'" class="calendar__cards">
  <div class="calendar__table-actions">
    <div *ngIf="weekIndexSize > 1" class="calendar__change-week">
      <mat-icon
        class="svg-enlarged"
        svgIcon="previous_circle"
        (click)="changeWeek('previous')"
        (keyup)="$event.stopPropagation()"
        [ngClass]="selectedWeekIndex === 0 ? 'icon-disabled' : ''"
        [matTooltip]="
          selectedWeekIndex === 0 ? '' : ('previousWeek' | translate)
        "
        aria-label="previous"
      >
      </mat-icon>
      <mat-icon
        svgIcon="next_circle"
        class="svg-enlarged"
        (click)="changeWeek('next')"
        (keyup)="$event.stopPropagation()"
        [ngClass]="
          selectedWeekIndex === weekIndexSize - 1 ? 'icon-disabled' : ''
        "
        [matTooltip]="
          selectedWeekIndex === weekIndexSize - 1
            ? ''
            : ('nextWeek' | translate)
        "
        aria-label="next"
      >
      </mat-icon>
    </div>

    <div class="calendar__export-options">
      <mat-icon
        class="svg-enlarged"
        svgIcon="xlsx"
        (click)="exportScheduleToXlsx()"
        (keyup)="$event.stopPropagation()"
        matTooltip="{{ 'excelDownload' | translate }}"
        aria-label="xlsx"
      ></mat-icon>
    </div>
  </div>

  <div
    class="day__card"
    *ngFor="
      let day of calendarShifts.weeklyAssignedShifts[selectedWeekIndex]
        .assignedShifts;
      let d = index;
      trackBy: trackByDay
    "
  >
    <mat-card-title
      [class.primary-color]="
        datepickerService.isToday(day.date) && !singleDayCalendar
      "
      >{{ day.dayOfWeek | lowercase | translate }}, {{
      datepickerService.getDateInStringFormatForUI(day.date) }}
    </mat-card-title>
    <mat-divider
      class="day__card-divider"
      [class.primary-border]="
        datepickerService.isToday(day.date) && !singleDayCalendar
      "
    ></mat-divider>
    <div class="shift__cards" [@listAnimation]>
      <mat-card
        appearance="outlined"
        class="shift__card"
        [class.primary-border]="
          datepickerService.isToday(day.date) && !singleDayCalendar
        "
        [ngClass]="identifyExistingShifts(day, weeklyShift) ? '' : 'no__shifts'"
        *ngFor="
          let weeklyShift of calendarShifts.weeklySeparateShifts;
          let ws = index;
          trackBy: trackByWeeklyShift
        "
      >
        <mat-card-header class="shift__card-header">
          <span
            >{{ datepickerService.formatUITime(weeklyShift.startTime,
            twelveHourFormat) }}
            <code> &#8212;</code>
            {{ datepickerService.formatUITime(weeklyShift.endTime,
            twelveHourFormat) }}</span
          >
          <span *ngIf="weeklyShift.name">({{ weeklyShift.name }})</span>
        </mat-card-header>

        <mat-divider
          [class.primary-border]="
            datepickerService.isToday(day.date) && !singleDayCalendar
          "
        ></mat-divider>

        <ng-container
          *ngFor="let shift of day.shifts; let s = index; trackBy: trackByShift"
        >
          <mat-card-content
            class="shift__card-content"
            *ngIf="
              weeklyShift.ids?.includes(shift.id) &&
              shift.assignedEmployees.length > 0
            "
          >
            <span
              class="job__position-name"
              [style.color]="
                customColorService.getColor(shift.jobPositionPreferredColor)
              "
              >{{ shift.jobPositionName }}</span
            >

            <div class="calendar__list-employees">
              <div
                class="calendar__list-employees-box"
                [ngClass]="employee.hs ? 'assigment__error-card' : ''"
                *ngFor="
                  let employee of shift.assignedEmployees;
                  let e = index;
                  trackBy: trackByEmployeeLight
                "
              >
                <span
                  class="employee__name"
                  [style.color]="employee.preferredColor"
                  >{{ employee.firstName }} {{ employee.lastName }}</span
                >
              </div>
            </div>
          </mat-card-content>
        </ng-container>
      </mat-card>
    </div>

    <ng-container *ngIf="!day.atLeastOneShiftFound">
      <p class="zero__margin">{{ "noneShifts" | translate }}</p>
    </ng-container>
  </div>
</div>
