<div [ngClass]="previewMode ? '' : 'calendar__page'">
  <div class="calendar__table-actions">
    <div *ngIf="weekIndexSize > 1" class="calendar__change-week">
      <mat-icon
        class="svg-enlarged"
        svgIcon="previous_circle"
        (click)="changeWeek('previous')"
        (keyup)="$event.stopPropagation()"
        [ngClass]="selectedWeekIndex === 0 ? 'icon-disabled' : ''"
        [matTooltip]="
          selectedWeekIndex === 0 ? '' : ('previousWeek' | translate)
        "
        aria-label="previous"
      >
      </mat-icon>
      <mat-icon
        class="svg-enlarged"
        svgIcon="next_circle"
        (click)="changeWeek('next')"
        (keyup)="$event.stopPropagation()"
        [ngClass]="
          selectedWeekIndex === weekIndexSize - 1 ? 'icon-disabled' : ''
        "
        [matTooltip]="
          selectedWeekIndex === weekIndexSize - 1
            ? ''
            : ('nextWeek' | translate)
        "
        aria-label="next"
      >
      </mat-icon>
    </div>
    <app-zoom-calendar [zoomContainer]="zoomContainer"></app-zoom-calendar>
  </div>
  <div #scheduleTable class="table__container calendar__table">
    <table mat-table [dataSource]="calendarShifts.weeklySeparateShifts">
      <ng-container matColumnDef="timeRange">
        <th mat-header-cell *matHeaderCellDef>
          <button
            mat-icon-button
            type="button"
            (click)="exportScheduleToPDF()"
            matTooltip="{{ 'pdfWeekDownload' | translate }}"
          >
            <mat-icon svgIcon="pdf" aria-label="pdf"></mat-icon>
          </button>
          <button
            mat-icon-button
            type="button"
            matTooltip="{{ 'excelDownload' | translate }}"
            (click)="exportScheduleToXlsx()"
          >
            <mat-icon svgIcon="xlsx" aria-label="xlsx"></mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let shift" class="header__shift">
          <span>
            {{ datepickerService.formatUITime(shift.startTime, twelveHourFormat)
            }}
            <code>&nbsp;&#8212;&nbsp;</code>
            {{ datepickerService.formatUITime(shift.endTime, twelveHourFormat)
            }}
          </span>
          <span *ngIf="shift.name"><br />{{ shift.name }}</span>
        </td>
      </ng-container>

      <ng-container
        *ngFor="
          let day of calendarShifts.weeklyAssignedShifts[selectedWeekIndex]
            .assignedShifts;
          let d = index;
          trackBy: trackByDay
        "
        matColumnDef="{{ 'week' + selectedWeekIndex + '-day' + d }}"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [class.primary-background]="
            datepickerService.isToday(day.date) && !singleDayCalendar
          "
        >
          <span
            >{{ day.dayOfWeek | lowercase | translate }} {{
            datepickerService.getDateNoYearInStringFormatForUI(day.date) }}
          </span>
        </th>
        <td mat-cell *matCellDef="let assignedShift">
          <ng-container
            *ngFor="
              let shift of day.shifts;
              let s = index;
              trackBy: trackByShift
            "
          >
            <ng-container
              *ngIf="
                assignedShift.ids.includes(shift.id) &&
                shift.assignedEmployees.length > 0
              "
            >
              <!--
              <div
                class="calendar__shift-chip"
                [class.primary-border]="
                  isToday(day.date) &&
                  dateRangeSelection !== dateRangeOptions[0]
                "
              > -->
              <div
                class="calendar__shift-chip"
                [style.border-color]="
                  customColorService.getColor(shift.jobPositionPreferredColor)
                "
              >
                <span
                  class="job__position-name"
                  [style.color]="
                    customColorService.getColor(shift.jobPositionPreferredColor)
                  "
                  >{{ shift.jobPositionName }}</span
                >
                <div class="calendar__employees">
                  <span
                    class="employee__name"
                    [ngClass]="employee.hs ? 'assigment__error-table' : ''"
                    [style.color]="employee.preferredColor"
                    *ngFor="
                      let employee of shift.assignedEmployees;
                      let e = index;
                      trackBy: trackByEmployeeLight
                    "
                  >
                    {{ employee.firstName }} {{ employee.lastName }}
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [@listAnimation]
      ></tr>
    </table>
  </div>
</div>

<ng-container *ngIf="scheduleExportInProgress">
  <div id="calendar" class="calendar__table calendar__exportable">
    <div class="pdf__header">
      <span>{{ storeName }}</span>
      <div>
        <span>
          {{ datepickerService.getDateInStringFormatForUI(
          calendarShifts.weeklyAssignedShifts[selectedWeekIndex]
          .assignedShifts[0].date ) }}
        </span>
        <span *ngIf="!singleDayCalendar">
          <code> &#8212; </code>
          {{ datepickerService.getDateInStringFormatForUI(
          calendarShifts.weeklyAssignedShifts[selectedWeekIndex]
          .assignedShifts[
          calendarShifts.weeklyAssignedShifts[selectedWeekIndex]
          .assignedShifts.length - 1 ].date ) }}</span
        >
      </div>
    </div>
    <table mat-table [dataSource]="calendarShifts.weeklySeparateShifts">
      <ng-container matColumnDef="timeRange">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let shift" class="header__shift">
          <span>
            {{ datepickerService.formatUITime(shift.startTime, twelveHourFormat)
            }}
            <code>&nbsp;&#8212;&nbsp;</code>
            {{ datepickerService.formatUITime(shift.endTime, twelveHourFormat)
            }}
          </span>
          <span *ngIf="shift.name"><br />{{ shift.name }}</span>
        </td>
      </ng-container>

      <ng-container
        *ngFor="
          let day of calendarShifts.weeklyAssignedShifts[selectedWeekIndex]
            .assignedShifts;
          let d = index;
          trackBy: trackByDay
        "
        matColumnDef="{{ 'week' + selectedWeekIndex + '-day' + d }}"
      >
        <th mat-header-cell *matHeaderCellDef>
          <span
            >{{ day.dayOfWeek | lowercase | translate }} {{
            datepickerService.getDateNoYearInStringFormatForUI(day.date)
            }}</span
          >
        </th>
        <td mat-cell *matCellDef="let assignedShift">
          <ng-container
            *ngFor="
              let shift of day.shifts;
              let s = index;
              trackBy: trackByShift
            "
          >
            <ng-container
              *ngIf="
                assignedShift.ids.includes(shift.id) &&
                shift.assignedEmployees.length > 0
              "
            >
              <div
                class="calendar__shift-chip"
                [style.border-color]="
                  customColorService.getColor(shift.jobPositionPreferredColor)
                "
              >
                <span
                  class="job__position-name"
                  [style.color]="
                    customColorService.getColor(shift.jobPositionPreferredColor)
                  "
                  >{{ shift.jobPositionName }}</span
                >
                <div class="calendar__employees">
                  <span
                    class="employee__name"
                    *ngFor="
                      let employee of shift.assignedEmployees;
                      let e = index;
                      trackBy: trackByEmployeeLight
                    "
                  >
                    {{ employee.firstName }} {{ employee.lastName }}
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</ng-container>
