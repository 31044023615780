import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardInfo } from '../../model/dashboard/dashboard-info';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor(private http: HttpClient) {}

  loadDashboard(): Observable<DashboardInfo> {
    return this.http.get<DashboardInfo>('/api/business/dashboard');
  }
}
