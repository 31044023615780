<ng-container *ngIf="buttonOnly; else fullOverlay">
  <ng-container *ngTemplateOutlet="scrollButton"></ng-container>
</ng-container>

<ng-template #fullOverlay>
  <div class="overlay__button-group">
    <ng-container *ngTemplateOutlet="scrollButton"></ng-container>
  </div>
</ng-template>

<ng-template #scrollButton>
  <button
    (click)="onScrollToTop()"
    *ngIf="showScroll$ | async"
    [@fadeInOut]="(showScroll$ | async) ? 'in' : 'out'"
    color="secondary"
    mat-mini-fab
    matTooltip="{{ 'scrollUp' | translate }}"
    matTooltipPosition="left"
  >
    <mat-icon aria-label="up">arrow_upward</mat-icon>
  </button>
</ng-template>
