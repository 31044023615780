<app-wave-background></app-wave-background>
<div class="versions__container wave__background-after">
  <mat-accordion class="versions__icons-align">
    <mat-expansion-panel disabled>
      <mat-expansion-panel-header>
        <mat-panel-title> 1.1.0</mat-panel-title>
        <mat-panel-description>
          Coming Soon..
          <mat-icon aria-label="development">construction</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>1.0.0</mat-panel-title>
        <mat-panel-description>
          {{ getReleaseDate("1.0.0") }}
          <mat-icon aria-label="publish">rocket_launch</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ul class="version__list">
        <span [innerHTML]="'version100' | translate"></span>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>
</div>
