import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { RouterLink } from '@angular/router';
import { NgClass, NgIf } from '@angular/common';
import { DashboardInfo } from '../../../../model/dashboard/dashboard-info';
import { SvgWrap } from '../../../shared/svg-wrap/svg-wrap';

@Component({
  selector: 'app-dashboard-steps',
  standalone: true,
  imports: [
    TranslateModule,
    MatIcon,
    MatDivider,
    RouterLink,
    NgClass,
    NgIf,
    SvgWrap,
  ],
  templateUrl: './dashboard-steps.html',
})
export class DashboardSteps {
  @Input() dashboardInfo: DashboardInfo;
}
