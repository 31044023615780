import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as jsonData from '../../../assets/config.json';
import { NavbarStatusService } from './navbar-status-service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  readonly configProperties: any = jsonData;
  readonly readMessagesTimeout: number =
    this.configProperties.readMessagesTimeout;

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private navbarStatusService: NavbarStatusService,
  ) {}

  private getSnackbarClass(): string[] {
    return this.navbarStatusService.getNavbarOpen()
      ? ['snackbar__positioned']
      : ['do-nothing'];
  }

  showTimedMessage(message: string) {
    this.snackBar.open(this.translateService.instant(message), 'X', {
      duration: this.readMessagesTimeout,
      panelClass: this.getSnackbarClass(),
    });
  }

  showActionMessage(message: string) {
    this.snackBar.open(this.translateService.instant(message), 'X', {
      panelClass: this.getSnackbarClass(),
    });
  }

  showClasslessMessage(message: string) {
    this.snackBar.open(this.translateService.instant(message), 'X', {});
  }

  showTimedClasslessMessage(message: string) {
    this.snackBar.open(this.translateService.instant(message), 'X', {
      duration: this.readMessagesTimeout,
    });
  }

  showErrorMessage(message: string) {
    this.snackBar.open(this.translateService.instant(message), 'X', {
      panelClass: this.getSnackbarClass(),
    });
  }

  closeMessages() {
    this.snackBar.dismiss();
  }
}
