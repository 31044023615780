import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { NotificationService } from '../../../services/common/notification.service';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, provideNativeDateAdapter } from '@angular/material/core';
import { DatepickerService } from '../../../services/common/datepicker.service';
import { EmployeeAvailabilities } from '../../../model/employee/employee-availabilities';
import {
  MatCalendar,
  MatCalendarCellClassFunction,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { EmployeeWeeklyShiftAvailability } from '../../../model/employee/availabilities/employee-weekly-shift-availability';
import { EmployeeDateAvailability } from '../../../model/employee/availabilities/employee-date-availability';
import { AvailabilityType } from '../../../model/employee/availabilities/availability-type';
import { catchError, firstValueFrom, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import {
  KeyValuePipe,
  LowerCasePipe,
  NgClass,
  NgFor,
  NgIf,
  NgTemplateOutlet,
} from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { WeeklySeparateShift } from '../../../model/schedule/shift/weekly-separate-shift';
import { StoreMedium } from '../../../model/store/store-medium';
import { StoreWeeklyShift } from '../../../model/store/store-weekly-shift/store-weekly-shift';
import { ConfirmationDialog } from '../../shared/confirmation-dialog/confirmation-dialog';
import { MatDialog } from '@angular/material/dialog';
import { SetEmployeeAvailabilities } from '../../../model/employee/availabilities/set-employee-availabilities';
import { MatDivider } from '@angular/material/divider';
import { NavigationService } from '../../../services/common/navigation.service';
import { ScrollToTop } from '../../shared/scroll-to-top/scroll-to-top';
import { appearInOut, listAnimation } from '../../../utils/animations';
import {
  trackByStoreMedium,
  trackByWeeklyShift,
} from '../../../utils/common-utils';
import { CanDeactivateComponent } from '../../../utils/guards/can-deactivate-component';
import { MatTableModule } from '@angular/material/table';
import { UnavailabilityAffectedShifts } from '../../../model/employee/availabilities/unavailabilities/unavailability-affected-shifts';
import { MatTooltip } from '@angular/material/tooltip';
import { MatSortHeader } from '@angular/material/sort';
import { StoreWeeklyShiftLight } from '../../../model/store/store-weekly-shift/store-weekly-shift-light';
import { CustomColorService } from '../../../services/common/custom-color-service';
import { EmployeeAvailabilityService } from '../../../services/crud/employee-availability.service';

@Component({
  selector: 'app-employee-availabilities-form',
  standalone: true,
  imports: [
    MatTabsModule,
    MatCardModule,
    TranslateModule,
    MatBadgeModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatIconModule,
    NgIf,
    NgFor,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    NgTemplateOutlet,
    MatProgressSpinner,
    NgClass,
    KeyValuePipe,
    MatDivider,
    ScrollToTop,
    MatTableModule,
    LowerCasePipe,
    MatTooltip,
    MatSortHeader,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './employee-availabilities-form.html',
  animations: [appearInOut, listAnimation],
})
export class EmployeeAvailabilitiesForm
  implements OnInit, CanDeactivateComponent
{
  public datesLegends = 'in';
  public datesButtons = 'out';
  public shiftsLegends = 'in';
  public shiftsButtons = 'out';
  initialScrollPosition: number = 0;

  dataLoaded: boolean = false;
  errorLoadingData: boolean = false;
  requestSent: boolean = false;
  formSubmitted: boolean = false;

  @ViewChild('tabGroup') matTabGroup: ElementRef;

  availabilitiesUpdated: boolean = false;

  employee: EmployeeAvailabilities;

  today = this.datepickerService.getToday();
  todayFromString = new Date(this.today.toDateString());
  @ViewChild('calendar') dateCalendar: MatCalendar<Date>;

  uiSelectedDateAvailabilities: EmployeeDateAvailability[] = [];
  selectedDate: Date | null;
  selectedDateAvailabilityType: AvailabilityType = AvailabilityType.Null;
  selectedStringDate: string | null;
  foundUiSelectedDatesIndex: number = -1;

  AvailabilityType = AvailabilityType;
  separateShiftsMap = new Map<string, WeeklySeparateShift[]>();
  uiSelectedWeeklyShiftAvailabilities: EmployeeWeeklyShiftAvailability[] = [];
  selectedWeeklyShiftAvailability: StoreWeeklyShift | null;
  selectedWeeklyShiftAvailabilityType: AvailabilityType = AvailabilityType.Null;
  foundUiSelectedStoreWeeklyShiftIndex: number = -1;

  DAYS_STARTING_MONDAY = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
  ];
  DAYS_STARTING_SUNDAY = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
  ];

  unavailabilityAffectedShifts: UnavailabilityAffectedShifts[] = [];
  checkAffectedShifts: boolean = true;
  weeklyShiftsToBeReduced: Map<Date, string[]> = new Map();

  weeklyShiftsDisplayedColumn: string[] = [
    'action',
    'startTime',
    'endTime',
    'name',
    'jobPositionName',
  ];

  assignmentsDisplayedColumn: string[] = [
    'action',
    'startDate',
    'endDate',
    'name',
    'jobPositionName',
  ];

  constructor(
    private employeeAvailabilityService: EmployeeAvailabilityService,
    private notificationService: NotificationService,
    private _adapter: DateAdapter<any>,
    protected datepickerService: DatepickerService,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private router: Router,
    private navigationService: NavigationService,
    protected customColorService: CustomColorService,
  ) {}

  async ngOnInit() {
    this.loadData().subscribe(async () => {
      setTimeout(() => {
        this.employee.dateAvailabilities.forEach((dateAvailability) => {
          this.addDateAvailability(dateAvailability.date, false);
          this.setDateAvailabilityType(dateAvailability.type, false);
        });
      }, 0);

      this.dataLoaded = true;
    });
  }

  addDateAvailability(date: Date | null, userAction: boolean) {
    if (date) {
      this.uiSelectedDateAvailabilities =
        this.uiSelectedDateAvailabilities.filter(
          (dateAvailability) =>
            dateAvailability.type !== AvailabilityType.Selected,
        );

      this.selectedStringDate =
        this.datepickerService.getDateInStringFormat(date);
      this.selectedDate = date;

      this.foundUiSelectedDatesIndex =
        this.uiSelectedDateAvailabilities.findIndex(
          (dateAvailability) =>
            this.datepickerService.getDateInStringFormat(
              dateAvailability.date,
            ) === this.selectedStringDate,
        );

      if (this.foundUiSelectedDatesIndex >= 0) {
        this.selectedDateAvailabilityType =
          this.uiSelectedDateAvailabilities[
            this.foundUiSelectedDatesIndex
          ].type;
      } else {
        this.uiSelectedDateAvailabilities.push({
          type: AvailabilityType.Selected,
          date: this.selectedDate,
          selectedDate: this.selectedStringDate,
        } as EmployeeDateAvailability);

        this.foundUiSelectedDatesIndex =
          this.uiSelectedDateAvailabilities.length - 1;
      }
      this.dateCalendar.updateTodaysDate();

      if (userAction) {
        this.initialScrollPosition = window.scrollY;
        this.matTabGroup.nativeElement.scrollIntoView({ behavior: 'smooth' });

        this.datesLegends = 'out';
        this.datesButtons = 'in';
      }
    }
  }

  setDateAvailabilityType(type: AvailabilityType, userAction: boolean) {
    this.uiSelectedDateAvailabilities.splice(this.foundUiSelectedDatesIndex, 1);

    if (type !== AvailabilityType.Null) {
      this.uiSelectedDateAvailabilities.push({
        type: type,
        date: this.selectedDate,
        selectedDate: this.selectedStringDate,
      } as EmployeeDateAvailability);
    }

    this.resetDateTabs();
    if (userAction) {
      this.availabilitiesUpdated = true;
    }

    window.scrollTo({ top: this.initialScrollPosition, behavior: 'smooth' });
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view == 'month') {
      let dateToFind = this.datepickerService.getDateInStringFormat(cellDate);
      let i = this.uiSelectedDateAvailabilities.findIndex(
        (dateAvailability) =>
          this.datepickerService.getDateInStringFormat(
            dateAvailability.date,
          ) === dateToFind,
      );
      if (i >= 0) {
        return this.uiSelectedDateAvailabilities[i].type.toLowerCase();
      }
    }
    return '';
  };

  getDateAvailabilitiesCount(): number {
    return this.uiSelectedDateAvailabilities.filter(
      (availability) =>
        availability.type !== AvailabilityType.Selected &&
        new Date(availability.date) >= this.todayFromString,
    ).length;
  }

  retrieveStoreShiftTableCell(
    weeklySeparateShift: WeeklySeparateShift,
    store: StoreMedium,
    day: string,
  ): StoreWeeklyShift | null {
    if (weeklySeparateShift.ids) {
      for (let id of weeklySeparateShift.ids) {
        for (let shift of store.shifts) {
          if (id === shift.id && shift.dayOfWeek === day) {
            return shift;
          }
        }
      }
    }
    return null;
  }

  getStoreShiftAvailabilitiesCount(storeId: string): number {
    return this.uiSelectedWeeklyShiftAvailabilities.filter(
      (availability) =>
        availability.storeWeeklyShift.storeId === storeId &&
        availability.type !== AvailabilityType.Null,
    ).length;
  }

  getShiftAvailabilityType(shiftId: string): AvailabilityType {
    const foundShift = this.uiSelectedWeeklyShiftAvailabilities.find(
      (shift) => shift.storeWeeklyShift.id === shiftId,
    );
    return foundShift ? foundShift.type : AvailabilityType.Null;
  }

  addWeeklyShiftAvailability(storeWeeklyShift: StoreWeeklyShift) {
    this.uiSelectedWeeklyShiftAvailabilities =
      this.uiSelectedWeeklyShiftAvailabilities.filter(
        (availability) => availability.type !== AvailabilityType.Null,
      );

    this.foundUiSelectedStoreWeeklyShiftIndex =
      this.uiSelectedWeeklyShiftAvailabilities.findIndex(
        (availability) =>
          availability.storeWeeklyShift.id === storeWeeklyShift.id,
      );

    this.selectedWeeklyShiftAvailability = storeWeeklyShift;
    if (this.foundUiSelectedStoreWeeklyShiftIndex >= 0) {
      this.selectedWeeklyShiftAvailabilityType =
        this.uiSelectedWeeklyShiftAvailabilities[
          this.foundUiSelectedStoreWeeklyShiftIndex
        ].type;
    } else {
      const newWeeklyShiftAvailability: EmployeeWeeklyShiftAvailability = {
        type: AvailabilityType.Null,
        storeWeeklyShift: storeWeeklyShift,
      };
      this.uiSelectedWeeklyShiftAvailabilities.push(newWeeklyShiftAvailability);

      this.foundUiSelectedStoreWeeklyShiftIndex =
        this.uiSelectedWeeklyShiftAvailabilities.length - 1;
    }

    this.initialScrollPosition = window.scrollY;
    this.matTabGroup.nativeElement.scrollIntoView({ behavior: 'smooth' });

    this.shiftsLegends = 'out';
    this.shiftsButtons = 'in';
  }

  setWeeklyShiftAvailability(type: AvailabilityType) {
    this.uiSelectedWeeklyShiftAvailabilities.splice(
      this.foundUiSelectedStoreWeeklyShiftIndex,
      1,
    );

    if (
      type !== AvailabilityType.Null &&
      this.selectedWeeklyShiftAvailability
    ) {
      this.uiSelectedWeeklyShiftAvailabilities.push({
        type: type,
        storeWeeklyShift: this.selectedWeeklyShiftAvailability,
      });
    }

    this.availabilitiesUpdated = true;
    this.resetShiftsTab();

    window.scrollTo({ top: this.initialScrollPosition, behavior: 'smooth' });
  }

  onTabChange(event: MatTabChangeEvent) {
    this.uiSelectedDateAvailabilities =
      this.uiSelectedDateAvailabilities.filter(
        (availability) => availability.type !== AvailabilityType.Selected,
      );
    this.resetDateTabs();
    this.resetShiftsTab();
  }

  abort() {
    if (this.availabilitiesUpdated) {
      const dialogRef = this.matDialog.open(ConfirmationDialog, {
        data: { dialogContentKey: 'cancelActionConfirmation' },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.formSubmitted = true;
          this.router.navigate(['/employees']).then(() => {});
        }
      });
    } else {
      this.router.navigate(['/employees']).then(() => {});
    }
  }

  async canDeactivate(): Promise<boolean> {
    if (this.formSubmitted) {
      return true;
    }

    if (this.availabilitiesUpdated) {
      const dialogRef = this.matDialog.open(ConfirmationDialog, {
        data: { dialogContentKey: 'cancelActionConfirmation' },
      });

      const result = await firstValueFrom(dialogRef.afterClosed());
      return result as boolean;
    } else {
      return true;
    }
  }

  setAvailabilities() {
    this.requestSent = true;
    this.notificationService.closeMessages();

    const { addedWeeklyShiftAvailabilities, removedWeeklyShiftAvailabilities } =
      this.calculateWeeklyShiftAvailabilities();

    const { addedDateAvailabilities, removedDateAvailabilities } =
      this.calculateDateAvailabilities();

    const setEmployeeAvailabilities: SetEmployeeAvailabilities = {
      employee: this.employee,
      checkAffectedShifts: this.checkAffectedShifts,
      shiftsToBeReduced: Object.fromEntries(this.weeklyShiftsToBeReduced),
      addedWeeklyShiftAvailabilities: addedWeeklyShiftAvailabilities,
      removedWeeklyShiftAvailabilities: removedWeeklyShiftAvailabilities,
      addedDateAvailabilities: addedDateAvailabilities,
      removedDateAvailabilities: removedDateAvailabilities,
    };

    this.employeeAvailabilityService
      .setEmployeeAvailabilities(setEmployeeAvailabilities)
      .subscribe({
        next: () => {
          this.notificationService.showTimedMessage(
            'employeeAvailabilitiesUpdated',
          );
          this.formSubmitted = true;
          this.router.navigate(['/employees']).then(() => {});
        },
        error: (error) => {
          if (error.status === 425) {
            this.notificationService.showActionMessage(
              'requestFailed_SchedulerInProgress',
            );
          } else if (error.status === 426) {
            this.notificationService.showTimedMessage(
              'foundUnavailableAffectedStoreShiftsAlert',
            );
            this.checkAffectedShifts = false;
            this.unavailabilityAffectedShifts = error.error;
          } else {
            this.notificationService.showErrorMessage('dataError');
          }
          this.requestSent = false;
        },
      });
  }

  calculateWeeklyShiftAvailabilities() {
    const addedWeeklyShiftAvailabilities: EmployeeWeeklyShiftAvailability[] =
      [];
    const removedWeeklyShiftAvailabilities: EmployeeWeeklyShiftAvailability[] =
      [];

    const uiSelectedWeeklyShiftsMap = new Map(
      this.uiSelectedWeeklyShiftAvailabilities.map((i) => [
        i.storeWeeklyShift.id,
        i,
      ]),
    );
    const employeeWeeklyShiftAvailabilitiesMap = new Map(
      this.employee.weeklyShiftAvailabilities.map((i) => [
        i.storeWeeklyShift.id,
        i,
      ]),
    );

    for (const [id, availability] of uiSelectedWeeklyShiftsMap) {
      if (!employeeWeeklyShiftAvailabilitiesMap.has(id)) {
        addedWeeklyShiftAvailabilities.push(availability);
        uiSelectedWeeklyShiftsMap.delete(id);
      }
    }

    for (const [id, availability] of employeeWeeklyShiftAvailabilitiesMap) {
      if (!uiSelectedWeeklyShiftsMap.has(id)) {
        removedWeeklyShiftAvailabilities.push(availability);
        employeeWeeklyShiftAvailabilitiesMap.delete(id);
      }
    }

    for (const [id, availability] of uiSelectedWeeklyShiftsMap) {
      const employeeAvailability = employeeWeeklyShiftAvailabilitiesMap.get(id);
      if (
        employeeAvailability &&
        employeeAvailability.type !== availability.type
      ) {
        employeeAvailability.type = availability.type;
        employeeAvailability.updated = true;
      }
    }

    return { addedWeeklyShiftAvailabilities, removedWeeklyShiftAvailabilities };
  }

  private loadData() {
    const employee$ = this.employeeAvailabilityService
      .getEmployeeAvailabilities(
        this.activatedRoute.snapshot.paramMap.get('id') ?? '',
      )
      .pipe(
        catchError(() => {
          return of(null);
        }),
      );

    return forkJoin({
      employee: employee$,
    }).pipe(
      map(({ employee }) => {
        if (!employee) {
          this.errorLoadingData = true;
          this.navigationService.setErrorLoading(true);
          return;
        }

        this.employee = employee;

        this.populateDateAvailabilities();
        this.populateWeeklyShiftAvailabilities();
      }),
    );
  }

  private populateDateAvailabilities() {
    this._adapter.setLocale(this.datepickerService.getPickerLocale());
    this._adapter.getFirstDayOfWeek = () =>
      this.datepickerService.defineStartOfWeek(
        this.employee.stores[0]?.startOfWeek,
      );
  }

  private resetDateTabs() {
    this.selectedDateAvailabilityType = AvailabilityType.Null;
    this.selectedStringDate = null;
    this.selectedDate = null;
    this.foundUiSelectedDatesIndex = -1;

    this.dateCalendar.updateTodaysDate();

    this.datesLegends = 'in';
    this.datesButtons = 'out';
  }

  private populateWeeklyShiftAvailabilities() {
    this.employee.stores.forEach((store) => {
      const separateShifts: WeeklySeparateShift[] = [];
      store.shifts
        .sort((a, b) => {
          // Convert start and end times to Date objects for comparison
          const aStart = new Date(`1970-01-01T${a.startTime}Z`);
          const bStart = new Date(`1970-01-01T${b.startTime}Z`);
          const aEnd = new Date(`1970-01-01T${a.endTime}Z`);
          const bEnd = new Date(`1970-01-01T${b.endTime}Z`);

          // Compare start times
          if (aStart < bStart) {
            return -1;
          } else if (aStart > bStart) {
            return 1;
          }

          // If start times are equal, compare end times
          if (aEnd < bEnd) {
            return -1;
          } else if (aEnd > bEnd) {
            return 1;
          }

          // If start and end times are equal, compare orderIndex
          if (a.jobPosition.orderIndex < b.jobPosition.orderIndex) {
            return -1;
          } else if (a.jobPosition.orderIndex > b.jobPosition.orderIndex) {
            return 1;
          }

          return 0;
        })
        .forEach((shift) => {
          let foundShift = separateShifts.find(
            (separateShift) =>
              separateShift.startTime === shift.startTime &&
              separateShift.endTime === shift.endTime &&
              separateShift.jobPositionName === shift.jobPosition.name,
          );

          if (foundShift?.ids) {
            foundShift.ids.push(shift.id);
          } else {
            const separateShift: WeeklySeparateShift = {
              name: shift.name,
              startTime: shift.startTime,
              endTime: shift.endTime,
              jobPositionName: shift.jobPosition.name,
              preferredColor: this.customColorService.getColor(
                shift.jobPosition.preferredColor,
              ),
              ids: [shift.id],
            };
            separateShifts.push(separateShift);
          }
        });

      this.separateShiftsMap.set(store.id, separateShifts);
    });

    this.employee.weeklyShiftAvailabilities.forEach(
      (weeklyShiftAvailability) => {
        const newWeeklyShiftAvailability: EmployeeWeeklyShiftAvailability = {
          type: weeklyShiftAvailability.type,
          storeWeeklyShift: weeklyShiftAvailability.storeWeeklyShift,
        };

        this.uiSelectedWeeklyShiftAvailabilities.push(
          newWeeklyShiftAvailability,
        );
      },
    );
  }

  private resetShiftsTab() {
    this.selectedWeeklyShiftAvailabilityType = AvailabilityType.Null;
    this.selectedWeeklyShiftAvailability = null;
    this.foundUiSelectedStoreWeeklyShiftIndex = -1;

    this.shiftsLegends = 'in';
    this.shiftsButtons = 'out';
  }

  private calculateDateAvailabilities() {
    const addedDateAvailabilities: EmployeeDateAvailability[] = [];
    const removedDateAvailabilities: EmployeeDateAvailability[] = [];

    const uiSelectedDatesMap = new Map(
      this.uiSelectedDateAvailabilities.map((i) => [i.selectedDate, i]),
    );
    const employeeDateAvailabilitiesMap = new Map(
      this.employee.dateAvailabilities.map((i) => [
        this.datepickerService.getDateInStringFormat(i.date),
        i,
      ]),
    );

    for (const [date, availability] of uiSelectedDatesMap) {
      if (!employeeDateAvailabilitiesMap.has(date)) {
        availability.date = new Date(availability.selectedDate);
        addedDateAvailabilities.push(availability);
        uiSelectedDatesMap.delete(date);
      }
    }

    for (const [date, availability] of employeeDateAvailabilitiesMap) {
      if (!uiSelectedDatesMap.has(date)) {
        availability.date = new Date(availability.selectedDate);
        removedDateAvailabilities.push(availability);
        employeeDateAvailabilitiesMap.delete(date);
      }
    }

    for (const [date, availability] of uiSelectedDatesMap) {
      const employeeAvailability = employeeDateAvailabilitiesMap.get(date);
      if (
        employeeAvailability &&
        employeeAvailability.type !== availability.type
      ) {
        employeeAvailability.type = availability.type;
        employeeAvailability.updated = true;
      }
    }

    return { addedDateAvailabilities, removedDateAvailabilities };
  }

  hasStoreShifts(storeId: string): boolean {
    const shifts = this.separateShiftsMap.get(storeId);
    return !!shifts && shifts.length > 0;
  }

  reduceWeeklyShift(
    weeklyShift: StoreWeeklyShiftLight,
    unavailabilityDate: Date,
  ) {
    if (!this.weeklyShiftsToBeReduced.has(unavailabilityDate)) {
      this.weeklyShiftsToBeReduced.set(unavailabilityDate, []);
    }
    this.weeklyShiftsToBeReduced.get(unavailabilityDate)?.push(weeklyShift.id);
    weeklyShift.requiredCount--;
  }

  undoReduceWeeklyShift(
    weeklyShift: StoreWeeklyShiftLight,
    unavailabilityDate: Date,
  ) {
    if (this.weeklyShiftsToBeReduced.has(unavailabilityDate)) {
      const index = this.weeklyShiftsToBeReduced
        .get(unavailabilityDate)
        ?.indexOf(weeklyShift.id);
      if (index !== undefined && index > -1) {
        this.weeklyShiftsToBeReduced.get(unavailabilityDate)?.splice(index, 1);
      }
    }
    weeklyShift.requiredCount++;
  }

  shiftExistsInReducedList(shiftId: string, unavailabilityDate: Date): boolean {
    const shiftIds = this.weeklyShiftsToBeReduced.get(unavailabilityDate);
    return !!shiftIds?.includes(shiftId);
  }

  protected readonly trackByStoreMedium = trackByStoreMedium;
  protected readonly trackByWeeklyShift = trackByWeeklyShift;
}
