import { Injectable } from '@angular/core';
import { InitializationService } from '../../services/core/initialization-service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private initializationService: InitializationService) {}

  canActivate(): boolean {
    return this.initializationService.isUserAuthenticated()
      ? true
      : (this.initializationService.logoutAuthenticatedUser(true), false);
  }
}
