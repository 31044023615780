import { Component, Input } from '@angular/core';
import { ScheduleEmployee } from '../../../../model/schedule/generator/schedule-employee';
import { NgClass, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { formatScheduleMinutes } from '../../../../utils/common-utils';

@Component({
  selector: 'app-employee-column',
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatTooltipModule,
    NgClass,
    TranslateModule,
    MatIconModule,
    MatBadgeModule,
  ],
  templateUrl: './employee-column.html',
})
export class EmployeeColumn {
  @Input() employee: ScheduleEmployee;

  constructor(private translateService: TranslateService) {}

  getViolation(): string {
    if (this.employee.violations.length === 0) {
      return '';
    }

    const violations = this.employee.violations;
    const weeklyHours = this.employee.contract.weeklyHours;

    if (violations.includes('strictContractNotMet')) {
      return this.translateService.instant(
        weeklyHours
          ? 'violation.employeeStrictWeeklyHoursContractNotMet'
          : 'violation.employeeStrictMonthlyHoursContractNotMet',
      );
    } else if (violations.includes('fluidContractNotMet')) {
      return this.translateService.instant(
        weeklyHours
          ? 'violation.employeeFluidWeeklyHoursContractNotMet'
          : 'violation.employeeFluidMonthlyHoursContractNotMet',
      );
    } else if (violations.includes('employeeWithContractNotMetNotAssigned')) {
      return this.translateService.instant(
        'violation.employeeWithContractNotMetNotAssigned',
      );
    } else {
      return this.translateService.instant(
        weeklyHours
          ? 'violation.employeeStrictWeeklyHoursContractNotMet'
          : 'violation.employeeStrictMonthlyHoursContractNotMet',
      );
    }
  }

  getReward(): string {
    if (this.employee.rewards.length === 0) {
      return '';
    }

    const rewards = this.employee.rewards;
    const weeklyHours = this.employee.contract.weeklyHours;

    if (rewards.includes('strictHoursContractMet')) {
      return this.translateService.instant(
        weeklyHours
          ? 'reward.employeeStrictWeeklyHoursContractMet'
          : 'reward.employeeStrictMonthlyHoursContractMet',
      );
    } else if (rewards.includes('fluidHoursContractMet')) {
      return this.translateService.instant(
        weeklyHours
          ? 'reward.employeeFluidWeeklyHoursContractMet'
          : 'reward.employeeFluidMonthlyHoursContractMet',
      );
    } else {
      return this.translateService.instant(
        weeklyHours
          ? 'reward.employeeStrictWeeklyHoursContractMet'
          : 'reward.employeeStrictMonthlyHoursContractMet',
      );
    }
  }

  protected readonly formatScheduleMinutes = formatScheduleMinutes;
}
