import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

const STORAGE_KEY = 'BEEEASE-COOKIES';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.html',
  standalone: true,
  imports: [NgIf, MatButtonModule, TranslateModule, MatIconModule],
})
export class Cookies {
  hasAcceptedCookies: boolean;

  constructor() {
    try {
      this.hasAcceptedCookies = localStorage.getItem(STORAGE_KEY) === 'true';
    } catch {
      this.hasAcceptedCookies = false;
    }
  }

  accept() {
    try {
      localStorage.setItem(STORAGE_KEY, 'true');
    } catch {}

    this.hasAcceptedCookies = true;
  }
}
