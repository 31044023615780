import { Component, OnInit } from '@angular/core';
import { JobPosition } from '../../model/job-position/job-position';
import { NotificationService } from '../../services/common/notification.service';
import { JobPositionService } from '../../services/crud/job-position.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Spinner } from '../shared/spinner/spinner';
import {
  AsyncPipe,
  NgFor,
  NgIf,
  NgOptimizedImage,
  NgStyle,
} from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeService } from '../../services/crud/employee.service';
import { EmployeeLight } from '../../model/employee/employee-light';
import { JobPositionFormDialog } from './form-dialog/job-position-form-dialog';
import { catchError, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDividerModule } from '@angular/material/divider';
import * as jsonData from '../../../assets/config.json';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NavigationService } from '../../services/common/navigation.service';
import { ScrollToTop } from '../shared/scroll-to-top/scroll-to-top';
import { popUp, reorder, scaleUp } from '../../utils/animations';
import { trackByJobPosition } from '../../utils/common-utils';
import { MatRipple } from '@angular/material/core';
import { ThemeLoaderService } from '../../services/core/theme-loader';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { ReorderObjectsList } from '../../model/general/reorder-objects-list';
import { ReorderObjectsRequest } from '../../model/general/reorder-objects-request';
import { DatepickerService } from '../../services/common/datepicker.service';
import { CustomColorService } from '../../services/common/custom-color-service';
import { MatTooltip } from '@angular/material/tooltip';
import { WaveBackground } from '../shared/wave-background/wave-background';
import { InitializationService } from '../../services/core/initialization-service';

@Component({
  selector: 'app-job-positions',
  standalone: true,
  imports: [
    Spinner,
    TranslateModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    NgIf,
    NgFor,
    MatButtonModule,
    RouterLink,
    ScrollToTop,
    NgOptimizedImage,
    MatRipple,
    DragDropModule,
    AsyncPipe,
    NgStyle,
    MatTooltip,
    WaveBackground,
  ],
  templateUrl: './job-positions.html',
  animations: [popUp, scaleUp, reorder],
})
export class JobPositions implements OnInit {
  readonly configProperties: any = jsonData;

  isOwner: boolean = false;

  reorderMode: boolean = false;
  dataLoaded: boolean = false;
  errorLoadingData: boolean = false;
  jobPositions: JobPosition[] = [];
  employees: EmployeeLight[] = [];

  hoverState: { [id: string]: string } = {};
  dialogOpen: boolean = false;

  buttonRippleEffect = '';

  constructor(
    private jobPositionService: JobPositionService,
    private notificationService: NotificationService,
    public matDialog: MatDialog,
    private employeeService: EmployeeService,
    private navigationService: NavigationService,
    private themeLoaderService: ThemeLoaderService,
    protected datepickerService: DatepickerService,
    protected customColorService: CustomColorService,
    private activatedRoute: ActivatedRoute,
    private initializationService: InitializationService,
  ) {}

  async ngOnInit() {
    this.isOwner = this.initializationService.isAuthenticateUserOwner();

    this.loadData().subscribe(() => {
      this.dataLoaded = true;

      if (
        this.activatedRoute.snapshot.url.some(
          (segment) => segment.path === 'add',
        )
      ) {
        this.addJobPosition();
      }
    });

    this.buttonRippleEffect =
      this.themeLoaderService.currentColorScheme === 'dark'
        ? 'rgba(74, 68, 89, 0.5)'
        : 'rgba(232, 222, 249, 0.5)';
  }

  loadData() {
    const employees$ = this.employeeService.getEmployees().pipe(
      catchError(() => {
        return of(null);
      }),
    );

    const jobPositions$ = this.jobPositionService.getJobPositions(true).pipe(
      catchError(() => {
        return of(null);
      }),
    );

    return forkJoin({
      employees: employees$,
      jobPositions: jobPositions$,
    }).pipe(
      map(({ employees, jobPositions }) => {
        if (!employees || !jobPositions) {
          this.errorLoadingData = true;
          this.navigationService.setErrorLoading(true);
          return;
        }
        this.employees = employees;
        this.jobPositions = jobPositions as JobPosition[];
      }),
    );
  }

  addJobPosition() {
    const dialogRef = this.matDialog.open(JobPositionFormDialog, {
      data: {
        employees: this.employees,
        listLength: this.jobPositions.length,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.jobPositions.push(result as JobPosition);
      }
    });
  }

  editJobPosition(jobPosition: JobPosition) {
    this.onHover(jobPosition.id);
    this.dialogOpen = true;

    const dialogRef = this.matDialog.open(JobPositionFormDialog, {
      data: {
        jobPosition: JSON.parse(JSON.stringify(jobPosition)),
        employees: this.employees,
        editMode: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.dialogOpen = false;

      if (result === 'deleted') {
        this.jobPositions = this.jobPositions.filter(
          (s) => s.id !== jobPosition.id,
        );
      } else if (result) {
        const index = this.jobPositions.findIndex(
          (s) => s.id === jobPosition.id,
        );
        if (index !== -1) {
          this.jobPositions[index] = result as JobPosition;
        }
      }
    });
  }

  onHover(id: string) {
    this.hoverState[id] = 'hovered';
  }

  onLeave(id: string) {
    this.hoverState[id] = 'default';
  }

  reorderJobPositions(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.jobPositions, event.previousIndex, event.currentIndex);

    const reorderObjectsList: ReorderObjectsList[] = this.jobPositions.map(
      (jobPosition, index) => {
        return { id: jobPosition.id, orderIndex: index + 1 };
      },
    );

    const reorderObjectsRequest: ReorderObjectsRequest = {
      objects: reorderObjectsList,
    };

    this.jobPositionService
      .reorderJobPositions(reorderObjectsRequest)
      .subscribe({
        next: () => {},
        error: () => {
          this.notificationService.showErrorMessage('dataError');
        },
      });
  }

  countWeeklyShifts(jobPosition: JobPosition): number {
    return jobPosition.storeWeeklyShifts.reduce(
      (acc, shift) => acc + shift.requiredCount,
      0,
    );
  }

  protected readonly trackByJobPosition = trackByJobPosition;
}
