import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/common/auth.service';
import { NotificationService } from '../../../services/common/notification.service';
import { NavigationService } from '../../../services/common/navigation.service';
import { MatDivider } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { SvgWrap } from '../../shared/svg-wrap/svg-wrap';
import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [
    MatCardModule,
    TranslateModule,
    MatIconModule,
    MatDivider,
    MatButtonModule,
    MatProgressSpinner,
    NgIf,
    SvgWrap,
  ],
  templateUrl: './about.html',
  animations: [
    trigger('popIn', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateY(30px)',
        }),
      ),
      state(
        '*',
        style({
          opacity: 1,
          transform: 'translateY(0) scale(1)',
        }),
      ),
      transition('void => *', [
        animate(
          '0.8s 2s ease-out',
          keyframes([
            style({
              opacity: 0,
              transform: 'translateY(30px) scale(0.5)',
              offset: 0,
            }),
            style({
              opacity: 1,
              transform: 'translateY(0) scale(1.2)',
              offset: 0.7,
            }),
            style({
              opacity: 1,
              transform: 'translateY(0) scale(1)',
              offset: 1,
            }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class About implements OnInit, AfterViewInit {
  resetPassword: boolean = false;
  register: boolean = false;

  @ViewChild('typingEffect', { static: false }) typingEffectElement: ElementRef;
  @ViewChild('typingCursor', { static: false }) typingCursorElement: ElementRef;
  @ViewChild('appOwner', { static: false }) appOwner: ElementRef;

  typingText: string = 'IKM Services!';
  displayedText: string = '';
  typingSpeed: number = 150;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.resetPassword = this.activatedRoute.snapshot.data['resetPassword'];
    this.register = this.activatedRoute.snapshot.data['register'];

    if (this.register || this.resetPassword) {
      this.authService
        .resetPasswordExists(this.activatedRoute.snapshot.params['id'])
        .subscribe({
          next: () => {
            this.navigationService.setResetPasswordOverlay(true);
          },
          error: () => {
            this.notificationService.showTimedMessage('passwordUpdatedSuccess');
          },
        });
    }
  }

  ngAfterViewInit() {
    if (!this.register && !this.resetPassword) {
      this.startTypingEffect();
    }
  }

  startTypingEffect() {
    let index = 0;
    const typeCharacter = () => {
      if (index < this.typingText.length) {
        const text = this.displayedText + this.typingText.charAt(index);
        this.renderer.setProperty(
          this.typingEffectElement.nativeElement,
          'textContent',
          text,
        );
        this.displayedText = text;
        index++;
        setTimeout(typeCharacter, this.typingSpeed);
      } else {
        // Hide the cursor once typing is complete
        this.renderer.setStyle(
          this.typingCursorElement.nativeElement,
          'display',
          'none',
        );

        this.renderer.addClass(this.appOwner.nativeElement, 'forced');
      }
    };
    typeCharacter();
  }
}
