<ng-container *ngIf="dataLoaded && !errorLoadingData">
  <div class="employee__forms-name secondary-color">
    <span
      >{{ employeePairings.sourceEmployee.firstName }}
      {{ employeePairings.sourceEmployee.lastName }}</span
    >
  </div>

  <mat-tab-group color="accent">
    <mat-tab
      *ngFor="let storePairing of employeePairings.storePairings; let i = index"
    >
      <ng-template mat-tab-label>
        <span
          [matBadge]="getPairingCount(storePairing)"
          matBadgeOverlap="false"
          matBadgeSize="medium"
          >{{ storePairing.store.name }}</span
        >
      </ng-template>

      <ng-container *ngIf="!hideBanners">
        <mat-card
          class="custom__card custom__card-larger"
          appearance="outlined"
        >
          <div class="custom__card-body">
            <div class="custom__card-container">
              <div>
                <mat-icon
                  class="card__icon-larger"
                  svgIcon="employee_pairings"
                  aria-label="pairings"
                ></mat-icon>
              </div>
              <div class="custom__card-section">
                <mat-card-header>
                  <mat-card-title
                    >{{ "employeePairings" | translate }}
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <p>{{ "employeePairingsExplanation1" | translate }}</p>
                  <p>{{ "employeePairingsExplanation2" | translate }}</p>
                  <p>{{ "employeePairingsExplanation3" | translate }}</p>
                  <ul class="card__list-items">
                    <span
                      [innerHTML]="'employeePairingsExplanation4' | translate"
                    ></span>
                  </ul>
                </mat-card-content>
              </div>
            </div>
          </div>
        </mat-card>
      </ng-container>

      <ng-container>
        <mat-card class="mat-mdc-card-outlined">
          <mat-card-content>
            <div
              *ngFor="let target of storePairing.targets"
              class="employee__pairing-form"
            >
              <mat-form-field>
                <mat-select
                  disabled
                  [(value)]="target.targetEmployee"
                  [compareWith]="compareEmployeeExtraLight"
                >
                  <mat-option
                    *ngFor="let employee of storePairing.employees"
                    [value]="employee"
                  >
                    {{ employee.firstName }} {{ employee.lastName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-select
                  [(value)]="target.type"
                  (selectionChange)="onTypeChange(target)"
                >
                  <mat-option
                    *ngFor="let type of employeePairingTypes"
                    [value]="type"
                  >
                    {{ getTranslatablePairingType(type) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button
                mat-icon-button
                class="stroked__icon-button"
                (click)="removePairing(storePairing, target, false)"
              >
                <mat-icon aria-label="remove">delete</mat-icon>
              </button>
            </div>

            <div
              *ngFor="let target of storePairing.addedPairings"
              class="employee__pairing-form"
            >
              <mat-form-field>
                <mat-select
                  [disabled]="target.targetEmployee !== undefined"
                  [(value)]="target.targetEmployee"
                  (selectionChange)="
                    onEmployeeSelectionChange(storePairing, $event)
                  "
                  placeholder="{{ 'selectEmployee' | translate }} *"
                >
                  <mat-option
                    [disabled]="employee.selected"
                    *ngFor="let employee of storePairing.employees"
                    [value]="employee"
                  >
                    {{ employee.firstName }} {{ employee.lastName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-select
                  [(value)]="target.type"
                  placeholder="{{ 'selectPairingType' | translate }} *"
                >
                  <mat-option
                    *ngFor="let type of employeePairingTypes"
                    [value]="type"
                  >
                    {{ getTranslatablePairingType(type) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button
                mat-icon-button
                class="stroked__icon-button"
                (click)="removePairing(storePairing, target, true)"
              >
                <mat-icon aria-label="remove">delete</mat-icon>
              </button>
            </div>

            <div class="employee__pairing-add">
              <button
                mat-raised-button
                (click)="addPairing(storePairing)"
                [disabled]="!isLastPairingValid(storePairing)"
              >
                <mat-icon aria-label="add">add</mat-icon>
                {{ "addNewF" | translate }}
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </mat-tab>
  </mat-tab-group>

  <mat-card style="margin-top: 0" class="form__actions-waved">
    <mat-card-content class="separate__container-actions">
      <button (click)="abort()" [disabled]="requestSent" mat-stroked-button>
        {{ "cancel" | translate }}
      </button>
      <button
        (click)="setPairings()"
        [disabled]="requestSent || !pairingsUpdated || !formValid"
        mat-flat-button
      >
        <mat-spinner *ngIf="requestSent" diameter="20"></mat-spinner>
        <span *ngIf="!requestSent">{{ "save" | translate }} </span>
        <mat-icon aria-label="save" *ngIf="!requestSent">save</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <app-scroll-to-top></app-scroll-to-top>
</ng-container>
