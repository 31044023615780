import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as jsonData from '../../../assets/config.json';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  readonly configProperties: any = jsonData;
  readonly secretKey: string = this.configProperties.calendarKey;

  constructor() {}

  encryptData(data: any): string {
    let b64 = CryptoJS.AES.encrypt(data, this.secretKey).toString();
    let e64 = CryptoJS.enc.Base64.parse(b64);
    return e64.toString(CryptoJS.enc.Hex);
  }

  decryptData(data: string): any {
    let reb64 = CryptoJS.enc.Hex.parse(data);
    let bytes = reb64.toString(CryptoJS.enc.Base64);
    let decrypt = CryptoJS.AES.decrypt(bytes, this.secretKey);
    return decrypt.toString(CryptoJS.enc.Utf8);
  }
}
