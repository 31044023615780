import { Injectable } from '@angular/core';
import { InitializationService } from '../../services/core/initialization-service';

@Injectable({ providedIn: 'root' })
export class IsLoggedIn {
  constructor(private initializationService: InitializationService) {}

  resolve(): void {
    this.initializationService.isUserAuthenticated() &&
      this.initializationService.redirectToHomePage();
  }
}
