<div class="login__overlay" [@slideIn]>
  <mat-card appearance="outlined" class="login__card">
    <mat-card-header>
      <mat-card-title>{{ "systemSignIn" | translate }}</mat-card-title>
      <button
        mat-icon-button
        type="button"
        class="stroked__icon-button"
        (click)="abort()"
        [disabled]="requestSent"
      >
        <mat-icon aria-label="close">close</mat-icon>
      </button>
    </mat-card-header>
    <mat-divider></mat-divider>

    <form (ngSubmit)="login()" [@shake]="shakeState" [formGroup]="loginForm">
      <mat-card-content>
        <mat-form-field appearance="outline" class="form__field">
          <mat-label>Email</mat-label>
          <input
            formControlName="email"
            matInput
            placeholder="{{ 'emailPlaceholder' | translate }}"
            type="email"
          />
          <mat-icon aria-label="email" matSuffix>alternate_email</mat-icon>
          <mat-error
            *ngIf="
              loginForm.controls['email'].invalid &&
              loginForm.controls['email'].touched
            "
          >
            {{ "emailRequired" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form__field">
          <mat-label>{{ "password" | translate }}</mat-label>
          <input
            [type]="passwordHide ? 'password' : 'text'"
            autocomplete="new-password"
            formControlName="password"
            matInput
            placeholder="{{ 'passwordPlaceholder' | translate }}"
          />
          <button
            (click)="passwordHide = !passwordHide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="passwordHide"
            mat-icon-button
            tabindex="-1"
            matSuffix
            type="button"
          >
            <mat-icon aria-label="show password"
              >{{ passwordHide ? "visibility_off" : "visibility" }}
            </mat-icon>
          </button>
          <mat-error
            *ngIf="
              loginForm.controls['password'].invalid &&
              loginForm.controls['password'].touched
            "
          >
            {{ "passwordRequired" | translate }}
          </mat-error>
        </mat-form-field>

        <a
          (click)="forgotPassword()"
          *ngIf="!requestSent && invalidCredentials"
          class="password__forgotten"
          href=""
          onclick="return false;"
          >{{ "forgotPassword" | translate }}</a
        >
      </mat-card-content>

      <mat-card-actions>
        <button
          [disabled]="loginForm.invalid || requestSent"
          color="primary"
          mat-stroked-button
          type="submit"
        >
          <mat-spinner *ngIf="requestSent" diameter="20"></mat-spinner>
          <span *ngIf="!requestSent">{{ "signIn" | translate }}</span>
          <mat-icon
            aria-label="login"
            *ngIf="!requestSent"
            iconPositionEnd
            matButtonIcon
            >login
          </mat-icon>
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
