import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageLoaderService } from '../../../services/core/language-loader';
import { AppLanguage } from '../../../model/app/app-language';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { NgForOf, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ThemeLoaderService } from '../../../services/core/theme-loader';
import { ConfirmationDialog } from '../../shared/confirmation-dialog/confirmation-dialog';
import { MatDialog } from '@angular/material/dialog';
import * as jsonData from '../../../../assets/config.json';
import { InitializationService } from '../../../services/core/initialization-service';
import { MatChipOption, MatChipsModule } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { ChipSelection } from '../../../model/app/chip-selection';
import { SvgSanitizerMedium } from '../../../utils/svg-sanitizer-medium';

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [
    MatCardModule,
    TranslateModule,
    MatButtonModule,
    MatRadioModule,
    NgForOf,
    FormsModule,
    MatDivider,
    MatSlideToggle,
    MatChipsModule,
    NgIf,
    MatIcon,
    SvgSanitizerMedium,
  ],
  templateUrl: './user-profile.html',
})
export class UserProfile implements OnInit {
  readonly configProperties: any = jsonData;

  languages: AppLanguage[] = [];
  languageOptions: AppLanguage[] = this.languageLoaderService.languages;
  currentLanguage: string;

  @ViewChildren('languageChipOptions')
  languageChipOptions: QueryList<MatChipOption>;

  themeOptions: ChipSelection[] = this.configProperties.themeOptions;
  currentTheme: string;

  @ViewChildren('themeChipOptions')
  themeChipOptions: QueryList<MatChipOption>;

  hideInfoBanners: boolean =
    this.initializationService.getUserPreferences().hideInfoBanners ?? false;

  constructor(
    private languageLoaderService: LanguageLoaderService,
    private themeLoaderService: ThemeLoaderService,
    private matDialog: MatDialog,
    private initializationService: InitializationService,
  ) {}

  ngOnInit() {
    this.languages = [
      this.configProperties.systemLanguageOption,
      ...this.languageLoaderService.languages,
    ];

    this.currentLanguage = this.languageLoaderService.systemLanguageUsed
      ? this.languages[0].name
      : (this.languageLoaderService.currentLanguage?.name ?? '');

    if (this.themeLoaderService.systemThemeUsed) {
      this.currentTheme = this.themeOptions[0].name;
    } else {
      this.currentTheme = this.themeLoaderService.currentColorScheme;
    }
  }

  changeLang(lang: AppLanguage) {
    if (lang.name === this.currentLanguage) {
      const index = this.languages.indexOf(lang);
      const chipToSelect = this.languageChipOptions.toArray()[index];
      chipToSelect.selected = true;
    } else {
      const language = this.languageOptions.find((l) => l.name === lang.name);
      if (language) {
        this.initializationService
          .updateLangPreference(lang.name, true)
          .then(() => {
            this.currentLanguage = lang.name;
          });
      } else {
        this.initializationService.updateLangPreference('', true).then(() => {
          this.currentLanguage = this.languages[0].name;
        });
      }
    }
  }

  changeTheme(t: ChipSelection) {
    if (t.name === this.currentTheme) {
      const index = this.themeOptions.indexOf(t);
      const chipToSelect = this.themeChipOptions.toArray()[index];
      chipToSelect.selected = true;
    } else if (t === this.themeOptions[0]) {
      this.initializationService.updateThemePreference('', true).then(() => {
        this.currentTheme = this.themeOptions[0].name;
      });
    } else {
      this.initializationService
        .updateThemePreference(t.name, true)
        .then(() => {
          this.currentTheme = t.name;
        });
    }
  }

  changeHideInfoBanners(event: any) {
    this.initializationService.updateHideInfoBannersPreference(event.checked);
  }

  async logout() {
    const dialogRef = this.matDialog.open(ConfirmationDialog, {
      data: { dialogContentKey: 'logoutConfirmation' },
      enterAnimationDuration: 0,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.initializationService
          .logoutAuthenticatedUser(false)
          .then(() => {});
      }
    });
  }
}
