<div class="welcome__container">
  <div class="welcome__header primary-color">
    <app-svg-wrap></app-svg-wrap>
    <div class="link__svgline link__svgline-primary">
      {{ "dashboardStepsWelcomeHeader" | translate }}
      <a href="" class="link__svgline app__info-owner-spaced">
        BeeEase!
        <svg class="link__svgline link__svgline-primary">
          <use xlink:href="#svg_line"></use>
        </svg>
      </a>
    </div>

    <p>{{ "dashboardStepsWelcomeSubtitle" | translate }}</p>
  </div>

  <div class="welcome__steps-grid">
    <div
      class="step__item"
      routerLink="/store"
      [ngClass]="dashboardInfo.stores.count === 0 ? '' : 'step__item-idle'"
    >
      <div class="step__item-header">
        <ng-container *ngIf="dashboardInfo.stores.count === 0; else checkmark">
          <mat-icon svgIcon="roman_one" aria-label="one"></mat-icon>
        </ng-container>
        <ng-template #checkmark>
          <mat-icon svgIcon="checkmark" aria-label="checkmark"></mat-icon>
        </ng-template>
        <span class="step__item-action">{{ "stores" | translate }}</span>
        <span></span>
      </div>

      <mat-divider class="lower__opacity"></mat-divider>

      <div class="step__item-content-wrap">
        <img
          srcset="/assets/svg/icons/menu/store.svg"
          alt="stores"
          class="step__item-content-bg"
        />
        <div class="step__item-content">
          <p>{{ "dashboardStepsStores1" | translate }}</p>
          <p>{{ "dashboardStepsStores2" | translate }}</p>
        </div>
      </div>
    </div>
    <div
      class="step__item"
      routerLink="/jobs/add"
      [ngClass]="
        dashboardInfo.stores.count > 0 && dashboardInfo.jobPositions.count === 0
          ? ''
          : 'step__item-idle'
      "
    >
      <div class="step__item-header">
        <ng-container
          *ngIf="dashboardInfo.jobPositions.count === 0; else checkmark"
        >
          <mat-icon svgIcon="roman_two" aria-label="two"></mat-icon>
        </ng-container>
        <ng-template #checkmark>
          <mat-icon svgIcon="checkmark" aria-label="checkmark"></mat-icon>
        </ng-template>
        <span class="step__item-action">{{ "jobPositions" | translate }}</span>
        <span></span>
      </div>

      <mat-divider class="lower__opacity"></mat-divider>

      <div class="step__item-content-wrap">
        <img
          srcset="/assets/svg/icons/menu/position.svg"
          alt="stores"
          class="step__item-content-bg"
        />
        <div class="step__item-content">
          <p>{{ "dashboardStepsJobPositions1" | translate }}</p>
          <p>{{ "dashboardStepsJobPositions2" | translate }}</p>
        </div>
      </div>
    </div>
    <div
      class="step__item"
      routerLink="/employee"
      [ngClass]="
        dashboardInfo.stores.count > 0 &&
        dashboardInfo.jobPositions.count > 0 &&
        dashboardInfo.employees.count === 0
          ? ''
          : 'step__item-idle'
      "
    >
      <div class="step__item-header">
        <ng-container
          *ngIf="dashboardInfo.employees.count === 0; else checkmark"
        >
          <mat-icon svgIcon="roman_three" aria-label="three"></mat-icon>
        </ng-container>
        <ng-template #checkmark>
          <mat-icon svgIcon="checkmark" aria-label="checkmark"></mat-icon>
        </ng-template>
        <span class="step__item-action">{{ "employees" | translate }}</span>
        <span></span>
      </div>

      <mat-divider class="lower__opacity"></mat-divider>

      <div class="step__item-content-wrap">
        <img
          srcset="/assets/svg/icons/menu/employee.svg"
          alt="stores"
          class="step__item-content-bg"
        />
        <div class="step__item-content">
          <p>{{ "dashboardStepsEmployees1" | translate }}</p>
          <p>{{ "dashboardStepsEmployees2" | translate }}</p>
          <p>{{ "dashboardStepsEmployees3" | translate }}</p>
        </div>
      </div>
    </div>
    <div
      class="step__item"
      routerLink="/stores/edit"
      [ngClass]="
        dashboardInfo.stores.count > 0 &&
        dashboardInfo.jobPositions.count > 0 &&
        dashboardInfo.employees.count > 0 &&
        dashboardInfo.weeklyShifts.count === 0
          ? ''
          : 'step__item-idle'
      "
    >
      <div class="step__item-header">
        <ng-container
          *ngIf="dashboardInfo.weeklyShifts.count === 0; else checkmark"
        >
          <mat-icon svgIcon="roman_four" aria-label="four"></mat-icon>
        </ng-container>
        <ng-template #checkmark>
          <mat-icon svgIcon="checkmark" aria-label="checkmark"></mat-icon>
        </ng-template>
        <span class="step__item-action">{{ "shifts" | translate }}</span>
        <span></span>
      </div>

      <mat-divider class="lower__opacity"></mat-divider>

      <div class="step__item-content-wrap">
        <img
          srcset="/assets/svg/icons/menu/shift.svg"
          alt="stores"
          class="step__item-content-bg"
        />
        <div class="step__item-content">
          <p>{{ "dashboardStepsShifts1" | translate }}</p>
          <p>{{ "dashboardStepsShifts2" | translate }}</p>
          <p>{{ "dashboardStepsShifts3" | translate }}</p>
          <p [innerHTML]="'dashboardStepsShifts4' | translate"></p>
        </div>
      </div>
    </div>
    <div
      class="step__item"
      routerLink="/schedule"
      [ngClass]="
        dashboardInfo.stores.count > 0 &&
        dashboardInfo.jobPositions.count > 0 &&
        dashboardInfo.employees.count > 0 &&
        dashboardInfo.weeklyShifts.count > 0 &&
        dashboardInfo.assignedShifts.count === 0
          ? ''
          : 'step__item-idle'
      "
    >
      <div class="step__item-header">
        <ng-container
          *ngIf="dashboardInfo.assignedShifts.count === 0; else checkmark"
        >
          <mat-icon svgIcon="roman_five" aria-label="five"></mat-icon>
        </ng-container>
        <ng-template #checkmark>
          <mat-icon svgIcon="checkmark" aria-label="checkmark"></mat-icon>
        </ng-template>
        <span class="step__item-action">{{ "generation" | translate }}</span>
        <span></span>
      </div>

      <mat-divider class="lower__opacity"></mat-divider>

      <div class="step__item-content-wrap">
        <img
          srcset="/assets/svg/icons/menu/generation.svg"
          alt="stores"
          class="step__item-content-bg"
        />
        <div class="step__item-content">
          <p>{{ "dashboardStepsGeneration1" | translate }}</p>
          <p>{{ "dashboardStepsGeneration2" | translate }}</p>
          <p>{{ "dashboardStepsGeneration3" | translate }}</p>
          <p>{{ "dashboardStepsGeneration4" | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
