<div mat-dialog-title>{{ "overlappingShiftsDates" | translate }}</div>
<mat-dialog-content>
  <p>{{ "overlappingShiftsDetected" | translate }}</p>
  <p>
    {{ "overlappingShiftsViewCalendar" | translate }}&nbsp;<a
      href="/calendar/{{ calendarRequest }}"
      target="_blank"
      >{{ "here" | translate }}</a
    >.
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-stroked-button
    color="primary"
    (click)="this.dialogRef.close(false)"
  >
    {{ "cancel" | translate }}
  </button>
  <button
    mat-flat-button
    class="tertiary-button"
    (click)="this.dialogRef.close(true)"
  >
    <mat-icon aria-label="confirm">warning</mat-icon>
    {{ "replace" | translate }}
  </button>
</mat-dialog-actions>
