import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmployeeAvailabilities } from '../../model/employee/employee-availabilities';
import { SetEmployeeAvailabilities } from '../../model/employee/availabilities/set-employee-availabilities';

@Injectable({ providedIn: 'root' })
export class EmployeeAvailabilityService {
  constructor(private http: HttpClient) {}

  getEmployeeAvailabilities(id: string): Observable<EmployeeAvailabilities> {
    return this.http.get<EmployeeAvailabilities>(
      `/api/employee/availability/${id}`,
    );
  }

  setEmployeeAvailabilities(
    setEmployeeAvailabilities: SetEmployeeAvailabilities,
  ): Observable<EmployeeAvailabilities> {
    return this.http.put<EmployeeAvailabilities>(
      '/api/employee/availability',
      setEmployeeAvailabilities,
    );
  }
}
