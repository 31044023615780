<ng-container *ngIf="!hasAcceptedCookies">
  <div class="cookies__overlay mat-elevation-z2">
    {{ "cookiesHeader" | translate }}

    <div class="cookies__buttons">
      <button (click)="accept()" color="primary" mat-button>
        {{ "cookiesAccept" | translate }}
        <mat-icon aria-label="cookie">cookie</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
