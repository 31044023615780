import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-schedule-explanations',
  standalone: true,
  imports: [MatCardModule, TranslateModule, MatIconModule],
  templateUrl: './schedule-explanations.html',
})
export class ScheduleExplanations {}
