import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TranslatedMatPaginator implements MatPaginatorIntl, OnDestroy {
  changes = new Subject<void>();
  firstPageLabel = '';
  itemsPerPageLabel = '';
  lastPageLabel = '';
  nextPageLabel = '';
  previousPageLabel = '';
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.getAndInitTranslations();
      });

    this.getAndInitTranslations();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getAndInitTranslations() {
    this.translateService
      .stream([
        'firstPageLabel',
        'resultsPerPageLabel',
        'lastPageLabel',
        'nextPageLabel',
        'previousPageLabel',
      ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((translation) => {
        this.firstPageLabel = translation['firstPageLabel'];
        this.itemsPerPageLabel = translation['resultsPerPageLabel'];
        this.lastPageLabel = translation['lastPageLabel'];
        this.nextPageLabel = translation['nextPageLabel'];
        this.previousPageLabel = translation['previousPageLabel'];

        this.changes.next();
      });
  }

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return '0 ' + this.translateService.instant('of') + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return (
      startIndex +
      1 +
      ' - ' +
      endIndex +
      ' ' +
      this.translateService.instant('of') +
      ' ' +
      length
    );
  }
}
