import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageLoaderService } from './services/core/language-loader';
import { Navigation } from './pages/navigation/navigation';
import { InitializationService } from './services/core/initialization-service';
import { Login } from './pages/auth/login/login';
import { NavigationService } from './services/common/navigation.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { ResetPassword } from './pages/auth/reset-password/reset-password';
import { IconsLoader } from './services/core/icons-loader';

@Component({
  selector: 'bee-ease-app',
  standalone: true,
  imports: [Navigation, Login, NgIf, AsyncPipe, ResetPassword],
  templateUrl: './bee-ease-app.html',
})
export class BeeEaseApp implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  errorPageLoaded: boolean = false;

  constructor(
    private router: Router,
    protected navigationService: NavigationService,
    private iconLoader: IconsLoader,
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.errorPageLoaded = event.urlAfterRedirects.includes('error');
        }
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private sortTranslation() {
    const jsonObj: { [key: string]: string } = {
      key: 'value',
    };

    const sortedKeys = Object.keys(jsonObj).sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }),
    );
    const sortedJsonObj: { [key: string]: string } = {};
    for (const key of sortedKeys) {
      sortedJsonObj[key] = jsonObj[key];
    }
    const sortedJsonString = JSON.stringify(sortedJsonObj, null, 2);
    console.log(sortedJsonString);
  }
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export function appInitializerFactory(
  languageLoaderService: LanguageLoaderService,
  translate: TranslateService,
) {
  return async () => {
    const languages = languageLoaderService
      .loadLanguages()
      .map((lang) => lang.name);
    translate.addLangs(languages);
    try {
      const loadTranslationPromises = languages.map((lang) =>
        firstValueFrom(translate.getTranslation(lang)),
      );
      await Promise.all(loadTranslationPromises);
    } catch (error) {
      throw new Error(String(error));
    }
  };
}

export function initializeApp(
  initializationService: InitializationService,
): () => Promise<any> {
  return async (): Promise<any> => {
    await initializationService.initialize();
  };
}
