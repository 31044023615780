import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SystemAnnouncement } from '../../../model/system/system-announcement';
import { NgIf } from '@angular/common';
import { InitializationService } from '../../../services/core/initialization-service';
import { MatButtonModule } from '@angular/material/button';
import { DatepickerService } from '../../../services/common/datepicker.service';

@Component({
  selector: 'app-system-announcement',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    TranslateModule,
    NgIf,
    MatButtonModule,
  ],
  templateUrl: './system-announcement-banner.html',
})
export class SystemAnnouncementBanner {
  @Input() systemAnnouncement: SystemAnnouncement;

  constructor(
    private initializationService: InitializationService,
    protected datePickerService: DatepickerService,
  ) {}

  toggleAnnouncementAck() {
    this.initializationService.updateAnnouncementAck();
  }

  protected readonly stop = stop;
}
