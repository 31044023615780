<ng-container *ngIf="dataLoaded && !errorLoadingData">
  <ng-container *ngIf="!businessInitMode; else initMode">
    <div class="dashboard__cards">
      <div class="dashboard__info-cards">
        <mat-card
          appearance="outlined"
          class="dashboard__card"
          routerLink="/stores"
          (mouseenter)="hovering[0] = true"
          (mouseleave)="hovering[0] = false"
          [@fadeIn]
        >
          <div class="dashboard__card-unhovered">
            <mat-card-header>
              <mat-card-title>
                <span class="primary-color">{{ "stores" | translate }}</span>
                <p>{{ dashboardInfo.stores.count }}</p>
              </mat-card-title>
              <div class="dashboard__card-icon surface-background">
                <mat-icon aria-label="stores" svgIcon="menu_store"></mat-icon>
              </div>
            </mat-card-header>
            <mat-card-content>
              <span>{{ "lastChange" | translate }}: </span>
              <span>{{
                datepickerService.getDateTimeInStringFormatForUI(
                  dashboardInfo.stores.updatedAt
                )
              }}</span>
            </mat-card-content>
          </div>
          <div
            *ngIf="hovering[0]"
            class="dashboard__card-hovered dashboard__card-icon primary-background"
            [@fadeIn]
          >
            <mat-icon
              aria-label="view"
              class="dashboard__card-hover-icon"
              [@slideFromLeft]
              >trending_flat
            </mat-icon>
          </div>
        </mat-card>

        <mat-card
          appearance="outlined"
          class="dashboard__card"
          routerLink="/jobs"
          (mouseenter)="hovering[1] = true"
          (mouseleave)="hovering[1] = false"
          [@fadeIn]
        >
          <div class="dashboard__card-unhovered">
            <mat-card-header>
              <mat-card-title>
                <span class="primary-color">{{
                  "jobPositions" | translate
                }}</span>
                <p>{{ dashboardInfo.jobPositions.count }}</p>
              </mat-card-title>
              <div class="dashboard__card-icon surface-background">
                <mat-icon aria-label="jobs" svgIcon="menu_position"></mat-icon>
              </div>
            </mat-card-header>
            <mat-card-content>
              <span>{{ "lastChange" | translate }}: </span>
              <span>{{
                datepickerService.getDateTimeInStringFormatForUI(
                  dashboardInfo.jobPositions.updatedAt
                )
              }}</span>
            </mat-card-content>
          </div>
          <div
            *ngIf="hovering[1]"
            class="dashboard__card-hovered dashboard__card-icon primary-background"
            [@fadeIn]
          >
            <mat-icon aria-label="view" class="dashboard__card-hover-icon"
              >trending_flat
            </mat-icon>
          </div>
        </mat-card>

        <mat-card
          appearance="outlined"
          class="dashboard__card"
          routerLink="/employees"
          (mouseenter)="hovering[2] = true"
          (mouseleave)="hovering[2] = false"
          [@fadeIn]
        >
          <div class="dashboard__card-unhovered">
            <mat-card-header>
              <mat-card-title>
                <span class="primary-color">{{ "employees" | translate }}</span>
                <p>{{ dashboardInfo.employees.count }}</p>
              </mat-card-title>
              <div class="dashboard__card-icon surface-background">
                <mat-icon
                  aria-label="employees"
                  svgIcon="menu_employee"
                ></mat-icon>
              </div>
            </mat-card-header>
            <mat-card-content>
              <span>{{ "lastChange" | translate }}: </span>
              <span>{{
                datepickerService.getDateTimeInStringFormatForUI(
                  dashboardInfo.employees.updatedAt
                )
              }}</span>
            </mat-card-content>
          </div>
          <div
            *ngIf="hovering[2]"
            class="dashboard__card-hovered dashboard__card-icon primary-background"
            [@fadeIn]
          >
            <mat-icon aria-label="view" class="dashboard__card-hover-icon"
              >trending_flat
            </mat-icon>
          </div>
        </mat-card>

        <mat-card
          appearance="outlined"
          class="dashboard__card"
          routerLink="/schedule"
          (mouseenter)="hovering[3] = true"
          (mouseleave)="hovering[3] = false"
          [@fadeIn]
        >
          <div class="dashboard__card-unhovered">
            <mat-card-header>
              <mat-card-title>
                <span class="primary-color">{{
                  "totalGeneratedShifts" | translate
                }}</span>
                <p>{{ dashboardInfo.assignedShifts.count }}</p>
              </mat-card-title>
              <div class="dashboard__card-icon surface-background">
                <mat-icon aria-label="shifts" svgIcon="menu_shift"></mat-icon>
              </div>
            </mat-card-header>
            <mat-card-content>
              <span>{{ "lastGeneration" | translate }}: </span>
              <span>{{
                datepickerService.getDateTimeInStringFormatForUI(
                  dashboardInfo.assignedShifts.updatedAt
                )
              }}</span>
            </mat-card-content>
          </div>
          <div
            *ngIf="hovering[3]"
            class="dashboard__card-hovered dashboard__card-icon primary-background"
            [@fadeIn]
          >
            <mat-icon aria-label="view" class="dashboard__card-hover-icon"
              >trending_flat
            </mat-icon>
          </div>
        </mat-card>
      </div>

      <mat-card appearance="outlined" class="dashboard__card" [@fadeIn]>
        <mat-card-header>
          <mat-card-title>
            <span class="primary-color">{{ "weekShifts" | translate }}</span>
            <p>{{ weekAssignedShifts }}</p>
          </mat-card-title>
          <button
            mat-icon-button
            type="button"
            class="primary-background"
            (click)="expandChart()"
          >
            <mat-icon aria-label="full screen">open_in_full</mat-icon>
          </button>
        </mat-card-header>

        <div class="dashboard__chart">
          <canvas
            baseChart
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
            [type]="lineChartType"
          >
          </canvas>
        </div>
      </mat-card>
    </div>
  </ng-container>

  <ng-template #initMode>
    <app-dashboard-steps [dashboardInfo]="dashboardInfo"></app-dashboard-steps>
  </ng-template>
</ng-container>
