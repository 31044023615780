import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EmployeeService } from '../../../services/crud/employee.service';
import { NotificationService } from '../../../services/common/notification.service';
import { Employee } from '../../../model/employee/employee';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as jsonData from '../../../../assets/config.json';
import { MatIconModule } from '@angular/material/icon';
import { Role } from '../../../model/user/security/role';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { JobPositionLight } from '../../../model/job-position/job-position-light';
import { StoreLight } from '../../../model/store/store-light';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { EmployeeContract } from '../../../model/employee/employee-contract';
import {
  catchError,
  firstValueFrom,
  forkJoin,
  Observable,
  of,
  Subject,
  takeUntil,
} from 'rxjs';
import { JobPositionService } from '../../../services/crud/job-position.service';
import { StoreService } from '../../../services/crud/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { MatCardModule } from '@angular/material/card';
import {
  MatStepper,
  MatStepperIntl,
  MatStepperModule,
  StepperOrientation,
} from '@angular/material/stepper';
import {
  checkForChanges,
  compareJobPositions,
  compareRoles,
  compareStores,
  preventNonNumericInput,
  trackByJobPositionLight,
  trackByRole,
  trackByStoreLight,
} from '../../../utils/common-utils';
import { maxHoursValidator } from '../../../utils/validators/max-hours-validator';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfirmationDialog } from '../../shared/confirmation-dialog/confirmation-dialog';
import { emailValidator } from '../../../utils/validators/email-validator';
import { EditEmployeeRequest } from '../../../model/employee/edit-employee-request';
import { CreateEmployeeRequest } from '../../../model/employee/create-employee-request';
import { NavigationService } from '../../../services/common/navigation.service';
import { ScrollToTop } from '../../shared/scroll-to-top/scroll-to-top';
import { InitializationService } from '../../../services/core/initialization-service';
import { CanDeactivateComponent } from '../../../utils/guards/can-deactivate-component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ColorOption } from '../../../services/common/custom-color-service';

@Component({
  selector: 'app-employee-form',
  standalone: true,
  imports: [
    TranslateModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDividerModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatProgressSpinner,
    NgIf,
    NgFor,
    MatRadioModule,
    MatCardModule,
    MatStepperModule,
    AsyncPipe,
    ScrollToTop,
    NgClass,
    ColorPickerModule,
  ],
  templateUrl: './employee-form.html',
})
export class EmployeeForm implements OnInit, OnDestroy, CanDeactivateComponent {
  readonly configProperties: any = jsonData;
  readonly smallBreakpoint = this.configProperties.smallBreakpoint;
  stepperOrientation: Observable<StepperOrientation>;

  dataLoaded: boolean = false;
  errorLoadingData: boolean = false;
  requestSent: boolean = false;
  formSubmitted: boolean = false;
  hideBanners: boolean = false;

  editMode: boolean = false;

  employee: Employee;
  enableSystemUser: boolean = false;
  checkDuplicateName: boolean = true;

  preferredColor: string;
  colorSuggestions: string[] = [];
  colorPickerToggle: boolean = false;

  initialStores: StoreLight[] = [];
  initialJobPositions: JobPositionLight[] = [];
  initialRoles: Role[] = [];
  initialName: string = '';
  initialEmail: string = '';
  initialEnableSystemUser: boolean = false;

  stores: StoreLight[] = [];
  jobPositions: JobPositionLight[] = [];
  roles: Role[] = [];

  employeeInfoForm: FormGroup;
  employeeContractForm: FormGroup;
  systemUserForm: FormGroup;

  @ViewChild('stepper') stepper: MatStepper;
  protected readonly compareStores = compareStores;
  protected readonly compareJobPositions = compareJobPositions;
  private destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private employeesService: EmployeeService,
    private jobPositionService: JobPositionService,
    private storeService: StoreService,
    private notificationService: NotificationService,
    private breakpointObserver: BreakpointObserver,
    private matStepperIntl: MatStepperIntl,
    translateService: TranslateService,
    private dialogRef: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private initializationService: InitializationService,
    private navigationService: NavigationService,
  ) {
    this.matStepperIntl.optionalLabel = translateService.instant('optional');
  }

  async ngOnInit() {
    this.roles = this.initializationService.getSystemRoles();

    this.loadData().subscribe(async () => {
      this.colorSuggestions = this.configProperties.preferredColorOptions.map(
        (option: ColorOption) => option.color,
      );

      this.dataLoaded = true;
      this.notificationService.closeMessages();
      this.hideBanners =
        this.initializationService.getUserPreferences().hideInfoBanners ??
        false;
    });

    this.stepperOrientation = this.breakpointObserver
      .observe(this.smallBreakpoint)
      .pipe(
        map(({ matches }) => (matches ? 'vertical' : 'horizontal')),
        takeUntil(this.destroy$),
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadData() {
    this.notificationService.showActionMessage('dataLoading');

    const jobPositions$ = this.jobPositionService.getJobPositions(false).pipe(
      catchError(() => {
        return of(null);
      }),
    );

    const stores$ = this.storeService.getBusinessStores(false).pipe(
      catchError((error) => {
        return of(null);
      }),
    );

    let employee$: Observable<Employee | null> = of(null);

    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id) {
      this.editMode = true;
      employee$ = this.employeesService.getEmployeeById(id).pipe(
        catchError(() => {
          return of(null);
        }),
      );
    }

    return forkJoin({
      jobPositions: jobPositions$,
      stores: stores$,
      employee: employee$,
    }).pipe(
      map(({ jobPositions, stores, employee }) => {
        if (!jobPositions || !stores || (this.editMode && !employee)) {
          this.errorLoadingData = true;
          this.navigationService.setErrorLoading(true);
          return;
        }

        this.stores = stores as StoreLight[];
        this.jobPositions = jobPositions;

        const emptyContract: EmployeeContract = {
          weeklyHours: null,
          minHours: 0,
          maxHours: 0,
        };

        const emptyUser = {
          email: '',
          roles: [],
          enabled: true,
        };

        if (employee) {
          this.employee = employee;

          if (!this.employee.user) {
            this.employee.user = emptyUser;
          }

          if (!this.employee.contract) {
            this.employee.contract = emptyContract;
          }

          this.initialStores = [...(this.employee.stores ?? [])];
          this.initialJobPositions = [...(this.employee.jobPositions ?? [])];
          this.initialRoles = [...(this.employee.user.roles ?? [])];
          this.initialName =
            (this.employee.firstName ?? '') +
            ' ' +
            (this.employee.lastName ?? '');
          this.initialEmail = this.employee.user.email ?? '';
          this.initialEnableSystemUser = !!this.employee.user.email;
        } else {
          this.employee = {
            firstName: '',
            lastName: '',
            preferredColor: '',
            stores: [],
            jobPositions: [],
            contract: emptyContract,
            disabled: false,
            user: emptyUser,
          };

          if (this.stores.length === 1) {
            this.employee.stores = [this.stores[0]];
          }

          if (this.jobPositions.length === 1) {
            this.employee.jobPositions = [this.jobPositions[0]];
          }
        }

        this.initInfoForm();
        this.initContractForm();
        this.initSystemUserForm();
      }),
    );
  }

  private initInfoForm() {
    this.employeeInfoForm = new FormGroup({
      firstName: new FormControl(this.employee.firstName, Validators.required),
      lastName: new FormControl(this.employee.lastName, Validators.required),
      stores: new FormControl(this.employee.stores, Validators.required),
      jobPositions: new FormControl(
        this.employee.jobPositions,
        Validators.required,
      ),
      disabled: new FormControl(this.employee.disabled),
    });

    this.employeeInfoForm.valueChanges.subscribe((value) => {
      this.employee.firstName = value.firstName ?? '';
      this.employee.lastName = value.lastName ?? '';
      this.employee.stores = value.stores ?? [];
      this.employee.jobPositions = value.jobPositions ?? [];
      this.employee.disabled = value.disabled ?? false;
    });

    this.employeeInfoForm.controls['firstName'].valueChanges.subscribe(() => {
      this.checkDuplicateName = true;
    });

    this.employeeInfoForm.controls['lastName'].valueChanges.subscribe(() => {
      this.checkDuplicateName = true;
    });
  }

  private initContractForm() {
    this.employeeContractForm = new FormGroup({
      weeklyHours: new FormControl(
        this.employee.contract.weeklyHours != null
          ? this.employee.contract.weeklyHours.toString()
          : 'null',
      ),
      minHours: new FormControl(
        {
          value: this.employee.contract.minHours,
          disabled: this.employee.contract.weeklyHours === null,
        },
        this.employee.contract.weeklyHours !== null
          ? [Validators.required]
          : null,
      ),
      maxHours: new FormControl(
        {
          value: this.employee.contract.maxHours,
          disabled: this.employee.contract.weeklyHours === null,
        },
        this.employee.contract.weeklyHours !== null
          ? [Validators.required]
          : null,
      ),
    });

    if (this.employee.contract.weeklyHours !== null) {
      this.employeeContractForm.setValidators([maxHoursValidator]);
    }

    this.employeeContractForm.valueChanges.subscribe((value) => {
      this.employee.contract.minHours = value.minHours ?? null;
      this.employee.contract.maxHours = value.maxHours ?? null;
    });
  }

  onMinHoursFocusOut(event: any): void {
    const value = Number(event.target.value);
    this.employeeContractForm.controls['minHours'].setValue(value);
  }

  onMaxHoursFocusOut(event: any): void {
    const value = Number(event.target.value);
    this.employeeContractForm.controls['maxHours'].setValue(value);
  }

  updateWeeklyHours(value: boolean | null) {
    if (value === this.employee.contract.weeklyHours) {
      return;
    } else if (value === null) {
      this.employeeContractForm.get('weeklyHours')?.setValue(null);
      this.employee.contract.weeklyHours = null;

      this.employeeContractForm.get('minHours')?.disable();
      this.employeeContractForm.get('maxHours')?.disable();

      this.employeeContractForm.setValidators(null);
      this.employeeContractForm.updateValueAndValidity();
    } else {
      this.employee.contract.weeklyHours = value;

      // Enable minHours and maxHours form controls and add required validation
      const minHoursControl = this.employeeContractForm.get('minHours');
      minHoursControl?.enable();
      minHoursControl?.setValidators([Validators.required]);
      minHoursControl?.updateValueAndValidity();

      const maxHoursControl = this.employeeContractForm.get('maxHours');
      maxHoursControl?.enable();
      maxHoursControl?.setValidators([Validators.required]);
      maxHoursControl?.updateValueAndValidity();

      this.employeeContractForm.setValidators(maxHoursValidator);
      this.employeeContractForm.updateValueAndValidity();
    }

    this.employeeContractForm.markAsDirty();
  }

  private initSystemUserForm() {
    this.systemUserForm = new FormGroup({
      enableSystemUser: new FormControl(!!this.employee.user.email),
      email: new FormControl(this.employee.user.email),
      roles: new FormControl(this.employee.user.roles),
      enabled: new FormControl(
        this.employee.user.email ? this.employee.user.enabled : true,
      ),
    });
    this.systemUserForm.valueChanges.subscribe((value) => {
      this.employee.user.email = value.email ?? '';
      this.employee.user.roles = value.roles ?? [];
      this.employee.user.enabled = value.enabled ?? false;
      this.enableSystemUser = value.enableSystemUser ?? false;
    });

    if (this.editMode && this.employee.user.email) {
      this.setSystemUserFormState(true);
    } else {
      this.setSystemUserFormState(false);
    }

    this.systemUserForm.controls['enableSystemUser'].valueChanges.subscribe(
      (enableSystemUser) => {
        this.setSystemUserFormState(enableSystemUser);
      },
    );
  }

  private setSystemUserFormState(enableSystemUser: boolean) {
    if (enableSystemUser) {
      this.systemUserForm.get('email')?.enable();
      this.systemUserForm.controls['email'].setValidators([
        Validators.required,
        emailValidator,
      ]);

      this.systemUserForm.get('roles')?.enable();
      this.systemUserForm.controls['roles'].setValidators([
        Validators.required,
      ]);

      this.systemUserForm.get('enabled')?.enable();
    } else {
      this.systemUserForm.get('email')?.disable();
      this.systemUserForm.get('roles')?.disable();
      this.systemUserForm.get('enabled')?.disable();

      this.systemUserForm.controls['email'].clearValidators();
      this.systemUserForm.controls['roles'].clearValidators();
    }
    this.systemUserForm.controls['email'].updateValueAndValidity();
    this.systemUserForm.controls['roles'].updateValueAndValidity();
  }

  getInfoError() {
    if (this.employeeInfoForm.controls['firstName'].hasError('required')) {
      return this.translate.instant('firstName');
    } else if (
      this.employeeInfoForm.controls['lastName'].hasError('required')
    ) {
      return this.translate.instant('lastName');
    } else if (this.employeeInfoForm.controls['stores'].hasError('required')) {
      return this.translate.instant('stores');
    } else if (
      this.employeeInfoForm.controls['jobPositions'].hasError('required')
    ) {
      return this.translate.instant('jobPositions');
    }
    return '';
  }

  getContractError() {
    if (this.employeeContractForm.hasError('maxLessThanMin')) {
      return this.translate.instant('contractHours');
    }

    return '';
  }

  getSystemUserError() {
    if (this.systemUserForm.controls['email'].hasError('invalidEmail')) {
      return this.translate.instant('email');
    } else if (this.systemUserForm.controls['roles'].hasError('required')) {
      return this.translate.instant('roles');
    }

    return '';
  }

  saveEmployee() {
    if (
      this.employeeInfoForm.valid &&
      this.employeeContractForm.valid &&
      this.systemUserForm.valid
    ) {
      this.requestSent = true;
      this.notificationService.closeMessages();

      let request;
      let employeeObservable;
      if (this.editMode) {
        let {
          addedList: addedStores,
          removedList: removedStores,
          updated: updatedStores,
        } = checkForChanges(
          this.initialStores,
          this.employeeInfoForm.get('stores')?.value,
        );
        let {
          addedList: addedJobPositions,
          removedList: removedJobPositions,
          updated: updatedJobPositions,
        } = checkForChanges(
          this.initialJobPositions,
          this.employeeInfoForm.get('jobPositions')?.value,
        );
        let {
          addedList: addedRoles,
          removedList: removedRoles,
          updated: updatedRoles,
        } = checkForChanges(
          this.initialRoles,
          this.systemUserForm.get('roles')?.value,
        );

        request = {
          employee: JSON.parse(JSON.stringify(this.employee)),
          checkDuplicateName: this.checkDuplicateName,
          enableSystemUser: this.enableSystemUser,
          updatedName:
            this.initialName !==
            this.employee.firstName + ' ' + this.employee.lastName,
          updatedStores: updatedStores,
          addedStores: addedStores,
          removedStores: removedStores,
          updatedJobPositions: updatedJobPositions,
          addedJobPositions: addedJobPositions,
          removedJobPositions: removedJobPositions,
          updatedEmail: this.initialEmail !== this.employee.user.email,
          updatedRoles: updatedRoles,
          addedRoles: addedRoles,
          removedRoles: removedRoles,
        } as EditEmployeeRequest;

        if (
          request.employee.contract.minHours === 0 &&
          request.employee.contract.maxHours === 0
        ) {
          request.employee.contract.weeklyHours = null;
        }

        employeeObservable = this.employeesService.updateEmployee(request);
      } else {
        request = {
          employee: JSON.parse(JSON.stringify(this.employee)),
          checkDuplicateName: this.checkDuplicateName,
          enableSystemUser: this.enableSystemUser,
        } as CreateEmployeeRequest;

        if (
          request.employee.contract.minHours === 0 &&
          request.employee.contract.maxHours === 0
        ) {
          request.employee.contract.weeklyHours = null;
        }

        employeeObservable = this.employeesService.createEmployee(request);
      }

      employeeObservable.subscribe({
        next: () => {
          if (this.editMode) {
            if (!this.initialEnableSystemUser && this.enableSystemUser) {
              this.notificationService.showTimedMessage(
                'employeeUpdatedAndPasswordAssigned',
              );
            } else if (this.initialEnableSystemUser && !this.enableSystemUser) {
              this.notificationService.showTimedMessage(
                'employeeUpdatedAndDeletedUser',
              );
            } else {
              this.notificationService.showTimedMessage('employeeUpdated');
            }
          } else if (this.enableSystemUser) {
            this.notificationService.showTimedMessage(
              'employeeAddedAndPasswordAssigned',
            );
          } else {
            this.notificationService.showTimedMessage('employeeAdded');
          }

          this.formSubmitted = true;
          this.router.navigate(['/employees']).then(() => {});
        },
        error: (err) => {
          if (err.status === 409) {
            this.checkDuplicateName = false;
            this.stepper.selectedIndex = 0;
            this.notificationService.showActionMessage('employeeNameExists');
          } else if (err.status === 422) {
            this.stepper.selectedIndex = 2;
            this.notificationService.showActionMessage('emailAlreadyInUse');
          } else if (err.status === 425) {
            this.notificationService.showActionMessage(
              'requestFailed_SchedulerInProgress',
            );
          } else {
            this.notificationService.showActionMessage('dataError');
          }
          this.requestSent = false;
        },
      });
    }
  }

  deleteEmployee() {
    const dialogRef = this.dialogRef.open(ConfirmationDialog, {
      data: { dialogContentKey: 'deleteEmployeeConfirmation' },
      enterAnimationDuration: 0,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.employee.id) {
          this.requestSent = true;

          this.employeesService.deleteEmployee(this.employee.id).subscribe({
            next: () => {
              this.notificationService.closeMessages();
              this.notificationService.showTimedMessage('employeeDeleted');

              this.formSubmitted = true;
              this.router.navigate(['/employees']).then(() => {});
            },
            error: (err) => {
              this.requestSent = false;
              this.notificationService.closeMessages();
              if (err.status === 425) {
                this.notificationService.showActionMessage(
                  'requestFailed_SchedulerInProgress',
                );
              } else {
                this.notificationService.showActionMessage('dataError');
              }
            },
          });
        }
      }
    });
  }

  abort() {
    if (
      this.employeeInfoForm.dirty ||
      this.employeeContractForm.dirty ||
      this.systemUserForm.dirty
    ) {
      const dialogRef = this.dialogRef.open(ConfirmationDialog, {
        data: { dialogContentKey: 'cancelActionConfirmation' },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.formSubmitted = true;
          this.router.navigate(['/employees']).then(() => {});
        }
      });
    } else {
      this.router.navigate(['/employees']).then(() => {});
    }
  }

  async canDeactivate(): Promise<boolean> {
    if (this.formSubmitted) {
      return true;
    }

    if (
      this.dataLoaded &&
      !this.errorLoadingData &&
      (this.employeeInfoForm.dirty ||
        this.employeeContractForm.dirty ||
        this.systemUserForm.dirty)
    ) {
      const dialogRef = this.dialogRef.open(ConfirmationDialog, {
        data: { dialogContentKey: 'cancelActionConfirmation' },
      });

      const result = await firstValueFrom(dialogRef.afterClosed());
      return result as boolean;
    } else {
      return true;
    }
  }

  protected readonly compareRoles = compareRoles;
  protected readonly trackByStoreLight = trackByStoreLight;
  protected readonly trackByJobPositionLight = trackByJobPositionLight;
  protected readonly trackByRole = trackByRole;
  protected readonly preventNonNumericInput = preventNonNumericInput;
}
