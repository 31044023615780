<mat-card appearance="outlined" class="custom__card custom__card-banner">
  <div class="custom__card-body">
    <div class="custom__card-container">
      <div>
        <mat-icon aria-label="store">storefront</mat-icon>
      </div>
      <div class="custom__card-section">
        <mat-card-header>
          <mat-card-title>{{ "storeSelection" | translate }} </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>{{ "scheduleGenerationInstructionsStore1" | translate }}</p>
          <p>{{ "scheduleGenerationInstructionsStore2" | translate }}</p>
        </mat-card-content>
      </div>
    </div>
  </div>
</mat-card>

<mat-card appearance="outlined" class="custom__card custom__card-banner">
  <div class="custom__card-body">
    <div class="custom__card-container">
      <div>
        <mat-icon aria-label="dates">date_range</mat-icon>
      </div>
      <div class="custom__card-section">
        <mat-card-header>
          <mat-card-title>{{ "dateRange" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>{{ "scheduleGenerationInstructionsDateRange1" | translate }}</p>
          <p>{{ "scheduleGenerationInstructionsDateRange2" | translate }}</p>
          <p>{{ "scheduleGenerationInstructionsDateRange3" | translate }}</p>
        </mat-card-content>
      </div>
    </div>
  </div>
</mat-card>

<mat-card appearance="outlined" class="custom__card custom__card-banner">
  <div class="custom__card-body">
    <div class="custom__card-container">
      <div>
        <mat-icon aria-label="algorithm">lightbulb</mat-icon>
      </div>
      <div class="custom__card-section">
        <mat-card-header>
          <mat-card-title>{{ "solverAlgorithm" | translate }} </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p
            [innerHTML]="'scheduleGenerationInstructionsAlgorithm1' | translate"
          ></p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsAlgorithm2' | translate"
          ></p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsAlgorithm3' | translate"
          ></p>
          <p
            [innerHTML]="'scheduleGenerationInstructionsAlgorithm4' | translate"
          ></p>
          <p
            [innerHTML]="'scheduleGenerationInstructionsAlgorithm5' | translate"
          ></p>
          <p
            [innerHTML]="'scheduleGenerationInstructionsAlgorithm6' | translate"
          ></p>
        </mat-card-content>
      </div>
    </div>
  </div>
</mat-card>

<mat-card appearance="outlined" class="custom__card custom__card-banner">
  <div class="custom__card-body">
    <div class="custom__card-container">
      <div>
        <mat-icon aria-label="schedule">pending_actions</mat-icon>
      </div>
      <div class="custom__card-section">
        <mat-card-header>
          <mat-card-title
            >{{ "generatedSchedule" | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p
            [innerHTML]="'scheduleGenerationInstructionsSchedule1' | translate"
          ></p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsSchedule2' | translate"
          ></p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsSchedule3' | translate"
          ></p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsSchedule4' | translate"
          ></p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsSchedule5' | translate"
          ></p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsSchedule6' | translate"
          ></p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsSchedule7' | translate"
          ></p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsSchedule8' | translate"
          ></p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsSchedule9' | translate"
          ></p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsSchedule10' | translate"
          ></p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsSchedule11' | translate"
          ></p>
        </mat-card-content>
      </div>
    </div>
  </div>
</mat-card>

<mat-card appearance="outlined" class="custom__card custom__card-banner">
  <div class="custom__card-body">
    <div class="custom__card-container">
      <div>
        <mat-icon aria-label="error">report</mat-icon>
      </div>
      <div class="custom__card-section">
        <mat-card-header>
          <mat-card-title>{{ "generationError" | translate }} </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>{{ "scheduleGenerationInstructionsError1" | translate }}</p>

          <p
            [innerHTML]="'scheduleGenerationInstructionsError2' | translate"
          ></p>
        </mat-card-content>
      </div>
    </div>
  </div>
</mat-card>

<mat-card
  appearance="outlined"
  class="custom__card custom__card-banner"
  style="opacity: 0.6"
>
  <div class="custom__card-body">
    <div class="custom__card-container">
      <div>
        <mat-icon>event_busy</mat-icon>
      </div>
      <div class="custom__card-section">
        <mat-card-header>
          <mat-card-title>{{ "excludeDays" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>Coming Soon...</p>
        </mat-card-content>
      </div>
    </div>
  </div>
</mat-card>
