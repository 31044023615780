import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  trackByDay,
  trackByEmployeeLight,
  trackByShift,
  trackByWeeklyShift,
} from '../../../../../utils/common-utils';
import { LowerCasePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarShifts } from '../../../../../model/schedule/shift/calendar-shifts';
import { DatepickerService } from '../../../../../services/common/datepicker.service';
import { CustomColorService } from '../../../../../services/common/custom-color-service';
import { NotificationService } from '../../../../../services/common/notification.service';
import { ScheduleExporterService } from '../../../../../services/common/schedule-exporter/schedule-exporter-service';
import { listAnimation } from '../../../../../utils/animations';
import { ScheduleShiftCalendar } from '../../../../../model/schedule/shift/schedule-shift-calendar';
import { WeeklySeparateShift } from '../../../../../model/schedule/shift/weekly-separate-shift';
import { RetrieveCalendarShiftsRequest } from '../../../../../model/schedule/shift/retrieve-calendar-shifts-request';

@Component({
  selector: 'app-calendar-list',
  standalone: true,
  imports: [
    LowerCasePipe,
    MatCardModule,
    MatDivider,
    MatIcon,
    MatIconButton,
    MatTooltip,
    NgForOf,
    NgIf,
    TranslateModule,
    NgClass,
  ],
  templateUrl: './calendar-list.html',
  animations: [listAnimation],
})
export class CalendarList implements OnInit, AfterViewInit {
  @Input() calendarShifts: CalendarShifts;
  @Input() twelveHourFormat: boolean;
  @Input() singleDayCalendar: boolean;
  @Input() shiftRequest: RetrieveCalendarShiftsRequest;
  @Input() previewMode: boolean;

  selectedWeekIndex: number = 0;
  weekIndexSize: number;

  constructor(
    protected datepickerService: DatepickerService,
    protected customColorService: CustomColorService,
    protected scheduleExporterService: ScheduleExporterService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.weekIndexSize = this.calendarShifts.weeklyAssignedShifts.length;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  identifyExistingShifts(
    day: ScheduleShiftCalendar,
    separateShift: WeeklySeparateShift,
  ) {
    let shifts = day.shifts;
    let found = shifts.some(
      (shift) =>
        !separateShift.ids ||
        (separateShift.ids.includes(shift.id) &&
          shift.assignedEmployees.length > 0),
    );
    if (found) {
      day.atLeastOneShiftFound = true;
    }
    return found;
  }

  changeWeek(direction: string) {
    if (
      direction === 'next' &&
      this.selectedWeekIndex < this.weekIndexSize - 1
    ) {
      this.selectedWeekIndex++;
    } else if (direction === 'previous' && this.selectedWeekIndex > 0) {
      this.selectedWeekIndex--;
    }

    this.cdr.detectChanges();
  }

  async exportScheduleToXlsx() {
    this.scheduleExporterService.setScheduleExportInProgress(true);
    try {
      await this.scheduleExporterService.xlsxExport(this.shiftRequest);
    } catch (error) {
      this.notificationService.showErrorMessage('dataError');
    } finally {
      this.scheduleExporterService.setScheduleExportInProgress(false);
    }
  }

  protected readonly trackByDay = trackByDay;
  protected readonly trackByWeeklyShift = trackByWeeklyShift;
  protected readonly trackByShift = trackByShift;
  protected readonly trackByEmployeeLight = trackByEmployeeLight;
}
