import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import * as jsonData from '../../../../assets/config.json';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/common/auth.service';
import { NotificationService } from '../../../services/common/notification.service';
import { slideIn } from '../../../utils/animations';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { InitializationService } from '../../../services/core/initialization-service';
import { confirmPasswordValidator } from '../../../utils/validators/confirm-password-validator';
import { NavigationService } from '../../../services/common/navigation.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDivider,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    NgIf,
    MatProgressSpinnerModule,
  ],
  templateUrl: './reset-password.html',
  animations: [slideIn],
})
export class ResetPassword implements OnInit {
  configProperties: any = jsonData;

  resetPasswordForm: FormGroup;
  passwordHide = true;
  requestSent = false;

  isResetMode: boolean = false;
  resetToken: string = '';

  constructor(
    private authService: AuthService,
    private initializationService: InitializationService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private router: Router,
  ) {}

  ngOnInit() {
    const url = this.router.url;
    this.isResetMode = url.includes('reset-password');
    this.resetToken = url.substring(url.lastIndexOf('/') + 1);

    this.resetPasswordForm = new FormGroup(
      {
        password: new FormControl('', [Validators.required]),
        repeatPassword: new FormControl('', [Validators.required]),
      },
      { validators: confirmPasswordValidator },
    );
  }

  async resetPassword() {
    if (this.resetPasswordForm.valid) {
      const { password } = this.resetPasswordForm.value;

      this.requestSent = true;

      this.authService.updatePassword(this.resetToken, password).subscribe({
        next: (data) => {
          this.notificationService.showTimedMessage('loginSuccess');
          this.initializationService.loginAuthenticatedUser(data).then(() => {
            this.navigationService.setResetPasswordOverlay(false);
          });
        },
        error: () => {
          this.notificationService.showActionMessage('dataError');
          this.requestSent = false;
        },
      });
    }
  }

  abort() {
    this.router.navigate(['']).then(() => {
      this.navigationService.setResetPasswordOverlay(false);
    });
  }
}
