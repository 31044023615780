import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-store-shift-info',
  standalone: true,
  imports: [
    MatDialogModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatDivider,
    MatTooltip,
  ],
  templateUrl: './store-shift-info.html',
})
export class StoreShiftInfo {
  constructor(public dialogRef: MatDialogRef<StoreShiftInfo>) {}
}
