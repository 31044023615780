import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private http: HttpClient) {}

  login(email: string, password: string): Observable<any> {
    return this.http.post('/api/auth/login', { email, password });
  }

  updatePassword(token: string, password: string): Observable<any> {
    return this.http.post('/api/auth/updatePassword', { token, password });
  }

  resetPassword(email: string): Observable<any> {
    return this.http.post('/api/auth/resetPassword?email=' + email, {});
  }

  resetPasswordExists(token: string): Observable<any> {
    return this.http.get<any>('/api/auth/resetPassword?token=' + token);
  }
}
