<mat-sidenav-container class="navigation__sidenav-container">
  <mat-sidenav
    #sidenav
    [attr.role]="isHandset ? 'dialog' : 'navigation'"
    [mode]="isHandset ? 'over' : 'side'"
    [opened]="isAuthenticated && !isHandset"
    class="navigation__sidenav"
    fixedInViewport
  >
    <div class="inner__sidenav-container">
      <mat-nav-list
        (click)="onMenuItemSelected()"
        (keyup)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
      >
        <a
          [activated]="isActivated(['/dashboard']) || !isAuthenticated"
          class="navbar__header"
          mat-list-item
          routerLink=""
        >
          <img
            alt="logo"
            class="navbar__logo"
            srcset="../../../assets/beeease.svg"
          />
          <span>BeeEase</span>
        </a>
        <ng-container *ngIf="isAuthenticated">
          <ng-container *ngIf="isOwner || isManager; else userPanel">
            <mat-divider></mat-divider>

            <h3 matSubheader>{{ "schedule" | translate }}</h3>

            <mat-list-item
              disabled
              routerLink="today"
              [activated]="isActivated(['/today'])"
            >
              <mat-icon
                aria-label="calendar"
                matListItemIcon
                svgIcon="menu_today"
              ></mat-icon>
              <div matListItemTitle>{{ "today" | translate }}</div>
            </mat-list-item>
            <mat-list-item
              routerLink="calendar"
              [activated]="isActivated(['/calendar'])"
            >
              <mat-icon
                aria-label="today"
                matListItemIcon
                svgIcon="menu_calendar"
              >
              </mat-icon>
              <div matListItemTitle>{{ "calendar" | translate }}</div>
            </mat-list-item>

            <mat-list-item
              routerLink="schedule"
              [activated]="isActivated(['/schedule'])"
            >
              <mat-icon
                aria-label="schedule"
                matListItemIcon
                svgIcon="menu_generation"
              >
              </mat-icon>
              <div matListItemTitle>{{ "generation" | translate }}</div>
            </mat-list-item>

            <mat-divider></mat-divider>

            <h3 matSubheader>{{ "administration" | translate }}</h3>
            <mat-list-item
              routerLink="stores"
              [activated]="isActivated(['/store'])"
            >
              <mat-icon
                aria-label="stores"
                matListItemIcon
                svgIcon="menu_store"
              >
              </mat-icon>
              <div matListItemTitle>{{ "stores" | translate }}</div>
            </mat-list-item>

            <mat-list-item
              routerLink="jobs"
              [activated]="isActivated(['/jobs'])"
            >
              <mat-icon
                aria-label="jobs"
                matListItemIcon
                svgIcon="menu_position"
              ></mat-icon>
              <div matListItemTitle>{{ "jobPositions" | translate }}</div>
            </mat-list-item>

            <mat-list-item
              routerLink="employees"
              [activated]="isActivated(['/employee'])"
            >
              <mat-icon
                aria-label="employees"
                svgIcon="menu_employee"
                matListItemIcon
              ></mat-icon>
              <div matListItemTitle>{{ "employees" | translate }}</div>
            </mat-list-item>

            <mat-divider></mat-divider>
            <h3 matSubheader>{{ "statistics" | translate }}</h3>
            <mat-list-item
              disabled
              routerLink="statistics"
              [activated]="isActivated(['/statistics'])"
            >
              <mat-icon
                aria-label="statistics"
                matListItemIcon
                svgIcon="menu_statistics"
              >
              </mat-icon>
              <div matListItemTitle>{{ "employees" | translate }}</div>
            </mat-list-item>
          </ng-container>

          <ng-template #userPanel>
            <mat-divider></mat-divider>

            <h3 matSubheader>{{ "schedule" | translate }}</h3>
            <mat-list-item
              routerLink="calendar"
              [activated]="isActivated(['/calendar'])"
            >
              <mat-icon
                aria-label="calendar"
                matListItemIcon
                svgIcon="menu_calendar"
              >
              </mat-icon>
              <div matListItemTitle>{{ "calendar" | translate }}</div>
            </mat-list-item>
          </ng-template>

          <ng-container>
            <mat-divider></mat-divider>
            <h3 matSubheader>{{ "about" | translate }}</h3>

            <mat-list-item
              routerLink="versions"
              [activated]="isActivated(['/versions'])"
            >
              <mat-icon
                aria-label="versions"
                matListItemIcon
                svgIcon="menu_version"
              >
              </mat-icon>
              <div matListItemTitle>{{ "versions" | translate }}</div>
            </mat-list-item>
            <mat-list-item
              routerLink="policies"
              [activated]="isActivated(['/policies'])"
            >
              <mat-icon
                aria-label="policies"
                matListItemIcon
                svgIcon="menu_policies"
              >
              </mat-icon>
              <div matListItemTitle>{{ "policies" | translate }}</div>
            </mat-list-item>
          </ng-container>
        </ng-container>
      </mat-nav-list>

      <div class="footer__copyright">
        <a href="https://ikmservices.gr" target="_blank"
          >Powered by
          <span class="ikm__services-link">IKM Services</span> ©2024-{{ year
          }}.</a
        >
        <span>All rights reserved.</span>
        <span>{{ "version" | translate }}: 1.0.0</span>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    [ngClass]="
      isHandset && isSidenavOpened() ? 'navigation__sidenav-mobile' : ''
    "
  >
    <ng-container *ngIf="isAuthenticated; else publicToolbar">
      <mat-toolbar color="primary" class="navigation__toolbar">
        <button (click)="toggleSidenav()" mat-icon-button type="button">
          <mat-icon aria-label="sidenav">menu</mat-icon>
        </button>
        <div class="navigation__title primary-color">
          <span>{{ navigationService.title$ | async }}</span>
        </div>

        <div class="profile__chip-container">
          <mat-chip
            [class.profile__chip-container-activated]="isActivated(['/user'])"
            class="profile__chip-container-activated"
            routerLink="/user"
          >
            <mat-icon aria-label="profile" matChipAvatar>person</mat-icon>
            <span class="username">{{ username }}</span>
          </mat-chip>
        </div>
      </mat-toolbar>
    </ng-container>

    <ng-template #publicToolbar>
      <mat-toolbar
        color="primary"
        class="navigation__toolbar navigation__toolbar-public"
      >
        <span></span>
        <div class="app__name primary-color">
          <span>BeeEase</span>
        </div>

        <button
          mat-icon-button
          type="button"
          class="stroked__icon-button login__button"
          (click)="openLoginDialog()"
          matTooltip="{{ 'signIn' | translate }}"
        >
          <mat-icon
            svgIcon="menu_login_card"
            aria-label="login"
            class="primary-icon"
          ></mat-icon>
        </button>
      </mat-toolbar>
    </ng-template>

    <mat-progress-bar
      *ngIf="navigationService.loading$ | async"
      color="accent"
      mode="query"
    ></mat-progress-bar>
    <mat-progress-bar
      *ngIf="navigationService.sending$ | async"
      color="accent"
      mode="indeterminate"
    ></mat-progress-bar>

    <ng-container *ngIf="systemAnnouncement">
      <app-system-announcement
        [systemAnnouncement]="systemAnnouncement"
      ></app-system-announcement>
    </ng-container>

    <mat-card
      *ngIf="(navigationService.errorLoading$ | async) && isAuthenticated"
      appearance="outlined"
      class="custom__card custom__card-alert"
    >
      <div class="custom__card-body">
        <div class="custom__card-container">
          <div>
            <mat-icon aria-label="error">error</mat-icon>
          </div>
          <div class="custom__card-section">
            <mat-card-header>
              <mat-card-title>{{ "error" | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <p>{{ "errorOccurred" | translate }}</p>
            </mat-card-content>
          </div>
        </div>
      </div>
    </mat-card>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<div
  class="initialize__process-overlay"
  *ngIf="
    (navigationService.initializationProcess$ | async) &&
    !isActivated(['/dashboard'])
  "
>
  <a routerLink="/dashboard" class="overlay__heavy-button">
    <svg
      id="setup"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.88 122.88"
    >
      <g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M101.589,14.7l8.818,8.819c2.321,2.321,2.321,6.118,0,8.439l-7.101,7.101 c1.959,3.658,3.454,7.601,4.405,11.752h9.199c3.283,0,5.969,2.686,5.969,5.968V69.25c0,3.283-2.686,5.969-5.969,5.969h-10.039 c-1.231,4.063-2.992,7.896-5.204,11.418l6.512,6.51c2.321,2.323,2.321,6.12,0,8.44l-8.818,8.819c-2.321,2.32-6.119,2.32-8.439,0 l-7.102-7.102c-3.657,1.96-7.601,3.456-11.753,4.406v9.199c0,3.282-2.685,5.968-5.968,5.968H53.629 c-3.283,0-5.969-2.686-5.969-5.968v-10.039c-4.063-1.232-7.896-2.993-11.417-5.205l-6.511,6.512c-2.323,2.321-6.12,2.321-8.441,0 l-8.818-8.818c-2.321-2.321-2.321-6.118,0-8.439l7.102-7.102c-1.96-3.657-3.456-7.6-4.405-11.751H5.968 C2.686,72.067,0,69.382,0,66.099V53.628c0-3.283,2.686-5.968,5.968-5.968h10.039c1.232-4.063,2.993-7.896,5.204-11.418l-6.511-6.51 c-2.321-2.322-2.321-6.12,0-8.44l8.819-8.819c2.321-2.321,6.118-2.321,8.439,0l7.101,7.101c3.658-1.96,7.601-3.456,11.753-4.406 V5.969C50.812,2.686,53.498,0,56.78,0h12.471c3.282,0,5.968,2.686,5.968,5.969v10.036c4.064,1.231,7.898,2.992,11.422,5.204 l6.507-6.509C95.471,12.379,99.268,12.379,101.589,14.7L101.589,14.7z M61.44,36.92c13.54,0,24.519,10.98,24.519,24.519 c0,13.538-10.979,24.519-24.519,24.519c-13.539,0-24.519-10.98-24.519-24.519C36.921,47.9,47.901,36.92,61.44,36.92L61.44,36.92z"
        />
      </g>
    </svg>
    <span>{{ "installation" | translate }}</span>
  </a>
</div>

<app-cookies></app-cookies>
