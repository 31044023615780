import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '../../model/store/store';
import { CreateStoreRequest } from '../../model/store/create-store-request';
import { EditStoreRequest } from '../../model/store/edit-store-request';
import { StoreLight } from '../../model/store/store-light';
import { StoreSchedule } from '../../model/store/store-schedule';
import { ReorderObjectsRequest } from '../../model/general/reorder-objects-request';

@Injectable({ providedIn: 'root' })
export class StoreService {
  constructor(private http: HttpClient) {}

  getBusinessStores(full: boolean): Observable<Store[] | StoreLight[]> {
    const params = new HttpParams().set('full', full.toString());
    return this.http.get<Store[] | StoreLight[]>('/api/store', {
      params,
    });
  }

  getEmployeeStores(): Observable<StoreLight[]> {
    return this.http.get<StoreLight[]>('/api/store/employee');
  }

  getSchedulerStores(): Observable<StoreSchedule[]> {
    return this.http.get<StoreSchedule[]>('/api/store/scheduler');
  }

  getStore(id: string): Observable<Store> {
    return this.http.get<Store>(`/api/store/${id}`);
  }

  createStore(createStoreRequest: CreateStoreRequest): Observable<Store> {
    return this.http.post<Store>('/api/store', createStoreRequest);
  }

  updateStore(editStoreRequest: EditStoreRequest): Observable<Store> {
    return this.http.put<Store>(
      `/api/store/${editStoreRequest.store.id}`,
      editStoreRequest,
    );
  }

  reorderStores(
    reorderObjectsRequest: ReorderObjectsRequest,
  ): Observable<void> {
    return this.http.post<void>('/api/store/reorder', reorderObjectsRequest);
  }
}
