import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  trackByDay,
  trackByEmployeeLight,
  trackByShift,
} from '../../../../utils/common-utils';
import { LowerCasePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatIconButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { listAnimation } from '../../../../utils/animations';
import { RetrieveCalendarShiftsRequest } from '../../../../model/schedule/shift/retrieve-calendar-shifts-request';
import { CalendarShifts } from '../../../../model/schedule/shift/calendar-shifts';
import { DatepickerService } from '../../../../services/common/datepicker.service';
import { CustomColorService } from '../../../../services/common/custom-color-service';
import { ScheduleExporterService } from '../../../../services/common/schedule-exporter/schedule-exporter-service';
import { NotificationService } from '../../../../services/common/notification.service';
import { ZoomCalendar } from '../zoom/zoom-calendar';

@Component({
  selector: 'app-calendar-table',
  standalone: true,
  imports: [
    MatTableModule,
    MatIconButton,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    NgIf,
    NgFor,
    LowerCasePipe,
    NgClass,
    ZoomCalendar,
  ],
  templateUrl: './calendar-table.html',
  animations: [listAnimation],
})
export class CalendarTable implements OnInit {
  @Input() calendarShifts: CalendarShifts;
  @Input() storeName: string;
  @Input() twelveHourFormat: boolean;
  @Input() singleDayCalendar: boolean;
  @Input() shiftRequest: RetrieveCalendarShiftsRequest;
  @Input() previewMode: boolean;

  displayedColumns: string[];
  selectedWeekIndex: number = 0;
  weekIndexSize: number;

  scheduleExportInProgress: boolean = false;

  @ViewChild('scheduleTable', { static: false }) zoomContainer: ElementRef;

  constructor(
    protected datepickerService: DatepickerService,
    protected customColorService: CustomColorService,
    protected scheduleExporterService: ScheduleExporterService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.weekIndexSize = this.calendarShifts.weeklyAssignedShifts.length;

    this.updateTableView();
  }

  changeWeek(direction: string) {
    if (
      direction === 'next' &&
      this.selectedWeekIndex < this.weekIndexSize - 1
    ) {
      this.selectedWeekIndex++;
    } else if (direction === 'previous' && this.selectedWeekIndex > 0) {
      this.selectedWeekIndex--;
    }

    this.updateTableView();
  }

  updateTableView() {
    this.displayedColumns = [
      'timeRange',
      ...this.calendarShifts.weeklyAssignedShifts[
        this.selectedWeekIndex
      ].assignedShifts.map(
        (_, i) => 'week' + this.selectedWeekIndex + '-day' + i,
      ),
    ];

    this.cdr.detectChanges();
  }

  async exportScheduleToXlsx() {
    this.scheduleExporterService.setScheduleExportInProgress(true);
    try {
      await this.scheduleExporterService.xlsxExport(this.shiftRequest);
    } catch (error) {
      this.notificationService.showErrorMessage('dataError');
    } finally {
      this.scheduleExporterService.setScheduleExportInProgress(false);
    }
  }

  async exportScheduleToPDF() {
    this.scheduleExportInProgress = true;
    this.scheduleExporterService.setScheduleExportInProgress(true);
    try {
      await this.scheduleExporterService.pdfExport(
        this.storeName,
        this.calendarShifts,
        this.selectedWeekIndex,
      );
    } catch (error) {
      console.log('Error while exporting pdf: ', error);
      this.notificationService.showErrorMessage('dataError');
    } finally {
      this.scheduleExportInProgress = false;
      this.scheduleExporterService.setScheduleExportInProgress(false);
    }
  }

  protected readonly trackByShift = trackByShift;
  protected readonly trackByEmployeeLight = trackByEmployeeLight;
  protected readonly trackByDay = trackByDay;
}
