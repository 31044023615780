<ng-container *ngIf="dataLoaded && !errorLoadingData">
  <mat-card class="mat-mdc-card-outlined">
    <mat-stepper
      #stepper
      (selectionChange)="onStepChange($event)"
      [orientation]="(stepperOrientation | async)!"
    >
      <mat-step
        [errorMessage]="getInfoFormError()"
        [stepControl]="storeInfoForm"
      >
        <form [formGroup]="storeInfoForm">
          <ng-template matStepLabel
            >{{ "information" | translate }}
          </ng-template>

          <p></p>
          <mat-form-field class="form__field">
            <mat-label>{{ "storeNaming" | translate }}</mat-label>
            <input
              formControlName="name"
              matInput
              placeholder="{{ 'namingPlaceholder' | translate }}"
            />
            <mat-error
              *ngIf="storeInfoForm.controls['name'].hasError('required')"
              >{{ "nameRequired" | translate }}
            </mat-error>
          </mat-form-field>

          <div class="form__grouped-two-elements">
            <div>
              <p>{{ "shiftsHoursOffsetSameStore" | translate }}:</p>
              <div class="flex__box">
                <mat-form-field>
                  <mat-label>{{ "hoursOffset" | translate }}</mat-label>
                  <input
                    formControlName="shiftsHoursOffset"
                    matInput
                    (keydown)="preventNonNumericInput($event)"
                    onfocusout="this.value = Number(this.value)"
                    placeholder="{{ 'hoursOffsetPlaceholder' | translate }}"
                  />
                </mat-form-field>

                <button
                  mat-icon-button
                  type="button"
                  tabindex="-1"
                  matTooltip="{{ 'shiftsHoursOffsetTooltip' | translate }}"
                >
                  <mat-icon aria-label="info">question_mark</mat-icon>
                </button>
              </div>
            </div>

            <div>
              <p>{{ "shiftsHoursOffsetOtherStores" | translate }}:</p>
              <div class="flex__box">
                <mat-form-field>
                  <mat-label>{{ "hoursOffset" | translate }}</mat-label>
                  <input
                    formControlName="shiftsHoursOffsetOtherStores"
                    matInput
                    (keydown)="preventNonNumericInput($event)"
                    onfocusout="this.value = Number(this.value)"
                    placeholder="{{ 'hoursOffsetPlaceholder' | translate }}"
                  />
                </mat-form-field>

                <button
                  mat-icon-button
                  type="button"
                  tabindex="-1"
                  matTooltip="{{
                    'shiftsHoursOffsetOtherStoresTooltip' | translate
                  }}"
                >
                  <mat-icon aria-label="info">question_mark</mat-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="form__grouped-two-elements">
            <div class="flex__box">
              <mat-slide-toggle formControlName="oneShiftPerDay">
                {{ "oneShiftPerDayPlaceholder" | translate }}
              </mat-slide-toggle>

              <button
                mat-icon-button
                type="button"
                tabindex="-1"
                matTooltip="{{ 'oneShiftPerDayTooltip' | translate }}"
              >
                <mat-icon aria-label="info">question_mark</mat-icon>
              </button>
            </div>

            <div class="flex__box">
              <div class="grouped__radio-box">
                <label class="toggle__label-like"
                  >{{ "startOfWeek" | translate }}:</label
                >
                <mat-radio-group formControlName="startOfWeek" color="primary">
                  <mat-radio-button value="SUNDAY"
                    >{{ "sunday" | translate }}
                  </mat-radio-button>
                  <mat-radio-button value="MONDAY"
                    >{{ "monday" | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <button
                mat-icon-button
                type="button"
                tabindex="-1"
                matTooltip="{{ 'startOfWeekTooltip' | translate }}"
              >
                <mat-icon aria-label="info">question_mark</mat-icon>
              </button>
            </div>
          </div>

          <div class="form__grouped-two-elements">
            <div class="flex__box">
              <mat-form-field>
                <mat-label>{{ "maxJobCountPerShift" | translate }}</mat-label>
                <input
                  formControlName="maxJobCountPerShift"
                  matInput
                  (keydown)="preventNonNumericInput($event)"
                  onfocusout="this.value = this.value ? Number(this.value) : 20"
                  placeholder="{{
                    'maxJobCountPerShiftPlaceholder' | translate
                  }}"
                />
              </mat-form-field>

              <button
                mat-icon-button
                type="button"
                tabindex="-1"
                matTooltip="{{ 'maxJobCountPerShiftTooltip' | translate }}"
              >
                <mat-icon aria-label="info">question_mark</mat-icon>
              </button>
            </div>

            <div class="flex__box">
              <div class="grouped__radio-box">
                <label class="toggle__label-like"
                  >{{ "timeFormat" | translate }}:</label
                >
                <mat-radio-group
                  formControlName="twelveHourFormat"
                  color="primary"
                >
                  <mat-radio-button [value]="true"
                    >{{ "timeFormat12" | translate }}
                  </mat-radio-button>
                  <mat-radio-button [value]="false"
                    >{{ "timeFormat24" | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <button
                mat-icon-button
                type="button"
                tabindex="-1"
                matTooltip="{{ 'timeFormatTooltip' | translate }}"
              >
                <mat-icon aria-label="info">question_mark</mat-icon>
              </button>
            </div>
          </div>
        </form>

        <div class="stepper__step-actions">
          <button disabled mat-icon-button matStepperPrevious>
            <mat-icon aria-label="previous">keyboard_arrow_left</mat-icon>
          </button>
          <button mat-icon-button matStepperNext>
            <mat-icon aria-label="next">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step
        [stepControl]="shiftsErrorForm"
        [errorMessage]="getWeeklyDayShiftsFormError()"
      >
        <ng-template matStepLabel>{{ "shifts" | translate }}</ng-template>

        <p></p>
        <div class="shifts__days-form">
          <mat-button-toggle-group
            [hideSingleSelectionIndicator]="true"
            [(ngModel)]="selectedWeekDay"
          >
            <mat-button-toggle
              *ngFor="let day of weekDays; let i = index"
              [value]="day.order"
            >
              <span class="hide__small-devices"
                >{{ day.name | translate }}</span
              >
              <span class="flex__box-small-screen"
                >{{ day.name | translate | firstLetter }}</span
              >
            </mat-button-toggle>
          </mat-button-toggle-group>

          <table class="shifts__form-table">
            <tbody>
              <ng-container
                *ngFor="
                  let shiftForm of getShiftForms(
                    storeWeekDayShiftsForm[selectedWeekDay]
                  );
                  let i = index
                "
              >
                <tr
                  class="shift__main-row"
                  [ngClass]="isShiftExpanded(i) ? 'expanded__shift-row' : ''"
                >
                  <td
                    class="shift__column-expand"
                    (click)="toggleExpandedShift(i)"
                    (keyup)="$event.stopPropagation()"
                    (keydown)="$event.stopPropagation()"
                  >
                    <button mat-icon-button type="button">
                      <mat-icon aria-label="expand"
                        >{{ isShiftExpanded(i) ? "keyboard_arrow_up" :
                        "keyboard_arrow_down" }}
                      </mat-icon>
                    </button>
                  </td>
                  <td
                    class="shift__column-name"
                    (click)="toggleExpandedShift(i)"
                    (keyup)="$event.stopPropagation()"
                    (keydown)="$event.stopPropagation()"
                  >
                    {{ getShiftName(shiftForm, i) }}
                  </td>
                  <td class="shift__column-delete">
                    <button
                      mat-icon-button
                      type="button"
                      (click)="$event.stopPropagation(); removeShift(i)"
                    >
                      <mat-icon aria-label="delete">delete</mat-icon>
                    </button>
                  </td>
                </tr>
                <tr
                  class="shift__expanded-row"
                  *ngIf="isShiftExpanded(i)"
                  [@expandDownAnimated]
                >
                  <td colspan="3">
                    <form [formGroup]="shiftForm" class="shifts__expanded-form">
                      <div class="shift__hours-container">
                        <div class="group__box-header">
                          {{ "workingHours" | translate }}
                        </div>
                        <div class="form__grouped-two-elements zero__padding">
                          <mat-form-field>
                            <mat-label
                              >{{ "startTime" | translate }}
                            </mat-label>
                            <ngx-mat-timepicker
                              [cancelBtnTmpl]="timePickerCancelButton"
                              [confirmBtnTmpl]="timePickerSetButton"
                              #startTimePicker
                            ></ngx-mat-timepicker>
                            <input
                              readonly
                              formControlName="startTime"
                              matInput
                              [ngxMatTimepicker]="startTimePicker"
                              [format]="store.twelveHourFormat ? 12 : 24"
                              placeholder="{{
                                'startTimePlaceholder' | translate
                              }}"
                            />
                            <mat-icon
                              aria-label="schedule"
                              matSuffix
                              (click)="startTimePicker.open()"
                              (keyup)="$event.stopPropagation()"
                              (keydown)="$event.stopPropagation()"
                              >schedule
                            </mat-icon>
                            <mat-error
                              *ngIf="
                                shiftForm.get('startTime')?.touched &&
                                shiftForm
                                  .get('startTime')
                                  ?.hasError('invalidTime')
                              "
                            >
                              {{ "startTimeRequired" | translate }}
                            </mat-error>
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>{{ "endTime" | translate }}</mat-label>
                            <ngx-mat-timepicker
                              [cancelBtnTmpl]="timePickerCancelButton"
                              [confirmBtnTmpl]="timePickerSetButton"
                              #endTimePicker
                            ></ngx-mat-timepicker>
                            <input
                              formControlName="endTime"
                              matInput
                              [ngxMatTimepicker]="endTimePicker"
                              [format]="store.twelveHourFormat ? 12 : 24"
                              placeholder="{{
                                'endTimePlaceholder' | translate
                              }}"
                            />
                            <mat-icon
                              aria-label="scheduler"
                              matSuffix
                              (click)="endTimePicker.open()"
                              (keyup)="$event.stopPropagation()"
                              (keydown)="$event.stopPropagation()"
                              >schedule
                            </mat-icon>
                            <mat-error
                              *ngIf="
                                shiftForm.get('endTime')?.touched &&
                                shiftForm
                                  .get('endTime')
                                  ?.hasError('invalidTime')
                              "
                            >
                              {{ "endTimeRequired" | translate }}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="form__grouped-two-elements">
                        <div class="flex__box">
                          <mat-slide-toggle formControlName="hasBreakIncluded">
                            {{ "hasBreakIncludedPlaceholder" | translate }}
                          </mat-slide-toggle>

                          <button
                            mat-icon-button
                            type="button"
                            tabindex="-1"
                            matTooltip="{{
                              'hasBreakIncludedTooltip' | translate
                            }}"
                          >
                            <mat-icon aria-label="info">question_mark</mat-icon>
                          </button>
                        </div>

                        <mat-form-field>
                          <mat-label
                            >{{ "breakDurationInMinutes" | translate }}
                          </mat-label>
                          <input
                            formControlName="breakMinutesDuration"
                            matInput
                            (keydown)="preventNonNumericInput($event)"
                            (focusout)="
                              onBreakMinutesDurationFocusOut($event, shiftForm)
                            "
                            placeholder="{{
                              'breakDurationPlaceholder' | translate
                            }}"
                          />
                          <mat-icon aria-label="break" matSuffix
                            >restaurant
                          </mat-icon>
                        </mat-form-field>
                      </div>

                      <div
                        class="job__positions-container"
                        formArrayName="jobPositions"
                      >
                        <div class="group__box-header">
                          {{ "shiftJobPositions" | translate }}
                          <button
                            (click)="openJobPositionsInfo()"
                            mat-icon-button
                            type="button"
                          >
                            <mat-icon aria-label="info">info</mat-icon>
                          </button>
                        </div>

                        <div
                          *ngFor="
                            let jobPosition of getJobPositions(shiftForm)
                              .controls;
                            let j = index
                          "
                          [formGroupName]="j"
                          class="job__position-form"
                        >
                          <div class="job__position-form-info">
                            <label [style.color]="getPositionColor(jobPosition)"
                              >{{ jobPosition.get("name")?.value }}</label
                            >
                            <label>{{ jobPosition.get("count")?.value }}</label>
                          </div>
                          <mat-slider
                            min="0"
                            [max]="store.maxJobCountPerShift"
                            step="1"
                            showTickMarks
                            discrete
                          >
                            <input formControlName="count" matSliderThumb />
                          </mat-slider>
                        </div>
                      </div>
                    </form>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>

          <ng-container *ngIf="jobPositions.length > 0; else addShiftDisabled">
            <button mat-raised-button (click)="addShift()">
              {{ "addShift" | translate }}
            </button>
          </ng-container>

          <ng-template #addShiftDisabled>
            <button
              mat-raised-button
              matTooltip="{{ 'addShiftDisabledTooltip' | translate }}"
            >
              {{ "addShift" | translate }}
            </button>
          </ng-template>
        </div>

        <div class="stepper__step-actions">
          <button mat-icon-button matStepperPrevious>
            <mat-icon aria-label="previous">keyboard_arrow_left</mat-icon>
          </button>
          <button mat-icon-button matStepperNext>
            <mat-icon aria-label="next">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step
        [errorMessage]="getWeeklyShiftNamesError()"
        [optional]="storeWeeklyShiftNamesForm.get('doNotSetNames')?.value"
        [stepControl]="storeWeeklyShiftNamesForm"
      >
        <form [formGroup]="storeWeeklyShiftNamesForm">
          <ng-template matStepLabel
            >{{ "storeWeeklyShiftNamings" | translate }}
          </ng-template>

          <p></p>
          <div class="form__grouped-two-elements form__divider-after">
            <div class="flex__box">
              <mat-slide-toggle formControlName="doNotSetNames">
                {{ "storeWeeklyShiftNamingsPlaceholder" | translate }}
              </mat-slide-toggle>

              <button
                mat-icon-button
                type="button"
                tabindex="-1"
                matTooltip="{{ 'storeWeeklyShiftNamingsTooltip' | translate }}"
              >
                <mat-icon aria-label="info">question_mark</mat-icon>
              </button>
            </div>
          </div>

          <mat-divider
            [ngClass]="
              storeWeeklyShiftNamesForm.get('doNotSetNames')?.value === false
                ? ''
                : 'lower__opacity'
            "
          ></mat-divider>

          <div formArrayName="shiftNames">
            <div class="shift__names-form-input form__divider-before">
              <mat-form-field
                *ngFor="
                  let shiftName of getWeeklyShiftNamesForm().controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <mat-label>{{ shiftName.get("timeRange")?.value }}</mat-label>
                <input
                  formControlName="name"
                  matInput
                  placeholder="{{ 'namingPlaceholder' | translate }}"
                />
                <mat-error
                  *ngIf="
                    shiftName.get('name')?.touched &&
                    shiftName.get('name')?.hasError('required')
                  "
                >
                  {{ "namingRequired" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="stepper__step-actions">
            <button mat-icon-button matStepperPrevious>
              <mat-icon aria-label="previous">keyboard_arrow_left</mat-icon>
            </button>
            <button mat-icon-button matStepperNext>
              <mat-icon aria-label="next">keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="storeEmployeesForm" optional>
        <form [formGroup]="storeEmployeesForm" class="store__employees-form">
          <ng-template matStepLabel>{{ "employees" | translate }}</ng-template>

          <p></p>
          <mat-form-field class="form__field zero__padding">
            <mat-label>{{ "businessEmployees" | translate }}</mat-label>
            <mat-select
              [compareWith]="compareEmployees"
              formControlName="selectedEmployees"
              multiple
              placeholder="{{ 'selectEmployeesPlaceholder' | translate }}"
            >
              <mat-select-trigger>
                {{ selectedEmployeeIds.length }} {{ "selectedEmployees" |
                translate }}
              </mat-select-trigger>
              <mat-option>
                <ngx-mat-select-search
                  formControlName="selectedEmployeesSearch"
                  noEntriesFoundLabel="{{ 'noResults' | translate }}"
                  placeholderLabel="{{ 'searchName' | translate }}"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let employee of filteredEmployees"
                [value]="employee"
              >
                {{ employee.firstName }} {{ employee.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <ng-container
            *ngIf="selectedEmployeeIds.length === 0; else showEmployees"
          >
            <div class="light__text">
              {{ "jobPositionEmployeesNotRequired" | translate }}
            </div>
          </ng-container>

          <ng-template #showEmployees>
            <div
              (click)="showSelectedEmployees = !showSelectedEmployees"
              (keydown.enter)="showSelectedEmployees = !showSelectedEmployees"
              class="aligned__div light__text on__hover"
              tabindex="0"
            >
              {{ "showEmployeeNames" | translate }}
              <mat-icon aria-label="expand"
                >{{ showSelectedEmployees ? "arrow_drop_up" : "arrow_drop_down"
                }}
              </mat-icon>
            </div>
            <div *ngIf="showSelectedEmployees" class="light__text no__hover">
              {{ getSelectedEmployeeNames() }}
            </div>
          </ng-template>
        </form>

        <div class="stepper__step-actions">
          <button mat-icon-button matStepperPrevious>
            <mat-icon aria-label="previous">keyboard_arrow_left</mat-icon>
          </button>
          <button disabled mat-icon-button matStepperNext>
            <mat-icon aria-label="next">keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step
        *ngIf="editMode"
        label="Coming Soon.."
        state="delete"
      ></mat-step>
      <ng-template matStepperIcon="delete">
        <mat-icon aria-label="delete">delete</mat-icon>
      </ng-template>
    </mat-stepper>
  </mat-card>

  <mat-card class="form__actions-waved">
    <mat-card-content class="separate__container-actions">
      <button (click)="abort()" [disabled]="requestSent" mat-stroked-button>
        {{ "cancel" | translate }}
      </button>

      <button
        (click)="saveStore()"
        [disabled]="
          storeInfoForm.invalid ||
          isWeeklyDayShiftsFormInvalid() ||
          storeWeeklyShiftNamesForm.invalid ||
          requestSent ||
          !(
            storeInfoForm.dirty ||
            storeEmployeesForm.dirty ||
            isWeeklyDayShiftsFormDirty() ||
            storeWeeklyShiftNamesForm.dirty
          )
        "
        mat-flat-button
      >
        <mat-spinner *ngIf="requestSent" diameter="20"></mat-spinner>
        <span *ngIf="!requestSent">{{ "save" | translate }} </span>
        <mat-icon aria-label="save" *ngIf="!requestSent">save</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <ng-template #timePickerCancelButton>
    <button mat-stroked-button style="margin-right: 10px">
      {{ "cancel" | translate }}
    </button>
  </ng-template>
  <ng-template #timePickerSetButton>
    <button mat-flat-button>{{ "save" | translate }}</button>
  </ng-template>
</ng-container>
