import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-disabled-employees-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatListModule,
    TranslateModule,
    NgIf,
    NgFor,
    MatButtonModule,
  ],
  templateUrl: './disabled-employees-dialog.html',
})
export class DisabledEmployeesDialog {
  affectedEmployees: string[];

  editedJobPosition: boolean = false;
  removedJobPosition: boolean = false;
  editedStore: boolean = false;
  deletedStore: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DisabledEmployeesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.affectedEmployees = this.data.affectedEmployees;
    this.editedJobPosition = this.data.editedJobPosition || false;
    this.removedJobPosition = this.data.removedJobPosition || false;
    this.editedStore = this.data.editedStore || false;
    this.deletedStore = this.data.deletedStore || false;
  }
}
