import { Injectable } from '@angular/core';
import { InitializationService } from '../../services/core/initialization-service';

@Injectable({ providedIn: 'root' })
export class OwnerGuard {
  constructor(private initializationService: InitializationService) {}

  canActivate(): boolean {
    if (this.initializationService.isAuthenticatedUserInRoles(['OWNER'])) {
      return true;
    }
    this.initializationService.logoutAuthenticatedUser(true).then(() => {});
    return false;
  }
}
