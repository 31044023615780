import { Component, Inject, OnInit } from '@angular/core';
import * as jsonData from '../../../../assets/config.json';
import { EmployeeLight } from '../../../model/employee/employee-light';
import { JobPosition } from '../../../model/job-position/job-position';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { NotificationService } from '../../../services/common/notification.service';
import { JobPositionService } from '../../../services/crud/job-position.service';
import { ConfirmationDialog } from '../../shared/confirmation-dialog/confirmation-dialog';
import { checkForChanges, compareEmployees } from '../../../utils/common-utils';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { EditJobPositionRequest } from '../../../model/job-position/edit-job-position-request';
import { CreateJobPositionRequest } from '../../../model/job-position/create-job-position-request';
import { startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';
import { DisabledEmployeesDialog } from '../../shared/disabled-employees-dialog/disabled-employees-dialog';
import { MatDivider } from '@angular/material/divider';
import { PreferredColorOption } from '../../../model/app/preferred-color-option';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-job-position-form-dialog',
  standalone: true,
  imports: [
    TranslateModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatProgressSpinner,
    MatButtonModule,
    NgIf,
    NgFor,
    MatDivider,
    MatTooltip,
  ],
  templateUrl: './job-position-form-dialog.html',
})
export class JobPositionFormDialog implements OnInit {
  readonly configProperties: any = jsonData;
  readonly preferredColorOptions: PreferredColorOption[] =
    this.configProperties.preferredColorOptions;

  editMode: boolean = false;
  requestSent: boolean = false;

  initialEmployees: EmployeeLight[] = [];
  filteredEmployees: EmployeeLight[] = [];
  selectedEmployeeIds: string[] = [];
  showSelectedEmployees: boolean = false;

  initialName: string = '';

  jobPosition: JobPosition;

  employees: EmployeeLight[] = [];
  jobPositionForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<JobPositionFormDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private jobPositionService: JobPositionService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.editMode = this.data.editMode;

    this.employees = this.data.employees;
    this.filteredEmployees = [...this.employees];

    this.jobPosition = this.editMode
      ? this.data.jobPosition
      : {
          name: '',
          employees: [],
          orderIndex: this.data.listLength + 1,
          preferredColor: this.preferredColorOptions[0].name,
        };

    if (this.data.editMode) {
      this.initialEmployees = [...(this.jobPosition.employees ?? [])];
      this.selectedEmployeeIds = this.initialEmployees.map(
        (employee) => employee.id,
      );
      this.initialName = this.jobPosition.name ?? '';
    }

    this.jobPositionForm = new FormGroup({
      name: new FormControl(this.jobPosition.name, Validators.required),
      selectedEmployees: new FormControl(this.jobPosition.employees),
      selectedEmployeesSearch: new FormControl(''),
      preferredColor: new FormControl(this.jobPosition.preferredColor),
    });

    this.jobPositionForm.valueChanges.subscribe((value) => {
      this.jobPosition.name = value.name ?? '';
      this.jobPosition.employees = value.selectedEmployees ?? [];
      this.jobPosition.preferredColor = value.preferredColor;
    });

    this.jobPositionForm.controls['selectedEmployeesSearch'].valueChanges
      .pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter(name) : this.employees.slice())),
      )
      .subscribe((filteredEmployees) => {
        this.filteredEmployees = filteredEmployees;
      });

    this.jobPositionForm.controls['selectedEmployees'].valueChanges.subscribe(
      (selectedEmployeeIds) => {
        this.selectedEmployeeIds = selectedEmployeeIds;
      },
    );
  }

  saveJobPosition() {
    if (this.jobPositionForm.valid) {
      this.requestSent = true;
      this.notificationService.closeMessages();

      let request;
      let jobPositionObservable;

      if (this.editMode) {
        let {
          addedList: addedEmployees,
          removedList: removedEmployees,
          updated: updatedEmployees,
        } = checkForChanges(
          this.initialEmployees,
          this.jobPositionForm.value.selectedEmployees,
        );

        request = {
          jobPosition: JSON.parse(JSON.stringify(this.jobPosition)),
          updatedName: this.initialName !== this.jobPosition.name,
          updatedEmployees: updatedEmployees,
          addedEmployees: addedEmployees,
          removedEmployees: removedEmployees,
        } as EditJobPositionRequest;

        jobPositionObservable =
          this.jobPositionService.updateJobPosition(request);
      } else {
        request = {
          jobPosition: JSON.parse(JSON.stringify(this.jobPosition)),
        } as CreateJobPositionRequest;

        jobPositionObservable =
          this.jobPositionService.createJobPosition(request);
      }

      jobPositionObservable.subscribe({
        next: (data) => {
          if (data.affectedEmployees && data.affectedEmployees.length > 0) {
            const disabledEmployeesDialogRef = this.dialog.open(
              DisabledEmployeesDialog,
              {
                data: {
                  affectedEmployees: data.affectedEmployees,
                  editedJobPosition: this.editMode,
                },
              },
            );

            disabledEmployeesDialogRef.afterClosed().subscribe(() => {
              this.handleSuccessfulSave(data);
            });
          } else {
            this.handleSuccessfulSave(data);
          }
        },
        error: (err) => {
          if (err.status === 409) {
            this.notificationService.showActionMessage('jobPositionNameExists');
          } else if (err.status === 404) {
            this.notificationService.showActionMessage('jobPositionNotFound');
          } else if (err.status === 425) {
            this.notificationService.showActionMessage(
              'requestFailed_SchedulerInProgress',
            );
          } else {
            this.notificationService.showActionMessage('dataError');
          }
          this.requestSent = false;
        },
      });
    }
  }

  abort() {
    if (this.jobPositionForm.dirty) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: { dialogContentKey: 'cancelActionConfirmation' },
        enterAnimationDuration: 0,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  deleteJobPosition() {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: { dialogContentKey: 'deleteJobPositionConfirmation' },
      enterAnimationDuration: 0,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.requestSent = true;
        this.jobPositionService
          .deleteJobPosition(this.jobPosition.id ?? 'default')
          .subscribe({
            next: (affectedEmployees) => {
              if (affectedEmployees && affectedEmployees.length > 0) {
                const disabledEmployeesDialogRef = this.dialog.open(
                  DisabledEmployeesDialog,
                  {
                    data: {
                      affectedEmployees: affectedEmployees,
                      removedJobPosition: true,
                    },
                  },
                );

                disabledEmployeesDialogRef.afterClosed().subscribe(() => {
                  this.handleSuccessfulDelete();
                });
              } else {
                this.handleSuccessfulDelete();
              }
            },
            error: (err) => {
              if (err.status === 425) {
                this.notificationService.showActionMessage(
                  'requestFailed_SchedulerInProgress',
                );
              } else {
                this.notificationService.showActionMessage('dataError');
              }

              this.requestSent = false;
            },
          });
      }
    });
  }

  getSelectedEmployeeNames(): string {
    return this.jobPositionForm.controls['selectedEmployees'].value
      .map(
        (employee: EmployeeLight) =>
          employee.firstName + ' ' + employee.lastName,
      )
      .join(', ');
  }

  private handleSuccessfulSave(data: JobPosition) {
    this.notificationService.showTimedMessage(
      this.editMode ? 'jobPositionUpdated' : 'jobPositionAdded',
    );

    this.dialogRef.close(data);
  }

  private handleSuccessfulDelete() {
    this.notificationService.showTimedMessage('jobPositionDeleted');
    this.dialogRef.close('deleted');
  }

  private _filter(name: string): EmployeeLight[] {
    const filterValue = name.toLowerCase();

    return this.employees.filter((employee) =>
      (employee.firstName + ' ' + employee.lastName)
        .toLowerCase()
        .includes(filterValue),
    );
  }

  protected readonly compareEmployees = compareEmployees;
}
