import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ChipSelection } from '../../../../model/app/chip-selection';
import * as jsonData from '../../../../../assets/config.json';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { trackByChip } from '../../../../utils/common-utils';
import { FormsModule } from '@angular/forms';
import { CalendarShifts } from '../../../../model/schedule/shift/calendar-shifts';
import { RetrieveCalendarShiftsRequest } from '../../../../model/schedule/shift/retrieve-calendar-shifts-request';
import { DatepickerService } from '../../../../services/common/datepicker.service';
import { ScheduleExporterService } from '../../../../services/common/schedule-exporter/schedule-exporter-service';
import { CalendarTable } from '../../../calendar/views/table/calendar-table';
import { Subscription } from 'rxjs';
import { CalendarList } from '../../../calendar/views/list/calendar-list/calendar-list';
import { MatDivider } from '@angular/material/divider';
import { Spinner } from '../../../shared/spinner/spinner';

@Component({
  selector: 'app-preview-calendar',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatIcon,
    MatIconButton,
    TranslateModule,
    MatTooltip,
    MatButtonToggleModule,
    NgForOf,
    FormsModule,
    NgIf,
    CalendarTable,
    CalendarList,
    MatDivider,
    Spinner,
    NgClass,
  ],
  templateUrl: './preview-calendar-dialog.html',
})
export class PreviewCalendarDialog implements OnInit, OnDestroy {
  readonly configProperties: any = jsonData;
  readonly calendarLayoutOptions: ChipSelection[] =
    this.configProperties.calendarLayoutOptions;

  preferredUserLayout: string;
  calendarShifts: CalendarShifts;
  storeName: string;
  twelveHourFormat: boolean;
  singleDayCalendar: boolean;
  shiftRequest: RetrieveCalendarShiftsRequest;

  scheduleExportInProgress: boolean = false;
  private scheduleExportSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PreviewCalendarDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected datepickerService: DatepickerService,
    private scheduleExporterService: ScheduleExporterService,
  ) {
    this.preferredUserLayout = data.preferredUserLayout;
    this.calendarShifts = data.calendarShifts;
    this.storeName = data.storeName;
    this.twelveHourFormat = data.twelveHourFormat;
    this.singleDayCalendar = data.singleDayCalendar;
    this.shiftRequest = data.shiftRequest;
  }

  ngOnInit() {
    this.scheduleExportSubscription = this.scheduleExporterService
      .getScheduleExportInProgress()
      .subscribe((status) => (this.scheduleExportInProgress = status));
  }

  ngOnDestroy() {
    this.scheduleExportSubscription.unsubscribe();
  }

  protected readonly trackByChip = trackByChip;
}
