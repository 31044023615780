<div class="flex__box dialog__header" mat-dialog-title>
  <span></span>
  <div class="dialog__header-actions">
    <button
      (click)="dialogRef.close()"
      class="stroked__icon-button"
      mat-icon-button
      type="button"
      matTooltip="{{ 'close' | translate }}"
    >
      <mat-icon aria-label="full screen off">close_fullscreen</mat-icon>
    </button>
  </div>
</div>

<div class="dashboard__chart-dialog">
  <canvas
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [legend]="lineChartLegend"
    [options]="lineChartOptions"
    [type]="lineChartType"
    baseChart
  >
  </canvas>
</div>
