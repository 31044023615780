import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { TermsEn } from '../terms-and-conditions/languages/en/terms-en/terms-en';

@Component({
  selector: 'app-policies',
  standalone: true,
  imports: [MatTabsModule, TranslateModule, MatCardModule, TermsEn],
  templateUrl: './policies.html',
})
export class Policies {}
