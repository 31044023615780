import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavbarStatusService {
  private navbarOpen: boolean = false;

  setNavbarOpen(status: boolean) {
    this.navbarOpen = status;
  }

  getNavbarOpen(): boolean {
    return this.navbarOpen;
  }
}
