import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenerateScheduleRequest } from '../../model/schedule/generator/generate-schedule-request';
import { GenerateScheduleResponse } from '../../model/schedule/generator/generate-schedule-response';
import { FinalizeScheduleRequest } from '../../model/schedule/generator/finalize-schedule-request';

@Injectable({ providedIn: 'root' })
export class ScheduleGeneratorService {
  constructor(private http: HttpClient) {}

  generateSchedule(
    generateScheduleRequest: GenerateScheduleRequest,
  ): Observable<GenerateScheduleResponse> {
    return this.http.post<GenerateScheduleResponse>(
      '/api/schedule/generate',
      generateScheduleRequest,
    );
  }

  getScheduleStatus(
    scheduleProblemId: string,
    slowSolver: boolean,
  ): Observable<GenerateScheduleResponse> {
    const params = new HttpParams()
      .set('id', scheduleProblemId)
      .set('slowSolver', String(slowSolver));

    return this.http.get<GenerateScheduleResponse>('/api/schedule/generate', {
      params: params,
    });
  }

  retryScheduleGeneration(
    generateScheduleRequest: GenerateScheduleRequest,
  ): Observable<GenerateScheduleResponse> {
    return this.http.post<GenerateScheduleResponse>(
      '/api/schedule/generate/retry',
      generateScheduleRequest,
    );
  }

  finalizeSchedule(
    finalizeScheduleRequest: FinalizeScheduleRequest,
  ): Observable<any> {
    return this.http.put<any>(
      '/api/schedule/generate/finalize',
      finalizeScheduleRequest,
    );
  }

  deleteScheduleJob(
    scheduleProblemId: string,
    slowSolver: boolean,
  ): Observable<any> {
    const params = new HttpParams()
      .set('id', scheduleProblemId)
      .set('slowSolver', String(slowSolver));
    return this.http.delete<any>(`/api/schedule/generate`, {
      params: params,
    });
  }
}
