import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconsLoader {
  constructor(
    private matIconReg: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
    this.loadMiscIcons();
    this.loadMenuIcons();
    this.loadEmployeeIcons();
    this.loadScheduleIcons();
    this.loadNumbersIcons();
  }

  loadMiscIcons() {
    this.matIconReg.addSvgIcon(
      'xlsx',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/xlsx.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'pdf',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/pdf.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'dynamic_edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/dynamic_edit.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'calendar_plain',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/calendar_plain.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'edit_pencil',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/edit_pencil.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'reorder_cards',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/reorder_cards.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'cards_layout',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/cards_layout.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'eye_preview',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/eye_preview.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'logout_plain',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/logout_plain.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'checkmark',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/checkmark.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'group',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/group.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'shift_fireball',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/shift_fireball.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'edit_history',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/edit_history.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'zoom_in',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/zoom_in.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'zoom_out',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/zoom_out.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'next_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/next_circle.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'previous_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/previous_circle.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'clock_history',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/clock_history.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'circle_ok',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/circle_ok.svg',
      ),
    );
  }

  loadMenuIcons() {
    this.matIconReg.addSvgIcon(
      'menu_employee',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/menu/employee.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'menu_store',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/menu/store.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'menu_position',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/menu/position.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'menu_calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/menu/calendar.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'menu_today',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/menu/today.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'menu_generation',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/menu/generation.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'menu_statistics',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/menu/statistics.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'menu_login_card',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/menu/login_card.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'menu_shift',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/menu/shift.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'menu_version',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/menu/version.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'menu_policies',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/menu/policies.svg',
      ),
    );
  }

  loadEmployeeIcons() {
    this.matIconReg.addSvgIcon(
      'employee_avatar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/employee/avatar.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'employee_access',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/employee/access.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'employee_no_access',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/employee/no_access.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'employee_creation',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/employee/creation.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'employee_edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/employee/edit.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'employee_contract',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/employee/contract.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'employee_enabled',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/employee/enabled.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'employee_pairings',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/employee/pairings.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'employee_keychain',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/employee/keychain.svg',
      ),
    );
  }

  loadScheduleIcons() {
    this.matIconReg.addSvgIcon(
      'schedule_generate',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/schedule/generate.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'schedule_regenerate',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/schedule/regenerate.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'schedule_cancel',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/schedule/cancel.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'schedule_save',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/schedule/save.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'schedule_stars',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/schedule/stars.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'schedule_swapped',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/schedule/swapped.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'schedule_person',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/schedule/person.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'schedule_violations',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/schedule/violations.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'schedule_heart',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/schedule/heart.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'schedule_pairing_separate',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/schedule/pairing_separate.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'schedule_employee_history',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/schedule/employee_history.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'schedule_pairing_rotate',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/schedule/pairing_rotate.svg',
      ),
    );
  }

  loadNumbersIcons() {
    this.matIconReg.addSvgIcon(
      'roman_one',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/numbers/roman_one.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'roman_two',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/numbers/roman_two.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'roman_three',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/numbers/roman_three.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'roman_four',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/numbers/roman_four.svg',
      ),
    );
    this.matIconReg.addSvgIcon(
      'roman_five',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg/icons/numbers/roman_five.svg',
      ),
    );
  }
}
