import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ChartConfiguration, ChartType } from 'chart.js';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { BaseChartDirective } from 'ng2-charts';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-chart-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    BaseChartDirective,
    MatTooltip,
    TranslateModule,
  ],
  templateUrl: './chart-dialog.html',
})
export class ChartDialog {
  public lineChartOptions: ChartConfiguration['options'] = {};
  public lineChartLabels: string[] = [];
  public lineChartType: ChartType;
  public lineChartLegend: boolean;
  public lineChartData: any[] = [];

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  constructor(
    public dialogRef: MatDialogRef<ChartDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.lineChartOptions = data.lineChartOptions;
    this.lineChartLabels = data.lineChartLabels;
    this.lineChartType = data.lineChartType;
    this.lineChartLegend = data.lineChartLegend;
    this.lineChartData = data.lineChartData;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.chart?.render();
  }
}
