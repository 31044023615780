import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-shift-violations-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    TranslateModule,
    MatIconModule,
    MatIconButton,
    NgFor,
    MatTooltip,
  ],
  templateUrl: './shift-violations-dialog.html',
})
export class ShiftViolationsDialog {
  violations: string[];

  constructor(
    public dialogRef: MatDialogRef<ShiftViolationsDialog>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.violations = data.violations;
  }
}
