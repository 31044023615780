import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemAnnouncement } from '../../model/system/system-announcement';

@Injectable({ providedIn: 'root' })
export class SystemAnnouncementService {
  constructor(private http: HttpClient) {}

  getLatestAnnouncement(): Observable<SystemAnnouncement> {
    return this.http.get<SystemAnnouncement>('/api/system/announcement');
  }
}
